import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { Flex } from '@/separatedModules/components';

interface DateAndTimePickerProps {
  value: {
    date: Dayjs;
    time: Dayjs;
  };
  onChange: (date: Dayjs, time: Dayjs) => void;
  minDate: Dayjs;
  maxDate: Dayjs;
  displayText: string;
  minTime: Dayjs;
  label?: string;
}

export const DateAndTimePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  displayText,
  minTime: initialMinTime,
  label
}: DateAndTimePickerProps) => {
  return (
    <>
      <Flex sx={{ padding: '0px 16px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label || 'Start date'}
            value={value.date}
            onChange={(newDate) => onChange(newDate!, value.time)}
            sx={{ width: '288px', marginTop: '16px', marginRight: '16px' }}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{
              textField: {
                helperText: 'MM/DD/YYYY'
              }
            }}
          />
          <TimePicker
            label={label || 'Start time'}
            value={value.time}
            onChange={(newTime) => onChange(value.date, newTime!)}
            {...(value.date.isSame(dayjs(), 'day') && { minTime: initialMinTime })}
            sx={{
              width: '288px'
            }}
            slotProps={{
              textField: {
                helperText: 'HH:MM'
              },
              popper: {
                sx: { '& .MuiList-root': { '::after': { display: 'none' } } }
              }
            }}
          />
        </LocalizationProvider>
      </Flex>
      <Text variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)', padding: '0px 16px', marginTop: '20px' }}>
        {displayText}
      </Text>
    </>
  );
};
