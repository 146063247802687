import classNames from 'classnames';

import { Table, TableContainer } from '@mui/material';

import { TableHeader } from './components/TableHead/TableHeader';
import { TableWrapper } from './components/TableWrapper';

export const Step1 = () => {
  return (
    <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed', overflow: 'hidden' }}>
      <Table
        className={classNames('mt-0', {
          [`MuiTable-root__columns-custom-${3}`]: 3
        })}
      >
        <TableHeader />
        <TableWrapper />
      </Table>
    </TableContainer>
  );
};
