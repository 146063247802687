import { Box, Checkbox } from '@mui/material';

import { ChevronLeftIcon, ChevronRightIcon } from '@/separatedModules/components';

import { LocationItem } from '../../helpers';

interface LocationOptionProps {
  option: LocationItem;
  history: any[];
  onToggleLocation: (id: string) => void;
  onGoDeeper: (option: LocationItem) => void;
  onGoBack: () => void;
}

export const LocationOption = ({ option, history, onToggleLocation, onGoDeeper, onGoBack }: LocationOptionProps) => {
  if (option.value === '__folder__') {
    return (
      <li
        onClick={(e) => e.stopPropagation()}
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#000',
          borderBottom: '1px solid #ccc',
          padding: '8px 16px'
        }}
      >
        {history.length > 0 && (
          <ChevronLeftIcon
            onClick={(e) => {
              e.stopPropagation();
              onGoBack();
            }}
            sx={{ cursor: 'pointer', marginRight: '8px' }}
          />
        )}
        <span
          style={{
            fontWeight: 500
          }}
        >
          {option.label}
        </span>
      </li>
    );
  } else {
    const hasChildren = option.children.length > 0;
    return (
      <li
        onClick={(e) => e.stopPropagation()}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#000',
          padding: '8px 16px'
        }}
      >
        <Box>
          <Checkbox
            checked={option.checked}
            onChange={(event) => {
              event.stopPropagation();
              onToggleLocation(option.value);
            }}
            indeterminate={option.indeterminate}
          />
          <span>{option.label}</span>
        </Box>
        {hasChildren && (
          <ChevronRightIcon
            onClick={(e) => {
              e.stopPropagation();
              onGoDeeper(option);
            }}
            sx={{ cursor: 'pointer' }}
          />
        )}
      </li>
    );
  }
};
