import { ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

import { EquipmentRes } from '@/logicLayers/domain';

import { ConfigTableI, DomainStatusChip, EMPTY_CELL_VALUE } from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { TranslationT } from '@/subsidiaryBinders/types';

import { i18n } from '@/i18n';

import { PopoverLink } from './components/LocationsPopoverLink';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT) => ReactNode;
}

export const CONFIG_TABLE: ConfigTableI<EquipmentRes> = {
  cells: [
    {
      order: 0,
      columnName: 'equipment-name',
      columnClass: 'MuiTableCell-equipment-name',
      title: i18n.t('allEquipment.table.cells.name', { ns: 'equipment' }),
      Render: ({ name, itemDescription }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            <Box sx={{ margin: '0px' }}>
              {name ? (
                <Text
                  className={'m-0'}
                  variant={'titleSm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {name}
                </Text>
              ) : null}

              {itemDescription ? (
                <Text
                  className={'m-0'}
                  variant={'bodySm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {itemDescription}
                </Text>
              ) : (
                EMPTY_CELL_VALUE
              )}
            </Box>
          </TableCell>
        );
      }
    },
    {
      order: 1,
      columnName: 'equipment-system-name',
      columnClass: 'MuiTableCell-equipment-system-name',
      title: i18n.t('allEquipment.table.cells.systemName', { ns: 'equipment' }),
      Render: ({ name, systemName, id }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            <PopoverLink systemName={systemName} id={id || ''} />
          </TableCell>
        );
      }
    },
    {
      order: 2,
      columnName: 'equipment-domain',
      columnClass: 'MuiTableCell-equipment-domain',
      title: i18n.t('allEquipment.table.cells.domain', { ns: 'equipment' }),
      Render: ({ name, domain }, className, index) => (
        <TableCell key={name + index} className={className}>
          {domain?.id ? <DomainStatusChip color={domain?.color} label={domain.name} /> : EMPTY_CELL_VALUE}
        </TableCell>
      )
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
