import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { StatusesTypesList } from '@/separatedModules/pages/Tasks/pages/TasksCustom/helpers';

import { CloseIcon, SearchIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { StyledSearchInput } from './styles';

interface MenuOption {
  value: string;
  label: string;
  checked: boolean;
  disabled: boolean;
}

interface MobileLocationFilterProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onChangeFilter: (selectedStatuses: string[]) => void;
}

export const MobileStatusesFilter = ({ isOpen, onClose, onOpen, onChangeFilter }: MobileLocationFilterProps) => {
  const { t: tTasks } = useTranslation('tasks');

  const [searchFilter, setSearchFilter] = useState('');
  const [items, setItems] = useState<MenuOption[]>([]);

  useEffect(() => {
    setItems(StatusesTypesList.map((item) => ({ ...item, checked: false })));
  }, []);

  const areAllChecked = items.every((option) => option.checked);

  const handleItemClick = (option: MenuOption) => {
    setItems((prev) =>
      prev.map((prevItem) => {
        if (prevItem.value === option.value) {
          return { ...prevItem, checked: !option.checked };
        }
        return prevItem;
      })
    );
  };

  const handleSelectAllClick = () => {
    setItems((prev) => prev.map((item) => ({ ...item, checked: !areAllChecked })));
  };

  const handleApplyClick = () => {
    const selectedStatuses = items.filter((item) => item.checked).map((item) => item.value);
    onChangeFilter(selectedStatuses);
    onClose();
  };

  const handleCancelClick = () => {
    setItems((prev) => prev.map((item) => ({ ...item, checked: false })));
    onChangeFilter([]);
    onClose();
  };

  const filteredMenuList = useMemo(() => {
    return items.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [items, searchFilter]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '32px',
              height: '4px',
              backgroundColor: '#5C6C83',
              opacity: '0.4',
              borderRadius: '100px',
              margin: '16px'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '40px',
            position: 'relative',
            alignItems: 'center'
          }}
        >
          <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
            {tTasks('mobile.filterTitle')}
          </Text>
          <CloseIcon
            sx={{
              fontSize: '24px',
              position: 'absolute',
              right: '12px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '16px', gap: '16px' }}>
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{'Statuses'}</Text>
          <FormControl fullWidth variant="outlined" className="m-0">
            <StyledSearchInput
              value={searchFilter}
              placeholder={'Search status'}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              autoFocus
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchFilter(e.target.value)}
            />
          </FormControl>
        </Box>

        {/* Select All */}
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '8px 16px' }}>
          <Checkbox
            size="small"
            label={tTasks('mobile.showAll')}
            onClick={handleSelectAllClick}
            checked={areAllChecked}
          />
        </Box>
        <Divider />

        {/* Filtered Items */}
        <Box sx={{ maxHeight: '168px', overflowY: 'scroll' }}>
          {filteredMenuList.map((item) => (
            <Box
              key={item.value}
              sx={{
                height: '42px',
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '100%'
              }}
            >
              <Checkbox size="small" label={item.label} onClick={() => handleItemClick(item)} checked={item.checked} />
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', padding: '12px 16px', justifyContent: 'space-between', marginTop: '16px' }}>
          <Button variant="text" onClick={handleCancelClick}>
            {tTasks('mobile.clearBtn')}
          </Button>
          <Button variant="contained" onClick={handleApplyClick}>
            {tTasks('mobile.applyBtn')}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
