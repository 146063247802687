import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { Flex, ImageLazy, TableAsideItem } from '@/separatedModules/components';

import { formatSizeInMb } from '../../helpers';

export const AsideContent = (props: any) => {
  return (
    <>
      <TableAsideItem
        title={'Uploaded by'}
        subTitle={
          <Flex sx={{ alignItems: 'center', marginTop: '12px' }}>
            <>
              {props?.createdBy?.userAvatar?.url ? (
                <ImageLazy
                  width={24}
                  height={24}
                  src={props?.createdBy?.userAvatar?.url}
                  sxStyle={{
                    borderRadius: '16px',
                    border: '1px solid #F7F8F9',
                    position: 'relative'
                  }}
                  alt={''}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    background: 'rgb(240, 240, 241)',
                    border: '1px solid #F7F8F9'
                  }}
                />
              )}
            </>
            <Text
              component={'span'}
              variant={'bodyMd'}
              sx={{
                margin: '0px',
                marginLeft: '8px',
                fontSize: '16px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {props?.createdBy?.firstName} {props?.createdBy?.lastName}
            </Text>
          </Flex>
        }
      />
      <TableAsideItem
        title={'Type'}
        subTitle={props?.type ? props?.type.charAt(0).toUpperCase() + props?.type.slice(1).toLowerCase() : ''}
      />

      <TableAsideItem title={'Upload size'} subTitle={formatSizeInMb(props?.size)} />

      <TableAsideItem title={'Upload date'} subTitle={dayjs(props?.createdDate).format('DD MMM YYYY')} />
    </>
  );
};
