import { gql as gqlApollo } from '@apollo/client';

export const SEARCH_METRICS = gqlApollo(`
  query SearchMetrics($searchCriteria: SearchCriteriaInput!) {
    dataItems: parameterNames(searchCriteria: $searchCriteria) {
      items {
        id
        name
        createdBy {
            firstName
            lastName
            userAvatar {
                fileKey
                url
            }
        }
        lastModifiedDate    
      }
      total
    }
  }
`);

export const CREATE_METRIC = gqlApollo(`
  mutation CreateMetric($name: String!) {
    createParameterName(name: $name) {
      id
      name
    }
  }
`);
