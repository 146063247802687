import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton } from '@mui/material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { GET_PRESIGNED_UPLOAD_URL, GetPresignedUploadUrlQuery, S3Path } from '@/logicLayers/domain';
import { ParameterType, RoundParametersRes } from '@/logicLayers/domain';
import { ApiService } from '@/logicLayers/infrastructure/services';

import {
  AddPhotoIcon,
  AlertWarningIcon,
  ArrowBackIcon,
  DeleteTrashIcon,
  ImageIcon2,
  MoreVertIcon,
  TextFieldControl
} from '@/separatedModules/components';

import { PARAMETER_VALUE_REG_EXP, TEXT_FIELD_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

type ParameterItemProps = {
  item: RoundParametersRes;
  isPreview?: boolean;
};

export const ParameterItem = ({ item, isPreview = false }: ParameterItemProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTasks } = useTranslation('tasks');

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    trigger,
    watch,
    unregister
  } = useFormContext();

  const { refetch } = useQuery<GetPresignedUploadUrlQuery>(GET_PRESIGNED_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    variables: { path: S3Path.RoundFormPhoto },
    skip: true
  });

  const [fileName, setFileName] = useState<string | null>(null);

  const handleGetUrlForUpload = async () => {
    return await refetch().then(({ data }) => data);
  };

  const handleCapture = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFileName(file.name);

      const imageUrlForUpload = await handleGetUrlForUpload();

      const { isSuccess } = await ApiService.AmazonS3.updateImage(imageUrlForUpload.presignedUploadUrl.url, file);

      if (isSuccess) {
        setValue(`${item.id}_photo`, imageUrlForUpload.presignedUploadUrl.fileKey);
        trigger(`${item.id}_photo`);
      } else {
        setError(`${item.id}_photo`, {
          type: 'manual',
          message: 'Upload error'
        });
      }
    }
  };

  const [isPhotoDrawerOpen, setIsPhotoDrawerOpen] = useState(false);
  const [isCancelFormDrawerOpen, setIsCancelFormDrawerOpen] = useState(false);
  const [isCancelFormDialogOpen, setIsCancelFormDialogOpen] = useState(false);

  const [isParameterCancelled, setIsParameterCancelled] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleRePhoto = () => {
    setIsPhotoDrawerOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeletePhoto = () => {
    setIsPhotoDrawerOpen(false);
    setFileName(null);
    setValue(`${item.id}_photo`, null);
    trigger(`${item.id}_photo`);
  };

  const cancelFormValue = watch(`${item.id}_cancelReason`);

  useEffect(() => {
    if (isParameterCancelled) {
      unregister(`${item.id}_value`);
      unregister(`${item.id}_note`);
      unregister(`${item.id}_photo`);
    }
  }, [isParameterCancelled, unregister]);

  return (
    <Box
      sx={{
        padding: '16px 12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        borderRadius: '4px',
        border: '1px solid rgba(41, 49, 62, 0.06)',
        background: 'rgba(41, 49, 62, 0.03)',
        marginBottom: '12px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '12px'
        }}
      >
        <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{item.parameterDetails.name}</Text>
        {!isPreview && (
          <IconButton onClick={() => setIsCancelFormDrawerOpen(true)}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        <Text
          sx={{
            fontSize: '14px',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-all',
            overflow: 'hidden'
          }}
        >
          {item.parameterDetails.description}
        </Text>
      </Box>
      <Box sx={{ width: '100%' }}>
        {item.parameterDetails.type === ParameterType.NumericInput ||
        item.parameterDetails.type === ParameterType.String ? (
          <Controller
            name={`${item.id}_value`}
            control={control}
            rules={{
              required: {
                value: !isParameterCancelled,
                message: tGlobal('validation.required')
              },
              pattern: {
                value:
                  item.parameterDetails.type === ParameterType.String ? TEXT_FIELD_REG_EXP : PARAMETER_VALUE_REG_EXP,
                message:
                  item.parameterDetails.type === ParameterType.String
                    ? tGlobal('validation.textFieldLength')
                    : tGlobal('validation.numberWithPoint', {
                        value: 2
                      })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  placeholder={tTasks('mobile.roundForm.inputPlaceholder')}
                  name={`${item.id}_value`}
                  value={value}
                  validation={{
                    isValid: !errors[`${item.id}_value`]?.message,
                    error: errors[`${item.id}_value`]?.message as string
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={isParameterCancelled || isPreview}
                />
              );
            }}
          />
        ) : item.parameterDetails.type === ParameterType.DataTime ? (
          <Controller
            name={`${item.id}_value`}
            control={control}
            defaultValue={{ date: null, time: null }}
            rules={{
              validate: isParameterCancelled
                ? {}
                : {
                    date: (value) => value.date !== null || tTasks('validation.requiredDate'),
                    time: (value) => value.time !== null || tTasks('validation.requiredTime')
                  }
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ width: '100%', marginTop: '16px' }}>
                    <DatePicker
                      label={tTasks('mobile.roundForm.date')}
                      value={value?.date}
                      onChange={(newDate) => onChange({ date: newDate!, time: value.time })}
                      sx={{ marginTop: '16px', marginRight: '16px' }}
                      slotProps={{
                        textField: {
                          helperText: 'MM/DD/YYYY',
                          error: errors[`${item.id}_value`]?.type === 'date'
                        }
                      }}
                      disabled={isParameterCancelled || isPreview}
                    />
                    <TimePicker
                      label={tTasks('mobile.roundForm.time')}
                      value={value?.time}
                      onChange={(newTime) => onChange({ date: value.date, time: newTime! })}
                      slotProps={{
                        textField: {
                          helperText: 'HH:MM',
                          error: errors[`${item.id}_value`]?.type === 'time'
                        },
                        popper: {
                          sx: { '& .MuiList-root': { '::after': { display: 'none' } } }
                        }
                      }}
                      disabled={isParameterCancelled || isPreview}
                    />
                  </Box>
                </LocalizationProvider>
              );
            }}
          />
        ) : (
          <Controller
            name={`${item.id}_value`}
            control={control}
            defaultValue={false}
            render={({ field }) => {
              return (
                <RadioGroup {...field} sx={{ marginTop: '16px' }}>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={
                      item.condition?.valueAliases
                        ? item.condition.valueAliases.find((item) => item.key === 'true')?.value
                        : 'Yes'
                    }
                    sx={{ '& .MuiFormControlLabel-label': { color: '#121B2A' } }}
                    disabled={isParameterCancelled || isPreview}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={
                      item.condition?.valueAliases
                        ? item.condition.valueAliases.find((item) => item.key === 'false')?.value
                        : 'No'
                    }
                    sx={{ '& .MuiFormControlLabel-label': { color: '#121B2A' } }}
                    disabled={isParameterCancelled || isPreview}
                  />
                </RadioGroup>
              );
            }}
          />
        )}
      </Box>
      {(item.addNote || item.uploadPhoto) && <Divider sx={{ margin: '12px 0', width: '100%' }} />}
      {item.addNote && (
        <Box sx={{ width: '100%' }}>
          <Controller
            name={`${item.id}_note`}
            control={control}
            rules={{
              required: {
                value: !isParameterCancelled,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: /^[\w\s`'.\-,#№()_]{3,2000}$/,
                message: tGlobal('validation.textFieldMinMaxLength', {
                  valueMin: 3,
                  valueMax: 2000
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  label={tTasks('mobile.roundForm.commentLabel')}
                  placeholder={tTasks('mobile.roundForm.commentPlaceholder')}
                  name={`${item.id}_note`}
                  rows={6}
                  maxContentLength={2000}
                  value={value}
                  validation={{
                    isValid: !errors[`${item.id}_note`]?.message,
                    error: errors[`${item.id}_note`]?.message as string
                  }}
                  multiline
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={isParameterCancelled || isPreview}
                />
              );
            }}
          />
        </Box>
      )}
      {item.uploadPhoto && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexWrap: 'wrap'
          }}
        >
          <Controller
            name={`${item.id}_photo`}
            control={control}
            rules={{
              required: {
                value: !isParameterCancelled,
                message: tTasks('mobile.roundForm.photoRequired')
              }
            }}
            render={() => (
              <>
                <input
                  name={`${item.id}_photo`}
                  accept="image/*"
                  capture="environment"
                  style={{ display: 'none' }}
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    handleCapture(e);
                  }}
                  ref={fileInputRef}
                />
                {fileName ? (
                  <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <ImageIcon2 />
                      <Text sx={{ fontSize: '12px', color: '#000' }}>{fileName}</Text>
                    </Box>
                    <IconButton onClick={() => setIsPhotoDrawerOpen(true)}>
                      <MoreVertIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    variant="text"
                    sx={{ background: 'transparent !important', height: '40px' }}
                    startIcon={
                      <AddPhotoIcon
                        sx={{
                          fill:
                            isParameterCancelled || isPreview
                              ? 'rgba(9, 14, 22, 0.30) !important'
                              : '#313849 !important'
                        }}
                      />
                    }
                    component="span"
                    onClick={handleRePhoto}
                    disabled={isParameterCancelled || isPreview}
                  >
                    {tTasks('mobile.roundForm.uploadPhotoBtn')}
                  </Button>
                )}
              </>
            )}
          />
          {errors[`${item.id}_photo`] && (
            <Text sx={{ color: 'red' }}>{errors[`${item.id}_photo`]?.message as string}</Text>
          )}
        </Box>
      )}
      <SwipeableDrawer
        anchor="bottom"
        open={isPhotoDrawerOpen}
        onClose={() => setIsPhotoDrawerOpen(false)}
        onOpen={() => setIsPhotoDrawerOpen(true)}
        PaperProps={{
          sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '32px',
                height: '4px',
                backgroundColor: '#5C6C83',
                opacity: '0.4',
                borderRadius: '100px',
                margin: '16px'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '40px',
              position: 'relative',
              alignItems: 'center'
            }}
          >
            <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
              {tTasks('mobile.roundForm.drawerTitle')}
            </Text>
            <CloseIcon
              sx={{
                fontSize: '24px',
                position: 'absolute',
                right: '12px',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
              onClick={() => setIsPhotoDrawerOpen(false)}
            />
          </Box>
          <Box sx={{ padding: '16px 0px 37px 0px' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px 16px' }}
              onClick={handleDeletePhoto}
            >
              <DeleteTrashIcon sx={{ fontSize: '20px' }} />
              <Text>{tTasks('mobile.roundForm.deletePhoto')}</Text>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px 16px' }}
              onClick={handleRePhoto}
            >
              <AddPhotoIcon sx={{ fontSize: '20px' }} />
              <Text>{tTasks('mobile.roundForm.rePhoto')}</Text>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="bottom"
        open={isCancelFormDrawerOpen}
        onClose={() => setIsCancelFormDrawerOpen(false)}
        onOpen={() => setIsCancelFormDrawerOpen(true)}
        PaperProps={{
          sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '32px',
                height: '4px',
                backgroundColor: '#5C6C83',
                opacity: '0.4',
                borderRadius: '100px',
                margin: '16px'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '40px',
              position: 'relative',
              alignItems: 'center'
            }}
          >
            <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
              {tTasks('mobile.roundForm.drawerTitle')}
            </Text>
            <CloseIcon
              sx={{
                fontSize: '24px',
                position: 'absolute',
                right: '12px',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
              onClick={() => setIsCancelFormDrawerOpen(false)}
            />
          </Box>
          <Box sx={{ padding: '16px 0px 37px 0px' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px 16px' }}
              onClick={() => setIsCancelFormDialogOpen(true)}
            >
              <AlertWarningIcon sx={{ fontSize: '20px', fill: '#5C6C83' }} />
              <Text>{tTasks('mobile.roundForm.noPerform')}</Text>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
      <Dialog
        fullScreen
        PaperProps={{
          sx: { borderRadius: '0px !important' }
        }}
        open={isCancelFormDialogOpen}
        onClose={() => setIsCancelFormDialogOpen(false)}
      >
        <Box sx={{ height: '100vh', position: 'relative' }}>
          <Box sx={{ flex: '1 1 auto', overflowY: 'auto', paddingBottom: '155px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100vw',
                justifyContent: 'flex-start',
                padding: '16px'
              }}
            >
              <IconButton
                onClick={() => {
                  setIsCancelFormDialogOpen(false);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box sx={{ marginLeft: '8px' }}>
                <Text component={'h2'} variant={'titleXl'} sx={{ fontSize: '18px', fontWeight: 600, margin: '0' }}>
                  {tTasks('mobile.roundForm.cancelParameter.title')}
                </Text>
                <Text sx={{ color: '#505864', fontSize: '12px' }}>{item.parameterDetails.name}</Text>
              </Box>
            </Box>
            <Box sx={{ padding: '16px' }}>
              <ul>
                <li>
                  <Text sx={{ fontSize: '16px' }}> {tTasks('mobile.roundForm.cancelParameter.description.part1')}</Text>
                </li>
                <li>
                  <Text sx={{ fontSize: '16px' }}>{tTasks('mobile.roundForm.cancelParameter.description.part2')}</Text>
                </li>
              </ul>
              <Controller
                name={`${item.id}_cancelReason`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  },
                  pattern: {
                    value: /^[\w\s`'.\-,#№()_]{3,400}$/,
                    message: tGlobal('validation.textFieldMinMaxLength', {
                      valueMin: 3,
                      valueMax: 400
                    })
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldControl
                    label={tTasks('mobile.onHoldField.label')}
                    placeholder={tTasks('mobile.onHoldField.placeholder')}
                    name={`${item.id}_cancelReason`}
                    rows={5}
                    maxContentLength={400}
                    value={value}
                    validation={{
                      isValid: !errors[`${item.id}_cancelReason`]?.message,
                      error: errors[`${item.id}_cancelReason`]?.message as string
                    }}
                    multiline
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: 0,
              left: 0,
              padding: '16px 16px 58px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '1px solid rgba(41, 49, 62, 0.15)',
              background: '#FFFFFF'
            }}
          >
            <Button
              variant="outlined"
              size="large"
              sx={{ width: '100px' }}
              onClick={() => {
                setValue(`${item.id}_cancelReason`, '');
                setIsCancelFormDialogOpen(false);
                setIsCancelFormDrawerOpen(false);
                setIsParameterCancelled(false);
              }}
            >
              {tTasks('mobile.roundForm.cancelParameter.cancelBtn')}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ width: '100px' }}
              onClick={() => {
                setIsParameterCancelled(true);
                setIsCancelFormDialogOpen(false);
                setIsCancelFormDrawerOpen(false);
                setValue(`${item.id}_value`, '');
                setValue(`${item.id}_note`, '');
                setValue(`${item.id}_photo`, '');
              }}
              disabled={!!errors[`${item.id}_cancelReason`] || !cancelFormValue}
            >
              {tTasks('mobile.roundForm.cancelParameter.submitBtn')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
