import React, { Key, useState } from 'react';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Button, SvgIcon, Tab, Tabs } from '@mui/material';

import { TaskStatus } from '@/logicLayers/domain';

import { getBreadcrumbs } from '@/separatedModules/pages/Locations/components/PageTopBox/helpers';

import {
  ArrowForwardIcon,
  Breadcrumbs,
  ChevronRightIcon,
  EMPTY_CELL_VALUE,
  Flex,
  ImageLazy,
  TableAsideItem,
  TooltipOverflowItem
} from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useTranslation } from '@/i18n';

import { TabPanel } from './components/TabPanel';

import { getParamItemConfig } from '../../helpers';

interface Parameter {
  parameterDetails: {
    title: string;
    name: string;
    type: string;
  };
}

interface ServiceStaff {
  userAvatar: { url: string | undefined };
  firstName: string;
  lastName: string;
}

interface Equipment {
  fileUrl: {
    url: string;
  };
  name: string;
  location: string;
}

interface AsideContentProps {
  parameters: Parameter[];
  description?: string;
  serviceStaff: ServiceStaff[];
  createdDate?: string;
  equipment: Equipment[];
  status: keyof typeof TaskStatus;
  handleChangeTest: () => void;
}

export const AsideContent = (props: AsideContentProps) => {
  const { t: tGlobal } = useTranslation('global');
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const paramsConfig = props?.parameters.map(
    (parameter: {
      parameterDetails: {
        title: string;
        name: string;
        type: string;
      };
    }) => {
      return {
        ...getParamItemConfig({ original: { type: parameter.parameterDetails.type } }),
        type: parameter.parameterDetails?.name
      };
    }
  );

  const name = props?.equipment[0].name;
  const breadcrumbsLinks = getBreadcrumbs(props?.equipment[0].location, true);

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        sx={{
          position: 'relative',
          padding: '0px',
          margin: '0px'
        }}
      >
        <Tab
          label="Overview"
          sx={{
            backgroundColor: 'transparent',
            textTransform: 'none',
            color: '#505864',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              textTransform: 'none',
              color: '#121B2A'
            }
          }}
        />
        <Tab
          label="Parameters"
          sx={{
            backgroundColor: 'transparent',
            textTransform: 'none',
            color: '#505864',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              textTransform: 'none',
              color: '#121B2A'
            }
          }}
        />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <hr
          style={{
            height: '1px',
            background: 'rgba(9, 14, 22, 0.15)',
            position: 'absolute',
            top: '120px',
            left: '0',
            width: '100%'
          }}
        />

        <TableAsideItem title={tGlobal('table.aside.description')} subTitle={props?.description} />

        <TableAsideItem
          title={'Service staff'}
          subTitle={
            <Box>
              {props?.serviceStaff.map(
                (
                  service: { userAvatar: { url: string | undefined }; firstName: string; lastName: string },
                  index: Key | null | undefined
                ) => {
                  const displayName =
                    service?.firstName.length && service?.lastName.length
                      ? `${service.firstName} ${service.lastName}`
                      : EMPTY_CELL_VALUE;

                  return (
                    <Flex sx={{ alignItems: 'center', marginTop: '12px' }} key={index}>
                      <>
                        {service?.userAvatar?.url ? (
                          <ImageLazy
                            width={24}
                            height={24}
                            src={service.userAvatar?.url}
                            sxStyle={{
                              borderRadius: '16px',
                              border: '1px solid #F7F8F9',
                              position: 'relative'
                            }}
                            alt={''}
                          />
                        ) : (
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              flex: '0 0 24px',
                              borderRadius: '50%',
                              background: 'rgb(240, 240, 241)',
                              border: '1px solid #F7F8F9'
                            }}
                          />
                        )}
                      </>
                      <Text
                        component={'span'}
                        variant={'bodyMd'}
                        sx={{
                          margin: '0px',
                          marginLeft: '8px',
                          fontSize: '16px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                          wordBreak: 'break-all'
                        }}
                      >
                        {`${displayName}`}
                      </Text>
                    </Flex>
                  );
                }
              )}
            </Box>
          }
        />

        <TableAsideItem
          title={'Creation date & time'}
          subTitle={
            <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
              {props?.createdDate ? dayjs(props?.createdDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
            </Text>
          }
        />

        <TableAsideItem
          title={'Equipment & Location'}
          subTitle={
            <Flex alignItems="center">
              <Box sx={{ margin: '0px' }}>
                {name ? (
                  <Text
                    className={'m-0'}
                    variant={'inherit'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-all'
                    }}
                  >
                    {name}
                  </Text>
                ) : (
                  EMPTY_CELL_VALUE
                )}

                <Text className={'m-0'} variant={'bodyLg'}>
                  {breadcrumbsLinks.length > 0 ? (
                    <Breadcrumbs
                      links={breadcrumbsLinks}
                      separator={<ChevronRightIcon sx={{ height: '14px', width: '20px' }} />}
                      sx={{ padding: 0, width: '260px' }}
                      fontSize="14px"
                      separatorStyle={{ padding: '0px', width: '20px', paddingTop: '3px' }}
                    />
                  ) : (
                    EMPTY_CELL_VALUE
                  )}
                </Text>
              </Box>
            </Flex>
          }
        />

        <TableAsideItem title={'Status'} subTitle={<TaskStatusChip status={props?.status} />} />

        <Button
          variant={'text'}
          size={'small'}
          sx={{
            margin: '0 0 0 auto !important',
            '&.MuiButton-text': {
              '&:not(:active, :hover)': {
                background: 'transparent'
              }
            }
          }}
          onClick={props?.handleChangeTest}
          endIcon={<SvgIcon component={ArrowForwardIcon} />}
        >
          {'Details'}
        </Button>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <hr
          style={{
            height: '1px',
            background: 'rgba(9, 14, 22, 0.15)',
            position: 'absolute',
            top: '120px',
            left: '0',
            width: '100%'
          }}
        />

        <Box sx={{ marginTop: '20px' }}>
          {paramsConfig.map((param) => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '32px' }} key={param.id}>
                {param?.logoPath ? (
                  <ImageLazy
                    src={param?.logoPath}
                    variant={'rectangular'}
                    width={32}
                    height={32}
                    sxStyle={{
                      margin: '0 16px 0 0',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    alt={''}
                  />
                ) : null}

                <Box component={'span'} sx={{ width: 268 }}>
                  {param.type ? (
                    <Text
                      component={'span'}
                      className={'m-0'}
                      variant={'titleSm'}
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400'
                      }}
                    >
                      <TooltipOverflowItem>{param.type}</TooltipOverflowItem>
                    </Text>
                  ) : null}

                  {param.title ? (
                    <Text
                      component={'span'}
                      variant={'bodySm'}
                      sx={{
                        margin: '4px 0 0 0 !important',
                        color: 'var(--text-subtle, #505668)',
                        fontSize: '14px',
                        fontWeight: '400',
                        marginTop: '12px !important'
                      }}
                    >
                      {param.title}
                    </Text>
                  ) : null}
                </Box>
              </Box>
            );
          })}
        </Box>
      </TabPanel>
    </Box>
  );
};
