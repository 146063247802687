import classNames from 'classnames';

import TableBody from '@mui/material/TableBody';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../Providers';

import { RowActionMenu } from './components/RowActionMenu';

import { CONFIG_TABLE } from '../../helpers';

import { StyledTableRow } from './style';

export const TableBodyWrap = () => {
  const { t: tTemplates } = useTranslation('templates');

  const state = useContextState();

  const { handleClickRow, handleSelectRow } = useContextUpdateState();

  return (
    <TableBody>
      {state.bodyDataRows?.map((row) => {
        return (
          <StyledTableRow
            key={row.id}
            className={classNames({
              'Mui-selected': state.selectedItems.includes(row.id as string)
            })}
            onClick={() => {
              row.id && handleSelectRow(row.id);
            }}
            onDoubleClick={() => {
              row.id && handleClickRow(row.id);
            }}
          >
            <>
              {CONFIG_TABLE.cells.map((i, index) => {
                const className = `${CONFIG_TABLE.cells[index].columnClass}`;

                return i.Render(row, className, index, tTemplates);
              })}
            </>
            <RowActionMenu {...row} id={row.id ?? undefined} />
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
