import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

export const StyledTableRow = styled(TableRow)`
  position: relative;

  :hover {
    .edit-role-button {
      visibility: visible;
    }
  }
` as typeof TableRow;
