import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import {
  EquipmentParameterRes,
  EquipmentParametersQuery,
  GET_EQUIPMENT_PARAMETERS,
  ParameterType
} from '@/logicLayers/domain';

import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps';

import { EMPTY_CELL_VALUE, ImageLazy, TooltipOverflowItem } from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

import { VisibilityIcon } from '../../../../../../../../components/Icon/svg-mui-theme/Visibility';
import { VisibilityOffIcon } from '../../../../../../../../components/Icon/svg-mui-theme/VisibilityOff';

export const getParamItemConfig = (row: any) => {
  return PARAMETERS_LIST_FULL.find((paramItem) => row.original.type === paramItem.parameterType);
};

const columnHelper = createColumnHelper<EquipmentParameterRes>();

export const ParameterTypeTyped = {
  NUMERIC_INPUT: ParameterType.NumericInput.toUpperCase(),
  NUMERIC_FORMULA: ParameterType.NumericFormula.toUpperCase(),
  NUMERIC_FIXED: ParameterType.NumericFixed.toUpperCase(),
  DATA_TIME: ParameterType.DataTime.toUpperCase(),
  BOOLEAN: ParameterType.Boolean.toUpperCase(),
  STRING: ParameterType.String.toUpperCase()
};

export const ParametersTypesList = [
  {
    label: 'Numeric data, Data input',
    value: ParameterTypeTyped.NUMERIC_INPUT,
    checked: false,
    disabled: false
  },
  {
    label: 'Numeric data, Set formula',
    value: ParameterTypeTyped.NUMERIC_FORMULA,
    checked: false,
    disabled: false
  },
  {
    label: 'Numeric data, Fixed value',
    value: ParameterTypeTyped.NUMERIC_FIXED,
    checked: false,
    disabled: false
  },
  {
    label: 'Boolean',
    value: ParameterTypeTyped.BOOLEAN,
    checked: false,
    disabled: false
  },
  {
    label: 'String',
    value: ParameterTypeTyped.STRING,
    checked: false,
    disabled: false
  },
  {
    label: 'Date & Time',
    value: ParameterTypeTyped.DATA_TIME,
    checked: false,
    disabled: false
  }
];

export const isVisibleItemParams = [
  ParameterTypeTyped.NUMERIC_INPUT,
  ParameterTypeTyped.BOOLEAN,
  ParameterTypeTyped.STRING,
  ParameterTypeTyped.DATA_TIME
];
export const isUnVisibleItemParams = [ParameterTypeTyped.NUMERIC_FIXED, ParameterTypeTyped.NUMERIC_FORMULA];

export const ParametersVisibilityList = [
  {
    checked: false,
    label: i18n.t('equipmentItem.parameters.table.rowsCells.visible', { ns: 'templates' }),
    value: isVisibleItemParams,
    icon: <VisibilityIcon sx={{ fontSize: 20 }} />
  },
  {
    checked: false,
    label: i18n.t('equipmentItem.parameters.table.rowsCells.unVisible', { ns: 'templates' }),
    value: isUnVisibleItemParams,
    icon: <VisibilityOffIcon sx={{ fontSize: 20 }} />
  }
];

export const checkIsFormulaOrFixed = (row: any) => {
  const isFormulaOrFixed = isUnVisibleItemParams.includes(row?.original?.type?.toUpperCase());

  if (!row) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isFormulaOrFixed ? (
        <>
          {ParametersVisibilityList[1].icon}
          <Text variant={'bodyMd'} sx={{ margin: '0 0 0 8px', fontSize: '16px' }}>
            {ParametersVisibilityList[1].label}
          </Text>
        </>
      ) : (
        <>
          {ParametersVisibilityList[0].icon}
          <Text variant={'bodyMd'} sx={{ margin: '0 0 0 8px', fontSize: '16px' }}>
            {ParametersVisibilityList[0].label}
          </Text>
        </>
      )}
    </Box>
  );
};

export const columns = [
  columnHelper.accessor('title', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      const paramItemConfig = getParamItemConfig(row);

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {paramItemConfig?.logoPath ? (
            <ImageLazy
              src={paramItemConfig?.logoPath}
              alt={`Equipment item parameter ${row.original.title} type preview`}
              variant={'rectangular'}
              width={24}
              height={24}
              sxStyle={{
                margin: '0 8px 0 0',
                display: 'flex',
                alignItems: 'center'
              }}
            />
          ) : null}

          <Box component={'span'} sx={{ width: 268 }}>
            {row.original?.name ? (
              <Text component={'span'} className={'m-0'} variant={'titleSm'}>
                <TooltipOverflowItem>{row.original?.name}</TooltipOverflowItem>
              </Text>
            ) : null}

            {row.original?.description ? (
              <Text
                component={'span'}
                variant={'bodySm'}
                sx={{ margin: '4px 0 0 0 !important', color: 'var(--text-subtle, #505668)' }}
              >
                <TooltipOverflowItem>{row.original?.description}</TooltipOverflowItem>
              </Text>
            ) : null}
          </Box>
        </Box>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'visibility',
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => checkIsFormulaOrFixed(row)
  }),
  columnHelper.accessor('type', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      const paramItemConfig = getParamItemConfig(row);

      return (
        <Chip
          variant={'filled'}
          size={'small'}
          label={paramItemConfig?.title || row.original?.type}
          color={'secondary'}
          sx={{ margin: '0' }}
        />
      );
    }
  }),
  columnHelper.accessor('createdDate', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.createdDate ? dayjs(row.original.createdDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDomains: [],
    sortByType: [],
    sortByTypeSelected: '',
    sortByDate: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT, templateId: string | undefined) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<EquipmentParametersQuery>(GET_EQUIPMENT_PARAMETERS, {
    fetchPolicy: 'no-cache',
    variables: {
      equipmentId: templateId || undefined,
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.filters.search.length ? 0 : state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        }
      },
      types: state.filters.sortByType.length ? state.filters.sortByType : undefined
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByType = useCallback((value: string[], type: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByType: value,
          sortByTypeSelected: type
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByType,
    handleResetSelectedRows,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
