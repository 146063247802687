import { FC } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { Box, TableBody as MuiTableBody } from '@mui/material';

import { DomainStatusChip, EMPTY_CELL_VALUE } from '@/separatedModules/components';

import { DEFAULT_HIDDEN_KEY_COLUMN, TableRow } from './table.config';

import { StyledTableBodyRow, StyledTableCell } from './style';

interface TableBodyProps {
  rows: TableRow[];
  hiddenColumns: string[];
  onRowClick: (id: string, type: number) => void;
}

export const TableBody: FC<TableBodyProps> = ({ rows, hiddenColumns, onRowClick }) => {
  return (
    <MuiTableBody>
      {rows?.map((row, key) => (
        <StyledTableBodyRow
          key={key}
          selected={row?.selected}
          onClick={(event: { detail: number }) => {
            if ((event.detail === 1 || event.detail === 2) && row.id) {
              onRowClick(row.id, event.detail);
            }
          }}
        >
          {Object.entries(row)?.map(([key, value]) => {
            const isDomainCell = key === 'domain';
            const domainValues = isDomainCell && value.length ? value : false;

            return (
              <StyledTableCell
                key={key}
                align={'left'}
                // ToDo Refactor
                hide={Boolean(
                  key === DEFAULT_HIDDEN_KEY_COLUMN ||
                    key === 'selected' ||
                    key === 'userAvatar' ||
                    hiddenColumns.includes(key)
                )}
              >
                {isDomainCell ? (
                  domainValues ? (
                    domainValues.map((domainValue: { color: string; name: string }, index: number) => (
                      <Box key={index} sx={{ display: 'flex', width: 'fit-content' }}>
                        <DomainStatusChip color={domainValue.color} label={domainValue.name} />
                      </Box>
                    ))
                  ) : (
                    EMPTY_CELL_VALUE
                  )
                ) : value ? (
                  <Text
                    sx={{
                      fontSize: '0.875rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-all'
                    }}
                  >
                    {value}
                  </Text>
                ) : (
                  EMPTY_CELL_VALUE
                )}
              </StyledTableCell>
            );
          })}
        </StyledTableBodyRow>
      ))}
    </MuiTableBody>
  );
};
