import { ReactNode } from 'react';
import { Link, Location } from 'react-router-dom';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

import { RoleTemplateRes } from '@/logicLayers/domain';

import { ImageLazy } from '@/separatedModules/components';
import { skeletonTableCellRect } from '@/separatedModules/components/Table';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';
import { TranslationT } from '@/subsidiaryBinders/types';

import { i18n } from '@/i18n';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT, location?: Location<any>) => ReactNode;
}

export interface ConfigTableI {
  cells: TableCellI<RoleTemplateRes>[];
  pagination: {
    page: number[];
    rowsPerPage: number[];
    rowsPerPageOptions: number[];
  };
}

export const CONFIG_TABLE: ConfigTableI = {
  cells: [
    {
      order: 0,
      columnName: 'title',
      columnClass: 'MuiTableCell-title',
      title: i18n.t('role.table.cells.title', { ns: 'templates' }),
      Render: ({ name, description }, className, index, _, location) => (
        <TableCell key={name + index} className={className}>
          <Box>
            <Text className={'m-0'} variant={'titleSm'}>
              {name ?? '-'}
            </Text>
            {location?.pathname === '/role' && (
              <Text
                sx={{
                  color: '#505668',
                  fontSize: '12px',
                  fontWeight: 400,
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  maxWidth: '300px',
                  overflow: 'hidden'
                }}
              >
                {description}
              </Text>
            )}
          </Box>
        </TableCell>
      )
    },
    {
      order: 1,
      columnName: 'createdBy',
      columnClass: 'MuiTableCell-createdBy',
      title: 'Created by',
      Render: ({ createdBy }, className, index) => (
        <TableCell key={index} className={className}>
          <Box sx={{ width: '100%' }}>
            <Box display={'flex'}>
              {createdBy.userAvatar?.url ? (
                <ImageLazy
                  src={createdBy.userAvatar.url}
                  alt={`Equipment Created by ${createdBy.firstName} ${createdBy.lastName} logo`}
                  width={24}
                  height={24}
                  sxStyle={{
                    margin: '0 8px 0 0'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    background: 'rgb(240, 240, 241)',
                    border: '1px solid #F7F8F9',
                    margin: '0 8px 0 0'
                  }}
                />
              )}

              <Text
                variant={'bodyLg'}
                className="m-0"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {createdBy.firstName} {createdBy.lastName}
              </Text>
            </Box>
          </Box>
        </TableCell>
      )
    },
    {
      order: 2,
      columnName: 'lastModified',
      columnClass: 'MuiTableCell-lastModified',
      title: i18n.t('role.table.cells.lastModified', { ns: 'templates' }),
      Render: ({ lastModifiedDate }, className, index) => {
        return (
          <TableCell key={lastModifiedDate + index} className={className}>
            {lastModifiedDate ? dayjs(lastModifiedDate).format('DD MMM YYYY, HH:mm A') : '-'}
          </TableCell>
        );
      }
    },
    {
      order: 3,
      columnName: 'itemsTemplate',
      columnClass: 'MuiTableCell-itemsTemplate',
      title: i18n.t('role.table.cells.itemsTemplate', { ns: 'templates' }),
      Render: ({ usageCount, id }, className, index, _, link) => (
        <TableCell key={usageCount + index} className={className}>
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            {link?.pathname === '/role' ? (
              <Link
                className={'m-0'}
                to={LINKS_PAGES.usersRole.replace(':roleId', id)}
                style={{ textDecoration: 'underline' }}
              >
                {usageCount ?? 0}
              </Link>
            ) : (
              <Text className={'m-0'} variant={'bodyMd'}>
                {usageCount ?? 0}
              </Text>
            )}
          </Box>
        </TableCell>
      )
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
