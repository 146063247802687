import { ReactNode, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { SxProps, Theme } from '@mui/material';

import { SearchDomainsQuery } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { formatSelectOptions } from '@/separatedModules/pages/Users/components/AddUser/components/AddUserModal/helpers';

import { ButtonLoading, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { AutoCompleteParamsControl } from '../../Controls/AutoCompleteParamsControl';

import { SEARCH_METRICS } from '@/logicLayers/metric/metrics';

export interface EditInputItemProps {
  editFieldType?: string;
  editFieldName: string;
  editFieldValue: string;
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  patternMessage: string;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  inputLabel: string;
  inputPlaceholder?: string;
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  regExpMinLength?: number;
  regExpMaxLength?: number;
  maxContentLength?: number;
  rows?: number;
  regExp: RegExp;
  ContentAfterTitle?: ReactNode;
  multiline?: boolean;
  sxTitle?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  onSubmitForm: (getValues: any, updateModel: any) => void;
}

const styles = [
  'color: #090E16',
  'background: #E6F6EF',
  'font-size: 10px',
  'border: 1px solid #06845B',
  'padding: 2px'
].join(';');

export const EditParameterTitle = ({
  editFieldName,
  editFieldValue,
  navigateToUrl,
  mutationQuery,
  successAlertMessage,
  errorAlertMessage,
  requiredMessage,
  patternMessage,
  minLengthMessage,
  maxLengthMessage,
  inputLabel,
  inputPlaceholder,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  regExp,
  regExpMinLength = 3,
  regExpMaxLength = 64,
  ContentAfterTitle,
  onSubmitForm,
  sxTitle
}: EditInputItemProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data, error }] = useMutation(mutationQuery);

  const { data: metricsData, loading: metricsLoading } = useQuery<SearchDomainsQuery>(SEARCH_METRICS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: { pageSize: 1000 }
      }
    }
  });

  const metricsList = useMemo(() => {
    if (!metricsData?.dataItems?.items?.length) return [{ value: 'request-new', label: 'Request new parameter title' }];

    return [
      { value: 'request-new', label: 'Request new parameter title' },
      ...formatSelectOptions(metricsData.dataItems.items, 'id', 'name')
    ];
  }, [metricsData?.dataItems?.items]);

  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [editFieldName]: editFieldValue
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm?.(getValues, updatedData);
  });

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const REQUIRED_MESSAGE = requiredMessage || tGlobal('validation.required');
  const MIN_LENGTH_MESSAGE = minLengthMessage || tGlobal('validation.textFieldMinLength', { value: regExpMinLength });
  const MAX_LENGTH_MESSAGE = maxLengthMessage || tGlobal('validation.textFieldMaxLength', { value: regExpMaxLength });
  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  // ToDo Remove
  console.log('%c' + `editFieldName, editFieldValue - ${editFieldName}, ${editFieldValue}`, styles);

  return (
    <form onSubmit={handleSubmitForm}>
      <TextBlockControl
        title={pageTitle}
        subTitle={pageSubTitle}
        ContentAfterTitle={ContentAfterTitle}
        isTitleLg
        isBodyLg
        sxTitle={sxTitle}
      />

      <Controller
        name={editFieldName}
        control={control}
        rules={{
          required: {
            value: true,
            message: REQUIRED_MESSAGE
          },
          pattern: {
            value: regExp,
            message: patternMessage
          },
          minLength: {
            value: regExpMinLength,
            message: MIN_LENGTH_MESSAGE
          },
          maxLength: {
            value: regExpMaxLength,
            message: MAX_LENGTH_MESSAGE
          }
        }}
        render={({ field }) => {
          const { onChange, onBlur, value } = field;

          return (
            <AutoCompleteParamsControl
              title={inputLabel}
              subTitle={inputPlaceholder}
              label={inputLabel}
              name={editFieldName}
              options={metricsList}
              validation={{
                isValid: !errors[editFieldName]?.message,
                error: errors[editFieldName]?.message as string
              }}
              getOptionKey={(option: any) => option.value as string}
              value={value as any}
              loading={metricsLoading}
              onChange={(e, value: any) => {
                if (value?.value === 'request-new') {
                  onChange(null);
                  return;
                }

                onChange(value);
              }}
              onBlur={onBlur}
            />
          );
        }}
      />

      <ButtonLoading
        className={'edit__content__btn'}
        variant={'contained'}
        type={'submit'}
        loading={loading}
        disabled={Boolean(data)}
      >
        {SUBMIT_BTN_TEXT}
      </ButtonLoading>
    </form>
  );
};
