import { viewModeType } from '../ContextProvider';

export const viewModeValues: { label: string; value: viewModeType }[] = [
  { value: 'month', label: 'Month' },
  { value: '3-months', label: '3 Months' },
  { value: 'year', label: 'Year' }
];

export const roundFrequency = [
  { color: '#E6F3FF', label: 'Daily', value: 'DAILY' },
  { color: '#C9E6FF', label: 'Weekly', value: 'WEEKLY' },
  { color: '#70BBFE', label: 'Monthly', value: 'MONTHLY' },
  { color: '#2D96FF', label: 'Triennially', value: 'EVERY_3_MONTH' },
  { color: '#367DDB', label: 'Half-yearly', value: 'EVERY_6_MONTH' },
  { color: '#265299', label: 'Yearly', value: 'YEARLY' }
];

export const options = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'EVERY_3_MONTH', label: 'Every 3 month' },
  { value: 'EVERY_6_MONTH', label: 'Every 6 month' },
  { value: 'YEARLY', label: 'Yearly' },
  { value: 'SINGLE_TIME_ROUND', label: 'One-time round' }
];
