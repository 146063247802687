import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import TableCell from '@mui/material/TableCell';

import { DomainDto } from '@/logicLayers/domain';

import { skeletonTableCellRect } from '@/separatedModules/components/Table';

import { TranslationT } from '@/subsidiaryBinders/types';

import { i18n } from '@/i18n';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT) => ReactNode;
}

export interface ConfigTableI {
  cells: TableCellI<DomainDto>[];
  pagination: {
    page: number[];
    rowsPerPage: number[];
    rowsPerPageOptions: number[];
  };
}

export const CONFIG_TABLE: ConfigTableI = {
  cells: [
    {
      order: 0,
      columnName: 'title',
      columnClass: 'MuiTableCell-title',
      title: i18n.t('role.table.cells.title', { ns: 'templates' }),
      Render: ({ name }, className, index) => (
        <TableCell key={name + index} className={className}>
          <Text className={'m-0'} variant={'titleSm'} sx={{ maxWidth: '284px' }}>
            {name ?? '-'}
          </Text>
        </TableCell>
      )
    },
    {
      order: 1,
      columnName: 'lastModified',
      columnClass: 'MuiTableCell-lastModified',
      title: i18n.t('role.table.cells.lastModified', { ns: 'templates' }),
      Render: ({ lastModifiedDate }, className, index) => {
        return (
          <TableCell key={lastModifiedDate + index} className={className}>
            {lastModifiedDate ? dayjs(lastModifiedDate).format('DD MMM YYYY, HH:mm A') : '-'}
          </TableCell>
        );
      }
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [[skeletonTableCellRect], [skeletonTableCellRect], [skeletonTableCellRect]];
