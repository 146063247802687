import { useState } from 'react';
import { Button } from 'infinitytechnologies-ui';

import { ExportCSVModal } from './components/ExportCSVModal';

export const ExportCSVWrapper = () => {
  const [isOpenCSVModal, setIsOpenCSVModal] = useState(false);

  return (
    <>
      <ExportCSVModal isOpenCSVModal={isOpenCSVModal} handleCloseCSVModal={() => setIsOpenCSVModal(false)} />
      <Button variant={'contained'} onClick={() => setIsOpenCSVModal(true)}>
        Export CSV
      </Button>
    </>
  );
};
