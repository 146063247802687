import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { EquipmentQuery, GET_EQUIPMENT } from '@/logicLayers/domain';

import { EditPageWrap, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { EditProvider } from './components';

const useQueryData = ({ templateId }: { templateId: string }) => {
  const { data: dataEquipmentTemplate, loading: loadingEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: templateId || undefined
    }
  });

  return {
    dataEquipmentTemplate,
    loadingEquipmentTemplate
  };
};

export const TemplatesEquipmentItemEditPage = () => {
  const [searchParams] = useSearchParams();
  const { templateId = '' } = useParams();
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');

  const { dataEquipmentTemplate, loadingEquipmentTemplate } = useQueryData({ templateId });

  const editValue = searchParams.get('field') ?? '';
  const isLoading = loadingEquipmentTemplate;

  const isEquipmentTemplate = location.pathname.includes('templates');
  const navigateToUrl = isEquipmentTemplate
    ? LINKS_PAGES.templatesEquipmentItem.replace(':templateId', templateId)
    : LINKS_PAGES.equipmentModel.replace(':templateId', templateId);
  const isContact = editValue.includes('emergencyContact');

  const Component = dataEquipmentTemplate?.equipment
    ? EditProvider({
        ...dataEquipmentTemplate,
        /* custom fields */
        editType: editValue,
        navigateToUrl,
        tModel: tTemplates,
        tGlobal,
        isEquipmentTemplate
      })
    : null;

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={isContact ? 10 : 6} item>
        {isLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
        ) : (
          <EditPageWrap navigateTo={navigateToUrl}>
            {Component ? Component : <Navigate to={LINKS_PAGES.home} />}
          </EditPageWrap>
        )}
      </Grid>
    </Grid>
  );
};
