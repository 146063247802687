import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Dayjs } from 'dayjs';
import QRCodeStyling from 'qr-code-styling';

import { EquipmentRes } from '@/logicLayers/domain';

import { MenuOption } from '@/separatedModules/components';
import { ConfigTableI } from '@/separatedModules/components';

import { CONFIG_TABLE } from '../../helpers';

export interface FirstMountState {
  isFirstMount: boolean;
  initCount: number;
}

export type CompanyFilters = {
  search: string[];
  hiddenColumns: string[];
  sortByDomains: string[];
  sortByDate: MenuOption[];
};

export const generateSvgString = (systemName: string) => {
  return `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 400" width="1600" height="400" preserveAspectRatio="xMidYMid meet">
    <rect width="100%" height="100%" fill="white"/>
    <text x="50%" y="50%" dominant-baseline="central" text-anchor="middle" font-family="Arial" font-size="260" fill="black">
      ${systemName}
    </text>
  </svg>
`;
};

export const generateImageFromSvg = async (svgString: string) => {
  const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
  const svgUrl = URL.createObjectURL(svgBlob);
  const img = new Image();
  img.src = svgUrl;

  return new Promise<string>((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const pngUrl = canvas.toDataURL('image/png');
        resolve(pngUrl);
      } else {
        reject('Failed to generate image');
      }
    };
    img.onerror = () => {
      reject('Failed to load SVG image');
    };
  });
};

export const qrCode = new QRCodeStyling({
  width: 180,
  height: 180,
  data: '',
  image: '',
  dotsOptions: {
    color: '#000000',
    type: 'rounded'
  },
  backgroundOptions: {
    color: 'transparent'
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    imageSize: 0.7
  },
  qrOptions: {
    errorCorrectionLevel: 'M'
  }
});

export interface initStateI extends Omit<ConfigTableI, 'cellsHeader' | 'cellsBody' | 'cells'> {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: CompanyFilters;
  selectedItems: string[];
  bodyDataRows: EquipmentRes[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
  isOpenMoveModal: boolean;
  isOpenQrCodeModal: boolean;
  currentStep: number;
  totalSteps: number;
  allChecked: boolean;
  size: string;
  progress: number;
  error: string | null;
  CSVFilters: {
    sortByCSVStartDate: Dayjs | null;
    sortByCSVEndDate: Dayjs | null;
    sortByLocations: string[] | [];
    sortByDomain: string | null;
    sortByCyclicality: string | null;
  };
}

export interface ContextStateI extends initStateI {
  loading: boolean;
  setState: Dispatch<SetStateAction<initStateI>>;
}

export interface ContextUpdateStateI {
  handleChangeBySearch: (value: string) => void;
  handleChangePagination: (a: number) => void;
  handleChangeRowsPerPage: (a: number) => void;
  handleChangeSortByDate: () => void;
  handleClickRow: (a: string) => void;
  handleResetSelectedRows: () => void;
  handleSelectRow: (a: string) => void;
  handleShowAllColumns: () => void;
  handleToggleActions: () => void;
  handleToggleColumnVisibility: (key: string) => void;
  handleChangeSortByDomains: (a: string[]) => void;
  handleDoubleClickRow: (a: string) => void;
  handleSetPrevStep: () => void;
  handleSetNextStep: () => void;
  toggleChecked: (id: string) => void;
  toggleAllChecked: (isChecked: boolean) => void;
  handleSetCSVStartDate: (startDate: Dayjs | null) => void;
  handleSetCSVEndDate: (endDate: Dayjs | null) => void;
  handleResetStep: () => void;
  handleSortByLocations: (locationId: string[]) => void;
  sortByDomain: (domainId: string) => void;
  sortByCyclicality: (value: string) => void;
}

export interface TableProviderProps {
  children: ReactNode;
}

// ToDo Refactor
const getHiddenColumns = localStorage.getItem('locationsTableColumns');

export const initState: initStateI = {
  filters: {
    search: [],
    // ToDo Refactor
    hiddenColumns: getHiddenColumns ? JSON.parse(getHiddenColumns) : [],
    sortByDomains: [],
    sortByDate: []
  },
  actions: {},
  pagination: CONFIG_TABLE.pagination,
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  bodyDataRows: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false,
  isOpenMoveModal: false,
  isOpenQrCodeModal: false,
  currentStep: 1,
  totalSteps: 2,
  allChecked: true,
  size: '',
  progress: 0,
  error: null,
  CSVFilters: {
    sortByCSVStartDate: null,
    sortByCSVEndDate: null,
    sortByLocations: [],
    sortByDomain: null,
    sortByCyclicality: null
  }
};
