import { ReactNode, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TypedDocumentNode, useMutation } from '@apollo/client';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { ROUND_FIELDS } from '@/separatedModules/pages/Templates/pages/Equipment/pages/RoundRegulations/pages/EditRound/components/RoundEditProvider/helpers';

import { ContextConsumer } from './ContextConsumer';
import { ContextProvider } from './ContextProvider';

interface EditRoundParametersProps {
  ContentAfterTitle?: ReactNode;
  selectedItems: any[];
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  onSubmitForm: (getValues: any, updateModel: any) => void;
  navigateToUrl: string;
}

export const EditRoundParameters = ({
  ContentAfterTitle,
  selectedItems,
  mutationQuery,
  onSubmitForm,
  successAlertMessage,
  navigateToUrl,
  errorAlertMessage
}: EditRoundParametersProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const { control } = useForm({
    mode: 'all',
    defaultValues: {
      [ROUND_FIELDS.PARAMETERS]: {
        correctParameters: selectedItems.map((item, index) => ({
          id: item.parameterDetails.id,
          addNote: item.addNote,
          uploadPhoto: item.uploadPhoto,
          order: index,
          condition: item.condition
            ? {
                exactValue: item.condition.exactValue,
                maxValue: item.condition.maxValue,
                minValue: item.condition.minValue,
                type: item.condition.type,
                valueAliases: item.condition?.valueAliases?.map(
                  (obj: { key: string; value: string; __typename?: string }) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { __typename, ...rest } = obj;
                    return rest;
                  }
                )
              }
            : null
        })),
        parameters: selectedItems.map((item, index) => ({
          ...item.parameterDetails,
          addNote: item.addNote,
          uploadPhoto: item.uploadPhoto,
          order: index,
          condition: item.condition
            ? {
                exactValue: item.condition.exactValue,
                maxValue: item.condition.maxValue,
                minValue: item.condition.minValue,
                type: item.condition.type,
                valueAliases: item.condition?.valueAliases?.map(
                  (obj: { key: string; value: string; __typename?: string }) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { __typename, ...rest } = obj;
                    return rest;
                  }
                )
              }
            : null
        }))
      }
    }
  });

  const [updatedData, { data, error }] = useMutation(mutationQuery);

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const watchedValues = useWatch({
    control,
    name: ROUND_FIELDS.PARAMETERS
  });

  const handleSubmitForm = () => {
    onSubmitForm?.(watchedValues, updatedData);
  };

  return (
    <Controller
      name={ROUND_FIELDS.PARAMETERS}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field;

        return (
          <ContextProvider>
            <ContextConsumer
              ContentAfterTitle={ContentAfterTitle}
              selectedItems={value.parameters}
              value={value.correctParameters}
              handleSubmitEditForm={handleSubmitForm}
              onChangeParameters={(parameters: any) => {
                onChange((prevValue: any) => {
                  return {
                    ...prevValue,
                    parameters
                  };
                });
              }}
              onChange={(correctParameters: any) => {
                onChange((prevValue: any) => ({
                  ...prevValue,
                  correctParameters
                }));
              }}
            />
          </ContextProvider>
        );
      }}
    />
  );
};
