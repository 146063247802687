import { gql as gqlApollo } from '@apollo/client';

const LOCATION_ITEM_FRAGMENT = gqlApollo(`
  fragment locationFolderRes on LocationFolderRes {
    id
    name
    active
    parentsLocationFolders {
      depth
      id
      name
    }
  }
`);

export const GET_EQUIPMENT = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query Equipment($id: String!) {
    equipment(id: $id) {
      active
      archived
      createdDate
      companyId
      itemDescription
      templateDescription
      id
      lastModifiedDate
      manufacturer
      modelNumber
      systemName
      name
      specifications
      domainId
      domain {
        color
        name
        itemsCount
        id
      }
      responsible {
        email
        id
        status
        firstName
        lastName
        phoneNumber
        userAvatar {
          url
        }
      }
      createdBy {
        email
        id
        status
        firstName
        lastName
        phoneNumber
        userAvatar {
          url
        }
      }
      location {
        ...locationFolderRes
        groupItems: locationFolders {
          items {
            ...locationFolderRes
            groupItems: locationFolders {
              items {
                ...locationFolderRes
                groupItems: locationFolders {
                  items {
                    ...locationFolderRes
                    groupItems: locationFolders {
                      items {
                        ...locationFolderRes
                        groupItems: locationFolders {
                          items {
                            ...locationFolderRes
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_ITEM_NAME = gqlApollo(`
  query EquipmentItemName($id: String!) {
    equipment(id: $id) {
      id
      name
    }
  }
`);

export const GET_EQUIPMENT_ITEM_INFORMATION = gqlApollo(`
  query EquipmentItemInformation($id: String!) {
    equipment(id: $id) {
      id
      name
      domain {
        color
        name
        id
      }
    }
  }
`);

export const EDIT_EQUIPMENT = gqlApollo(`
  mutation EditEquipment($id: String!, $equipment: EditEquipmentReqInput!) {
    editEquipment(id: $id, equipment: $equipment) {
      id
    }
  }
`);

export const EDIT_PARAMETER = gqlApollo(`
  mutation editEquipmentParameter($parameterId: String!, $editRequest: EditParameterRequestInput!) {
    editEquipmentParameter(parameterId: $parameterId, editRequest: $editRequest) {
      id
    }
  }
`);

export const EDIT_ROUND = gqlApollo(`
  mutation editRoundRegulation($roundId: String!, $editRequest: EditRoundRegulationReqInput!) {
    editRoundRegulation(roundId: $roundId, editRequest: $editRequest) {
      id
    }
  }
`);

export const EDIT_ROUND_TASK = gqlApollo(`
  mutation editRound($roundId: String!, $editRequest: EditRoundReqInput!) {
    editRound(roundId: $roundId, editRequest: $editRequest) {
      id
    }
  }
`);

export const EDIT_ROUND_PARAMETER = gqlApollo(`
  mutation editRoundRegulationParameters($roundId: String!, $editRequest: EditRoundParametersReqInput!) {
    editRoundRegulationParameters(roundId: $roundId, editRequest: $editRequest) {
      id
    }
  }
`);

export const EDIT_ROUND_TASK_PARAMETER = gqlApollo(`
  mutation editRoundParameters($roundId: String!, $editRequest: EditRoundParametersReqInput!) {
    editRoundParameters(roundId: $roundId, editRequest: $editRequest) {
      id
    }
  }
`);

export const GET_EQUIPMENTS = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query Equipments($searchCriteria: SearchCriteriaInput, $type: EquipmentType!, $templateId: String, $locationIds: [String]) {
    dataItems: equipments(searchCriteria: $searchCriteria, type: $type, templateId: $templateId, locationIds: $locationIds) {
      items {
        active
        archived
        createdDate
        lastModifiedDate
        templateDescription
        itemDescription
        domainId
        id
        companyId
        manufacturer
        modelNumber
        name
        specifications
        systemName
        locationId
        location {
          ...locationFolderRes
          groupItems: locationFolders(searchCriteria: $searchCriteria) {
            items {
              ...locationFolderRes
              groupItems: locationFolders(searchCriteria: $searchCriteria) {
                items {
                  ...locationFolderRes
                  groupItems: locationFolders(searchCriteria: $searchCriteria) {
                    items {
                      ...locationFolderRes
                      groupItems: locationFolders(searchCriteria: $searchCriteria) {
                        items {
                          ...locationFolderRes
                          groupItems: locationFolders(searchCriteria: $searchCriteria) {
                            items {
                              ...locationFolderRes
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        domain {
          color
          id
          name
          itemsCount
        }
      }
      total
    }
  }
`;

export const CREATE_EQUIPMENT = gqlApollo(`
  mutation CreateEquipment($equipment: CreateEquipmentReqInput!) {
    createEquipment(equipment: $equipment) {
      id
      name
      systemName
    }
  }
`);

export const CREATE_EQUIPMENT_BY_TEMPLATE = gqlApollo(`
  mutation CreateEquipmentByTemplate($templateId: String!, $locationId: String) {
    createEquipmentByTemplate(templateId: $templateId, locationId: $locationId) {
      id
      name
      systemName
    }
  }
`);

export const SWITCH_EQUIPMENTS_ACTIVE_STATE = gqlApollo(`
  mutation SwitchEquipmentsActiveState($request: SwitchEquipmentActiveStateReqInput!) {
    switchEquipmentsActiveState(request: $request)
  }
`);

export const SWITCH_EQUIPMENTS_ARCHIVE_STATE = gqlApollo(`
  mutation SwitchEquipmentsArchiveState($request: SwitchEquipmentArchiveStateReqInput!) {
    switchEquipmentsArchiveState(request: $request)
  }
`);

export const SWITCH_EQUIPMENT_PARAMETERS_ARCHIVE_STATE = gqlApollo(`
  mutation SwitchEquipmentParametersArchiveState($request: SwitchParameterArchiveStateReqInput!) {
    switchEquipmentParametersArchiveState(request: $request)
  }
`);

export const SWITCH_EQUIPMENT_PARAMETERS_ACTIVE_STATE = gqlApollo(`
  mutation SwitchEquipmentParametersActiveState($request: SwitchParameterActiveStateReqInput!) {
    switchEquipmentParametersActiveState(request: $request)
  }
`);

export const CREATE_EQUIPMENT_PARAMETER = gqlApollo(`
  mutation CreateEquipmentParameter($parameter: CreateParameterReqInput!) {
    createEquipmentParameter(parameter: $parameter) {
      createdBy
      description
      equipmentId
      propertyId
      id
      name
    }
  }
`);

export const GET_EQUIPMENT_PARAMETERS = gqlApollo(`
  query EquipmentParameters($equipmentId: String!, $searchCriteria: SearchCriteriaInput, $types: [ParameterType]) {
    dataItems: equipmentParameters(equipmentId: $equipmentId, searchCriteria: $searchCriteria, types: $types) {
      items {
        active
        archived
        description
        equipmentId
        createdDate
        readableFormula
        id
        propertyId
        name
        type
        unitId
        value
      }
      total
    }
  }
`);

export const GET_ROUND_REGULATIONS_CSV = gqlApollo`
   query RoundRegulationsCSV(
      $fromDate: Date!, 
      $toDate: Date!, 
      $frequency: RoundFrequencyEnum!, 
      $locationIds: [String]!, 
      $domainId: String!
   ) {
      dataItems: exportRoundsInCsv(
         fromDate: $fromDate, 
         toDate: $toDate, 
         frequency: $frequency, 
         locationIds: $locationIds, 
         domainId: $domainId
      ) {
         content
         fileName
      }
   }
`;

export const GET_EQUIPMENT_ROUND_REGULATIONS = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query RoundRegulations($equipmentIds: [String], $searchCriteria: SearchCriteriaInput, $type: RoundType!, $frequencies: [RoundFrequencyEnum]) {
    dataItems: rounds(equipmentIds: $equipmentIds, searchCriteria: $searchCriteria, type: $type, frequencies: $frequencies) {
      items {
       name
       id
       description
       serviceStaff {
        userAvatar {
          url
        }
        firstName
        lastName
       }
       createdDate
       lastModifiedDate
       activationTargetDate
       equipment {
          name
          id
          domain {
            color
            id
            name
          }
          location {
            ...locationFolderRes
            groupItems: locationFolders(searchCriteria: $searchCriteria) {
              items {
                ...locationFolderRes
                groupItems: locationFolders(searchCriteria: $searchCriteria) {
                  items {
                    ...locationFolderRes
                    groupItems: locationFolders(searchCriteria: $searchCriteria) {
                      items {
                        ...locationFolderRes
                        groupItems: locationFolders(searchCriteria: $searchCriteria) {
                          items {
                            ...locationFolderRes
                            groupItems: locationFolders(searchCriteria: $searchCriteria) {
                              items {
                                ...locationFolderRes
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } 
       }
       frequency
       active
      }
      total
    }
  }
`;

export const CREATE_EQUIPMENT_ROUND = gqlApollo(`
  mutation createRoundRegulation($createRequest: CreateRoundRegulationRequestInput!) {
    createRoundRegulation(createRequest: $createRequest){
      id
    }
  }
`);

export const GET_EQUIPMENT_ROUND_REGULATION = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query RoundRegulation($searchCriteria: SearchCriteriaInput, $roundId: String!) {
    dataItems: round(roundId: $roundId) {
      id
      name
      description
      serviceStaff {
        userAvatar {
          url
        }
        firstName
        lastName
        id
        domains {
          name
          color
        }
      }
      createdBy {
        userAvatar {
          url
        }
        firstName
        lastName
        id
        domains {
          name
          color
        }
      }
      createdDate
      lastModifiedDate
      activationTargetDate
      completionDate
      frequency
      active,
      archived
      status
      equipment {
          id
          domain {
            color
            name
          }
          name 
          location {
            ...locationFolderRes
            groupItems: locationFolders(searchCriteria: $searchCriteria) {
              items {
                ...locationFolderRes
                groupItems: locationFolders(searchCriteria: $searchCriteria) {
                  items {
                    ...locationFolderRes
                    groupItems: locationFolders(searchCriteria: $searchCriteria) {
                      items {
                        ...locationFolderRes
                        groupItems: locationFolders(searchCriteria: $searchCriteria) {
                          items {
                            ...locationFolderRes
                            groupItems: locationFolders(searchCriteria: $searchCriteria) {
                              items {
                                ...locationFolderRes
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } 
       } 
      parameters {
        condition {
          exactValue
          maxValue
          minValue
          type
          valueAliases {
              key 
              value
          }
        }
        description
        id
        uploadPhoto
        addNote
        parameterDetails {
          active
          archived
          description
          value
          order
          name
          type
          readableFormula
          unit {
            name
          }
          id
        }
        parameterResult {
          id
          status
          value
          photoUrl {
            fileKey
            url
          }
          note
          cancelReason
        }
      }
    }
  }
`;

export const SWITCH_ROUND_ARCHIVE_STATE = gqlApollo(`
  mutation SwitchRoundArchiveState($request: SwitchRoundArchiveStateRequestInput!) {
    switchRoundArchiveState(request: $request)
  }
`);

export const SWITCH_ROUND_ACTIVE_STATE = gqlApollo(`
  mutation SwitchRoundActiveState($request: SwitchRoundActiveStateRequestInput!) {
    switchRoundActiveState(request: $request)
  }
`);

export const GET_EQUIPMENT_PARAMETER = gqlApollo(`
  query EquipmentParameter($id: String!) {
    dataItems: equipmentParameter(id: $id) {
      active,
      archived,
      createdBy,
      createdDate,
      description,
      equipmentId,
      nameId,
      id,
      lastModifiedDate,
      order,
      propertyId,
      name,
      type,
      unitId,
      readableFormula,
      unit {
        name
      }
      value
    }
  }
`);

export const MOVE_EQUIPMENTS_TO_LOCATION = gqlApollo(`
  mutation MoveEquipmentsToLocation($equipmentIds: [String]!, $locationId: String) {
    moveEquipmentsToLocation(moveRequest: { equipmentIds: $equipmentIds, locationId: $locationId })
  }
`);

export const GET_ROUND_PARAMETERS = gqlApollo(`
  query RoundParameters($roundId: String!, $types: [ParameterType], $excludeDisabled: Boolean) {
    dataItems: roundParameters(roundId: $roundId, types: $types, excludeDisabled: $excludeDisabled) {
      addNote
      condition {
        exactValue
        maxValue
        minValue
        type
        valueAliases {
          key 
          value
        }
      } 
      description
      id
      uploadPhoto
      validationConditions
      parameterDetails {
        active
        archived
        description
        value
        order
        name
        type
        readableFormula
        unit {
          name
        }
        id
      }
    }
  }
`);

export const UPDATE_PARAMETER_ORDER = gqlApollo(`
  mutation UpdateParameterOrder($request: UpdateParameterOrderReqInput!) {
    updateParameterOrder(request: $request)
  }
`);
