import { MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { CustomTaskRes, UPDATE_TASK_STATUS } from '@/logicLayers/domain';

import { DotIcon, MoreVertIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface ActionMenuProps {
  refetchTask: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<CustomTaskRes>>;
  renderItemTemplateStatus: string;
}

export const ActionMenu = ({ refetchTask, renderItemTemplateStatus }: ActionMenuProps) => {
  const { t: tTasks } = useTranslation('tasks');
  const { taskId = '' } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [UpdateTaskStatus] = useMutation(UPDATE_TASK_STATUS);

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeStatus = (status: string) => {
    if (renderItemTemplateStatus === 'IN_PROGRESS') {
      UpdateTaskStatus({
        variables: {
          taskId,
          status: status
        },
        onCompleted: refetchTask
      });
    }
  };

  return (
    <Box component={'td'} className={'table-edit-button'}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={'custom-task-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'custom-task-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeStatus('PENDING')}>
          <DotIcon />
          {tTasks('tasksCustom.status.pending')}
        </MenuItem>

        <MenuItem onClick={() => handleChangeStatus('CANCELED')}>
          <DotIcon fill={'#BE0E2C'} />
          {tTasks('tasksCustom.status.canceled')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
