import { Button } from 'infinitytechnologies-ui';

import { PlusIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface AddUnitButtonProps {
  onOpenModal: () => void;
}

export const AddUnitButton = ({ onOpenModal }: AddUnitButtonProps) => {
  const { t: tTemplates } = useTranslation('templates');

  return (
    <>
      <Button variant={'contained'} startIcon={<PlusIcon />} disableElevation onClick={onOpenModal}>
        {tTemplates('domains.create.btnAdd')}
      </Button>
    </>
  );
};
