import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';

import { InfoIcon, SearchAutocomplete } from '@/separatedModules/components';

import { StyledTableFiltersBox } from './style';

type TableFiltersBoxProps = {
  searchPlaceholder: string;
  sxFiltersContainer?: SxProps<Theme>;
  sxCollapse?: SxProps<Theme>;
  sxBoxFilters?: SxProps<Theme>;
  sxBoxRight?: SxProps<Theme>;
  selectedItems?: any[];
  children: ReactNode;
  withSearch?: boolean;
  withInfoIcon?: boolean;
  isCompactSearch?: boolean;
  handleToggleAside?: () => void;
  handleChangeBySearch: (value: string) => void;
};

export const TableFiltersBox = ({
  searchPlaceholder,
  sxFiltersContainer,
  children,
  withSearch = true,
  withInfoIcon = true,
  handleToggleAside,
  handleChangeBySearch,
  isCompactSearch = false
}: TableFiltersBoxProps) => {
  const handleClearSearch = () => handleChangeBySearch('');
  const handleChangeSearch = (value: string) => handleChangeBySearch(value || '');

  return (
    <StyledTableFiltersBox sx={sxFiltersContainer} withSearch={withSearch}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{children}</Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <SearchAutocomplete
            placeholder={searchPlaceholder}
            options={[]}
            sx={{ margin: '0', width: isCompactSearch ? '138px' : '100%' }}
            onChange={handleChangeSearch}
            onClear={handleClearSearch}
          />

          {withInfoIcon && (
            <IconButton size={'medium'} sx={{ borderRadius: '100%' }} onClick={handleToggleAside}>
              <InfoIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </StyledTableFiltersBox>
  );
};
