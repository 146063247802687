import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, IconButton } from '@mui/material';

import {
  COMPLETE_ROUND,
  GET_EQUIPMENT_ROUND_REGULATION,
  GET_ROUND_PARAMETERS,
  GET_TASKS,
  ParameterType,
  RoundParametersQuery,
  RoundRegulationQuery
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { getBreadcrumbs } from '@/separatedModules/pages/Locations/components/PageTopBox/helpers';
import { ParameterItem } from '@/separatedModules/pages/Tasks/pages/ExecuteTaskMobile/components/ParameterItem/ParameterItem';

import {
  ArrowBackIcon,
  Breadcrumbs,
  ChevronRightIcon,
  EMPTY_CELL_VALUE,
  Flex,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { transformObjectToArray } from './helpers';

export const ExecuteTaskMobile = () => {
  const { taskId = '' } = useParams();
  const navigateTo = useNavigate();

  const { t: tGlobal } = useTranslation('global');
  const { t: tTasks } = useTranslation('tasks');

  const methods = useForm({
    mode: 'all'
  });

  const { data: dataTask, loading } = useQuery<RoundRegulationQuery>(GET_EQUIPMENT_ROUND_REGULATION, {
    fetchPolicy: 'no-cache',
    variables: {
      roundId: taskId || undefined,
      searchCriteria: {}
    }
  });

  const { data: dataParameters } = useQuery<RoundParametersQuery>(GET_ROUND_PARAMETERS, {
    fetchPolicy: 'no-cache',
    variables: {
      roundId: taskId || undefined,
      excludeDisabled: true,
      types: ['STRING', 'NUMERIC_INPUT', 'BOOLEAN', 'DATA_TIME']
    }
  });

  const [completeRound] = useMutation(COMPLETE_ROUND);

  const onSubmit = (data: any) => {
    const result = transformObjectToArray(data);
    completeRound({
      variables: { roundId: taskId, request: { parameters: result } },
      refetchQueries: [GET_TASKS],
      onCompleted: () => {
        navigateTo('/');
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.taskCompleted'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const renderItemName = dataTask?.dataItems.name || EMPTY_CELL_VALUE;
  const renderItemEquipment = dataTask?.dataItems.equipment;

  const equipmentBreadcrumbs = getBreadcrumbs(renderItemEquipment?.[0].location, true, true);

  return (
    <>
      {loading ? (
        <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer />
      ) : (
        <Box sx={{ height: '100vh' }}>
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            sx={{
              width: '100vw',
              padding: '16px',
              position: 'sticky',
              top: '0px',
              background: '#ffffff',
              zIndex: '1000'
            }}
          >
            <IconButton onClick={() => navigateTo(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Box>
              <Text component={'h2'} variant={'titleXl'} sx={{ fontSize: '18px', fontWeight: 600, margin: '0' }}>
                {renderItemName}
              </Text>
              <Breadcrumbs
                links={equipmentBreadcrumbs}
                separator={<ChevronRightIcon sx={{ height: '10px', width: '16px' }} />}
                sx={{
                  padding: 0,
                  width: 'calc(100vw - 64px)',
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
                fontSize="12px"
                separatorStyle={{ padding: '0px', width: '16px' }}
              />
            </Box>
          </Flex>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box sx={{ padding: '20px 16px 20px 16px' }}>
                {dataParameters?.dataItems
                  ?.filter(
                    (item) =>
                      item.parameterDetails.type !== ParameterType.NumericFixed &&
                      item.parameterDetails.type !== ParameterType.NumericFormula
                  )
                  .map((item, index) => <ParameterItem key={index} item={item} />)}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '16px 16px 40px 16px',
                  position: 'sticky',
                  bottom: '0px',
                  background: '#ffffff'
                }}
              >
                <Button
                  className="m-0"
                  variant={'contained'}
                  sx={{ width: '100%' }}
                  type="submit"
                  disabled={!methods.formState.isValid}
                >
                  {tTasks('mobile.roundForm.generateReportBtn')}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      )}
    </>
  );
};
