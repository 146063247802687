interface DataItems {
  content: string;
  fileName: string;
}

export const downloadCSV = (dataItems: DataItems, onClose: () => void) => {
  try {
    const { content, fileName } = dataItems;
    const decodedContent = atob(content);
    const blob = new Blob([decodedContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onClose();
  } catch (error) {
    console.error(error);
  }
};
