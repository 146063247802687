import { Divider } from '@mui/material';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_SIDEBAR_TASKS_TOP_MENU, LINKS_SIDEBAR_TEMPLATES_BOTTOM_MENU } from '@/subsidiaryBinders/constants';

import { NavListSimple } from '../NavListSimple';
import { NavListSimpleWithBtn } from '../NavListSimpleWithBtn';

export const SideBarTasks = () => {
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  return (
    <Sidebar>
      <SideBarGroup>
        <NavListSimpleWithBtn
          menu={LINKS_SIDEBAR_TASKS_TOP_MENU}
          insertValue={undefined}
          replaceValue={undefined}
          isDisabledButton={!isUserCanCRUD}
        />
      </SideBarGroup>

      <Divider sx={{ mt: '8px', mb: '8px' }} />

      <SideBarGroup>
        <NavListSimple
          menu={LINKS_SIDEBAR_TEMPLATES_BOTTOM_MENU.filter((item) => !item.isHidden)}
          insertValue={undefined}
          replaceValue={undefined}
        />
      </SideBarGroup>
    </Sidebar>
  );
};
