import { ReduxStore } from '@/logicLayers/infrastructure/redux';
import { AuthService } from '@/logicLayers/infrastructure/services';

import { parseJwt } from '@/utils/global';

import { PERMISSION_TAGS } from './permissions';

interface ParsedToken {
  sub: string;
  realm_access: {
    roles: string[];
  };
}

interface SuperAdminReturnType {
  superAdminId: string | undefined;
  superAdminTemplateId: string;
  isSuperAdmin: boolean;
}

export const SUPER_ADMIN_TEMPLATE_ID = 'f72e18f9-3490-4470-82d1-3be43b9e492e';

export const isSuperAdmin = (): boolean => {
  const {
    user: { profile }
  } = ReduxStore.getState();

  if (!profile.permissions.length) {
    return false;
  }

  return profile.permissions.some((role) => role?.name === PERMISSION_TAGS.ROLE_SUPER_ADMIN);
};

export const useSuperAdmin = (): SuperAdminReturnType => {
  const {
    user: { profile }
  } = ReduxStore.getState();

  const isUserSuperAdmin = isSuperAdmin();

  const token = AuthService.getAccessToken();
  const parsedToken = parseJwt<ParsedToken>(token);

  if (!parsedToken) {
    return {
      superAdminId: undefined,
      superAdminTemplateId: SUPER_ADMIN_TEMPLATE_ID,
      isSuperAdmin: false
    };
  }

  return {
    superAdminTemplateId: SUPER_ADMIN_TEMPLATE_ID,
    superAdminId: profile?.id,
    isSuperAdmin: isUserSuperAdmin
  };
};
