import { gql as gqlApollo } from '@apollo/client';

export const GET_DOCUMENT = gqlApollo(`
    query Document($types: [DocumentType], $searchCriteria: SearchCriteriaInput, $equipmentId: String!) {
      dataItems: documents(equipmentId: $equipmentId, searchCriteria: $searchCriteria, types: $types) {
        items {
          createdBy {
            firstName
            lastName
            userAvatar {
              fileKey
              url
            }
          }
          createdDate
          fileKey
          id
          size
          title
          type
          documentUrl {
            fileKey
            url
          }
        }
        total
      }
    }
`);

export const CREATE_DOCUMENT = gqlApollo(`
  mutation CreateDocument($request: CreateDocumentReqInput!) {
    createDocument(request: $request) {
      id
    }
  }
`);

export const DELETE_DOCUMENT = gqlApollo(`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id) 
  }
`);
