import { useState } from 'react';
import classNames from 'classnames';
import { Autocomplete, AutocompleteProps } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material/styles';

import { TextBlockControl } from '@/separatedModules/components';
import { PlusIcon } from '@/separatedModules/components';

import { StyledAutocompleteControlControl } from '../AutocompleteControl/style';

interface AutocompleteControlProps extends AutocompleteProps {
  title?: string;
  subTitle?: string;
  sxTitle?: SxProps<Theme>;
  sxSubTitle?: SxProps<Theme>;
  sxSelect?: SxProps<Theme>;
}

export const AutoCompleteParamsControl = ({
  title,
  subTitle,
  sxTitle,
  sxSubTitle,
  sxSelect,
  ...rest
}: AutocompleteControlProps) => {
  const isHasTitles = !title && !subTitle;
  const [open, setOpen] = useState(false);

  return (
    <StyledAutocompleteControlControl className={classNames({ 'no-titles': isHasTitles })}>
      <TextBlockControl
        className="control-autocomplete__text-wrp"
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
      />

      <Autocomplete
        sx={sxSelect}
        {...rest}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderOption={(props, option: any) => {
          const isRequestNew = option.value === 'request-new';

          return (
            <li
              {...props}
              onMouseDown={(e) => {
                if (isRequestNew) e.preventDefault();
              }}
              onClick={(e) => {
                if (isRequestNew) {
                  window.location.href = 'mailto:olegk@cyber-bms.com';
                } else if (rest.onChange) {
                  rest.onChange(e, option);
                  setOpen(false);
                }
              }}
              style={{
                display: isRequestNew ? 'flex' : undefined,
                justifyContent: isRequestNew ? 'space-between' : undefined,
                alignItems: isRequestNew ? 'center' : undefined,
                color: isRequestNew ? '#000' : 'inherit'
              }}
            >
              <span>{option.label}</span>
              {isRequestNew && <PlusIcon />}
            </li>
          );
        }}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-listbox': {
                '& .MuiAutocomplete-option[data-option-index="0"]': {
                  borderBottom: '1px solid #ccc'
                }
              }
            }
          }
        }}
      />
    </StyledAutocompleteControlControl>
  );
};
