import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Drawer, Grid } from '@mui/material';

import { EquipmentParametersQuery } from '@/logicLayers/domain';
import { ParameterType } from '@/logicLayers/domain';

import { ParameterItem as FormParameterItem } from '@/separatedModules/pages/Tasks/pages/ExecuteTaskMobile/components/ParameterItem';

import { AddParameter } from './components/AddParameter';
import { RoundDescription } from './components/RoundDescription';
import { SelectedParametersList } from './components/SelectedParametersList';
import { StepParametersHeader } from './components/StepParametersHeader';

import { ROUND_PARAMETERS, ROUND_SELECTED_PARAMETERS } from '../../helpers';

export type EquipmentParameterItem = NonNullable<EquipmentParametersQuery['dataItems']['items'][number]>;

export const Step4 = () => {
  const { watch, setValue } = useFormContext();

  const params: EquipmentParameterItem[] = watch(ROUND_PARAMETERS);
  const selectedParams: EquipmentParameterItem[] = watch(ROUND_SELECTED_PARAMETERS);

  const [parameters, setParameters] = useState(params ?? []);
  const [selectedParameters, setSelectedParameters] = useState(selectedParams ?? []);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const newItems = [...parameters];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setParameters(newItems);
    setValue(ROUND_PARAMETERS, newItems);

    const newSelectedItems = [...selectedParameters];
    const [movedSelectedItem] = newSelectedItems.splice(result.source.index, 1);
    newSelectedItems.splice(result.destination.index, 0, movedSelectedItem);

    setSelectedParameters(newSelectedItems);
    setValue(
      ROUND_SELECTED_PARAMETERS,
      newSelectedItems.map((item, index) => ({ ...item, order: index }))
    );
  };

  useEffect(() => {
    setSelectedParameters(selectedParams);
    setParameters(params);
  }, [selectedParams, params]);

  const methods = useForm({
    mode: 'all'
  });

  const formattedData = useMemo(
    () =>
      parameters
        ?.filter((item) => item.type !== ParameterType.NumericFixed && item.type !== ParameterType.NumericFormula)
        .map((item) => ({
          addNote: item.addNote,
          uploadPhoto: item.uploadPhoto,
          description: item.description,
          id: item.id,
          parameterDetails: {
            active: item.active,
            archived: item.archived,
            description: item.description,
            name: item.name,
            readableFormula: item.readableFormula,
            type: item.type,
            value: item.value
          },
          condition: selectedParameters.find((selectedItem) => selectedItem.id === item.id)?.condition
        })),
    [parameters, selectedParameters]
  );

  return (
    <Grid justifyContent={'center'} container row mb={'77px'} sx={{ marginTop: '48px' }}>
      <Grid xs={12} sm={10} md={8} lg={7} item sx={{ padding: '0px' }}>
        <StepParametersHeader handleOpenRoundForm={() => setIsDrawerOpen(true)} />
        <AddParameter />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                {parameters.map((parameter, index) => (
                  <Draggable key={parameter.id} draggableId={parameter.id ?? ''} index={index}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <SelectedParametersList parameter={parameter} />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <RoundDescription />
        <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} anchor="right">
          <Box
            sx={{
              backgroundColor: '#F5F7FA',
              borderRadius: '4px',
              height: 'fit-content',
              width: '300px'
            }}
          >
            <FormProvider {...methods}>
              <Box sx={{ padding: '20px 16px 20px 16px' }}>
                {formattedData.map((item, index) => (
                  <FormParameterItem key={index} item={item} isPreview />
                ))}
              </Box>
            </FormProvider>
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  );
};
