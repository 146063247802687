import { FC, useState } from 'react';

import { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import { AutocompleteChangeReason } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { CloseIcon, SearchIcon } from '@/separatedModules/components';

import { StyledAutocomplete, StyledIconButton, Wrapper } from './style';

type Value = AutocompleteValue<any, any, any, any>;

interface SearchAutocompleteProps {
  placeholder: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  options?: Value[];
  onChange: (value: string) => void;
  onClear: () => void;
}

export const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  placeholder,
  options = [],
  sx,
  disabled,
  onChange,
  onClear
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleSearch = () => {
    onChange(inputValue);
  };

  const handleClear = () => {
    setInputValue('');
    onClear?.();
  };

  const handleChange = (value: Value, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      handleClear();
    }
    if (reason === 'selectOption') {
      onChange(value.title);
    }
  };

  const handleKeyDownChange = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleSearch();
    }
  };

  return (
    // Remove this Wrapper when we will need autocomplete menu options
    <Wrapper width={'100%'}>
      <StyledAutocomplete
        inputValue={inputValue}
        popupIcon={''}
        sx={{
          maxWidth: '584px',
          '.MuiInputBase-root': {
            minHeight: '32px'
          },
          ...sx
        }}
        options={options}
        clearOnBlur={false}
        fullWidth
        disablePortal
        disabled={disabled}
        getOptionLabel={(option) => option.title}
        onKeyDown={handleKeyDownChange}
        onInputChange={(_, value) => setInputValue(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon onClick={handleSearch} sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ display: inputValue ? 'flex' : 'none' }}>
                  <StyledIconButton onClick={handleClear}>
                    <CloseIcon />
                  </StyledIconButton>
                </InputAdornment>
              )
            }}
            placeholder={placeholder}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#3C4656 !important'
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#3C4656 !important'
                }
              }
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} style={{ fontSize: 14 }}>
            {option.title}
          </li>
        )}
        onChange={(_, value, reason) => handleChange(value, reason)}
      />
    </Wrapper>
  );
};
