import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import Stack from '@mui/material/Stack';

import {
  CreateOrEditRoleTemplateReqInput,
  EDIT_ROLE_TEMPLATE,
  GET_ROLE_TEMPLATE_BY_ID,
  GetRoleTemplateQuery,
  updateRequestDataModel
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { FIELD_DESCRIPTION, FIELD_PERMISSIONS, FIELD_ROLE } from '@/separatedModules/pages';

import { Flex, PageTitle, Preloader, PreloaderVariantsE, TextFieldControl } from '@/separatedModules/components';

import { ROLE_DESCRIPTION_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

interface FormProps {
  [FIELD_ROLE]: string;
  [FIELD_DESCRIPTION]?: string | null;
  [FIELD_PERMISSIONS]: string[];
}

export const UpdateRoleDescription: FC = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');

  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();

  const { data: roleTemplateData, loading: roleTemplateDataLoading } = useQuery<GetRoleTemplateQuery>(
    GET_ROLE_TEMPLATE_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: templateId
      }
    }
  );
  const [EditRole, { error: editRoleError }] = useMutation(EDIT_ROLE_TEMPLATE);

  const roleTemplate = roleTemplateData?.roleTemplate;
  const rolePermissions = roleTemplate?.permissions;

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_ROLE]: '',
      [FIELD_DESCRIPTION]: '',
      [FIELD_PERMISSIONS]: []
    }
  });

  useEffect(() => {
    if (roleTemplate?.name) {
      setValue(FIELD_ROLE, roleTemplate?.name);
    }

    if (roleTemplate?.description) {
      setValue(FIELD_DESCRIPTION, roleTemplate?.description);
    }

    if (rolePermissions?.length) {
      setValue(
        FIELD_PERMISSIONS,
        rolePermissions?.map((item) => item?.name ?? '')
      );
    }

    if (editRoleError) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [roleTemplate?.name, rolePermissions, editRoleError]);

  const handleSubmitForm = handleSubmit((data) => {
    EditRole({
      variables: {
        id: templateId,
        ...updateRequestDataModel<GetRoleTemplateQuery, CreateOrEditRoleTemplateReqInput>(
          roleTemplateData as GetRoleTemplateQuery,
          {
            name: data.name,
            description: data.description,
            permissions: data.permissions
          }
        )
      }
    })
      .then(() => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.success.edits'),
          severity: 'success'
        });
        navigateTo(-1);
      })
      .catch(() => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      });
  });

  return (
    <form onSubmit={handleSubmitForm}>
      {roleTemplateDataLoading ? (
        <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
      ) : (
        <Stack spacing={'48px'}>
          <PageTitle
            title={tTemplates('detailsRole.details.mainInfo.roleDescription')}
            sxTitle={{ mt: '32px', mb: '28px' }}
          />

          <Controller
            name={FIELD_DESCRIPTION}
            control={control}
            rules={{
              required: {
                value: false,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: ROLE_DESCRIPTION_REG_EXP,
                message: tGlobal('validation.descriptionLength', {
                  value: 100
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextFieldControl
                label={tTemplates('createRole.mainInfo.descriptionInputLabel')}
                name={FIELD_DESCRIPTION}
                value={value}
                validation={{
                  isValid: !errors[FIELD_DESCRIPTION]?.message,
                  error: errors[FIELD_DESCRIPTION]?.message
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />

          <Flex justifyContent={'flex-end'}>
            <Button variant={'contained'} type={'submit'} disabled={!!errors[FIELD_DESCRIPTION]?.message}>
              {tTemplates('createRole.mainInfo.btnSubmit')}
            </Button>
          </Flex>
        </Stack>
      )}
    </form>
  );
};
