import {
  EquipmentQuery,
  RoundParametersRes,
  RoundRegulationQuery,
  updateEquipmentItemModel,
  updateEquipmentParameterItemModel,
  updateEquipmentRoundItemModel,
  updateEquipmentRoundTaskModel
} from '@/logicLayers/domain';

import { TranslationT } from '@/subsidiaryBinders/types';

export const ROUND_FIELDS = {
  NAME: 'roundTitle',
  DESCRIPTION: 'roundDescription',
  FREQUENCY: 'roundFrequency',
  DATE: 'roundDate',
  STAFF: 'roundStaff',
  PARAMETERS: 'roundParameters'
};

export interface GetEditComponentProps {
  navigateToUrl: string;
  editType: string;
  tGlobal: TranslationT;
  tModel: TranslationT;
  templateId?: string;
  isTask: boolean;
  dataItems?: {
    activationTargetDate: string;
    active: boolean;
    archived: boolean;
    description: string | null;
    frequency: string;
    name: string;
    serviceStaff: [{ firstName: string; lastName: string; userAvatar: { url: string }; id: string }];
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    parameters: Array<RoundParametersRes>;
    equipment?: [
      { name: string; id: string; domain?: { color: string; name: string }; fileUrl: { fileKey: string; url: string } }
    ];
    __typename: 'RoundRes';
  };
}

export const onSubmitUpdateName = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { name } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundItemModel(data.dataItems, { name }) }
      }
    });
  };
};

export const onSubmitUpdateTaskName = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { name } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundTaskModel(data.dataItems, { name }) }
      }
    });
  };
};

export const onSubmitUpdateFrequency = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { frequency } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundItemModel(data.dataItems, { frequency }) }
      }
    });
  };
};

export const onSubmitUpdateDate = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { date: newDate } = getValues();

    const date = newDate.date.format('YYYY-MM-DD');
    const time = newDate.time.format('HH:mm:ss');

    const roundTime = `${date}T${time}`;

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundItemModel(data.dataItems, { activationTargetDate: roundTime }) }
      }
    });
  };
};

export const onSubmitUpdateUsers = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { users } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundItemModel(data.dataItems, { serviceStaff: users }) }
      }
    });
  };
};

export const onSubmitUpdateTaskUsers = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { users } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundTaskModel(data.dataItems, { serviceStaff: users }) }
      }
    });
  };
};

export const onSubmitUpdateParameters = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (values, updateModel) => {
    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { parameters: values.correctParameters }
      }
    });
  };
};

export const onSubmitUpdateRoundDescription = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { description } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundItemModel(data.dataItems, { description }) }
      }
    });
  };
};

export const onSubmitUpdateRoundTaskDescription = (data: RoundRegulationQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { description } = getValues();

    updateModel({
      variables: {
        roundId: data.dataItems.id,
        editRequest: { ...updateEquipmentRoundTaskModel(data.dataItems, { description }) }
      }
    });
  };
};

export const onSubmitUpdateItemDescription = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { itemDescription } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { itemDescription }) }
      }
    });
  };
};

export const onSubmitUpdateModelNumber = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { modelNumber } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { modelNumber }) }
      }
    });
  };
};

export const onSubmitUpdateManufacturer = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { manufacturer } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { manufacturer }) }
      }
    });
  };
};

export const onSubmitUpdateDomainId = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { domainId } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { domainId: domainId[0].value }) }
      }
    });
  };
};

export const onSubmitUpdateParameterTitle = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { title } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { title }) }
      }
    });
  };
};

export const onSubmitUpdateParameterNumericValue = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { value } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { value: value.replace(/[,/]/g, '.') }) }
      }
    });
  };
};

export const onSubmitUpdateParameterDescription = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { description } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { description }) }
      }
    });
  };
};

export const onSubmitUpdateParameterUnit = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { unitId } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { unitId: unitId[0].value }) }
      }
    });
  };
};
