import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Button, SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';

import {
  ArrowForwardIcon,
  ImageLazy,
  SystemStatusChip,
  SystemStatusesEnum,
  TableAsideItem
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { renderBreadcrumbs } from '../../helpers';

export const AsideContent = ({ itemDescription, fileUrl, systemName, active, location, id, ...props }: any) => {
  const { t: tGlobal } = useTranslation('global');

  return (
    <>
      {fileUrl?.url ? (
        <Box sx={{ margin: '20px 0' }}>
          <ImageLazy
            src={fileUrl?.url}
            alt={`Equipment ${name} logo`}
            variant={'rectangular'}
            width={'100%'}
            height={'auto'}
            minHeight={170}
            sxStyle={{
              img: {
                maxHeight: '170px',
                objectFit: 'cover',
                borderRadius: 'var(--4, 4px)'
              }
            }}
          />
        </Box>
      ) : null}

      <TableAsideItem title={tGlobal('table.aside.description')} subTitle={itemDescription} />

      <TableAsideItem title={tGlobal('table.aside.location')} subTitle={renderBreadcrumbs(location)} />

      <TableAsideItem
        title={tGlobal('table.aside.uid')}
        subTitle={
          <Link to={''}>
            <Text sx={{ color: '#0C66E4' }}>{systemName}</Text>
          </Link>
        }
      />

      <TableAsideItem
        title={tGlobal('table.aside.status')}
        subTitle={<SystemStatusChip status={active ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled} />}
      />

      <TableAsideItem
        title={tGlobal('table.aside.creationDate')}
        subTitle={dayjs(props?.createdDate).format('DD MMM YYYY, HH:mm A')}
      />

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        onClick={() => props?.handleChangeTest(id)}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
      >
        {tGlobal('table.aside.detailsBtn')}
      </Button>
    </>
  );
};
