import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function UploadHeader(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 15.4115C11.8795 15.4115 11.7673 15.3923 11.6634 15.3538C11.5596 15.3154 11.4609 15.2493 11.3673 15.1557L8.2577 12.0462C8.10898 11.8974 8.03559 11.7234 8.03753 11.5241C8.03944 11.3247 8.11283 11.1475 8.2577 10.9923C8.41283 10.8372 8.59103 10.7571 8.7923 10.752C8.99358 10.7468 9.17179 10.8218 9.32692 10.977L11.25 12.9V5.25C11.25 5.03718 11.3218 4.85898 11.4654 4.7154C11.609 4.5718 11.7872 4.5 12 4.5C12.2128 4.5 12.391 4.5718 12.5346 4.7154C12.6782 4.85898 12.7499 5.03718 12.7499 5.25V12.9L14.673 10.977C14.8217 10.8282 14.9983 10.7548 15.2028 10.7568C15.4073 10.7587 15.5871 10.8372 15.7422 10.9923C15.8871 11.1475 15.9621 11.3231 15.9672 11.5193C15.9724 11.7154 15.8974 11.891 15.7422 12.0462L12.6327 15.1557C12.5391 15.2493 12.4403 15.3154 12.3365 15.3538C12.2327 15.3923 12.1205 15.4115 12 15.4115ZM6.3077 19.5C5.80257 19.5 5.375 19.325 5.025 18.975C4.675 18.625 4.5 18.1974 4.5 17.6923V15.7307C4.5 15.5179 4.5718 15.3397 4.7154 15.1961C4.85898 15.0525 5.03718 14.9808 5.25 14.9808C5.46282 14.9808 5.64102 15.0525 5.7846 15.1961C5.92818 15.3397 5.99997 15.5179 5.99997 15.7307V17.6923C5.99997 17.7692 6.03202 17.8397 6.09612 17.9038C6.16024 17.9679 6.23077 18 6.3077 18H17.6922C17.7692 18 17.8397 17.9679 17.9038 17.9038C17.9679 17.8397 18 17.7692 18 17.6923V15.7307C18 15.5179 18.0718 15.3397 18.2154 15.1961C18.3589 15.0525 18.5371 14.9808 18.75 14.9808C18.9628 14.9808 19.141 15.0525 19.2845 15.1961C19.4281 15.3397 19.5 15.5179 19.5 15.7307V17.6923C19.5 18.1974 19.325 18.625 18.975 18.975C18.625 19.325 18.1974 19.5 17.6922 19.5H6.3077Z"
          fill="#3C4656"
        />
      </svg>
    </SvgIcon>
  );
}
