import { MouseEvent, ReactNode, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { SEARCH_DOMAINS, SWITCH_DOMAIN_ACTIVE_STATE, SWITCH_DOMAIN_ARCHIVE_STATE } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AlertCheckNoticeIcon,
  DoNotDisturbOnIcon,
  EditIcon,
  MoreVertIcon,
  StarOutlineIcon
} from '@/separatedModules/components';

import { TranslationT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

import { EditDomainModal } from '../../../AddOrEditDomain/components/AddOrEditDomainModal/EditDomainModal';

interface RowActionMenuProps {
  id?: string | undefined;
  name: string;
  itemsCount: number;
  lastModifiedDate?: string;
  active?: boolean;
  archived?: boolean;
  renderModalContent?: () => ReactNode;
}

interface RowActionMenuState {
  isModalOpen: boolean;
  isEditModalOpen: boolean;
  modalType: string;
}

const getModalData = (
  row: RowActionMenuProps,
  state: RowActionMenuState,
  tTemplates: TranslationT,
  handleChangeItemActiveState: () => void,
  handleChangeItemArchiveState: () => void
) => {
  const isItemActive = row?.active;

  const checkDisable = (text: string) => {
    return isItemActive && !row?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkEnable = (text: string) => {
    return !isItemActive && !row?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkArchive = (text: string) => {
    return !isItemActive && !row?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const checkUnArchive = (text: string) => {
    return !isItemActive && row?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const modalTitleForDisable = checkDisable(tTemplates('domains.modal.titleToDisable'));
  const modalTitleForEnable = checkEnable(tTemplates('domains.modal.titleToEnable'));
  const modalTitleForArchive = checkArchive(tTemplates('domains.modal.titleToArchive'));
  const modalTitleForUnArchive = checkUnArchive(tTemplates('domains.modal.titleToUnArchive'));

  const modalContentForDisable = checkDisable(tTemplates('domains.modal.contentToDisable'));
  const modalContentForEnable = checkEnable(tTemplates('domains.modal.contentToEnable'));
  const modalContentForArchive = checkArchive(tTemplates('domains.modal.contentToArchive'));
  const modalContentForUnArchive = checkUnArchive(tTemplates('domains.modal.contentToUnArchive'));

  const modalBtnTextForDisable = checkDisable(tTemplates('domains.modal.btnDisable'));
  const modalBtnTextForEnable = checkEnable(tTemplates('domains.modal.btnEnable'));
  const modalBtnTextForArchive = checkArchive(tTemplates('domains.modal.btnArchive'));
  const modalBtnTextForUnArchive = checkUnArchive(tTemplates('domains.modal.btnUnArchive'));

  const modalTitle = modalTitleForDisable || modalTitleForEnable || modalTitleForArchive || modalTitleForUnArchive;
  const modalContent =
    modalContentForDisable || modalContentForEnable || modalContentForArchive || modalContentForUnArchive;
  const modalBtnText =
    modalBtnTextForDisable || modalBtnTextForEnable || modalBtnTextForArchive || modalBtnTextForUnArchive;

  const handleModalAction =
    modalTitleForDisable || modalTitleForEnable ? handleChangeItemActiveState : handleChangeItemArchiveState;

  return {
    modalTitle,
    modalContent,
    modalBtnText,
    handleModalAction
  };
};

export const RowActionMenu = (row: RowActionMenuProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const [state, setState] = useState<RowActionMenuState>({
    isModalOpen: false,
    isEditModalOpen: false,
    modalType: ''
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [SwitchDomainActiveState] = useMutation(SWITCH_DOMAIN_ACTIVE_STATE, {
    refetchQueries: [SEARCH_DOMAINS]
  });

  const [SwitchDomainArchiveState] = useMutation(SWITCH_DOMAIN_ARCHIVE_STATE, {
    refetchQueries: [SEARCH_DOMAINS]
  });

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickBox = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOpenEditModal = () => {
    setAnchorEl(null);
    setState((state) => ({ ...state, isEditModalOpen: true }));
  };

  const handleChangeItemActiveState = () => {
    if (row) {
      const isActive = !row?.active;

      SwitchDomainActiveState({
        variables: {
          request: {
            ids: [row?.id],
            active: isActive
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isActive
          ? tGlobal('alertMessages.success.templates.equipment.edit.isEnabled', { value: row?.name })
          : tGlobal('alertMessages.success.templates.equipment.edit.isDisabled', { value: row?.name }),
        severity: 'success',
        autoHideDuration: 1500
      });
    }
  };

  const handleChangeItemArchiveState = () => {
    if (row && row?.active === false) {
      const isArchive = !row?.archived;

      SwitchDomainArchiveState({
        variables: {
          request: {
            ids: [row?.id],
            archived: !row?.archived
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isArchive
          ? tGlobal('alertMessages.success.templates.equipment.edit.isArchived', { value: row?.name })
          : tGlobal('alertMessages.success.templates.equipment.edit.isUnArchived', { value: row?.name }),
        severity: 'success',
        autoHideDuration: 1500
      });
    }
  };

  const handleOpenModal = (modalType: string) => {
    setAnchorEl(null);

    setState((state) => ({ ...state, isModalOpen: true, modalType }));
  };

  const handleCloseModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
  };

  const handleCloseEditModal = () => {
    setState((state) => ({ ...state, isEditModalOpen: false }));
  };

  const { modalTitle, modalContent, modalBtnText, handleModalAction } = getModalData(
    row,
    state,
    tTemplates,
    handleChangeItemActiveState,
    handleChangeItemArchiveState
  );

  return (
    <Box component={'td'} className={'table-edit-button'} onClick={handleClickBox}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenEditModal} disabled={!row?.active}>
          <EditIcon sx={{ fontSize: 20 }} />

          {tTemplates('equipment.table.rowEditMenu.edit')}
        </MenuItem>

        <MenuItem disabled>
          <StarOutlineIcon sx={{ fontSize: 20 }} />

          {tTemplates('equipment.table.rowEditMenu.addToFavorites')}
        </MenuItem>

        <Box
          sx={{
            height: '1px',
            background: 'var(--border-default, rgba(9, 14, 22, 0.15))'
          }}
        />

        {!row?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')} disabled={row?.archived}>
            <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 20 }} />

            {tGlobal('statuses.enable')}
          </MenuItem>
        ) : null}

        {row?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')}>
            <DoNotDisturbOnIcon />

            {tGlobal('statuses.disable')}
          </MenuItem>
        ) : null}
      </Menu>

      <Dialog
        headerTitle={modalTitle}
        hideHeaderBorder
        contentFooter={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={'my-0'}
              variant={'text'}
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCloseModal}
            >
              {tGlobal('modal.btnCancel')}
            </Button>

            <Button className={'my-0'} variant={'contained'} onClick={handleModalAction}>
              {modalBtnText}
            </Button>
          </div>
        }
        open={state.isModalOpen}
        onClose={handleCloseModal}
      >
        <Text
          className={'m-0'}
          variant={'bodyLg'}
          dangerouslySetInnerHTML={{
            __html: modalContent as string
          }}
          sx={{
            b: {
              wordBreak: 'break-word'
            }
          }}
        />

        <Box sx={{ width: '100%', flex: '0 1 100%', marginTop: '24px' }}>
          <Box
            sx={{
              height: '48px',
              padding: '8px 16px',
              background: '#F7F8F9',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '4px'
            }}
          >
            <Text m={0} sx={{ fontSize: '16px', fontWeight: '500' }}>
              {row?.name}
            </Text>
          </Box>
        </Box>
      </Dialog>
      <EditDomainModal domainId={row?.id} isModalOpen={state.isEditModalOpen} onCloseModal={handleCloseEditModal} />
    </Box>
  );
};
