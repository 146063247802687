import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';

import { CreateUnitMutation } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { CloseIcon, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_DOMAIN_NAME } from './helpers';

import { CREATE_UNIT, SEARCH_TEMPLATES_UNITS } from '@/logicLayers/units';

interface FormProps {
  [FIELD_DOMAIN_NAME]: string;
}

interface AddOrEditUnitModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const AddUnitModal = ({ isModalOpen, onCloseModal }: AddOrEditUnitModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');
  const { t: tErrors } = useTranslation('errors');

  const [CreateDomain] = useMutation<CreateUnitMutation>(CREATE_UNIT, {
    refetchQueries: [SEARCH_TEMPLATES_UNITS]
  });

  const {
    setError,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_DOMAIN_NAME]: ''
    }
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    CreateDomain({
      variables: { name: data.name },
      onCompleted: ({ createDomain }) => {
        handleCloseModal();

        if (createDomain.id) {
          setTimeout(() => {
            AlertService.showAlert({
              title: 'Unit created',
              severity: 'success'
            });
          }, 500);
        }
      },
      onError: (error) => {
        const errorMessage = JSON.parse(error.message);

        if (errorMessage?.errorCode) {
          setError(FIELD_DOMAIN_NAME, {
            message: tErrors(`templates.create.${errorMessage.errorCode.replace('error.', '')}`)
          });
        }
      }
    });
  });

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 24px' }}>
          <Text variant="titleLg" sx={{ fontSize: 20 }}>
            {'Create unit'}
          </Text>
          <CloseIcon onClick={onCloseModal} />
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box sx={{ padding: '32px 24px 20px 24px' }}>
          <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            <Controller
              name={FIELD_DOMAIN_NAME}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: ROLE_NAME_REG_EXP,
                  message: tGlobal('validation.textFieldLength')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextFieldControl
                    label={'Unit title'}
                    placeholder={'Enter unit title'}
                    name={FIELD_DOMAIN_NAME}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_DOMAIN_NAME]?.message,
                      error: errors[FIELD_DOMAIN_NAME]?.message
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </form>
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 24px' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tTemplates('domains.table.edit.modal.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_DOMAIN_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tTemplates('domains.table.create.modal.btnSave')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
