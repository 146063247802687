import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { CompanyLogoName, HeaderMenu } from './components';

export const LeftPart = () => {
  return (
    <Grid className={'header__left'} xl={7} item>
      <Link className={'d-inline-block'} to={LINKS_PAGES.home}>
        <CompanyLogoName />
      </Link>

      <HeaderMenu />
    </Grid>
  );
};
