import { ReactNode, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Drawer, Grid } from '@mui/material';

import { ParameterType } from '@/logicLayers/domain';

import { ParameterItem as FormParameterItem } from '@/separatedModules/pages/Tasks/pages/ExecuteTaskMobile/components/ParameterItem';

import { ModalDiscardAllChanges, MultiStepFooter } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { EditRoundParametersHeader } from './components/EditRoundParametersHeader';
import { useContextState } from './ContextProvider';
import { Steps } from './Steps';

import { DEFAULT_VALUES, FORM_ID, FormProps } from './helpers';

interface ContextConsumerProps {
  ContentAfterTitle?: ReactNode;
  selectedItems: any[];
  value: any;
  onChange: any;
  onChangeParameters: any;
  handleSubmitEditForm: () => void;
}

export const ContextConsumer = ({
  ContentAfterTitle,
  selectedItems,
  onChange,
  value,
  onChangeParameters,
  handleSubmitEditForm
}: ContextConsumerProps) => {
  const { templateId = '', roundRegulationId = '' } = useParams();
  const { t: tGlobal } = useTranslation('global');

  const navigate = useNavigate();
  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { setState, handleSetPrevStep, ...state } = useContextState();

  const handleCreateRound = () => {
    handleSubmitEditForm();
  };

  const isFirstStep = state.currentStep === 1;

  const methods = useForm<FormProps>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const handleSubmitForm = methods.handleSubmit(() => {
    if (state.currentStep < state.totalSteps) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('isRoundTable', 'false');

      navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });

      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else {
      handleCreateRound();
    }
  });

  const handleClick = () => {
    if (isFirstStep) {
      navigate(LINKS_PAGES.templatesParametersCreate.replace(':templateId', templateId));
    }
  };

  const formattedData = useMemo(
    () =>
      selectedItems
        ?.filter((item) => item.type !== ParameterType.NumericFixed && item.type !== ParameterType.NumericFormula)
        .map((item) => ({
          addNote: item.addNote,
          uploadPhoto: item.uploadPhoto,
          condition: value.find((i: any) => i.id === item.id).condition,
          description: item.description,
          id: item.id,
          parameterDetails: {
            active: item.active,
            archived: item.archived,
            description: item.description,
            name: item.name,
            order: item.order,
            readableFormula: item.readableFormula,
            type: item.type,
            unit: item.unit,
            value: item.value
          }
        })),
    [selectedItems, value]
  );

  const isTemplate = location.pathname.includes('item');

  const navigateToUrl = isTemplate
    ? `/templates/equipment/item/${templateId}/rounds/round-regulations`
    : LINKS_PAGES.equipmentItemRoundRegulation
        .replace(':templateId', templateId)
        .replace(':parameterId', roundRegulationId);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={isFirstStep ? 12 : 10} md={isFirstStep ? 12 : 10} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            <ModalDiscardAllChanges navigateToUrl={navigateToUrl} sxBox={{ margin: '0 0 32px' }} />
            <EditRoundParametersHeader
              title={isFirstStep ? 'Select parameters' : 'Set up the parameters'}
              isFirstStep={isFirstStep}
              onClick={handleClick}
              handleOpenRoundForm={() => setIsDrawerOpen(true)}
              description={
                isFirstStep
                  ? 'Choose the parameters that you want to include in the round inspection'
                  : `Specify the validation rules for each parameter and apply additional options like need to upload photo or
        ability to add note for service staff`
              }
            />

            {ContentAfterTitle ? ContentAfterTitle : null}

            <Steps
              step={`step${state.currentStep}`}
              selectedItems={selectedItems}
              value={value}
              onChange={onChange}
              onChangeParameters={onChangeParameters}
            />

            <MultiStepFooter
              btnNextStepText={'Select'}
              btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
              btnCreateTemplateText={'Save'}
              isLoadingBtnCreate={false}
              isDisabledBtnCreate={state.isDataCreated}
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
              isRound
              handleSetPrevStep={handleSetPrevStep}
              isDisablePrevButton
            />
          </form>
        </FormProvider>
        <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} anchor="right">
          <Box
            sx={{
              backgroundColor: '#F5F7FA',
              borderRadius: '4px',
              height: 'fit-content',
              width: '300px'
            }}
          >
            <FormProvider {...methods}>
              <Box sx={{ padding: '20px 16px 20px 16px' }}>
                {formattedData.map((item, index) => (
                  <FormParameterItem key={index} item={item} isPreview />
                ))}
              </Box>
            </FormProvider>
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  );
};
