import { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  EquipmentQuery,
  GET_EQUIPMENT,
  GET_PRESIGNED_UPLOAD_URL,
  GetPresignedUploadUrlQuery
} from '@/logicLayers/domain';
import { CREATE_DOCUMENT, DELETE_DOCUMENT, GET_DOCUMENT } from '@/logicLayers/domain/documents';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AlertService, ApiService } from '@/logicLayers/infrastructure/services';

import {
  DeleteTrashIcon,
  Flex,
  handleGetSavedColumns,
  handleSetSavedColumns,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownCheckbox,
  TableDropdownColumnsVisibility,
  TableFiltersBox,
  TableManagement,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';
import { Upload } from '@/separatedModules/components/Icon/svg-mui-theme/Upload';
import { UploadHeader } from '@/separatedModules/components/Icon/svg-mui-theme/UploadHeader';

import { useIsUserCanCRUD, useIsUserSuperAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { AsideContent } from './components/AsideContent';
import { RowActionMenu } from './components/RowActionMenu';

import {
  allowedFileTypes,
  columns,
  getIconByDocumentType,
  initState,
  MIME_TYPE_TO_DOCUMENT_TYPE,
  skeletonTableLoaderCells,
  SORT_BY_DOCUMENT_TYPE,
  useTableData
} from './helpers';

export const TemplatesEquipmentItemDocumentsPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();
  const location = useLocation();
  const isDisableEdit = location.pathname.includes('/templates');

  const { profile } = useReduxSelector(selectUserData);

  const companyId = profile.companyId;
  const isUserSuperAdmin = useIsUserSuperAdmin();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const TABLE_SAVED_COLUMNS_ID = `locations/equipment/${templateId}/documents`;

  const { renderCount, ...tableState } = useTableData(initState, templateId);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const columnsVisibility = useMemo(() => {
    const columnsVisibilityStatus = table.getAllLeafColumns().reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.id]: currentValue.getIsVisible() };
    }, {});

    handleSetSavedColumns(TABLE_SAVED_COLUMNS_ID, columnsVisibilityStatus);

    return table.getAllLeafColumns().map((column) => {
      return {
        value: column.id,
        label: i18n.t(`equipmentItem.parameters.table.cells.${column.id}`, { ns: 'templates' }),
        checked: column.getIsVisible(),
        onToggleVisibility: column.toggleVisibility
      };
    });
  }, [columnVisibility]);

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });

  const { refetch } = useQuery<GetPresignedUploadUrlQuery>(GET_PRESIGNED_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    variables: { path: 'EQUIPMENT_DOCUMENT' },
    skip: true
  });

  const [CreateDocument] = useMutation(CREATE_DOCUMENT, {
    refetchQueries: [GET_DOCUMENT]
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    refetchQueries: [GET_DOCUMENT]
  });

  const [itemToDelete, setItemToDelete] = useState<{
    id: string;
    title: string;
    icon: React.ReactNode;
  } | null>(null);

  const handleOpenDeleteModal = (item: { id: string; title: string; icon: React.ReactNode }) => {
    setItemToDelete(item);
  };

  const handleCloseDeleteModal = () => {
    setItemToDelete(null);
  };

  const handleDeleteItem = (id: string) => {
    deleteDocument({ variables: { id } })
      .then(() => {
        AlertService.showAlert({
          title: `Document "${itemToDelete?.title}" has been successfully deleted.`,
          severity: 'success',
          autoHideDuration: 1500
        });
        handleCloseDeleteModal();
      })
      .catch(() => {
        AlertService.showAlert({
          title: `Failed to delete item "${itemToDelete?.title}".`,
          severity: 'error',
          autoHideDuration: 1500
        });
      });
  };

  const renderItemLocation = dataEquipmentTemplate?.equipment?.location;

  const breadcrumbsItemLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    {
      link: `${LINKS_PAGES.locations}${renderItemLocation ? `/${renderItemLocation.id}` : ''}`,
      titleFallback: renderItemLocation ? renderItemLocation.name : tTemplates('equipment.pageItemAllLocations')
    },
    {
      link: LINKS_PAGES.equipmentModel.replace(':templateId', templateId),
      titleFallback: dataEquipmentTemplate?.equipment?.name
    },
    { titleFallback: 'Documents' }
  ];

  const handleGetUrlForUpload = async () => {
    return await refetch().then(({ data }) => data);
  };

  const handleCapture = async (files: FileList | File[]) => {
    const filesArray = Array.from(files);

    for (const file of filesArray) {
      const documentType = MIME_TYPE_TO_DOCUMENT_TYPE[file.type];

      try {
        const { presignedUploadUrl } = await handleGetUrlForUpload();

        await ApiService.AmazonS3.updateImage(presignedUploadUrl.url, file);

        await CreateDocument({
          variables: {
            request: {
              title: file.name,
              fileKey: presignedUploadUrl.fileKey,
              type: documentType,
              size: file.size,
              equipmentId: templateId
            }
          }
        });

        AlertService.showAlert({
          title: `File "${file.name}" has been successfully uploaded.`,
          severity: 'success'
        });
      } catch (error) {
        console.error('Error uploading file or creating document:', error);
        AlertService.showAlert({
          title: 'There was an error uploading the file or creating the document.',
          severity: 'error'
        });
      }
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files;

    if (!files || files.length === 0) return;

    const invalidFiles = Array.from(files).filter((file) => !allowedFileTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      AlertService.showAlert({
        title: 'Selected file type is not supported. Please upload valid documents (PDF, DOC, or Spreadsheet).',
        severity: 'error'
      });
      return;
    }

    handleCapture(files);
  };

  const handleDownload = () => {
    const url = theData.find((value) => value?.id === tableState.state.selectedItems[0])?.documentUrl.url;

    const link = document.createElement('a');
    link.href = url || '';
    const filename = url?.split('/').pop() || 'download';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <TablePageTopBox
          title={'Documents'}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          btnText={'Upload document'}
          withBreadcrumbs
          breadcrumbsLinks={breadcrumbsItemLinks.map(({ link, titleFallback }) => ({
            link,
            titleFallback: titleFallback || ''
          }))}
          icon={<Upload />}
          type="file"
          onChangeFile={onChangeFile}
          isHideButton={
            !isUserCanCRUD ||
            (!isUserSuperAdmin && String(companyId) !== String(dataEquipmentTemplate?.equipment.companyId))
          }
        />

        <TableFiltersBox
          searchPlaceholder={'Search by Document title'}
          selectedItems={tableState.state.selectedItems}
          handleToggleAside={tableState.handleToggleAside}
          handleChangeBySearch={tableState.handleChangeBySearch}
        >
          <TableDropdownColumnsVisibility
            menu={columnsVisibility}
            toggleAllColumnsVisible={table.toggleAllColumnsVisible}
          />

          <TableDropdownCheckbox
            title={'Type'}
            menuList={SORT_BY_DOCUMENT_TYPE}
            withSelectAll
            withSearch
            searchPlaceholder={'Search by type'}
            selectAllTitle={'All types'}
            onChange={tableState.handleChangeByType}
          />

          <TableSortByOneItem menu={SORT_BY_DATE_CREATE} showIcon={true} onChange={tableState.handleChangeSortByDate} />
        </TableFiltersBox>

        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {renderCount ? (
              <Flex>
                <Box width={'100%'}>
                  <Collapse in={Boolean(tableState.state.selectedItems.length)}>
                    <TableManagement
                      count={tableState.state.selectedItems.length}
                      actionNodeList={[
                        {
                          node: <UploadHeader key={1} onClick={handleDownload} />,
                          disabled: false
                        },
                        {
                          node: (
                            <DeleteTrashIcon
                              key={2}
                              onClick={() =>
                                handleOpenDeleteModal({
                                  id: tableState.state.selectedItems[0],
                                  title:
                                    theData.find((item) => item?.id === tableState.state.selectedItems[0])?.title || '',
                                  icon: null
                                })
                              }
                            />
                          ),
                          disabled: isDisableEdit
                        }
                      ]}
                      open={Boolean(tableState.state.selectedItems.length)}
                      onClose={tableState.handleResetSelectedRows}
                      onClickInfo={tableState.handleToggleAside}
                    />
                  </Collapse>
                  <Box sx={{ overflow: 'auto' }}>
                    <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                      <Table className={'m-0 MuiTable-root__columns-4'}>
                        <TableHead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <TableCell key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>

                        <TableBody>
                          {table.getRowModel().rows.map((row) => {
                            const IconComponent = getIconByDocumentType(row?.original?.type as any);

                            return (
                              <TableRowWrap
                                key={row.original.id}
                                id={row.original.id as string}
                                isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                handleClickRow={tableState.handleClickRow}
                                handleDoubleClickRow={tableState.handleDoubleClickRow}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <TableCell key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                  );
                                })}

                                <RowActionMenu
                                  {...{
                                    ...row.original,
                                    id: row.original.id || '',
                                    url: row.original.documentUrl.url
                                  }}
                                  isDisabled={isDisableEdit}
                                  handleOpenDeleteModal={() =>
                                    handleOpenDeleteModal({
                                      id: row.original.id || '',
                                      title: row.original.title || '',
                                      icon: IconComponent && <IconComponent />
                                    })
                                  }
                                  icon={IconComponent && <IconComponent />}
                                />
                              </TableRowWrap>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <MuiTablePagination
                    count={renderCount}
                    page={tableState.state.page[0]}
                    rowsPerPage={tableState.state.rowsPerPage[0]}
                    rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                    onChangePage={tableState.handleChangePagination}
                    onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                  />
                </Box>

                <TableAside
                  rows={table.getRowModel().rows}
                  selectedItems={tableState.state.selectedItems}
                  isAsideOpen={tableState.state.isAsideOpen}
                  handleToggleAside={tableState.handleToggleAside}
                  sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '198px' }}
                  isDocuments
                >
                  {(props: any) => <AsideContent {...props} />}
                </TableAside>
              </Flex>
            ) : tableState.state.filters.search[0] ? (
              <NotFoundScreens type={'search'} />
            ) : (
              <NotFoundScreens
                type={'companies'}
                title={'No documents yet'}
                subTitle={'Click "Upload document" to add document'}
              />
            )}
          </>
        )}

        {itemToDelete && (
          <Dialog
            headerTitle={'Remove document'}
            open={Boolean(itemToDelete)}
            onClose={handleCloseDeleteModal}
            contentFooter={
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant={'text'} onClick={handleCloseDeleteModal}>
                  Cancel
                </Button>
                <Button variant={'contained'} onClick={() => handleDeleteItem(itemToDelete.id)}>
                  Remove
                </Button>
              </div>
            }
          >
            <Text>Are you sure that you want to remove this document from the equipment item?</Text>
            <Box
              sx={{
                borderRadius: 'var(--4, 4px)',
                background: 'var(--background-neutral-hovered, #F7F8F9)',
                margin: '24px 0px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                width: '100%'
              }}
            >
              {itemToDelete.icon}
              <Text
                variant={'labelLg'}
                m={0}
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '498px',
                  marginLeft: '8px'
                }}
              >
                {itemToDelete.title}
              </Text>
            </Box>
          </Dialog>
        )}
      </Grid>
    </Grid>
  );
};
