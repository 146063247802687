import { Text } from 'infinitytechnologies-ui';

import { useContextState, useContextUpdateState } from '../../../Providers';
import { DateRangePicker } from '../DateRangePicker';

export const Step2 = () => {
  const {
    CSVFilters: { sortByCSVEndDate, sortByCSVStartDate }
  } = useContextState();
  const { handleSetCSVStartDate, handleSetCSVEndDate } = useContextUpdateState();

  const isDateInvalid = sortByCSVStartDate && sortByCSVEndDate && sortByCSVStartDate.isAfter(sortByCSVEndDate);

  return (
    <>
      <Text>
        Specify a start and end date to export data in CSV format. Only data related to the selected interval will be
        included in the file.
      </Text>
      <DateRangePicker
        startDate={sortByCSVStartDate}
        endDate={sortByCSVEndDate}
        onStartDateChange={handleSetCSVStartDate}
        onEndDateChange={handleSetCSVEndDate}
        isDateInvalid={Boolean(isDateInvalid)}
      />
    </>
  );
};
