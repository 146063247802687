import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { AlertInfoIcon, Flex } from '@/separatedModules/components';

interface AlertMessageProps {
  noData: boolean;
  isDateInvalid: boolean;
}

export const AlertMessage = ({ noData, isDateInvalid }: AlertMessageProps) => {
  if (!isDateInvalid && !noData) return null;

  return (
    <Flex
      sx={{
        width: '552px',
        height: '130px',
        borderRadius: '4px',
        background: 'var(--background-danger-default, #FFF5F7)',
        marginTop: '20px',
        padding: '18px 12px'
      }}
    >
      <AlertInfoIcon
        sx={{
          '& path': {
            fill: '#BE0E2C !important'
          }
        }}
      />
      <Box sx={{ marginLeft: '12px' }}>
        <Text sx={{ fontWeight: 600 }}>{noData ? 'No Exportable Data' : 'Incorrect date range'}</Text>
        <Text
          sx={{
            color: '#505864',
            fontWeight: '400',
            maxWidth: '474px',
            marginTop: '4px'
          }}
        >
          {noData
            ? 'No data is available for export within the selected dates. Adjust your date range and try again'
            : 'Check the selected dates. The start date cannot be later than the end date and vice versa. Select the correct range and try again.'}
        </Text>
      </Box>
    </Flex>
  );
};
