import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { TextField } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { ButtonLoading, TextBlockControl, TextFieldMask } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export interface EditInputItemProps {
  editFieldType?: string;
  editFieldName: string;
  editFieldValue: string;
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  patternMessage: string;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  inputLabel: string;
  inputPlaceholder?: string;
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  regExpMinLength?: number;
  regExpMaxLength?: number;
  maxContentLength?: number;
  rows?: number;
  regExp: RegExp;
  ContentAfterTitle?: ReactNode;
  multiline?: boolean;
  sxTitle?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  onSubmitForm: (getValues: any, updateModel: any) => void;
}

const styles = [
  'color: #090E16',
  'background: #E6F6EF',
  'font-size: 10px',
  'border: 1px solid #06845B',
  'padding: 2px'
].join(';');

export const EditInputItem = ({
  editFieldType,
  editFieldName,
  editFieldValue,
  navigateToUrl,
  mutationQuery,
  successAlertMessage,
  errorAlertMessage,
  requiredMessage,
  patternMessage,
  minLengthMessage,
  maxLengthMessage,
  inputLabel,
  inputPlaceholder,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  regExp,
  regExpMinLength = 3,
  regExpMaxLength = 64,
  maxContentLength,
  rows,
  ContentAfterTitle,
  multiline = false,
  onSubmitForm,
  sxTitle,
  sxDescription
}: EditInputItemProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data, error }] = useMutation(mutationQuery);

  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [editFieldName]: editFieldValue
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm?.(getValues, updatedData);
  });

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const REQUIRED_MESSAGE = requiredMessage || tGlobal('validation.required');
  const MIN_LENGTH_MESSAGE = minLengthMessage || tGlobal('validation.textFieldMinLength', { value: regExpMinLength });
  const MAX_LENGTH_MESSAGE = maxLengthMessage || tGlobal('validation.textFieldMaxLength', { value: regExpMaxLength });
  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  // ToDo Remove
  console.log('%c' + `editFieldName, editFieldValue - ${editFieldName}, ${editFieldValue}`, styles);

  return (
    <form onSubmit={handleSubmitForm}>
      <TextBlockControl
        title={pageTitle}
        subTitle={pageSubTitle}
        ContentAfterTitle={ContentAfterTitle}
        isTitleLg
        isBodyLg
        sxTitle={sxTitle}
      />

      <Controller
        name={editFieldName}
        control={control}
        rules={{
          required: {
            value: false,
            message: REQUIRED_MESSAGE
          },
          pattern: {
            value: regExp,
            message: patternMessage
          },
          minLength: {
            value: regExpMinLength,
            message: MIN_LENGTH_MESSAGE
          },
          maxLength: {
            value: regExpMaxLength,
            message: MAX_LENGTH_MESSAGE
          }
        }}
        render={({ field }) => {
          const { onChange, onBlur, value } = field;

          return (
            <TextField
              label={inputLabel}
              placeholder={inputPlaceholder}
              maxContentLength={maxContentLength}
              rows={rows}
              value={value}
              sx={sxDescription}
              validation={{
                isValid: !errors[editFieldName]?.message,
                error: errors[editFieldName]?.message
              }}
              {...(editFieldType == 'phone'
                ? {
                    InputProps: {
                      inputComponent: TextFieldMask as any
                    }
                  }
                : {})}
              multiline={multiline}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />

      <ButtonLoading
        className={'edit__content__btn'}
        variant={'contained'}
        type={'submit'}
        loading={loading}
        disabled={Boolean(data)}
      >
        {SUBMIT_BTN_TEXT}
      </ButtonLoading>
    </form>
  );
};
