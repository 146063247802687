import { MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { CustomTaskRes, TaskStatus, UPDATE_ROUND_STATUS } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { DotIcon } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useTranslation } from '@/i18n';

interface ActionStatusMenuProps {
  refetchTask: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<CustomTaskRes>>;
  renderItemTemplateStatus: string;
}

export const ActionStatusMenu = ({ refetchTask, renderItemTemplateStatus }: ActionStatusMenuProps) => {
  const { t: tTasks } = useTranslation('tasks');
  const { taskId = '' } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [UpdateRoundStatus] = useMutation(UPDATE_ROUND_STATUS);

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeStatus = (status: string) => {
    if (renderItemTemplateStatus === 'ON_HOLD') {
      UpdateRoundStatus({
        variables: {
          roundId: taskId,
          status: status
        },
        onCompleted: () => {
          AlertService.showAlert({
            title: 'Status updated successfully',
            severity: 'success'
          });

          refetchTask();
        }
      });
    }
  };

  console.log('yes');

  return (
    <Box component={'td'} className={'table-edit-button'}>
      <Box
        className={'m-0'}
        sx={{
          display: 'inline-flex',
          cursor: renderItemTemplateStatus === 'ON_HOLD' ? 'pointer' : 'auto'
        }}
        onClick={(event) => renderItemTemplateStatus === 'ON_HOLD' && handleClickButton(event)}
      >
        <TaskStatusChip status={renderItemTemplateStatus as keyof typeof TaskStatus} />
      </Box>
      <Menu
        id={'custom-task-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'custom-task-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeStatus('PENDING')}>
          <DotIcon />
          {tTasks('tasksCustom.status.pending')}
        </MenuItem>

        <MenuItem onClick={() => handleChangeStatus('IN_PROGRESS')}>
          <DotIcon fill="rgb(94, 77, 178)" />
          {tTasks('tasksCustom.status.inProgress')}
        </MenuItem>

        <MenuItem onClick={() => handleChangeStatus('CANCELLED')}>
          <DotIcon fill={'#BE0E2C'} />
          {'Cancelled'}
        </MenuItem>
      </Menu>
    </Box>
  );
};
