import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LocationIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M12.0002 19.5135C13.9566 17.7622 15.4537 16.0824 16.4915 14.474C17.5294 12.8657 18.0483 11.457 18.0483 10.2481C18.0483 8.42498 17.4691 6.92627 16.3108 5.7519C15.1524 4.57753 13.7156 3.99035 12.0002 3.99035C10.2848 3.99035 8.84796 4.57753 7.68962 5.7519C6.53129 6.92627 5.95212 8.42498 5.95212 10.2481C5.95212 11.457 6.47103 12.8657 7.50885 14.474C8.54668 16.0824 10.0438 17.7622 12.0002 19.5135ZM12.0002 20.9403C11.8053 20.9403 11.6105 20.9067 11.4156 20.8394C11.2207 20.7721 11.0444 20.6679 10.8867 20.5269C9.98933 19.7 9.1496 18.8484 8.36755 17.9721C7.5855 17.0958 6.90569 16.2199 6.32812 15.3442C5.75056 14.4686 5.29351 13.6006 4.95697 12.7404C4.62042 11.8801 4.45215 11.0493 4.45215 10.2481C4.45215 7.94038 5.19862 6.07213 6.69157 4.64328C8.18451 3.21443 9.95405 2.5 12.0002 2.5C14.0463 2.5 15.8159 3.21443 17.3088 4.64328C18.8018 6.07213 19.5482 7.94038 19.5482 10.2481C19.5482 11.0493 19.38 11.8785 19.0434 12.7356C18.7069 13.5926 18.2514 14.4606 17.6771 15.3394C17.1027 16.2183 16.4245 17.0942 15.6425 17.9673C14.8604 18.8404 14.0207 19.6903 13.1232 20.5173C12.9679 20.6583 12.7913 20.7641 12.5935 20.8346C12.3957 20.9051 12.198 20.9403 12.0002 20.9403ZM12.0019 11.8654C12.4995 11.8654 12.9249 11.6882 13.2781 11.3339C13.6313 10.9795 13.8079 10.5536 13.8079 10.056C13.8079 9.55839 13.6307 9.133 13.2764 8.7798C12.922 8.4266 12.4961 8.25 11.9985 8.25C11.5009 8.25 11.0755 8.42717 10.7223 8.7815C10.3691 9.13583 10.1925 9.56179 10.1925 10.0594C10.1925 10.557 10.3697 10.9824 10.724 11.3356C11.0784 11.6888 11.5043 11.8654 12.0019 11.8654Z" />
    </SvgIcon>
  );
}
