// helpers.ts
export type LocationItem = {
  value: string;
  label: string;
  children: LocationItem[];
  checked: boolean;
  indeterminate: boolean;
};

export type HistoryEntry = {
  items: LocationItem[];
  path: string[];
};

export function transformLocationsTest(
  items: any[] = [],
  sortByLocations: string[] = [],
  parentChecked = false
): LocationItem[] {
  return items.map((item) => {
    const isChecked = parentChecked || sortByLocations.includes(item?.id ?? '');

    const children = item?.groupItems?.items
      ? transformLocationsTest(item.groupItems.items, sortByLocations, isChecked)
      : [];

    const isChildChecked = children.some((child) => child.checked || child.indeterminate);

    return {
      value: item?.id ?? '',
      label: item?.name ?? '',
      children,
      checked: isChecked,
      indeterminate: !isChecked && isChildChecked
    };
  });
}

export const computeEffectiveSelection = (items: LocationItem[]): string[] => {
  let result: string[] = [];

  for (const item of items) {
    if (item.checked) {
      result.push(item.value);
    }

    if (item.children.length > 0) {
      result = result.concat(computeEffectiveSelection(item.children));
    }
  }

  return result;
};

export const markChildren = (items: LocationItem[], checked: boolean): LocationItem[] =>
  items.map((item) => ({
    ...item,
    checked,
    indeterminate: false,
    children: markChildren(item.children, checked)
  }));

export const updateCheckedState = (items: LocationItem[], targetId: string): LocationItem[] => {
  return items.map((item) => {
    if (item.value === targetId) {
      const toggled = !item.checked;
      return {
        ...item,
        checked: toggled,
        indeterminate: false,
        children: markChildren(item.children, toggled)
      };
    }
    return {
      ...item,
      children: updateCheckedState(item.children, targetId)
    };
  });
};

export const updateParentStates = (items: LocationItem[]): LocationItem[] => {
  return items.map((item) => {
    if (item.children.length > 0) {
      const updatedChildren = updateParentStates(item.children);
      const total = updatedChildren.length;
      const checkedCount = updatedChildren.filter((child) => child.checked).length;
      const isAllChecked = total > 0 && checkedCount === total;
      const isNoneChecked = checkedCount === 0;
      return {
        ...item,
        checked: isAllChecked,
        indeterminate: !isAllChecked && !isNoneChecked,
        children: updatedChildren
      };
    }
    return item;
  });
};
