import { ReactNode } from 'react';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { Breadcrumbs, ChevronRightIcon, PageTitle, PlusIcon } from '@/separatedModules/components';

type TablePageTopBoxProps = {
  title: string;
  dataFoundTitle?: string;
  btnText?: string;
  children?: ReactNode;
  onClickBtn?: () => void;
  isStep?: boolean;
  withBreadcrumbs?: boolean;
  breadcrumbsLinks?: ({ link: string; titleFallback: string } | { titleFallback: any; link?: undefined })[];
  isHideButton?: boolean;
  icon?: React.ReactNode;
  type?: string;
  onChangeFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TablePageTopBox = ({
  title,
  dataFoundTitle,
  btnText,
  children,
  onClickBtn,
  isStep,
  withBreadcrumbs = false,
  breadcrumbsLinks = [{ titleFallback: '' }],
  isHideButton,
  icon,
  type,
  onChangeFile
}: TablePageTopBoxProps) => {
  const handleClick = () => {
    if (type === 'file') document.getElementById('file-upload')?.click();

    onClickBtn?.();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: !isStep ? '0 0 24px' : '0 0 16px'
      }}
    >
      {withBreadcrumbs ? (
        <Box>
          <Breadcrumbs
            links={breadcrumbsLinks}
            separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
            sx={{ paddingTop: 0 }}
          />
          <PageTitle
            title={title}
            dataFoundTitle={dataFoundTitle}
            sxTitle={{ margin: '0' }}
            sxDataFoundTitle={{ marginBottom: '0' }}
            sxBox={{ margin: '0 0 0' }}
          />
        </Box>
      ) : (
        <PageTitle
          title={title}
          dataFoundTitle={dataFoundTitle}
          sxTitle={{ margin: '0' }}
          sxDataFoundTitle={{ marginBottom: '0' }}
          sxBox={{ margin: '0 0 0' }}
        />
      )}
      {children ? (
        children
      ) : (
        <Button
          className={'m-0'}
          sx={{ display: !isHideButton ? 'flex' : 'none' }}
          variant={'contained'}
          startIcon={icon || <PlusIcon />}
          onClick={handleClick}
        >
          {btnText}
        </Button>
      )}
      <input id="file-upload" type="file" multiple style={{ display: 'none' }} onChange={onChangeFile} />
    </Box>
  );
};
