import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { GET_USERS, GetUsersQuery } from '@/logicLayers/domain';

import {
  EMPTY_CELL_VALUE,
  Flex,
  InfoIcon,
  MenuOption,
  MuiTablePagination,
  NotFoundScreens,
  SearchAutocomplete,
  SkeletonTableLoader,
  TableAside,
  TableManagement
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';
import { useIsUserSuperAdmin } from '@/utils';

import { useTranslation } from '@/i18n';

import { UsersAsideContent } from './components/UsersAsideContent';
import { FiltersContainer, MuiTable, PageTopBox, TableColumn } from './components';

import { skeletonTableLoaderCells, tableColumns } from './helpers';

import { FiltersContainerWrp } from './style';

export const UsersPage = () => {
  const { t: tUsers } = useTranslation('users');
  const isUserSuperAdmin = useIsUserSuperAdmin();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_USERS_CREATING', 'ROLE_USERS_UPDATING']
  });

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [searchUser, setSearchUser] = useState<string | null | undefined>('');
  const [filterRoles, setFilterRoles] = useState<string[]>([]);
  const [filterByCompany, setFilterByCompany] = useState<MenuOption[]>();
  const [filterByDomain, setFilterByDomain] = useState<string[]>();
  const [sortByName, setSortByName] = useState<MenuOption[]>();

  const {
    data: dataUsers,
    loading: loadingUsers,
    refetch
  } = useQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      searchCriteria: {
        query: searchUser,
        pageable: {
          page: searchUser?.length ? 0 : page,
          pageSize: perPage
        },
        sortable: {
          column: 'name',
          direction: sortByName?.length ? sortByName[0].value : 'ASC'
        },
        companyId: filterByCompany?.length ? filterByCompany[0].key : undefined,
        domainIds: filterByDomain?.length ? filterByDomain : undefined
      },
      permissions: filterRoles
    },
    fetchPolicy: 'no-cache'
  });

  const [isManagementOpen, setIsManagementOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<TableColumn[]>(
    tableColumns.filter((column) => column.key !== 'company' || isUserSuperAdmin)
  );
  const [selectedRows, setSelectedRows] = useState<[] | string[]>([]);
  const [isShowAside, setIsShowAside] = useState(false);

  const handleSetSelectedRow = (id: string) => {
    const isSelected = selectedRows.some((selectedRow) => selectedRow === id);

    setSelectedRows((prev) => {
      if (isSelected) {
        return prev.filter((prevRow) => prevRow !== id);
      }
      return [...prev, id];
    });
  };

  const handleColumnVisibilityChange = (checked: boolean, columnKey: string) => {
    setHiddenColumns((prev) =>
      prev.map((prevColumn) => {
        if (prevColumn.key === columnKey) {
          return { ...prevColumn, visible: checked };
        }

        return prevColumn;
      })
    );
  };

  const handleApplyShowColumnsClick = () => {
    setHiddenColumns(tableColumns.filter((column) => column.key !== 'company' || isUserSuperAdmin));
  };

  const handleFilterByCompany = (options: MenuOption[]) => {
    setFilterByCompany(options);
  };

  const handleFilterByDomain = (options: string[]) => {
    setFilterByDomain(options);
  };

  const handleSortByName = (options: MenuOption[]) => {
    setSortByName(options);
  };

  const handleToggleAside = () => {
    setIsShowAside(!isShowAside);
  };

  const handleToggleTableAside = (id: string) => {
    setSelectedRows([id]);
    setIsShowAside(!isShowAside);
  };

  const rows = useMemo(() => {
    if (!dataUsers?.dataItems.items) {
      return [];
    }

    return dataUsers?.dataItems.items.map((user) => {
      const company = user?.company as unknown as { name: string } | null;
      return {
        id: user?.id,
        name: user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : EMPTY_CELL_VALUE,
        ...(isUserSuperAdmin && {
          company: company?.name || EMPTY_CELL_VALUE
        }),
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        role: user?.permissionTag,
        domain: user?.domains,
        userAvatar: user?.userAvatar,
        selected: selectedRows.some((selectedRow) => selectedRow === user?.id)
      };
    });
  }, [dataUsers?.dataItems.items, selectedRows, isUserSuperAdmin]);

  const areNotUsersFound = dataUsers?.dataItems.total === 0;

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item sx={{ minHeight: '70vh', margin: '0px 104px' }}>
        <PageTopBox t={tUsers} data={dataUsers} refetch={refetch} isHideButton={!isUserCanCRUD} />

        <>
          <FiltersContainerWrp withSearch>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <FiltersContainer
                  hiddenColumns={hiddenColumns}
                  onSetFilterRoles={setFilterRoles}
                  onColumnVisibilityChange={handleColumnVisibilityChange}
                  onApplyShowColumnsClick={handleApplyShowColumnsClick}
                  onChangeFilterByCompany={handleFilterByCompany}
                  onChangeFilterByDomain={handleFilterByDomain}
                  onChangeSortByName={handleSortByName}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <SearchAutocomplete
                  options={[]}
                  placeholder={tUsers('table.filters.searchBy.placeholder')}
                  onChange={(value) => setSearchUser(value)}
                  onClear={() => setSearchUser('')}
                  sx={{ margin: '0' }}
                />
                <IconButton size={'medium'} sx={{ borderRadius: '100%' }} onClick={() => setIsShowAside(true)}>
                  <InfoIcon />
                </IconButton>
              </Box>
            </Box>
          </FiltersContainerWrp>

          <Collapse
            in={isManagementOpen}
            classes={{ root: 'MuiCollapse-root-filters', wrapperInner: 'MuiCollapse-wrapperInner-filters' }}
          >
            <TableManagement
              count={selectedRows.length}
              open={Boolean(selectedRows.length)}
              onOpen={setIsManagementOpen}
              onClose={() => {
                setSelectedRows([]);
              }}
              // ToDo
              onClickInfo={() => {}}
            />
          </Collapse>

          {loadingUsers ? (
            <SkeletonTableLoader cells={skeletonTableLoaderCells} />
          ) : (
            <>
              {areNotUsersFound ? (
                <NotFoundScreens type={'search'} />
              ) : (
                <>
                  <Flex>
                    <MuiTable
                      columns={hiddenColumns}
                      rows={rows.map((row) => ({ ...row, userAvatar: null }))}
                      onRowClick={handleSetSelectedRow}
                      onRowDoubleClick={handleToggleTableAside}
                    />
                    <TableAside
                      rows={rows.map((row) => ({ original: row }))}
                      selectedItems={selectedRows}
                      isAsideOpen={isShowAside}
                      handleToggleAside={handleToggleAside}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-all'
                      }}
                    >
                      {(props: any) => <UsersAsideContent {...props} />}
                    </TableAside>
                  </Flex>

                  <MuiTablePagination
                    page={page}
                    count={dataUsers?.dataItems?.total ?? 0}
                    rowsPerPage={perPage}
                    onChangePage={setPage}
                    onChangeRowsPerPage={setPerPage}
                  />
                </>
              )}
            </>
          )}
        </>
      </Grid>
    </Grid>
  );
};
