import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    maxWidth: '570px'
  },
  '.MuiModal-box': {
    maxWidth: '570px !important'
  },
  '.MuiModal-content': {
    padding: '24px !important'
  }
})) as typeof Dialog;
