import { Button, Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { DeleteTrashIcon, EditIcon } from '@/separatedModules/components';
import { Flex, PlusIcon } from '@/separatedModules/components';

import { EquipmentParameterItem } from '../../../../Step4';

import { SelectedParametersValidationHeaderConditions } from './SelectedParametersValidationHeaderConditions';

interface SelectedParametersValidationHeaderProps {
  handleOpenValidationModal: () => void;
  handleOpenEditValidationModal: () => void;
  handleRemoveParameterConditionModal: () => void;
  selectedParameters: EquipmentParameterItem[];
  parameterId: string | undefined;
  isBoolean: boolean;
  roundCustomParams?: {
    key: string;
    value: string;
  }[];
  isShowRoundCustomParams?: boolean;
}

export enum ConditionType {
  MATCH = 'Match the value',
  FIT_RANGE = 'Fit the range',
  OUT_OF_RANGE = 'Out of the range'
}

export const SelectedParametersValidationHeader = ({
  handleOpenValidationModal,
  selectedParameters,
  handleRemoveParameterConditionModal,
  parameterId,
  handleOpenEditValidationModal,
  isBoolean,
  roundCustomParams,
  isShowRoundCustomParams
}: SelectedParametersValidationHeaderProps) => {
  const selectedParameter = selectedParameters.find((selectedParameter) => selectedParameter.id === parameterId);
  const parameterValidation = selectedParameter?.condition;

  const booleanTrueType = isShowRoundCustomParams ? `"${roundCustomParams?.[0]?.value}"` : '"Yes"';
  const booleanFalseType = isShowRoundCustomParams ? `"${roundCustomParams?.[1]?.value}"` : '"No"';

  const booleanConditionType = `Validate if ${parameterValidation?.exactValue === 'Passed' || parameterValidation?.exactValue === 'true' ? booleanTrueType : booleanFalseType}`;

  const conditionType = isBoolean
    ? booleanConditionType
    : ConditionType[parameterValidation?.type as keyof typeof ConditionType];

  return (
    <Flex marginTop={'24px'} justifyContent="space-between" alignItems="start">
      <Box>
        <Text sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '18px', marginBottom: '16px' }}>
          Validation conditions
        </Text>
        {parameterValidation ? (
          <>
            <SelectedParametersValidationHeaderConditions title="Condition type:" value={conditionType} />
            {parameterValidation?.type === 'MATCH' && !isBoolean && (
              <SelectedParametersValidationHeaderConditions
                title="Exec value:"
                value={parameterValidation.exactValue}
              />
            )}
            {parameterValidation?.type !== 'MATCH' && (
              <>
                <SelectedParametersValidationHeaderConditions
                  title="Minimum value:"
                  value={parameterValidation.minValue}
                />
                <SelectedParametersValidationHeaderConditions
                  title="Maximum value:"
                  value={parameterValidation.maxValue}
                />
              </>
            )}
          </>
        ) : (
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '21px',
              maxWidth: '494px',
              color: 'var(--text-subtle, #505668)'
            }}
          >
            Please specify the validation rules for the parameter. If the entered value fails to meet the specified
            conditions, the parameter will be marked as unsatisfactory, potentially resulting in the failure of the
            round
          </Text>
        )}
      </Box>
      {parameterValidation ? (
        <Flex>
          <Flex
            sx={{ cursor: 'pointer', marginRight: '24px', alignItems: 'center' }}
            onClick={handleRemoveParameterConditionModal}
          >
            <DeleteTrashIcon sx={{ width: '18px', height: '18px', marginRight: '4px' }} />
            <Text sx={{ fontSize: '14px', fontWeight: '500' }}>Remove</Text>
          </Flex>
          <Flex sx={{ cursor: 'pointer', alignItems: 'center' }} onClick={handleOpenEditValidationModal}>
            <EditIcon sx={{ width: '18px', height: '18px', marginRight: '4px' }} />
            <Text sx={{ fontSize: '14px', fontWeight: '500' }}>Edit</Text>
          </Flex>
        </Flex>
      ) : (
        <Button
          variant="outlined"
          startIcon={<PlusIcon />}
          onClick={handleOpenValidationModal}
          size="large"
          sx={{
            height: '32px',
            width: '70px',
            '&.MuiButton-outlined.MuiButton-sizeLarge': {
              padding: '0 !important',
              background: 'var(--background-neutral-hovered, #F7F8F9) !important'
            },
            '.MuiButton-startIcon': {
              marginRight: '4px'
            }
          }}
        >
          <span style={{ fontSize: '14px' }}>Add</span>
        </Button>
      )}
    </Flex>
  );
};
