import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, Divider, IconButton } from '@mui/material';

import {
  ASSIGN_USER_TO_ROUND,
  GET_EQUIPMENT_ROUND_REGULATION,
  RoundRegulationQuery,
  TaskStatus,
  UPDATE_ROUND_STATUS
} from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import { getBreadcrumbs } from '@/separatedModules/pages/Locations/components/PageTopBox/helpers';
import { MobileActions } from '@/separatedModules/pages/Tasks/pages/TaskDetails/components/MobileActions';
import { ParameterItemMobile } from '@/separatedModules/pages/Tasks/pages/TaskDetails/components/ParameterItemMobile/ParameterItemMobile';
import { formatDate } from '@/separatedModules/pages/Tasks/pages/TaskDetails/helpers';

import {
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  Flex,
  ImageLazy,
  MoreVertIcon,
  ScheduleIcon
} from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

export const TaskPreviewMobile = () => {
  const navigateTo = useNavigate();
  const { taskId = '' } = useParams();
  const [mobileActionsOpen, setMobileActionsOpen] = useState(false);

  const { t: tTasks } = useTranslation('tasks');

  const { data: dataEquipmentTemplateRoundRegulation } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId: taskId || undefined,
        searchCriteria: {}
      }
    }
  );

  const { profile } = useReduxSelector(selectUserData);

  const [updateRoundStatus] = useMutation(UPDATE_ROUND_STATUS);
  const [assignUserToRound] = useMutation(ASSIGN_USER_TO_ROUND);

  const renderItemName = dataEquipmentTemplateRoundRegulation?.dataItems?.name;
  const renderItemEquipmentName = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0]?.name;
  const renderItemEquipmentImage = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0]?.fileUrl?.url;
  const renderItemTemplateDescription = dataEquipmentTemplateRoundRegulation?.dataItems?.description;
  const renderItemTemplateStatus = dataEquipmentTemplateRoundRegulation?.dataItems.status;
  const renderItemEquipment = dataEquipmentTemplateRoundRegulation?.dataItems.equipment;
  const renderDomainStatusChipName = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0].domain?.name;
  const renderDomainStatusChipColor = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0].domain?.color;
  const renderItemAssignedBy = dataEquipmentTemplateRoundRegulation?.dataItems?.createdBy;
  const renderItemActivationTargetDate = dataEquipmentTemplateRoundRegulation?.dataItems?.activationTargetDate;
  const renderItemParameters = dataEquipmentTemplateRoundRegulation?.dataItems?.parameters;

  const equipmentBreadcrumbsMobile = getBreadcrumbs(renderItemEquipment?.[0].location, true).map((item) => ({
    titleFallback: item.titleFallback
  }));

  const handleStartTask = () => {
    assignUserToRound({
      variables: {
        roundId: taskId,
        userId: profile?.id
      },
      onCompleted: () => {
        updateRoundStatus({
          variables: {
            roundId: taskId,
            status: 'IN_PROGRESS'
          }
        });
      }
    });
  };

  const handleOpenMobileActions = () => {
    setMobileActionsOpen(true);
  };

  const handleCloseMobileActions = () => {
    setMobileActionsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          overflowY: 'scroll',
          padding: '0px 16px 18px 16px',
          height: 'calc(100vh - 246px)'
        }}
      >
        <Box marginTop="16px">
          <Text component={'h3'} variant={'titleXl'} sx={{ fontSize: '16px', fontWeight: 600, margin: '0' }}>
            {renderItemEquipmentName}
          </Text>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px', paddingBottom: '12px' }}>
            <BuildingIcon sx={{ width: '18px', height: '18px', marginRight: '6px' }} />
            <Breadcrumbs
              links={equipmentBreadcrumbsMobile}
              separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
              sx={{ padding: 0 }}
              fontSize="12px"
            />
          </Box>
          <DomainStatusChip color={renderDomainStatusChipColor} label={renderDomainStatusChipName} />
          <ImageLazy
            width={'100%'}
            height={172}
            sxStyle={{ borderRadius: '4px', marginTop: '16px', objectFit: 'cover' }}
            src={renderItemEquipmentImage ? renderItemEquipmentImage : ''}
            alt={renderItemEquipmentImage ? renderItemEquipmentImage : ''}
          />
          <Flex sx={{ marginTop: '16px' }} justifyContent={'space-between'} alignItems={'end'}>
            <Box>
              <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
                {tTasks('mobile.status')}
              </Text>
              <TaskStatusChip status={renderItemTemplateStatus as keyof typeof TaskStatus} />
            </Box>
            {renderItemTemplateStatus === 'PENDING' || renderItemTemplateStatus === 'IN_PROGRESS' ? (
              <IconButton sx={{ padding: '4px' }} onClick={handleOpenMobileActions}>
                <MoreVertIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            ) : null}
          </Flex>
          <Box sx={{ marginTop: '16px' }}>
            <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
              {tTasks('mobile.assignedBy')}
            </Text>
            <Box>
              <Box sx={{ display: 'flex', marginBottom: '8px' }}>
                {renderItemAssignedBy?.userAvatar ? (
                  <ImageLazy
                    src={renderItemAssignedBy.userAvatar?.url}
                    alt=""
                    width={24}
                    height={24}
                    sxStyle={{
                      borderRadius: '50%',
                      border: '1px solid #F7F8F9',
                      position: 'relative',
                      margin: '0 8px 0 0'
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      flex: '0 0 24px',
                      borderRadius: '50%',
                      margin: '0 8px 0 0',
                      background: 'rgba(9, 14, 22, 0.06)',
                      border: '1px solid var(--border-inverse, #F7F8F9)'
                    }}
                  />
                )}
                <Text
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {!renderItemAssignedBy?.firstName && !renderItemAssignedBy?.lastName
                    ? EMPTY_CELL_VALUE
                    : `${renderItemAssignedBy?.firstName} ${renderItemAssignedBy?.lastName}`}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: '16px' }}>
            <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
              {tTasks('mobile.startDate')}
            </Text>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ScheduleIcon sx={{ width: '18px', marginRight: '6px' }} />
              <Text sx={{ fontSize: '14px', fontWeight: '400' }}>
                {renderItemActivationTargetDate ? formatDate(renderItemActivationTargetDate ?? '') : EMPTY_CELL_VALUE}
              </Text>
            </Box>
          </Box>
          <Divider sx={{ margin: '20px 0px' }} />
          <Box>
            <Text component={'h3'} variant={'titleXl'} sx={{ fontSize: '16px', fontWeight: 600, margin: '0' }}>
              {tTasks('mobile.description')}
            </Text>
            <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '12px' }}>
              {renderItemTemplateDescription ? renderItemTemplateDescription : EMPTY_CELL_VALUE}
            </Text>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            {renderItemParameters?.map((item) => {
              return <ParameterItemMobile item={item} key={item.id} />;
            })}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'sticky',
          bottom: '0px',
          left: '0px',
          padding: '16px 16px 58px 16px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          borderTop: '1px solid rgba(41, 49, 62, 0.15)',
          background: '#FFFFFF'
        }}
      >
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100px' }}
          disabled={
            renderItemTemplateStatus === 'OVERDUE' ||
            renderItemTemplateStatus === 'COMPLETED' ||
            renderItemTemplateStatus === 'CANCELLED' ||
            renderItemTemplateStatus === 'ON_HOLD_REQUESTED'
          }
          onClick={() => {
            if (renderItemTemplateStatus === 'PENDING') {
              handleStartTask();
            }
            navigateTo(`/tasks/${taskId}/execute`);
          }}
        >
          {tTasks('mobile.startBtn')}
        </Button>
      </Box>
      <MobileActions
        isOpen={mobileActionsOpen}
        onOpen={handleOpenMobileActions}
        onClose={handleCloseMobileActions}
        taskName={renderItemName ?? ''}
      />
    </>
  );
};
