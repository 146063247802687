import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, TextField } from 'infinitytechnologies-ui';
import { Text } from 'infinitytechnologies-ui';

import { Box, Switch } from '@mui/material';

import { ParameterTypeBox } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter';
import { PARAMETER_TYPE } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter/helpers';
import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components';

import { Flex, ImageLazy } from '@/separatedModules/components';

import { EquipmentParameterItem } from '../../Step4';

import { ValidationModalTypes } from './components/ValidationModalTypes';

import { FormProps, ROUND_EXEC_VALUE, ROUND_MAX_VALUE, ROUND_MIN_VALUE } from '../../../../helpers';
import { conditionsTitles, ConditionType } from './helpers';

interface ValidationModalProps {
  modalTitle: string;
  handleCloseModal: () => void;
  handleModalAction: (
    selectedCondition: string,
    roundExecValue: string | undefined,
    roundMinValue: string | undefined,
    roundMaxValue: string | undefined
  ) => void;
  modalBtnText: string;
  isOpen: boolean;
  modalContent: string;
  parameter: EquipmentParameterItem;
  isOpenEditValidationModal: boolean;
  isOpenModalAddValidation: boolean;
  isEdit?: boolean;
  isShowRoundCustomParams?: boolean;
  roundCustomParams: {
    key: string;
    value: string;
  }[];
  handleChangeCustomParamsVisibility: (value: boolean) => void;
  onChangeCustomValue: ({ key, value }: { key: string; value: string }) => void;
}

export const ValidationModal = ({
  handleCloseModal,
  handleModalAction,
  modalBtnText,
  modalTitle,
  isOpen,
  modalContent,
  parameter,
  isOpenEditValidationModal,
  isOpenModalAddValidation,
  isEdit = false,
  isShowRoundCustomParams,
  roundCustomParams,
  handleChangeCustomParamsVisibility,
  onChangeCustomValue
}: ValidationModalProps) => {
  const { t: tGlobal } = useTranslation('global');

  const isBoolean =
    parameter.type === 'BOOLEAN' && parameter.condition?.exactValue === 'true' ? 'Passed' : 'Not passed';

  const [selectedCondition, setSelectedCondition] = useState(
    isBoolean || conditionsTitles[parameter.condition?.type as ConditionType]
  );

  const { control, formState, watch, clearErrors, setValue } = useForm<FormProps>({ mode: 'all' });

  const paramItemConfig = PARAMETERS_LIST_FULL.find((paramItem) => parameter.type === paramItem.parameterType);

  const isNumeric = parameter.type.includes('NUMERIC');

  const roundExecValue = watch(ROUND_EXEC_VALUE);
  const roundMinValue = watch(ROUND_MIN_VALUE);
  const roundMaxValue = watch(ROUND_MAX_VALUE);

  const isDisabled =
    (selectedCondition === 'Match the value' && !roundExecValue?.length) ||
    (selectedCondition !== 'Match the value' && (!roundMaxValue?.length || !roundMinValue?.length));

  const handleReset = () => {
    setValue(ROUND_MIN_VALUE, '');
    setValue(ROUND_MAX_VALUE, '');
    setValue(ROUND_EXEC_VALUE, '');
  };

  useEffect(() => {
    if (!isOpenEditValidationModal && isOpenModalAddValidation) {
      handleReset();
      setSelectedCondition(parameter.type === 'BOOLEAN' ? 'Passed' : 'Match the value');
      clearErrors();
    }
  }, [isOpenEditValidationModal, isOpenModalAddValidation]);

  return (
    <Dialog
      headerTitle={modalTitle}
      hideHeaderBorder
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className={'my-0'}
            variant={'text'}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleCloseModal}
          >
            {tGlobal('btn.btnCancel')}
          </Button>

          <Button
            className={'my-0'}
            variant={'contained'}
            onClick={() => {
              handleModalAction(selectedCondition, roundExecValue, roundMinValue, roundMaxValue);
            }}
            disabled={parameter.type !== 'BOOLEAN' && isDisabled}
          >
            {modalBtnText}
          </Button>
        </div>
      }
      open={isOpen}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          maxWidth: '842px',
          '.MuiModal-box': {
            maxWidth: '842px !important'
          },
          '.MuiModal-content': {
            paddingTop: '20px !important'
          },
          '.MuiModal-header': {
            paddingBottom: '0px !important'
          }
        }
      }}
    >
      <Text
        className={'m-0'}
        variant={'bodyLg'}
        sx={{ fontSize: '14px', color: 'var(--text-subtle, #505668)', maxWidth: '730px' }}
        dangerouslySetInnerHTML={{
          __html: modalContent as string
        }}
      />

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderRadius: 'var(--4, 4px)',
            background: 'var(--background-neutral-hovered, #F7F8F9)',
            padding: '8px',
            margin: '24px 0 0',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: 'max-content'
          }}
        >
          <Flex alignItems="center">
            {paramItemConfig?.logoPath ? (
              <ImageLazy
                src={paramItemConfig?.logoPath}
                alt={`Equipment item parameter ${paramItemConfig.title} type preview`}
                variant={'rectangular'}
                width={32}
                height={32}
                sxStyle={{
                  margin: '0 8px 0 0'
                }}
              />
            ) : null}

            <Text variant={'labelLg'} m={0}>
              {parameter?.name}
            </Text>
          </Flex>

          <Box sx={{ marginLeft: '16px' }}>
            <ParameterTypeBox title={isNumeric ? 'Numeric data' : PARAMETER_TYPE[parameter.type as any]} />

            {isNumeric ? <ParameterTypeBox title={PARAMETER_TYPE[parameter.type as any]} /> : null}
          </Box>
        </Box>
        <ValidationModalTypes
          selectedCondition={selectedCondition}
          setSelectedCondition={setSelectedCondition}
          clearErrors={clearErrors}
          control={control}
          formState={formState}
          handleReset={handleReset}
          isBoolean={parameter.type === 'BOOLEAN'}
          isEdit={isEdit}
          parameter={parameter}
          isShowRoundCustomParams={isShowRoundCustomParams}
        />
        {parameter.type === 'BOOLEAN' && (
          <Box
            sx={{
              borderTop: '1px solid var(--border-default, rgba(41, 49, 62, 0.15))',
              marginTop: '32px',
              paddingTop: '32px'
            }}
          >
            <Flex alignItems="center">
              <Text
                variant={'bodyMd'}
                color={'#090E16'}
                sx={{
                  margin: '0',
                  marginRight: '8px',
                  fontWeight: 600
                }}
              >
                {'Enter custom condition type'}
              </Text>

              <Switch
                size="small"
                onChange={() => handleChangeCustomParamsVisibility(!isShowRoundCustomParams)}
                checked={isShowRoundCustomParams}
              />
            </Flex>
            <Flex
              alignItems="center"
              sx={{
                marginTop: '24px',
                opacity: isShowRoundCustomParams ? 1 : 0.3
              }}
            >
              <TextField
                label={'Custom condition type 1'}
                value={roundCustomParams[0]?.value}
                onChange={(e) => {
                  onChangeCustomValue({
                    key: 'true',
                    value: e.target.value
                  });
                }}
                sx={{
                  marginRight: '24px !important'
                }}
                disabled={!isShowRoundCustomParams}
              />
              <TextField
                label={'Custom condition type 2'}
                value={roundCustomParams[1]?.value}
                className="m-0"
                onChange={(e) => {
                  onChangeCustomValue({
                    key: 'false',
                    value: e.target.value
                  });
                }}
                disabled={!isShowRoundCustomParams}
              />
            </Flex>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
