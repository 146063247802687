import { ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

import {
  Breadcrumbs,
  ChevronRightIcon,
  ConfigTableI,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  Flex
} from '@/separatedModules/components';
import { LocationsIcon } from '@/separatedModules/components/Icon/svg-components/LocationsIcon';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { TranslationT } from '@/subsidiaryBinders/types';

import { getBreadcrumbs } from '../Templates';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT) => ReactNode;
}

export const CONFIG_TABLE: ConfigTableI = {
  cells: [
    {
      order: 0,
      columnName: 'equipment-name',
      columnClass: 'MuiTableCell-equipment-name',
      title: 'Title',
      Render: ({ name, description }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            <Box sx={{ margin: '0px' }}>
              {name ? (
                <Text
                  className={'m-0'}
                  variant={'titleSm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {name}
                </Text>
              ) : null}

              {description ? (
                <Text
                  className={'m-0'}
                  variant={'bodySm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {description}
                </Text>
              ) : (
                EMPTY_CELL_VALUE
              )}
            </Box>
          </TableCell>
        );
      }
    },
    {
      order: 1,
      columnName: 'equipment-domain',
      columnClass: 'MuiTableCell-equipment-domain',
      title: 'Domain',
      Render: ({ equipment }, className) => (
        <TableCell className={className}>
          {equipment[0].domain?.id ? (
            <DomainStatusChip color={equipment[0].domain?.color} label={equipment[0].domain.name} />
          ) : (
            EMPTY_CELL_VALUE
          )}
        </TableCell>
      )
    },
    {
      order: 2,
      columnName: 'equipment-system-name',
      columnClass: 'MuiTableCell-equipment-system-name',
      title: 'Locations',
      Render: ({ equipment }, className) => {
        const breadcrumbsLinks = getBreadcrumbs(equipment[0].location);

        return (
          <TableCell className={className}>
            <Flex alignItems="center">
              <Flex sx={{ margin: '0px' }} alignItems="center">
                <LocationsIcon />
                <Text className={'m-0'} variant={'bodyLg'}>
                  {breadcrumbsLinks.length > 0 ? (
                    <Breadcrumbs
                      links={breadcrumbsLinks}
                      separator={<ChevronRightIcon sx={{ height: '10px', width: '16px' }} />}
                      sx={{ padding: 0, width: '160px' }}
                      fontSize="12px"
                      separatorStyle={{ padding: '0px', width: '16px' }}
                    />
                  ) : (
                    EMPTY_CELL_VALUE
                  )}
                </Text>
              </Flex>
            </Flex>
          </TableCell>
        );
      }
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
