import Box from '@mui/material/Box';

import { PageTitle } from '@/separatedModules/components';

import { ExportCSVWrapper } from '../ExportCSVWrapper';

export const PageTopBox = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={'Round regulations'}
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
      />
      <ExportCSVWrapper />
    </Box>
  );
};
