import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { SearchDomainsQuery } from '@/logicLayers/domain';

import { formatSelectOptions } from '@/separatedModules/pages/Users/components/AddUser/components/AddUserModal/helpers';

import { TextFieldControl } from '@/separatedModules/components';
import { AutoCompleteParamsControl } from '@/separatedModules/components/Controls/AutoCompleteParamsControl';

import { ROLE_DESCRIPTION_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { PARAMETER_DESCRIPTION, PARAMETER_TITLE } from '../../../../../helpers';
import { checkParameterTypeText, MAX_GUIDE_LENGTH, MIN_GUIDE_LENGTH } from '../helpers';

import { SEARCH_METRICS } from '@/logicLayers/metric/metrics';

interface StringBooleanDateTimeStepsProps {
  parameterType: string;
}

export const StringBooleanDateTimeSteps: FC<StringBooleanDateTimeStepsProps> = ({ parameterType }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { data: metricsData, loading } = useQuery<SearchDomainsQuery>(SEARCH_METRICS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: { pageSize: 1000 }
      }
    }
  });

  const metricsList = useMemo(() => {
    if (!metricsData?.dataItems?.items?.length) return [{ value: 'request-new', label: 'Request new parameter title' }];

    return [
      { value: 'request-new', label: 'Request new parameter title' },
      ...formatSelectOptions(metricsData.dataItems.items, 'id', 'name')
    ];
  }, [metricsData?.dataItems?.items]);

  return (
    <Grid container justifyContent="center" sx={{ margin: '0 0 80px 0' }}>
      <Grid item xs={12} sm={10} md={8} lg={10}>
        <Controller
          name={PARAMETER_TITLE}
          defaultValue=""
          control={control}
          rules={{
            required: { value: true, message: tGlobal('validation.required') },
            pattern: {
              value: ROLE_DESCRIPTION_REG_EXP,
              message: tGlobal('validation.descriptionLength', { value: 100 })
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <AutoCompleteParamsControl
              title={tTemplates('createParameterItem.parameter.title.title')}
              subTitle={tTemplates('createParameterItem.parameter.title.subTitle', {
                value: checkParameterTypeText(parameterType)
              })}
              label={tTemplates('createParameterItem.parameter.title.title')}
              name={PARAMETER_TITLE}
              options={metricsList}
              validation={{
                isValid: !errors[PARAMETER_TITLE]?.message,
                error: errors[PARAMETER_TITLE]?.message as string
              }}
              getOptionKey={(option: any) => option.value as string}
              value={value}
              loading={loading}
              onChange={(e, value: any) => {
                if (value?.value === 'request-new') {
                  onChange(null);
                  return;
                }

                onChange(value);
              }}
              onBlur={onBlur}
            />
          )}
        />

        <Controller
          name={PARAMETER_DESCRIPTION}
          defaultValue=""
          control={control}
          rules={{
            pattern: {
              value: /^[\w\s`'.\-,#№()_]{3,400}$/,
              message: tGlobal('validation.textFieldMinMaxLength', {
                valueMin: MIN_GUIDE_LENGTH,
                valueMax: MAX_GUIDE_LENGTH
              })
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldControl
              title={tTemplates('createParameterItem.parameter.description.title')}
              subTitle={tTemplates('createParameterItem.parameter.description.subTitle', {
                value: checkParameterTypeText(parameterType)
              })}
              label={tTemplates('createParameterItem.parameter.description.titleLabel')}
              placeholder={tTemplates('createParameterItem.parameter.description.titlePlaceholder')}
              name={PARAMETER_DESCRIPTION}
              rows={7}
              maxContentLength={400}
              value={value}
              validation={{
                isValid: !errors[PARAMETER_DESCRIPTION]?.message,
                error: errors[PARAMETER_DESCRIPTION]?.message as string
              }}
              multiline
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
