import { MouseEvent, ReactNode, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  EquipmentParameterRes,
  EquipmentQuery,
  GET_EQUIPMENT,
  GET_EQUIPMENT_PARAMETER,
  GET_EQUIPMENT_PARAMETERS,
  GET_USER_PROFILE,
  GetProfileQuery,
  SWITCH_EQUIPMENT_PARAMETERS_ACTIVE_STATE,
  SWITCH_EQUIPMENT_PARAMETERS_ARCHIVE_STATE
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AlertCheckNoticeIcon,
  DeleteTrashIcon,
  DoNotDisturbOnIcon,
  EditIcon,
  MoreVertIcon
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';
import { TranslationT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

interface RowActionMenuProps extends EquipmentParameterRes {
  isTableVariant: boolean;
  isParameter?: boolean;
  renderModalContent?: () => ReactNode;
}

interface RowActionMenuState {
  isModalOpen: boolean;
  modalType: string;
}

const getModalData = (
  row: RowActionMenuProps,
  state: RowActionMenuState,
  t: TranslationT,
  tGlobal: TranslationT,
  handleChangeItemActiveState: () => void,
  handleChangeItemArchiveState: () => void
) => {
  const isItemActive = row?.active;

  const checkDisable = (text: string) => {
    return isItemActive && !row?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkEnable = (text: string) => {
    return !isItemActive && !row?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkArchive = (text: string) => {
    return !isItemActive && !row?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const checkUnArchive = (text: string) => {
    return !isItemActive && row?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const modalTitleForDisable = checkDisable(t('equipmentItem.parameters.modal.titleToDisable'));
  const modalTitleForEnable = checkEnable(t('equipmentItem.parameters.modal.titleToEnable'));
  const modalTitleForArchive = checkArchive(t('equipmentItem.parameters.modal.titleToArchive'));
  const modalTitleForUnArchive = checkUnArchive(t('equipmentItem.parameters.modal.titleToUnArchive'));

  const modalContentForDisable = checkDisable(
    t('equipmentItem.parameters.modal.contentToDisable', { value: row.title })
  );
  const modalContentForEnable = checkEnable(t('equipmentItem.parameters.modal.contentToEnable', { value: row.title }));
  const modalContentForArchive = checkArchive(
    t('equipmentItem.parameters.modal.contentToArchive', { value: row.title })
  );
  const modalContentForUnArchive = checkUnArchive(
    t('equipmentItem.parameters.modal.contentToUnArchive', { value: row.title })
  );

  const modalBtnTextForDisable = checkDisable(tGlobal('btn.btnDisable'));
  const modalBtnTextForEnable = checkEnable(tGlobal('btn.btnEnable'));
  const modalBtnTextForArchive = checkArchive(tGlobal('btn.btnRemove'));
  const modalBtnTextForUnArchive = checkUnArchive(tGlobal('btn.btnUnArchive'));

  const modalTitle = modalTitleForDisable || modalTitleForEnable || modalTitleForArchive || modalTitleForUnArchive;
  const modalContent =
    modalContentForDisable || modalContentForEnable || modalContentForArchive || modalContentForUnArchive;
  const modalBtnText =
    modalBtnTextForDisable || modalBtnTextForEnable || modalBtnTextForArchive || modalBtnTextForUnArchive;

  const handleModalAction =
    modalTitleForDisable || modalTitleForEnable ? handleChangeItemActiveState : handleChangeItemArchiveState;

  return {
    modalTitle,
    modalContent,
    modalBtnText,
    handleModalAction
  };
};

export const RowActionMenu = (row: RowActionMenuProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t } = useTranslation('templates');

  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const [state, setState] = useState<RowActionMenuState>({
    isModalOpen: false,
    modalType: ''
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [SwitchEquipmentsActiveState] = useMutation<any>(SWITCH_EQUIPMENT_PARAMETERS_ACTIVE_STATE, {
    refetchQueries: [
      row?.isTableVariant ? GET_EQUIPMENT_PARAMETERS : '',
      row?.isParameter ? GET_EQUIPMENT_PARAMETER : ''
    ]
  });

  const [SwitchEquipmentsArchiveState] = useMutation<any>(SWITCH_EQUIPMENT_PARAMETERS_ARCHIVE_STATE, {
    refetchQueries: [
      row?.isTableVariant ? GET_EQUIPMENT_PARAMETERS : '',
      row?.isParameter ? GET_EQUIPMENT_PARAMETER : ''
    ]
  });

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickBox = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleEditItem = () => {
    row?.id &&
      navigateTo(
        LINKS_PAGES.templatesEquipmentItemParameter.replace(':templateId', templateId).replace(':parameterId', row?.id)
      );
    setAnchorEl(null);
  };

  console.dir(row);

  const handleChangeItemActiveState = () => {
    if (row) {
      const isActive = !row?.active;

      SwitchEquipmentsActiveState({
        variables: {
          request: {
            ids: [row?.id],
            active: isActive
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isActive
          ? tGlobal('alertMessages.success.templates.equipment.parameter.isEnabled', { value: row?.title })
          : tGlobal('alertMessages.success.templates.equipment.parameter.isDisabled', { value: row?.title }),
        severity: 'success',
        autoHideDuration: 1500
      });
    }
  };

  const handleChangeItemArchiveState = () => {
    if (row && row?.active === false) {
      const isArchive = !row?.archived;

      SwitchEquipmentsArchiveState({
        variables: {
          request: {
            ids: [row?.id],
            archived: !row?.archived
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isArchive
          ? tGlobal('alertMessages.success.templates.equipment.parameter.isArchived', { value: row?.title })
          : tGlobal('alertMessages.success.templates.equipment.parameter.isUnArchived', { value: row?.title }),
        severity: 'success',
        autoHideDuration: 1500
      });

      if (!row?.isTableVariant) {
        setTimeout(() => {
          if (row?.isParameter) {
            return navigateTo(`/templates/equipment/item/${row?.equipmentId}/parameters`);
          }

          navigateTo(LINKS_PAGES.templatesEquipment);
        }, 1500);
      }
    }
  };

  const handleOpenModal = (modalType: string) => {
    setAnchorEl(null);

    setState({ isModalOpen: true, modalType });
  };

  const handleCloseModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
  };

  const { modalTitle, modalContent, modalBtnText, handleModalAction } = getModalData(
    row,
    state,
    t,
    tGlobal,
    handleChangeItemActiveState,
    handleChangeItemArchiveState
  );

  return (
    <Box component={'td'} className={'table-edit-button'} onClick={handleClickBox}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {row?.isTableVariant ? (
          <MenuItem onClick={handleEditItem} disabled={!row?.active || !isShowEditItem || !isUserCanCRUD}>
            <EditIcon sx={{ fontSize: 20 }} />

            {tGlobal('btn.btnEdit')}
          </MenuItem>
        ) : null}

        {row?.isTableVariant ? (
          <Box
            sx={{
              height: '1px',
              margin: '8px 0',
              background: 'var(--border-default, rgba(9, 14, 22, 0.15))'
            }}
          />
        ) : null}

        {!row?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')} disabled={row?.archived}>
            <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 20 }} />

            {tGlobal('statuses.enable')}
          </MenuItem>
        ) : null}

        {row?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')}>
            <DoNotDisturbOnIcon />

            {tGlobal('statuses.disable')}
          </MenuItem>
        ) : null}

        <MenuItem disabled={row?.active} onClick={() => handleOpenModal('toggleArchive')}>
          <DeleteTrashIcon sx={{ fontSize: 20 }} />

          {tGlobal('btn.btnRemove')}
        </MenuItem>
      </Menu>

      <Dialog
        headerTitle={modalTitle}
        hideHeaderBorder
        contentFooter={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={'my-0'}
              variant={'text'}
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCloseModal}
            >
              {tGlobal('btn.btnCancel')}
            </Button>

            <Button className={'my-0'} variant={'contained'} onClick={handleModalAction}>
              {modalBtnText}
            </Button>
          </div>
        }
        open={state.isModalOpen}
        onClose={handleCloseModal}
      >
        <Text
          className={'m-0'}
          variant={'bodyLg'}
          dangerouslySetInnerHTML={{
            __html: modalContent as string
          }}
        />

        <Box sx={{ width: '100%', flex: '0 1 100%' }}>{row?.renderModalContent ? row?.renderModalContent() : null}</Box>
      </Dialog>
    </Box>
  );
};
