import { useRef } from 'react';
import classNames from 'classnames';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import { TimeoutT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../Providers';

import { CONFIG_TABLE } from '../../helpers';

export const TableBodyWrap = () => {
  const { t: tCompanies } = useTranslation('companies');
  const state = useContextState();
  const { handleClickRow, handleDoubleClickRow } = useContextUpdateState();

  const timerId = useRef<TimeoutT>();

  const handleClickTimeout = (id: string, type: number) => {
    clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (type === 1) {
        handleClickRow(id);
      } else if (type === 2) {
        handleDoubleClickRow(id);
      }
    }, 200);

    return () => clearTimeout(timerId.current);
  };

  return (
    <TableBody>
      {state.bodyDataRows?.map((row) => {
        return (
          <TableRow
            key={row.id}
            className={classNames({
              'Mui-selected': state.selectedItems.includes(row.id as string)
            })}
            onClick={(event) => {
              if ((event.detail === 1 || event.detail === 2) && row.id) {
                handleClickTimeout(row.id, event.detail);
              }
            }}
          >
            {CONFIG_TABLE.cells.map((i, index) => {
              const className = `${CONFIG_TABLE.cells[index].columnClass}`;

              return i.Render(row, className, index, tCompanies);
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
