import { ReactNode } from 'react';
import { Location } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

import { UsersRoleTemplateRes } from '@/logicLayers/domain';

import { DomainStatusChip, ImageLazy } from '@/separatedModules/components';
import { EMPTY_CELL_VALUE, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { TranslationT } from '@/subsidiaryBinders/types';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT, location?: Location<any>) => ReactNode;
}

export interface ConfigTableI {
  cells: TableCellI<UsersRoleTemplateRes>[];
  pagination: {
    page: number[];
    rowsPerPage: number[];
    rowsPerPageOptions: number[];
  };
}

export const CONFIG_TABLE: ConfigTableI = {
  cells: [
    {
      order: 0,
      columnName: 'title',
      columnClass: 'MuiTableCell-title',
      title: 'Name',
      Render: ({ firstName, lastName, userAvatar }, className, index) => {
        const fullName = firstName && lastName ? `${firstName} ${lastName}` : EMPTY_CELL_VALUE;

        return (
          <TableCell key={index} className={className}>
            {userAvatar ? (
              <ImageLazy
                src={userAvatar?.url}
                alt={userAvatar?.url}
                width={24}
                height={24}
                sxStyle={{ marginRight: '8px' }}
              />
            ) : (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  borderRadius: '50%',
                  background: 'rgb(240, 240, 241)'
                }}
              />
            )}
            <Box>
              <Text
                className={'m-0'}
                variant={'titleSm'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {fullName}
              </Text>
            </Box>
          </TableCell>
        );
      }
    },
    {
      order: 1,
      columnName: 'email',
      columnClass: 'MuiTableCell-email',
      title: 'Email',
      Render: ({ email }, className, index) => (
        <TableCell key={index} className={className}>
          <Box>
            <Text variant={'bodyLg'} className="m-0">
              {email}
            </Text>
          </Box>
        </TableCell>
      )
    },
    {
      order: 2,
      columnName: 'phoneNumber',
      columnClass: 'MuiTableCell-phoneNumber',
      title: 'Phone number',
      Render: ({ phoneNumber }, className, index) => {
        return (
          <TableCell key={index} className={className}>
            {phoneNumber || EMPTY_CELL_VALUE}
          </TableCell>
        );
      }
    },
    {
      order: 3,
      columnName: 'domain',
      columnClass: 'MuiTableCell-domain',
      title: 'Domain',
      Render: ({ domains }, className, index) => (
        <TableCell key={index} className={className}>
          {domains?.map((domain, index) => (
            <Box key={index} sx={{ marginRight: '8px' }}>
              <DomainStatusChip color={domain?.color} label={domain?.name} />
            </Box>
          ))}
        </TableCell>
      )
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [[skeletonTableCellRect], [skeletonTableCellRect], [skeletonTableCellRect]];
