import { FC } from 'react';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';
import { NavListSimple } from '@/separatedModules/layouts/components/SideBarProvider/components/NavListSimple';

import { LINKS_SIDEBAR_RR_MENU } from '@/subsidiaryBinders/constants';

export const SideBarRoundRegulations: FC = () => (
  <Sidebar>
    {/*
     <SideBarGroup>
      <MuiTreeView
        tree={TestUsersTree}
        rootItemIcon={<GroupIcon />}
        defaultExpanded={[]}
        onNodeSelect={(nodeIds) => console.log('select', nodeIds)}
        onNodeActionClick={(nodeIds) => console.log('action', nodeIds)}
      />
    </SideBarGroup>

    <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))', margin: '8px 0' }} /> */}

    <SideBarGroup>
      <NavListSimple menu={LINKS_SIDEBAR_RR_MENU.filter((item) => !item.isHidden)} />
    </SideBarGroup>
  </Sidebar>
);
