import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { GET_NOTIFICATIONS, GET_ON_HOLD_REQUEST_INFO, PROCESS_REQUEST_ON_HOLD } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { DateAndTimePicker } from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate/components/ActivateRound/components/DateAndTimePicker';

import { ArrowOutwardIcon, CloseIcon, EMPTY_CELL_VALUE, ImageLazy } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { StyledDialog } from './style';

interface RequestOnHoldModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleRemove: (id: string) => void;
  requestIds: { resourceId: string; notificationId: string };
}

export const RequestOnHoldModal = ({ isOpen, onClose, handleRemove, requestIds }: RequestOnHoldModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [expirationDate, setExpirationDate] = useState<{ date: Dayjs; time: Dayjs }>({
    date: dayjs(),
    time: dayjs()
  });
  const [isShowDateInput, setIsShowDateInput] = useState<boolean>(false);

  const { data } = useQuery(GET_ON_HOLD_REQUEST_INFO, {
    variables: { requestId: requestIds.resourceId },
    skip: !requestIds.resourceId
  });

  const [processRequestOnHold] = useMutation(PROCESS_REQUEST_ON_HOLD, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const handleCancel = () => {
    onClose();
    setIsShowDateInput(false);
  };

  const handleDecline = () => {
    processRequestOnHold({
      variables: {
        request: {
          requestId: requestIds.resourceId,
          approve: false
        }
      },
      onCompleted: () => {
        handleRemove(requestIds.notificationId);
        AlertService.showAlert({
          title: tGlobal('alertMessages.success.onHoldDeclined'),
          severity: 'success'
        });
        onClose();
      }
    });
  };

  const handleApprove = () => {
    if (!isShowDateInput) {
      setIsShowDateInput(true);
    } else {
      processRequestOnHold({
        variables: {
          request: {
            requestId: requestIds.resourceId,
            approve: true,
            expirationDate: `${expirationDate.date?.format('YYYY-MM-DD')}T${expirationDate.time?.format('HH:mm')}`
          }
        },
        onCompleted: () => {
          handleRemove(requestIds.notificationId);
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.onHoldApproved'),
            severity: 'success'
          });
          onClose();
          setIsShowDateInput(false);
        }
      });
    }
  };

  return (
    <StyledDialog open={isOpen} onClose={handleCancel}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 24px' }}>
          <Text variant="titleLg" sx={{ fontSize: 20 }}>
            {tGlobal('notifications.requestOnHoldModal.title')}
          </Text>
          <CloseIcon onClick={handleCancel} />
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box sx={{ padding: '32px 24px 20px 24px', width: '100%' }}>
          <Text sx={{ fontSize: '16px', color: '#121B2A' }}>
            {isShowDateInput
              ? tGlobal('notifications.requestOnHoldModal.subTitleDate', {
                  value: data?.onHoldRequest?.roundInfo?.name
                })
              : tGlobal('notifications.requestOnHoldModal.subTitle')}
          </Text>
          {isShowDateInput ? (
            <Box
              sx={{
                borderRadius: '4px',
                background: 'var(--background-neutral-hovered, #F7F8F9)',
                padding: '8px 0px',
                marginTop: '24px'
              }}
            >
              <DateAndTimePicker
                onChange={(date: Dayjs, time: Dayjs) => {
                  setExpirationDate({ date, time });
                }}
                value={expirationDate}
                minTime={dayjs().subtract(1, 'minute')}
                displayText={tGlobal('notifications.requestOnHoldModal.dateSelectText', {
                  value: dayjs(data?.onHoldRequest?.requestedAt).format('DD/MM/YYYY HH:mm')
                })}
                maxDate={dayjs().add(2, 'year').endOf('year')}
                minDate={dayjs()}
                label={tGlobal('notifications.requestOnHoldModal.select')}
              />
            </Box>
          ) : (
            <Box
              sx={{
                background: '#F5F7FA',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
                marginTop: '24px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '24px' }}>
                <Box>
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#121B2A',
                      marginBottom: '12px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-all'
                    }}
                  >
                    {data?.onHoldRequest?.roundInfo?.name}
                  </Text>
                  <Box display={'flex'}>
                    {data?.onHoldRequest?.requestedBy?.userAvatar?.url ? (
                      <ImageLazy
                        src={data?.onHoldRequest?.requestedBy?.userAvatar?.url}
                        alt={`${data?.onHoldRequest?.requestedBy?.firstName} ${data?.onHoldRequest?.requestedBy?.lastName}`}
                        width={24}
                        height={24}
                        sxStyle={{
                          margin: '0 8px 0 0'
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          flex: '0 0 24px',
                          borderRadius: '50%',
                          margin: '0 8px 0 0',
                          background: 'rgba(9, 14, 22, 0.06)',
                          border: '1px solid var(--border-inverse, #F7F8F9)'
                        }}
                      />
                    )}
                    <Text
                      variant={'bodyLg'}
                      className="m-0"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-all'
                      }}
                    >
                      {data?.onHoldRequest?.requestedBy?.firstName} {data?.onHoldRequest?.requestedBy?.lastName}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant={'text'}
                    sx={{ background: '#F5F7FA ! important', width: '142px' }}
                    endIcon={<ArrowOutwardIcon />}
                    onClick={() => {
                      navigateTo(LINKS_PAGES.tasksDetails.replace(':taskId', data?.onHoldRequest?.roundId || ''));
                      onClose();
                    }}
                  >
                    {tGlobal('notifications.requestOnHoldModal.btnViewTask')}
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ margin: '16px 0' }} />
              <Box>
                <Text sx={{ fontSize: '14px', fontWeight: '500', color: '#505864', marginBottom: '12px' }}>
                  {data?.onHoldRequest?.reason || EMPTY_CELL_VALUE}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', padding: '20px 24px' }}>
          <Button className={'my-0'} variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCancel}>
            {tGlobal('modal.btnCancel')}
          </Button>

          <Button className={'my-0'} variant={'outlined'} onClick={handleDecline}>
            {tGlobal('notifications.requestOnHoldModal.btnDecline')}
          </Button>

          <Button className={'my-0'} variant={'contained'} onClick={handleApprove}>
            {tGlobal('notifications.requestOnHoldModal.btnApprove')}
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
};
