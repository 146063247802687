import { DocIcon } from '@/separatedModules/components/Icon/svg-mui-theme/DocIcon';
import { PdfIcon } from '@/separatedModules/components/Icon/svg-mui-theme/PdfIcon';
import { XlIcon } from '@/separatedModules/components/Icon/svg-mui-theme/XlIcon';

export enum DocumentType {
  DOCUMENT = 'DOCUMENT',
  PDF = 'PDF',
  SPREADSHEET = 'SPREADSHEET'
}

export const getDocumentTypeFromUrl = (url: string): DocumentType | null => {
  console.log(url);

  const cleanUrl = url?.split('?')[0];

  const extension = cleanUrl?.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return DocumentType.PDF;
    case 'doc':
    case 'docx':
      return DocumentType.DOCUMENT;
    case 'xls':
    case 'xlsx':
      return DocumentType.SPREADSHEET;
    default:
      return null;
  }
};

export const DocumentIcons: Record<DocumentType, React.FC> = {
  [DocumentType.PDF]: PdfIcon,
  [DocumentType.DOCUMENT]: DocIcon,
  [DocumentType.SPREADSHEET]: XlIcon
};

export const getIconByDocumentType = (type: DocumentType): React.FC | null => {
  return DocumentIcons[type] || null;
};
