import { Control, Controller, FormState, UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import {
  FormProps,
  ROUND_EXEC_VALUE,
  ROUND_MAX_VALUE,
  ROUND_MIN_VALUE
} from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate/helpers';

import { Flex, TextFieldControl } from '@/separatedModules/components';

import { EquipmentParameterItem } from '../../../../Step4';
import { ValidationConditionType } from '../ValidationConditionType';

const conditionTypes = [
  {
    label: 'Match the value',
    description: 'Entered value should match the fixed value'
  },
  {
    label: 'Fit the range',
    description: 'Entered value should fall within the range'
  },
  {
    label: 'Out of the range',
    description: 'Entered value should exceed the range'
  }
];

const booleanConditionTypes = [
  {
    label: 'Passed',
    description: 'Selected answer must be “Passed”'
  },
  {
    label: 'Not passed',
    description: 'Selected answer must be “Not passed”'
  }
];

interface ValidationModalTypesProps {
  selectedCondition: string;
  setSelectedCondition: (value: string) => void;
  control: Control<FormProps, any>;
  formState: FormState<FormProps>;
  clearErrors: UseFormClearErrors<FormProps>;
  handleReset: () => void;
  isBoolean: boolean;
  parameter?: EquipmentParameterItem;
  isEdit?: boolean;
  isShowRoundCustomParams?: boolean;
}

export const ValidationModalTypes = ({
  selectedCondition,
  setSelectedCondition,
  clearErrors,
  control,
  formState,
  handleReset,
  isBoolean,
  isEdit,
  parameter,
  isShowRoundCustomParams
}: ValidationModalTypesProps) => {
  const { t: tGlobal } = useTranslation('global');

  const isMatch = selectedCondition === 'Match the value';

  const handleConditionTypeClick = (label: string) => {
    setSelectedCondition(label);
    clearErrors();
    handleReset();
  };

  const conditionTypesToRender = isBoolean ? booleanConditionTypes : conditionTypes;

  const MAX_LENGTH_MESSAGE = tGlobal('validation.textFieldMaxLength', { value: 12 });

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Box
        sx={{ height: '1px', width: '100%', background: 'rgba(9, 14, 22, 0.15)', position: 'absolute', left: '0' }}
      />
      <Box sx={{ paddingTop: '20px' }}>
        <Text sx={{ fontSize: '14px', fontWeight: '600' }}>Select condition type</Text>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginTop="20px"
          maxWidth={isBoolean ? '520px' : '100%'}
          sx={{
            opacity: isShowRoundCustomParams && isBoolean ? 0.3 : 1
          }}
        >
          {conditionTypesToRender.map((type, id) => (
            <ValidationConditionType
              key={id}
              description={type.description}
              label={type.label}
              selectedCondition={selectedCondition}
              handleClick={() => handleConditionTypeClick(type.label)}
              isShowRoundCustomParams={isShowRoundCustomParams && isBoolean}
            />
          ))}
        </Flex>
      </Box>
      {!isBoolean && (
        <Box sx={{ paddingTop: '24px' }}>
          <Text sx={{ fontSize: '14px', fontWeight: '600' }}>Enter value</Text>

          <Flex alignItems={'start'} sx={{ marginTop: '24px' }}>
            {isMatch && (
              <Controller
                name={ROUND_EXEC_VALUE}
                control={control}
                defaultValue={isEdit ? (parameter?.condition?.exactValue as any) : ''}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  },
                  maxLength: {
                    value: 12,
                    message: MAX_LENGTH_MESSAGE
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      label={'Value'}
                      placeholder={'Enter numeric value'}
                      name={ROUND_EXEC_VALUE}
                      value={value}
                      validation={{
                        isValid: !formState.errors[ROUND_EXEC_VALUE]?.message,
                        error: formState.errors[ROUND_EXEC_VALUE]?.message
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                      sxInputBox={{ marginTop: '0px !important', width: '385px', marginRight: '24px !important' }}
                    />
                  );
                }}
              />
            )}

            {!isMatch && (
              <>
                <Controller
                  name={ROUND_MIN_VALUE}
                  control={control}
                  defaultValue={isEdit ? (parameter?.condition?.minValue as any) : ''}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    maxLength: {
                      value: 12,
                      message: MAX_LENGTH_MESSAGE
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <TextFieldControl
                        label={'Min value'}
                        placeholder={'Enter minimum value'}
                        name={ROUND_MIN_VALUE}
                        value={value}
                        validation={{
                          isValid: !formState.errors[ROUND_MIN_VALUE]?.message,
                          error: formState.errors[ROUND_MIN_VALUE]?.message
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                        sxInputBox={{ marginTop: '0px !important', width: '385px', marginRight: '24px !important' }}
                      />
                    );
                  }}
                />

                <Controller
                  name={ROUND_MAX_VALUE}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    maxLength: {
                      value: 12,
                      message: MAX_LENGTH_MESSAGE
                    }
                  }}
                  defaultValue={isEdit ? (parameter?.condition?.maxValue as any) : ''}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <TextFieldControl
                        label={'Max value'}
                        placeholder={'Enter maximum value'}
                        name={ROUND_MAX_VALUE}
                        value={value}
                        validation={{
                          isValid: !formState.errors[ROUND_MAX_VALUE]?.message,
                          error: formState.errors[ROUND_MAX_VALUE]?.message
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                        sxInputBox={{ margin: '0px !important', width: '385px' }}
                      />
                    );
                  }}
                />
              </>
            )}
          </Flex>
        </Box>
      )}
    </Box>
  );
};
