import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';

type CardMediaShortProps = {
  title: string;
  subTitle?: string;
  imgSrc?: string;
  sxWrap?: SxProps<Theme>;
  isLoading?: boolean;
  isShowImage?: boolean;
};

export const CardMediaShort = ({
  title,
  subTitle,
  imgSrc,
  sxWrap,
  isLoading,
  isShowImage = true
}: CardMediaShortProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 12px', margin: '0 0 12px', height: 56, ...sxWrap }}>
      {isLoading ? (
        <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={'100%'} animation={'wave'} />
      ) : (
        <>
          {isShowImage && (
            <Box
              className={'bg-img-centered'}
              sx={{
                width: 40,
                flex: '0 0 40px',
                height: 40,
                margin: '0 8px 0 0',
                borderRadius: 'var(--2, 2px)',
                backgroundImage: `url(${imgSrc})`
              }}
            ></Box>
          )}

          <Box>
            {title ? (
              <Text
                variant={'labelLg'}
                className={'line-clamp-1 m-0'}
                sx={{
                  color: 'var(--Custom-styles-Main-Black, #111827)',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {title}
              </Text>
            ) : null}

            {subTitle ? (
              <Text
                variant={'bodyMd'}
                className={'line-clamp-1'}
                sx={{
                  color: 'var(--text-subtle, #505668)',
                  lineHeight: '16px',
                  margin: '4px 0 0 0',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {subTitle}
              </Text>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};
