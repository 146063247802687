import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';

import { ActionsContainer } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Items/components';

import {
  Flex,
  handleGetSavedColumns,
  MuiTablePagination,
  NotFoundScreens,
  SelectsDropdown,
  SkeletonTableLoader,
  SORT_BY_ROUND_DATE_CREATE,
  TableAside,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItemWithKeyLabel
} from '@/separatedModules/components';

import { i18n } from '@/i18n';

import { RoundRegulationsAsideContent } from './components/RoundRegulationsAsideContent';

import { UNPLANNED_TASK_ROUND } from '../../../../../helpers';
import { columns, createStatuses, initState, skeletonTableLoaderCells, useTableData } from './helpers';

export const TaskUnplannedStep2 = () => {
  const { templateId = '' } = useParams();
  const { renderCount, renderItems, ...tableState } = useTableData(initState);

  const TABLE_SAVED_COLUMNS_ID = `templates/equipment/item/${templateId}/rounds/round-regulations`;

  const menuList = useMemo(() => createStatuses(), []);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const { control, watch } = useFormContext();

  const selectedRounds = watch(UNPLANNED_TASK_ROUND);

  return (
    <Controller
      name={UNPLANNED_TASK_ROUND}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <Grid justifyContent={'center'} container row>
            <Grid xs={12} item>
              <TablePageTopBox
                title={'Equipment item'}
                dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
                isHideButton
              />

              <TableFiltersBox
                searchPlaceholder={'Search'}
                selectedItems={selectedRounds}
                handleToggleAside={tableState.handleToggleAside}
                handleChangeBySearch={tableState.handleChangeBySearch}
              >
                <SelectsDropdown
                  id={'equipment-items-sort-by-status'}
                  title={'Status'}
                  menuList={menuList}
                  isRound
                  onChange={(value) => {
                    if (value.length) {
                      return tableState.handleChangeByStatus(value[0].value as boolean);
                    }

                    tableState.handleChangeByStatus(undefined);
                  }}
                />

                <TableSortByOneItemWithKeyLabel
                  menu={SORT_BY_ROUND_DATE_CREATE}
                  showIcon={true}
                  onChange={(value) => {
                    tableState.handleChangeSortByDate(value);
                  }}
                />
              </TableFiltersBox>

              <ActionsContainer
                state={{ ...tableState.state, selectedItems: selectedRounds }}
                handleResetSelectedRows={() => {
                  onChange([]);
                  tableState.handleResetSelectedRows();
                }}
                handleToggleAside={tableState.handleToggleAside}
              />

              {tableState.loading ? (
                <SkeletonTableLoader cells={skeletonTableLoaderCells} />
              ) : renderCount || tableState.state.filters?.search.length ? (
                renderItems ? (
                  <Flex>
                    <Box width={'100%'}>
                      <Box sx={{ overflow: 'auto' }}>
                        <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                          <Table className={'m-0 MuiTable-root__columns-6'}>
                            <TableHead>
                              {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                  {headerGroup.headers.map((header) => (
                                    <TableCell key={header.id}>
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableHead>
                            <TableBody>
                              {table.getRowModel().rows.map((row) => {
                                return (
                                  <TableRowWrap
                                    key={row.original.id}
                                    id={row.original.id as string}
                                    isSelected={selectedRounds.includes(row.original.id as string)}
                                    handleClickRow={(roundId) => {
                                      if (row.original.active) {
                                        onChange([roundId]);
                                        tableState.handleClickRow(roundId);
                                      }
                                    }}
                                    handleDoubleClickRow={tableState.handleDoubleClickRow}
                                  >
                                    {row.getVisibleCells().map((cell) => {
                                      return (
                                        <TableCell key={cell.id}>
                                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRowWrap>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <MuiTablePagination
                        count={renderCount}
                        page={tableState.state.page[0]}
                        rowsPerPage={tableState.state.rowsPerPage[0]}
                        rowsPerPageOptions={[20, 50, 100]}
                        onChangePage={tableState.handleChangePagination}
                        onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                      />
                    </Box>
                    <TableAside
                      rows={table.getRowModel().rows}
                      selectedItems={selectedRounds}
                      isAsideOpen={tableState.state.isAsideOpen}
                      handleToggleAside={tableState.handleToggleAside}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-all'
                      }}
                    >
                      {(props: any) => <RoundRegulationsAsideContent {...props} />}
                    </TableAside>
                  </Flex>
                ) : (
                  <Box sx={{ height: '59vh' }}>
                    <NotFoundScreens type={'search'} />
                  </Box>
                )
              ) : (
                <Box sx={{ height: '59vh' }}>
                  <NotFoundScreens
                    type={'companies'}
                    title={'No round regulations yet'}
                    subTitle={'Click “Create regulation" to add round regulation parameter'}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
