import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import { DomainDto, SearchUnitsTemplatesQuery } from '@/logicLayers/domain';

import { checkTablePagination } from '@/separatedModules/components';

import { ContextStateI, FirstMountState, initState, initStateI, TableProviderProps } from './helpers';

import { SEARCH_TEMPLATES_UNITS } from '@/logicLayers/units';

export const ContextState = createContext<ContextStateI>({
  ...initState,
  loadingUnits: false,
  setState: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ProviderState = ({ children }: TableProviderProps) => {
  const [state, setState] = useState<initStateI>(initState);

  const firstMountState = useRef<FirstMountState>({
    isFirstMount: false,
    initCount: 0
  });

  const { data: unitsData, loading: loadingUnits } = useQuery<SearchUnitsTemplatesQuery>(SEARCH_TEMPLATES_UNITS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          page: state.filters.search.length ? 0 : state.pagination.page[0],
          pageSize: state.pagination.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0].value : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        },
        isActive: state.filters.sortByStatus.length ? Boolean(state.filters.sortByStatus[0].value) : undefined,
        query: state.filters.search.length ? state.filters.search[0] : undefined
      }
    }
  });

  const contextValue = useMemo(() => {
    return {
      ...state,
      loadingUnits,
      setState
    };
  }, [state]);

  useEffect(() => {
    if (!unitsData) return;

    const { isVisiblePagination, rowsPerPageOptions } = checkTablePagination(
      unitsData?.dataItems.total || 0,
      state.pagination.rowsPerPageOptions
    );

    const bodyDataRows = (unitsData?.dataItems?.items || []) as DomainDto[];
    const resultSearchCount = bodyDataRows?.length || 0;

    if (!firstMountState.current.isFirstMount) {
      firstMountState.current.initCount = unitsData?.dataItems.total;
      firstMountState.current.isFirstMount = true;
    }

    setState((state) => {
      return {
        ...state,
        isVisiblePagination,
        bodyDataRows,
        loadingUnits,
        resultSearchCount,
        resultTotalCount: firstMountState.current.initCount,
        pagination: {
          ...state.pagination,
          rowsPerPageOptions
        }
      };
    });
  }, [unitsData, loadingUnits]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
