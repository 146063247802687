import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';

type TooltipOverflowProps = {
  children: ReactNode;
};

export const TooltipOverflowItem: FC<TooltipOverflowProps> = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);

  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, []);

  return (
    <Tooltip
      arrow
      title={children}
      disableHoverListener={!isOverflowed}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#0E1420',
            '& .MuiTooltip-arrow': {
              color: '#0E1420'
            },
            fontSize: '12px',
            padding: '6px'
          }
        }
      }}
    >
      <div
        ref={textElementRef}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          wordBreak: 'break-all'
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
