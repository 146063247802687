import { gql as gqlApollo } from '@apollo/client';

export const SEARCH_TEMPLATES_UNITS = gqlApollo(`
  query SearchUnits($searchCriteria: SearchCriteriaInput) {
    dataItems: searchUnits(searchCriteria: $searchCriteria) {
      items {
        id
        name
        lastModifiedDate
      }
      total
    }
  }
`);

export const CREATE_UNIT = gqlApollo(`
    mutation CreateUnit($name: String!) {
      createUnit(name: $name) {
        id
        name
      }
    }
`);

export const GET_UNIT_BY_ID = gqlApollo(`
    query Unit($id: String!) {
      unit(id: $id) {
        name
      }
    }
  `);

export const UPDATE_UNIT = gqlApollo(`
    mutation UpdateUnit($id: String!, $name: String!) {
      updateUnit(id: $id, name: $name) {
        id
        name
      }
    }
`);

export const SWITCH_DOMAIN_ACTIVE_STATE = gqlApollo(`
    mutation SwitchDomainActiveState($request: SwitchActiveStateRequestInput!) {
      switchDomainActiveState(request: $request)
    }
  `);

export const SWITCH_DOMAIN_ARCHIVE_STATE = gqlApollo(`
    mutation SwitchDomainArchiveState($request:  SwitchArchiveStateRequestInput!) {
      switchDomainArchiveState(request: $request)
    }
  `);
