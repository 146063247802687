import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function Upload(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
        <path d="M5.25642 16.7498C4.83547 16.7498 4.47917 16.604 4.1875 16.3123C3.89583 16.0207 3.75 15.6643 3.75 15.2434V13.6088C3.75 13.4315 3.80983 13.283 3.9295 13.1633C4.04915 13.0436 4.19765 12.9838 4.375 12.9838C4.55235 12.9838 4.70085 13.0436 4.8205 13.1633C4.94015 13.283 4.99998 13.4315 4.99998 13.6088V15.2434C4.99998 15.3075 5.02669 15.3663 5.0801 15.4197C5.13353 15.4731 5.19231 15.4998 5.25642 15.4998H14.7435C14.8077 15.4998 14.8664 15.4731 14.9199 15.4197C14.9733 15.3663 15 15.3075 15 15.2434V13.6088C15 13.4315 15.0598 13.283 15.1795 13.1633C15.2991 13.0436 15.4476 12.9838 15.625 12.9838C15.8023 12.9838 15.9508 13.0436 16.0705 13.1633C16.1901 13.283 16.25 13.4315 16.25 13.6088V15.2434C16.25 15.6643 16.1041 16.0207 15.8125 16.3123C15.5208 16.604 15.1645 16.7498 14.7435 16.7498H5.25642ZM9.375 6.65686L7.77244 8.25942C7.64851 8.38335 7.50134 8.44452 7.33094 8.44292C7.16053 8.44131 7.01069 8.37587 6.88142 8.24661C6.76069 8.11733 6.6982 7.97097 6.69394 7.80753C6.68966 7.64407 6.75215 7.4977 6.88142 7.36842L9.47275 4.77711C9.55074 4.69912 9.633 4.6441 9.71954 4.61205C9.80607 4.57999 9.89955 4.56396 9.99998 4.56396C10.1004 4.56396 10.1939 4.57999 10.2804 4.61205C10.367 4.6441 10.4492 4.69912 10.5272 4.77711L13.1185 7.36842C13.2425 7.49235 13.3036 7.63738 13.302 7.80351C13.3004 7.96963 13.2393 8.11733 13.1185 8.24661C12.9893 8.37587 12.8408 8.44265 12.673 8.44692C12.5053 8.4512 12.3568 8.3887 12.2275 8.25942L10.625 6.65686V13.0319C10.625 13.2092 10.5651 13.3577 10.4455 13.4774C10.3258 13.5971 10.1773 13.6569 9.99998 13.6569C9.82263 13.6569 9.67413 13.5971 9.55448 13.4774C9.43483 13.3577 9.375 13.2092 9.375 13.0319V6.65686Z" />
      </svg>
    </SvgIcon>
  );
}
