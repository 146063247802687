import { ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { CloseIcon, Flex, TableAsideItemsIcon } from '@/separatedModules/components';

import { i18n } from '@/i18n';

import { getIconByDocumentType } from './helpers';

import { StyledTableAside } from './style';

type TableAsideProps = {
  textFirst?: string;
  textSecond?: string;
  rows: any[];
  selectedItems: string[];
  isAsideOpen: boolean;
  children: (props: any) => ReactNode;
  handleToggleAside: () => void;
  sx?: SxProps<Theme>;
  isDocuments?: boolean;
};

export const TableAside = <AsideT extends TableAsideProps>({
  textFirst,
  textSecond,
  rows,
  selectedItems,
  isAsideOpen,
  handleToggleAside,
  children,
  sx,
  isDocuments
}: AsideT) => {
  const isNoItemSelected = selectedItems.length === 0;
  const isOneItemSelected = selectedItems.length === 1;
  const isMoreOneItemSelected = selectedItems.length > 1;

  const singlePreview = isOneItemSelected && rows.find((row: any) => row.original.id === selectedItems[0]);
  const isOneItemExist = isOneItemSelected && singlePreview;

  const renderTextFirst = textFirst || i18n.t('table.aside.selectItem', { ns: 'global' });
  const renderTextSecond =
    textSecond || i18n.t('table.aside.selectedItem', { ns: 'global', value: selectedItems.length });

  const IconComponent = getIconByDocumentType(singlePreview?.original?.type);

  return (
    <StyledTableAside isAsideOpen={isAsideOpen}>
      <Collapse in={isAsideOpen} orientation={'horizontal'} sx={{ height: '100%' }}>
        <Box display={'flex'} alignItems={'center'} sx={{ margin: '0 0 20px' }}>
          {isOneItemExist ? (
            <Flex alignItems="center">
              {isDocuments && IconComponent && (
                <Box
                  sx={{
                    marginRight: '8px'
                  }}
                >
                  {<IconComponent />}
                </Box>
              )}

              <Text className={'m-0'} variant={'titleMd'} sx={sx}>
                {singlePreview.original.title ||
                  singlePreview.original.name ||
                  `${singlePreview.original.firstName} ${singlePreview.original.lastName}`}
              </Text>
            </Flex>
          ) : null}

          <IconButton
            size={'medium'}
            className={'aside-table__btn__close'}
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleToggleAside}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {!isOneItemSelected ? <TableAsideItemsIcon className={'aside-table__icon'} /> : null}

        {isNoItemSelected ? <div className={'aside-table__text'}>{renderTextFirst}</div> : null}

        {isOneItemExist ? children(isOneItemExist.original) : null}

        {isMoreOneItemSelected ? <div className={'aside-table__text'}>{renderTextSecond}</div> : null}
      </Collapse>
    </StyledTableAside>
  );
};
