import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { EquipmentQuery, GET_EQUIPMENT, GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import { ActionsContainer } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Items/components';

import {
  Flex,
  handleGetSavedColumns,
  handleSetSavedColumns,
  ImageLazy,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownColumnsVisibility,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { AsideContent, RowActionMenu, TableFiltersBoxProvider } from './components';

import { columns, getParamItemConfig, initState, skeletonTableLoaderCells, useTableData } from './helpers';

export const TemplatesEquipmentItemParametersPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const TABLE_SAVED_COLUMNS_ID = `templates/equipment/item/${templateId}/parameters`;

  const { renderCount, ...tableState } = useTableData(initState, templateId);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const handleClick = () => {
    templateId && navigateTo(LINKS_PAGES.templatesParametersCreate.replace(':templateId', templateId));
  };

  const columnsVisibility = useMemo(() => {
    const columnsVisibilityStatus = table.getAllLeafColumns().reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.id]: currentValue.getIsVisible() };
    }, {});

    handleSetSavedColumns(TABLE_SAVED_COLUMNS_ID, columnsVisibilityStatus);

    return table.getAllLeafColumns().map((column) => {
      return {
        value: column.id,
        label: i18n.t(`equipmentItem.parameters.table.cells.${column.id}`, { ns: 'templates' }),
        checked: column.getIsVisible(),
        onToggleVisibility: column.toggleVisibility
      };
    });
  }, [columnVisibility]);

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;
  const renderItemLocation = dataEquipmentTemplate?.equipment?.location;

  const breadcrumbsTemplateLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { titleFallback: dataEquipmentTemplate?.equipment?.name }
  ];

  const breadcrumbsItemLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    {
      link: `${LINKS_PAGES.locations}${renderItemLocation ? `/${renderItemLocation.id}` : ''}`,
      titleFallback: renderItemLocation ? renderItemLocation.name : tTemplates('equipment.pageItemAllLocations')
    },
    {
      link: LINKS_PAGES.equipmentModel.replace(':templateId', templateId),
      titleFallback: dataEquipmentTemplate?.equipment?.name
    },
    { titleFallback: tTemplates('equipment.pageItemParameters') }
  ];

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsTemplateLinks : breadcrumbsItemLinks;

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <TablePageTopBox
          title={tTemplates('equipmentItem.parameters.pageTitle')}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          btnText={tTemplates('equipmentItem.parameters.btnCreateParameter')}
          onClickBtn={handleClick}
          withBreadcrumbs
          breadcrumbsLinks={breadcrumbs.map(({ link, titleFallback }) => ({
            link,
            titleFallback: titleFallback || ''
          }))}
          isHideButton={!isShowEditItem || !isUserCanCRUD}
        />

        <TableFiltersBox
          searchPlaceholder={tTemplates('equipmentItem.parameters.table.filters.searchBy.placeholder')}
          selectedItems={tableState.state.selectedItems}
          handleToggleAside={tableState.handleToggleAside}
          handleChangeBySearch={tableState.handleChangeBySearch}
        >
          <TableDropdownColumnsVisibility
            menu={columnsVisibility}
            toggleAllColumnsVisible={table.toggleAllColumnsVisible}
          />

          <TableFiltersBoxProvider tableState={tableState} selectedDependencies={tableState.state.filters.sortByType} />

          <TableSortByOneItem menu={SORT_BY_DATE_CREATE} showIcon={true} onChange={tableState.handleChangeSortByDate} />
        </TableFiltersBox>

        <ActionsContainer
          handleResetSelectedRows={tableState.handleResetSelectedRows}
          state={tableState.state}
          handleToggleAside={tableState.handleToggleAside}
        />

        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {renderCount ? (
              <Flex>
                <Box width={'100%'}>
                  <Box sx={{ overflow: 'auto' }}>
                    <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                      <Table className={'m-0 MuiTable-root__columns-4'}>
                        <TableHead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <TableCell key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>

                        <TableBody>
                          {table.getRowModel().rows.map((row) => {
                            return (
                              <TableRowWrap
                                key={row.original.id}
                                id={row.original.id as string}
                                isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                handleClickRow={tableState.handleClickRow}
                                handleDoubleClickRow={tableState.handleDoubleClickRow}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <TableCell key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                  );
                                })}

                                {isShowEditItem && isUserCanCRUD && (
                                  <RowActionMenu
                                    {...row.original}
                                    isTableVariant
                                    renderModalContent={() => {
                                      const paramItemConfig = getParamItemConfig(row);

                                      return (
                                        <Box
                                          sx={{
                                            borderRadius: 'var(--4, 4px)',
                                            background: 'var(--background-neutral-hovered, #F7F8F9)',
                                            padding: '8px',
                                            margin: '24px 0 0',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            alignItems: 'center'
                                          }}
                                        >
                                          {paramItemConfig?.logoPath ? (
                                            <ImageLazy
                                              src={paramItemConfig?.logoPath}
                                              alt={`Equipment item parameter ${row.original.title} type preview`}
                                              variant={'rectangular'}
                                              width={32}
                                              height={32}
                                              sxStyle={{
                                                margin: '0 8px 0 0'
                                              }}
                                            />
                                          ) : null}

                                          <Text variant={'labelLg'} m={0}>
                                            {row.original.title}
                                          </Text>

                                          <Chip
                                            variant={'filled'}
                                            size={'small'}
                                            label={paramItemConfig?.title || row.original?.type}
                                            color={'secondary'}
                                            sx={{ margin: '0 0 0 8px' }}
                                          />
                                        </Box>
                                      );
                                    }}
                                  />
                                )}
                              </TableRowWrap>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <MuiTablePagination
                    count={renderCount}
                    page={tableState.state.page[0]}
                    rowsPerPage={tableState.state.rowsPerPage[0]}
                    rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                    onChangePage={tableState.handleChangePagination}
                    onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                  />
                </Box>

                <TableAside
                  rows={table.getRowModel().rows}
                  selectedItems={tableState.state.selectedItems}
                  isAsideOpen={tableState.state.isAsideOpen}
                  handleToggleAside={tableState.handleToggleAside}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {(props: any) => (
                    <AsideContent
                      handleChangeTest={() => {
                        navigateTo(
                          isEquipmentTemplate
                            ? `/${TABLE_SAVED_COLUMNS_ID.replace('/parameters', '/parameter')}/${tableState.state.selectedItems[0]}`
                            : LINKS_PAGES.equipmentItemParameter
                                .replace(':templateId', templateId)
                                .replace(':parameterId', tableState.state.selectedItems[0])
                        );
                      }}
                      {...props}
                    />
                  )}
                </TableAside>
              </Flex>
            ) : tableState.state.filters.search[0] ? (
              <NotFoundScreens type={'search'} />
            ) : (
              <NotFoundScreens
                type={'companies'}
                title={tTemplates('equipmentItem.parameters.notFound.title')}
                subTitle={tTemplates('equipmentItem.parameters.notFound.subTitle')}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
