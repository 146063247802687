import { gql as gqlApollo } from '@apollo/client';

export const CREATE_TASK = gqlApollo(`
  mutation CreateTask($request: CreateOrEditTaskReqInput!) {
    createTask(request: $request) {
      id
      name
    }
  }
`);

export const CREATE_UNPLANNED_TASK = gqlApollo(`
  mutation CreateUnplannedRound($createRequest: CreateUnplannedRoundReqInput!) {
    createUnplannedRound(createRequest: $createRequest)
  }
`);

const LOCATION_ITEM_FRAGMENT = gqlApollo(`
  fragment locationFolderRes on LocationFolderRes {
    id
    name
    active
    parentsLocationFolders {
      depth
      id
      name
    }
  }
`);

export const SEARCH_ROUND_TASKS = gqlApollo(`
  query GroupedRounds($searchCriteria: SearchCriteriaInput, $statuses: [RoundStatus], $equipmentIds: [String]) {
    dataItems: groupedRounds(searchCriteria: $searchCriteria, statuses: $statuses, equipmentIds: $equipmentIds) {
      items {
        ids
        name
      }
      total
    }
  }
`);

export const GET_DATE_OF_TASKS = gqlApollo`
  query RoundDateTasks($date: Date) {
    dataItems: roundFrequenciesByDate(date: $date)
  }
`;

export const GET_TASKS = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query RoundTasks(
    $searchCriteria: SearchCriteriaInput, 
    $type: RoundType!, 
    $statuses: [RoundStatus],
    $roundRegulationIds: [String],
    $locationIds: [String],
    $equipmentIds: [String],
    $frequencies: [RoundFrequencyEnum],
    $date: Date,
    $userPermissionTags: [String],
    $serviceStaffIds: [String]
  ) {
    dataItems: rounds(
      searchCriteria: $searchCriteria, 
      type: $type, 
      statuses: $statuses,
      roundRegulationIds: $roundRegulationIds,
      locationIds: $locationIds,
      equipmentIds: $equipmentIds,
      frequencies: $frequencies,
      date: $date,
      userPermissionTags: $userPermissionTags,
      serviceStaffIds: $serviceStaffIds
    ) {
      items {
        name
        id
        active
        archived
        description
        serviceStaff {
          userAvatar {
            url
          }
          firstName
          lastName
        }
        assignee {
          roleTemplate {
            name
          }
        }
        parameters {
          parameterDetails {
            name
            type
          }
        }  
        createdDate
        activationTargetDate
        frequency
        equipment {
          id
          name
          itemDescription
          systemName
          domain {
            id
            name
            color
          }
          location {
            ...locationFolderRes
            groupItems: locationFolders(searchCriteria: $searchCriteria) {
              items {
                ...locationFolderRes
                groupItems: locationFolders(searchCriteria: $searchCriteria) {
                  items {
                    ...locationFolderRes
                    groupItems: locationFolders(searchCriteria: $searchCriteria) {
                      items {
                        ...locationFolderRes
                        groupItems: locationFolders(searchCriteria: $searchCriteria) {
                          items {
                            ...locationFolderRes
                            groupItems: locationFolders(searchCriteria: $searchCriteria) {
                              items {
                                ...locationFolderRes
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } 
        }
        status
      }
      total
    }
  }
`;

export const GET_UNPLANNED_TASKS = gqlApollo`
${LOCATION_ITEM_FRAGMENT}
  query UnplannedRounds($searchCriteria: SearchCriteriaInput, $statuses: [RoundStatus], $permissionTags: [String]) {
    dataItems: unplannedRounds(searchCriteria: $searchCriteria, statuses: $statuses, permissionTags: $permissionTags) {
      items {
        serviceStaff {
          userAvatar {
            url
          }
          firstName
          lastName
        }
        id
        name
        description
        createdDate
        assignee {
          roleTemplate {
            name
          }
        }
        status
        equipment {
          id
          name
          itemDescription
          systemName
          domain {
            id
            name
            color
          }
          location {
            ...locationFolderRes
            groupItems: locationFolders(searchCriteria: $searchCriteria) {
              items {
                ...locationFolderRes
                groupItems: locationFolders(searchCriteria: $searchCriteria) {
                  items {
                    ...locationFolderRes
                    groupItems: locationFolders(searchCriteria: $searchCriteria) {
                      items {
                        ...locationFolderRes
                        groupItems: locationFolders(searchCriteria: $searchCriteria) {
                          items {
                            ...locationFolderRes
                            groupItems: locationFolders(searchCriteria: $searchCriteria) {
                              items {
                                ...locationFolderRes
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } 
        }
      }
      total
    }
  }
`;

export const GET_CUSTOM_TASKS = gqlApollo`
  query CustomTasks($searchCriteria: SearchCriteriaInput, $statuses: [TaskStatus], $performerIds: [String]) {
    tasks(searchCriteria: $searchCriteria, statuses: $statuses, performerIds: $performerIds) {
      items {
        id
        name
        description
        status
        startDate
        endDate
        performer {
          id
          firstName
          lastName
          userAvatar {
            url
          }
        }
      }
      total
    }
  }
`;

export const COMPLETE_TASK = gqlApollo(`
  mutation CompleteTask($taskId: String!, $request: CompleteTaskReqInput!) {
    completeTask(taskId: $taskId, request: $request)
  }
`);

export const UPDATE_TASK_STATUS = gqlApollo(`
  mutation UpdateTaskStatus($taskId: String!, $status: TaskStatus!) {
    updateTaskStatus(taskId: $taskId, status: $status) {
      id
    }
  }
`);

export const GET_CUSTOM_TASK = gqlApollo`
  query Task($taskId: String!) {
    task(taskId: $taskId) {
      id
      name
      description
      status
      startDate
      endDate
      performer {
        id
        firstName
        lastName
        userAvatar {
          url
        }
      }
      addComment
      addPhoto
    }
  }
`;

export const REQUEST_ROUND_ON_HOLD = gqlApollo(`
  mutation RequestRoundOnHold($roundId: String!, $reason: String!) {
    requestRoundOnHold(roundId: $roundId, reason: $reason) {
      reason
      requestId
      requestedAt
      roundId
    }
  }
`);

export const COMPLETE_ROUND = gqlApollo(`
  mutation CompleteRound($roundId: String!, $request: CompleteRoundReqInput!) {
    completeRound(roundId: $roundId, request: $request)
  }
`);

export const UPDATE_ROUND_STATUS = gqlApollo(`
  mutation UpdateRoundStatus($roundId: String!, $status: RoundStatus!) {
    updateRoundStatus(roundId: $roundId, status: $status)
  }
`);

export const ASSIGN_USER_TO_ROUND = gqlApollo(`
  mutation AssignUserToRound($roundId: String!, $userId: String!) {
    assignUserToRound(roundId: $roundId, userId: $userId)
  }
`);
