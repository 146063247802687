import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import Box from '@mui/material/Box';

import { GetUsersQuery } from '@/logicLayers/domain';

import { PageTitle } from '@/separatedModules/components';

import { TranslationT } from '@/subsidiaryBinders/types';

import { AddUser } from '../index';

interface PageTopBoxProps {
  t: TranslationT;
  data?: GetUsersQuery;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetUsersQuery>>;
  isHideButton?: boolean;
}

export const PageTopBox = ({ t: tUsers, data, refetch, isHideButton }: PageTopBoxProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={tUsers('pageTitle')}
        subTitle={tUsers('usersCount', {
          value: data?.dataItems?.total
        })}
        sxBox={{ display: 'flex', alignItems: 'center', margin: '0' }}
        sxTitle={{ mb: 0 }}
        sxSubTitle={{ mb: '-5px', ml: '16px', fontSize: 11, fontWeight: 600 }}
      />
      {!isHideButton && <AddUser refetch={refetch} />}
    </Box>
  );
};
