import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Menu from '@mui/material/Menu';
import Skeleton from '@mui/material/Skeleton';

import { GET_LOCATION_FOLDER_WITH_STATUSES, LocationFolderWithStatusesQuery } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import {
  ArchiveIcon,
  BaseMenuItem,
  CreateNewFolderIcon,
  DisabledByDefaultIcon,
  DriveFileMoveIcon,
  EquipmentIcon,
  RenameIcon,
  StarOutlineIcon
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { locationActionsOptions, LocationActionTypeEnum, locationRootActionsOptions } from './../../helpers';

interface LocationActionsMenuProps {
  anchorEl: null | HTMLElement;
  folderId?: string;
  onModalOpen: (action: LocationActionTypeEnum) => void;
  onClose: () => void;
  isEquipmentDisabled?: boolean;
}

const checkAndSetActionIcon = (action: LocationActionTypeEnum) => {
  switch (action) {
    case LocationActionTypeEnum.CreateEquipment:
      return <EquipmentIcon />;
    case LocationActionTypeEnum.CreateLocation:
      return <CreateNewFolderIcon />;
    case LocationActionTypeEnum.AddToFavorites:
      return <StarOutlineIcon />;
    case LocationActionTypeEnum.RenameLocation:
      return <RenameIcon />;
    case LocationActionTypeEnum.Move:
      return <DriveFileMoveIcon />;
    case LocationActionTypeEnum.Activate:
    case LocationActionTypeEnum.Deactivate:
      return <DisabledByDefaultIcon />;
    case LocationActionTypeEnum.Archive:
      return <ArchiveIcon />;
    default:
      return null;
  }
};

export const LocationActionsMenu: FC<LocationActionsMenuProps> = ({
  anchorEl = null,
  folderId,
  onModalOpen,
  onClose,
  isEquipmentDisabled
}) => {
  const { currentLocationFolderData } = useLocations();
  const navigateTo = useNavigate();

  const { data: dataLocationFolder, loading: loadingLocationFolder } = useQuery<LocationFolderWithStatusesQuery>(
    GET_LOCATION_FOLDER_WITH_STATUSES,
    {
      variables: {
        id: folderId
      },
      skip: !folderId,
      fetchPolicy: 'no-cache'
    }
  );

  const open = Boolean(anchorEl);
  const isArchived = dataLocationFolder?.locationFolder.archived;
  const isActive = dataLocationFolder?.locationFolder.active;
  const isRoot = folderId === 'root';
  const allowCreateInRoot = isRoot || currentLocationFolderData.canCreateFolder;

  const locationOptions = isRoot ? locationRootActionsOptions : locationActionsOptions;

  return (
    <>
      <Menu
        id={'edit-location-menu'}
        elevation={0}
        anchorEl={anchorEl}
        keepMounted
        MenuListProps={{ 'aria-labelledby': 'location-menu-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ margin: '12px 0 0 0' }}
        autoFocus={false}
        open={open}
        onClose={onClose}
      >
        {locationOptions(isActive, isArchived, isEquipmentDisabled).map((option, index, originArr) => {
          const isCreateLocationOption = option.value === LocationActionTypeEnum.CreateLocation;
          const disableCreateLocationOption = isCreateLocationOption && !allowCreateInRoot;
          const isOptionDisabled =
            (isCreateLocationOption && disableCreateLocationOption) || (option.disabled && !isRoot);

          return !loadingLocationFolder ? (
            <BaseMenuItem
              key={index}
              disabled={isOptionDisabled}
              hasDivider={index === 1 || index === originArr.length - 3}
              onClick={
                option.value === LocationActionTypeEnum.CreateEquipment
                  ? () => navigateTo(LINKS_PAGES.equipmentItemCreate.replace(':locationId', folderId || 'root'))
                  : () => onModalOpen(option.value)
              }
            >
              {checkAndSetActionIcon(option.value)}
              {option.label}
            </BaseMenuItem>
          ) : (
            <Skeleton key={index} className={'m-0'} variant={'rectangular'} width={'100%'} height={36} />
          );
        })}
      </Menu>
    </>
  );
};
