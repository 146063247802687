import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { DocumentQuery, DocumentsRes } from '@/logicLayers/domain';
import { GET_DOCUMENT } from '@/logicLayers/domain/documents';

import { EMPTY_CELL_VALUE, Flex, ImageLazy } from '@/separatedModules/components';
import { DocIcon } from '@/separatedModules/components/Icon/svg-mui-theme/DocIcon';
import { PdfIcon } from '@/separatedModules/components/Icon/svg-mui-theme/PdfIcon';
import { XlIcon } from '@/separatedModules/components/Icon/svg-mui-theme/XlIcon';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

export const SORT_BY_DOCUMENT_TYPE = [
  { label: 'Document', value: 'DOCUMENT', checked: false },
  { label: 'Pdf', value: 'PDF', checked: false },
  { label: 'Spreadsheet', value: 'SPREADSHEET', checked: false }
];

export const formatSizeInMb = (size: string): string => {
  const bytes = parseInt(size, 10);
  if (isNaN(bytes)) {
    return 'Invalid size';
  }
  const megabytes = bytes / (1024 * 1024);
  return `${megabytes.toFixed(2)} MB`;
};

export const allowedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export enum DocumentType {
  DOCUMENT = 'DOCUMENT',
  PDF = 'PDF',
  SPREADSHEET = 'SPREADSHEET'
}

export const DocumentIcons: Record<DocumentType, React.FC> = {
  [DocumentType.PDF]: PdfIcon,
  [DocumentType.DOCUMENT]: DocIcon,
  [DocumentType.SPREADSHEET]: XlIcon
};

export const MIME_TYPE_TO_DOCUMENT_TYPE: Record<string, DocumentType> = {
  'application/pdf': DocumentType.PDF,
  'application/msword': DocumentType.DOCUMENT,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocumentType.DOCUMENT,
  'application/vnd.ms-excel': DocumentType.SPREADSHEET,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': DocumentType.SPREADSHEET
};

export const getIconByDocumentType = (type: DocumentType): React.FC | null => {
  return DocumentIcons[type] || null;
};

const columnHelper = createColumnHelper<DocumentsRes>();

export const columns = [
  columnHelper.accessor('title', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      const IconComponent = getIconByDocumentType(row.original.type as unknown as DocumentType);

      return (
        <Flex alignItems="center">
          {IconComponent && <IconComponent />}
          <Text
            className={'m-0'}
            component={'span'}
            variant={'bodyMd'}
            sx={{
              paddingLeft: '8px',
              fontWeight: 600,
              color: '#090E16',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-all'
            }}
          >
            {row.original.title}
          </Text>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'uploadedBy',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {'Uploaded by'}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Box>
          <Box display={'flex'}>
            {row.original.createdBy?.userAvatar?.url ? (
              <ImageLazy
                src={row.original.createdBy?.userAvatar.url}
                alt={`Equipment Created by ${row.original.createdBy?.firstName} ${row.original.createdBy?.lastName} logo`}
                width={24}
                height={24}
                sxStyle={{
                  margin: '0 8px 0 0'
                }}
              />
            ) : null}

            <Text
              variant={'bodyLg'}
              className="m-0"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {row.original.createdBy?.firstName} {row.original.createdBy?.lastName}
            </Text>
          </Box>
        </Box>
      );
    }
  }),
  columnHelper.accessor('size', {
    id: 'size',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {'Size'}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {formatSizeInMb(row.original.size)}
        </Text>
      );
    }
  }),
  columnHelper.accessor('createdDate', {
    id: 'uploadedDate',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {'Upload date'}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.createdDate ? dayjs(row.original.createdDate).format('DD MMM YYYY') : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
  file: File | null;
  isShowDeleteModal: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDomains: [],
    sortByType: [],
    sortByDate: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false,
  file: null,
  isShowDeleteModal: false
};

export const useTableData = (initState: initStateT, templateId: string | undefined) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<DocumentQuery>(GET_DOCUMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      equipmentId: templateId || undefined,
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        }
      },
      types: state.filters.sortByType.length ? state.filters.sortByType : undefined
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleSetFile = useCallback((file: File) => {
    setState((state) => {
      return {
        ...state,
        file
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByType = useCallback((value: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByType: value
        }
      };
    });
  }, []);

  const handleOpenDeleteModal = useCallback(() => {
    setState((state) => ({
      ...state,
      isShowDeleteModal: true
    }));
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setState((state) => ({
      ...state,
      isShowDeleteModal: false
    }));
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleResetSelectedRows,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByType,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0,
    handleSetFile
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
