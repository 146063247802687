import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import {
  EDIT_LOCATION_FOLDER,
  EditLocationFolderMutation,
  GET_LOCATION_FOLDER,
  GET_LOCATIONS_SIDEBAR
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Preloader, PreloaderVariantsE, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_FOLDER_NAME } from '../helpers';

interface FormProps {
  [FIELD_FOLDER_NAME]: string;
}

interface RenameLocationModalProps {
  folderId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const RenameLocationModal: FC<RenameLocationModalProps> = ({ folderId, isModalOpen, onCloseModal }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  // ToDo Remove
  console.log('folderId', folderId);

  const { data: dataLocationFolder, loading: loadingLocationFolder } = useQuery(GET_LOCATION_FOLDER, {
    variables: {
      id: folderId
    },
    skip: !folderId
  });

  const [EditLocationFolder] = useMutation<EditLocationFolderMutation>(EDIT_LOCATION_FOLDER, {
    variables: {
      id: folderId?.length ? folderId : undefined
    },
    refetchQueries: [GET_LOCATIONS_SIDEBAR, 'locationFolders']
  });

  const locationFolder = dataLocationFolder?.locationFolder;

  const {
    setValue,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_FOLDER_NAME]: ''
    }
  });

  useEffect(() => {
    if (locationFolder?.name) {
      setValue(FIELD_FOLDER_NAME, locationFolder?.name);
    }
  }, [locationFolder?.name]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    EditLocationFolder({
      variables: { name: data.name, description: locationFolder.description },
      onCompleted: () => {
        handleCloseModal();

        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.folderUpdated'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog
      headerTitle={tLocations('actions.modal.rename.title')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tLocations('actions.modal.rename.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_FOLDER_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tLocations('actions.modal.rename.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        {loadingLocationFolder ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '150px !important' }} isContainer />
        ) : (
          <>
            <Controller
              name={FIELD_FOLDER_NAME}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: ROLE_NAME_REG_EXP,
                  message: tGlobal('validation.textFieldLength')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextFieldControl
                    label={tLocations('actions.modal.rename.locationInputLabel')}
                    placeholder={tLocations('actions.modal.rename.locationInputPlaceholder')}
                    name={FIELD_FOLDER_NAME}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_FOLDER_NAME]?.message,
                      error: errors[FIELD_FOLDER_NAME]?.message
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </>
        )}
      </form>
    </Dialog>
  );
};
