import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { debounce } from 'lodash';

import { Box, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { GET_ROUND_REGULATIONS_CSV } from '@/logicLayers/domain';

import { ButtonLoading } from '@/separatedModules/components';

import { useContextState, useContextUpdateState } from '../../../Providers';
import { downloadCSV } from '../../utils';
import { AlertMessage } from '../AlertMessage';
import { Steps } from '../Steps';

import { StyledDialog } from './style';

interface ExportCSVModalProps {
  isOpenCSVModal: boolean;
  handleCloseCSVModal: () => void;
}

export const ExportCSVModal = ({ isOpenCSVModal, handleCloseCSVModal }: ExportCSVModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const {
    CSVFilters: { sortByCSVEndDate, sortByCSVStartDate, sortByCyclicality, sortByDomain, sortByLocations },
    currentStep
  } = useContextState();
  const {
    handleSetNextStep,
    handleResetStep,
    sortByCyclicality: sortByCyclicalityAction,
    sortByDomain: sortByDomainAction,
    handleSortByLocations,
    handleSetCSVStartDate,
    handleSetCSVEndDate
  } = useContextUpdateState();

  const isDateInvalid = sortByCSVStartDate && sortByCSVEndDate && sortByCSVStartDate.isAfter(sortByCSVEndDate);
  const isExportDisabled = !sortByCSVStartDate || !sortByCSVEndDate || isDateInvalid;
  const [noData, setNoData] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [getCsv, { data, error }] = useLazyQuery(GET_ROUND_REGULATIONS_CSV);

  const fetchCsv = useCallback(
    (start: Dayjs, end: Dayjs, frequency: string, locationIds: string[], domainId: string) => {
      if (!frequency || !locationIds?.length || !domainId) return;

      setNoData(false);
      setIsFetching(true);
      getCsv({
        variables: {
          fromDate: start.format('YYYY-MM-DD'),
          toDate: end.format('YYYY-MM-DD'),
          frequency,
          locationIds,
          domainId
        },
        onCompleted: () => setIsFetching(false)
      });
    },
    [getCsv]
  );

  const debouncedGetCsv = useRef(debounce(fetchCsv, 500)).current;

  useEffect(() => {
    if (!sortByCSVStartDate || !sortByCSVEndDate || isDateInvalid) return;

    debouncedGetCsv(sortByCSVStartDate, sortByCSVEndDate, sortByCyclicality || '', sortByLocations, sortByDomain || '');

    return () => {
      debouncedGetCsv.cancel();
    };
  }, [sortByCSVStartDate, sortByCSVEndDate, isDateInvalid, sortByCyclicality, sortByLocations, sortByDomain]);

  const handleDownload = () => {
    if (data?.dataItems?.content && data?.dataItems?.fileName) {
      downloadCSV(data.dataItems, handleCloseCSVModal);
      handleResetStep();
      handleClearItems();
    }
  };

  const handleClearItems = () => {
    sortByCyclicalityAction('');
    sortByDomainAction('');
    handleSortByLocations([]);
    handleSetCSVStartDate(null);
    handleSetCSVEndDate(null);
  };

  useEffect(() => {
    if (error) {
      setNoData(true);
    }
  }, [error]);

  const handleClick = () => {
    if (currentStep === 2) {
      return handleDownload();
    }
    handleSetNextStep();
  };

  const handleResetModal = () => {
    if (currentStep === 1) {
      handleCloseCSVModal();
      handleClearItems();
    }

    handleResetStep();
    setIsFetching(false);
  };

  const handleCloseModal = () => {
    handleCloseCSVModal();
    handleResetStep();
    handleClearItems();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDialog
        headerTitle={'Export Rounds Summary'}
        sx={{
          '& .MuiDialogContent-root': {
            paddingTop: '24px !important'
          },
          '& .MuiModal-footer': {
            paddingRight: '0px !important'
          },
          '& .MuiPaper-root': {
            maxWidth: '630px !important',
            width: '100%'
          },
          '& .MuiModal-box': {
            maxWidth: '630px !important',
            width: '100%'
          }
        }}
        hideHeaderBorder
        contentFooter={
          <Box display="flex" justifyContent="end">
            <Box display="flex">
              <Button variant="text" sx={{ margin: '0 24px 0 0 !important' }} onClick={handleResetModal}>
                {currentStep === 1 ? tGlobal('table.modal.move.btnCancel') : 'Previous'}
              </Button>
              <ButtonLoading
                variant="contained"
                sx={{ margin: '0 24px 0 0 !important' }}
                disabled={
                  (currentStep === 2 && (isExportDisabled || noData)) ||
                  (currentStep === 1 && (!sortByCyclicality || !sortByDomain || !sortByLocations.length))
                }
                loading={isFetching && currentStep === 2}
                onClick={handleClick}
              >
                {currentStep >= 2 ? 'Export CSV' : 'Next'}
              </ButtonLoading>
            </Box>
          </Box>
        }
        open={isOpenCSVModal}
        onClose={handleCloseModal}
      >
        <Steps step={String(currentStep)} />
        {currentStep === 2 && <AlertMessage noData={noData} isDateInvalid={Boolean(isDateInvalid)} />}
      </StyledDialog>
    </LocalizationProvider>
  );
};
