export const Project = {
  root: {
    '&.MuiTableCell-image': {
      img: {
        height: '24px',
        width: '24px',
        objectFit: 'cover',
        borderRadius: '50%',
        margin: '0px 8px 0px 0px'
      },
      '&__companies': {
        img: {
          height: '24px',
          width: '24px'
        }
      }
    }
  },
  head: {
    padding: '12px 8px !important',
    border: 'none !important',
    display: 'inline-flex',
    alignItems: 'center',
    '& > *': {
      margin: '0'
    },

    '&.checkbox': {
      width: '50px',
      minWidth: '50px',
      flex: '0 0 50px !important'
    }
  },
  body: {
    padding: '8px',
    minHeight: '52px',
    display: 'inline-flex',
    alignItems: 'center',
    letterSpacing: 'unset',
    border: 'none',

    '&.checkbox': {
      width: '50px',
      minWidth: '50px',
      flex: '0 0 50px !important'
    }
  }
};

export const Base = {};
