import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { TextField } from 'infinitytechnologies-ui';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InputAdornment } from '@mui/material';

import { LocationIcon } from '@/separatedModules/components/Icon/svg-mui-theme/LocationIcon';

import { LocationOption } from '../LocationOption';

import { HistoryEntry, LocationItem } from '../../helpers';
import { findItemRecursively, findParent, resetItemsRecursively } from './helpers';

import { StyledAutocomplete } from './style';

interface LocationsAutocompleteProps {
  selectedItems: LocationItem[];
  onToggleLocation: (id: string) => void;
  sortByLocations: string[];
  selectedPath: string;
  loading: boolean;
  handleSortByLocations: (locationId: string[]) => void;
  setSelectedItems: (value: SetStateAction<LocationItem[]>) => void;
}

export const LocationsAutocomplete = ({
  selectedItems,
  onToggleLocation,
  selectedPath,
  loading,
  handleSortByLocations,
  setSelectedItems
}: LocationsAutocompleteProps) => {
  const [open, setOpen] = useState(false);
  const [currentOptions, setCurrentOptions] = useState<LocationItem[]>([]);
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [path, setPath] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (path.length === 0) {
      setCurrentOptions(selectedItems);
    } else {
      let newOptions = selectedItems;
      for (const segment of path) {
        const found = findItemRecursively(newOptions, segment);
        if (found) {
          newOptions = found.children;
        } else {
          newOptions = [];
          break;
        }
      }
      setCurrentOptions(newOptions);
    }
  }, [selectedItems, path]);

  const handleGoDeeper = (option: LocationItem) => {
    setHistory((prev) => [...prev, { items: currentOptions, path: [...path] }]);
    setPath((prev) => [...prev, option.label]);
    setCurrentOptions(option.children);
    setSearch('');
    setOpen(true);
  };

  const handleGoBack = () => {
    setHistory((prev) => {
      const newHistory = [...prev];
      const last = newHistory.pop();
      if (last) {
        setCurrentOptions(last.items);
        setPath(last.path);
      }
      return newHistory;
    });
    setOpen(true);
  };

  const displayedOptions = useMemo(() => {
    if (path.length === 0) return currentOptions;
    const pathLabel = path.join(' / ');
    const fakeItem: LocationItem = {
      value: '__folder__',
      label: pathLabel,
      children: [],
      checked: false,
      indeterminate: false
    };
    return [fakeItem, ...currentOptions];
  }, [path, currentOptions, selectedPath]);

  const flattenLocations = (locations: LocationItem[]): LocationItem[] => {
    const result: LocationItem[] = [];
    const traverse = (items: LocationItem[]) => {
      for (const item of items) {
        result.push(item);
        if (item.children.length > 0) {
          traverse(item.children);
        }
      }
    };
    traverse(locations);
    return result;
  };

  const flatDisplayedOptions = useMemo(() => flattenLocations(displayedOptions), [displayedOptions]);

  const correctData = search.length
    ? flatDisplayedOptions.filter((value) => {
        const searchWords = search.toLowerCase().trim().split(/\s+/);
        const label = value.label.toLowerCase();
        return searchWords.every((word) => label.includes(word));
      })
    : displayedOptions;

  const handleOnToggle = (id: string) => {
    if (search.length) {
      const parentChain: string[] = [];
      let parent = findParent(selectedItems, id);
      while (parent) {
        parentChain.unshift(parent.label);
        parent = findParent(selectedItems, parent.value);
      }
      setHistory((prev) => [...prev, { items: currentOptions, path: [...path] }]);
      setPath(parentChain);

      let newOptions = selectedItems;
      for (const segment of parentChain) {
        const found = findItemRecursively(newOptions, segment);
        newOptions = found ? found.children : [];
      }
      setCurrentOptions(newOptions);
    }
    setSearch('');

    onToggleLocation(id);
  };

  return (
    <StyledAutocomplete
      value={selectedPath as any}
      sx={{
        width: '100%',
        marginTop: '8px',
        '.MuiInputLabel-root': {
          '&.Mui-focused': {
            color: 'black !important'
          }
        },
        '.MuiOutlinedInput-root': {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black !important'
          },
          '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'black !important'
          }
        }
      }}
      options={correctData}
      open={open}
      filterOptions={() => correctData}
      onOpen={() => setOpen(true)}
      onInputChange={(event: any) => {
        if (event) {
          setSearch(event.target.value);
        }
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select location"
          placeholder="Select location"
          sx={{
            width: '100%',
            '.MuiOutlinedInput-root .myLocationIcon': {
              fill: '#5C6C83'
            },
            '.MuiOutlinedInput-root.Mui-focused .myLocationIcon': {
              fill: '#1976d2'
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: '#6A7A94',
              opacity: 1
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <LocationIcon
                  className="myLocationIcon"
                  sx={{
                    width: '18px'
                  }}
                />
              </InputAdornment>
            )
          }}
        />
      )}
      disableCloseOnSelect
      onChange={(_, value) => {
        if (!value || value.length === 0) {
          setSearch('');
          handleSortByLocations([]);
          setSelectedItems((prevItems) => resetItemsRecursively(prevItems));
        }
      }}
      onClose={() => setOpen(false)}
      loading={loading}
      renderOption={(props, option: any) => {
        return (
          <LocationOption
            option={option}
            history={history}
            onToggleLocation={handleOnToggle}
            onGoDeeper={handleGoDeeper}
            onGoBack={handleGoBack}
          />
        );
      }}
    />
  );
};
