import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ROLE_TEMPLATES, GetRoleTemplatesQuery, RoleTemplateRes } from '@/logicLayers/domain';

import { checkTablePagination } from '@/separatedModules/components';

import { ContextStateI, initState, initStateI, TableProviderProps } from './helpers';

export const ContextState = createContext<ContextStateI>({
  ...initState,
  loadingRoleTemplates: false,
  setState: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ProviderState = ({ children }: TableProviderProps) => {
  const [state, setState] = useState<initStateI>(initState);

  const { data: dataRoleTemplates, loading: loadingRoleTemplates } = useQuery<GetRoleTemplatesQuery>(
    GET_ROLE_TEMPLATES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        searchCriteria: {
          pageable: {
            page: state.filters.search.length ? 0 : state.pagination.page[0],
            pageSize: state.pagination.rowsPerPage[0]
          },
          sortable: {
            column: state.filters.sortByDate.length ? 'lastModifiedDate' : undefined,
            direction: state.filters.sortByDate.length ? state.filters.sortByDate[0].value : undefined,
            nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
          },
          query: state.filters.search.length ? state.filters.search[0] : undefined
        }
      }
    }
  );

  const contextValue = useMemo(() => {
    return {
      ...state,
      loadingRoleTemplates,
      setState
    };
  }, [state]);

  useEffect(() => {
    if (!dataRoleTemplates) return;

    const { isVisiblePagination, rowsPerPageOptions } = checkTablePagination(
      dataRoleTemplates?.dataItems.total || 0,
      state.pagination.rowsPerPageOptions
    );

    const bodyDataRows = (dataRoleTemplates?.dataItems.items || []) as RoleTemplateRes[];
    const resultSearchCount = bodyDataRows?.length || 0;
    const resultTotalCount = dataRoleTemplates?.dataItems.total;

    setState((state) => {
      return {
        ...state,
        isVisiblePagination,
        bodyDataRows,
        loadingRoleTemplates,
        resultSearchCount,
        resultTotalCount,
        pagination: {
          ...state.pagination,
          rowsPerPageOptions
        }
      };
    });
  }, [dataRoleTemplates, loadingRoleTemplates]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
