import { useEffect, useState } from 'react';

import Collapse from '@mui/material/Collapse';

import {
  // ArchiveIcon,
  // DriveFileMoveIcon,
  // EditIcon,
  // StarOutlineIcon,
  TableManagement
} from '@/separatedModules/components';

import { initStateT } from '../../helpers';

type ActionContainerProps = {
  state: initStateT;
  handleResetSelectedRows: () => void;
  handleToggleAside: () => void;
};

export const ActionsContainer = ({ handleResetSelectedRows, handleToggleAside, state }: ActionContainerProps) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (state.selectedItems.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [state.selectedItems]);

  return (
    <Collapse in={checked}>
      <TableManagement
        count={state.selectedItems.length}
        actionNodeList={
          [
            // { node: <StarOutlineIcon key={1} />, disabled: true },
            // { node: <EditIcon key={1} />, disabled: true },
            // { node: <DriveFileMoveIcon key={1} />, disabled: true },
            // { node: <ArchiveIcon key={1} />, disabled: true }
          ]
        }
        open={checked}
        onClose={handleResetSelectedRows}
        onClickInfo={handleToggleAside}
      />
    </Collapse>
  );
};
