import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Autocomplete } from 'infinitytechnologies-ui';

import { SEARCH_DOMAINS, SearchDomainsQuery } from '@/logicLayers/domain';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/RoundRegulations/components/Providers';

export const SortByDomain = () => {
  const { sortByDomain } = useContextUpdateState();
  const {
    CSVFilters: { sortByDomain: sortByDomainAction }
  } = useContextState();

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const domainsOptions = useMemo(() => {
    if (!domainsData) return [];
    return domainsData.dataItems.items.map((domain) => ({
      label: domain?.name,
      value: domain?.id
    }));
  }, [domainsData]);

  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (domainsOptions.length > 0 && sortByDomainAction) {
      const selectedDomain = domainsOptions.find((item) => item.value === sortByDomainAction);
      setValue(selectedDomain || null);
    }
  }, [domainsOptions, sortByDomainAction]);

  return (
    <Autocomplete
      name="Select domains"
      label="Select domains"
      value={value}
      options={domainsOptions as any}
      getOptionLabel={(option: any) => option.label}
      getOptionKey={(option: any) => option.value}
      onChange={(e, newValue: any) => {
        setValue(newValue);
        if (newValue) {
          sortByDomain(newValue.value);
        } else {
          sortByDomain('');
        }
      }}
    />
  );
};
