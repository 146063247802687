import { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';

import { Flex, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from '../../../ContextProvider';

import { TaskItemPlate } from './components/TaskItemPlate';

import { TASK_TYPES_LIST } from './helpers';

export const Step1 = () => {
  const { t: tTasks } = useTranslation('tasks');

  const { handleSetTaskType } = useContextState();

  const [selectedId, setSelectedId] = useState(0);

  const selectTaskItemHandler = (id: number, taskType: string) => {
    setSelectedId(id);
    handleSetTaskType(taskType);
  };

  useEffect(() => {
    handleSetTaskType(TASK_TYPES_LIST[0].taskType);
  }, []);

  return (
    <Stack minHeight={'55vh'}>
      <TextBlockControl
        title={tTasks('createTask.step1.title')}
        subTitle={tTasks('createTask.step1.description')}
        sxBox={{ marginTop: '24px' }}
        sxSubTitle={{ marginBottom: 0, maxWidth: 660 }}
      />

      <Flex flexWrap={'wrap'} gap={'24px'}>
        {TASK_TYPES_LIST.map((taskItem: any) => (
          <TaskItemPlate
            key={taskItem.id}
            taskItem={taskItem}
            selectedId={selectedId}
            onClick={() => selectTaskItemHandler(taskItem.id, taskItem.taskType)}
          />
        ))}
      </Flex>
    </Stack>
  );
};
