import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import {
  Color,
  GET_EQUIPMENT,
  GET_USER_PROFILE,
  GetProfileQuery,
  SWITCH_EQUIPMENTS_ACTIVE_STATE,
  SWITCH_EQUIPMENTS_ARCHIVE_STATE,
  SwitchEquipmentsActiveStateMutation,
  UserRes
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AlertCheckNoticeIcon,
  ArchiveIcon,
  Breadcrumbs,
  ChevronRightIcon,
  DomainStatusChip,
  DoNotDisturbOnIcon,
  EMPTY_CELL_VALUE,
  Flex,
  Preloader,
  PreloaderVariantsE,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';
import { TranslationT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

import { useQueryData } from '../../helpers';

interface ModalState {
  isModalOpen: boolean;
  modalType: string;
}

const getModalData = (
  data:
    | {
        __typename?: 'EquipmentRes';
        active: boolean;
        archived: boolean;
        createdBy: UserRes;
        createdDate: any;
        itemDescription?: string | null;
        templateDescription?: string | null;
        id?: string | null;
        lastModifiedDate?: any | null;
        manufacturer?: string | null;
        modelNumber?: string | null;
        name: string;
        specifications?: string | null;
        domainId: string;
        fileUrl?: { __typename?: 'UrlInfo'; url: string; fileKey: string } | null;
        domain: { __typename?: 'DomainDto'; color: Color; name: string; itemsCount: any; id?: string | null };
      }
    | undefined,
  state: ModalState,
  tTemplates: TranslationT,
  handleChangeItemActiveState: () => void,
  handleChangeItemArchiveState: () => void,
  isEquipmentItem: boolean
) => {
  const isItemActive = data?.active;

  const checkDisable = (text: string) => {
    return isItemActive && !data?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkEnable = (text: string) => {
    return !isItemActive && !data?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkArchive = (text: string) => {
    return !isItemActive && !data?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const checkUnArchive = (text: string) => {
    return !isItemActive && data?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const modalTitleForDisable = checkDisable(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'titleToDisable' : 'titleToDisableItem'}`)
  );
  const modalTitleForEnable = checkEnable(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'titleToEnable' : 'titleToEnableItem'}`)
  );
  const modalTitleForArchive = checkArchive(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'titleToArchive' : 'titleToArchiveItem'}`)
  );
  const modalTitleForUnArchive = checkUnArchive(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'titleToUnArchive' : 'titleToUnArchiveItem'}`)
  );

  const modalContentForDisable = checkDisable(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'contentToDisable' : 'contentToDisableItem'}`, {
      value: data?.name
    })
  );
  const modalContentForEnable = checkEnable(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'contentToEnable' : 'contentToEnableItem'}`, {
      value: data?.name
    })
  );
  const modalContentForArchive = checkArchive(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'contentToArchive' : 'contentToArchiveItem'}`, {
      value: data?.name
    })
  );
  const modalContentForUnArchive = checkUnArchive(
    tTemplates(`equipment.modal.${!isEquipmentItem ? 'contentToUnArchive' : 'contentToUnArchiveItem'}`, {
      value: data?.name
    })
  );

  const modalBtnTextForDisable = checkDisable(tTemplates('equipment.modal.btnDisable'));
  const modalBtnTextForEnable = checkEnable(tTemplates('equipment.modal.btnEnable'));
  const modalBtnTextForArchive = checkArchive(tTemplates('equipment.modal.btnArchive'));
  const modalBtnTextForUnArchive = checkUnArchive(tTemplates('equipment.modal.btnUnArchive'));

  const modalTitle = modalTitleForDisable || modalTitleForEnable || modalTitleForArchive || modalTitleForUnArchive;
  const modalContent =
    modalContentForDisable || modalContentForEnable || modalContentForArchive || modalContentForUnArchive;
  const modalBtnText =
    modalBtnTextForDisable || modalBtnTextForEnable || modalBtnTextForArchive || modalBtnTextForUnArchive;

  const handleModalAction =
    modalTitleForDisable || modalTitleForEnable ? handleChangeItemActiveState : handleChangeItemArchiveState;

  return {
    modalTitle,
    modalContent,
    modalBtnText,
    handleModalAction
  };
};

export const TemplatesEquipmentItemDetailsPage = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();
  const location = useLocation();
  const navigateTo = useNavigate();

  const [state, setState] = useState<ModalState>({
    isModalOpen: false,
    modalType: ''
  });

  const { dataEquipmentTemplate, loadingEquipmentTemplate } = useQueryData({ templateId });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;

  const data = dataEquipmentTemplate?.equipment;
  const renderItemName = dataEquipmentTemplate?.equipment?.name;
  const renderUserAvatar = dataEquipmentTemplate?.equipment?.createdBy?.userAvatar?.url;
  const renderUserFirstName = dataEquipmentTemplate?.equipment?.createdBy?.firstName;
  const renderUserLastName = dataEquipmentTemplate?.equipment?.createdBy?.lastName;
  const renderItemCreatedDate = dataEquipmentTemplate?.equipment?.createdDate;
  const renderItemLastModifiedDate = dataEquipmentTemplate?.equipment?.lastModifiedDate;
  const renderItemActive = Boolean(dataEquipmentTemplate?.equipment?.active);
  const renderItemLocation = dataEquipmentTemplate?.equipment?.location;
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const isEquipmentTemplate = location.pathname.includes('item');

  const [SwitchEquipmentsActiveState] = useMutation<SwitchEquipmentsActiveStateMutation>(
    SWITCH_EQUIPMENTS_ACTIVE_STATE,
    {
      refetchQueries: [GET_EQUIPMENT]
    }
  );

  const [SwitchEquipmentsArchiveState] = useMutation<SwitchEquipmentsActiveStateMutation>(
    SWITCH_EQUIPMENTS_ARCHIVE_STATE,
    {
      refetchQueries: [GET_EQUIPMENT]
    }
  );

  const handleChangeItemActiveState = () => {
    if (data) {
      const isActive = !data?.active;

      SwitchEquipmentsActiveState({
        variables: {
          request: {
            ids: [data?.id],
            active: isActive
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isActive
          ? tGlobal('alertMessages.success.templates.equipment.edit.isEnabled', { value: data?.name })
          : tGlobal('alertMessages.success.templates.equipment.edit.isDisabled', { value: data?.name }),
        severity: 'success',
        autoHideDuration: 1500
      });
    }
  };

  const handleChangeItemArchiveState = () => {
    if (data && data?.active === false) {
      const isArchive = !data?.archived;

      SwitchEquipmentsArchiveState({
        variables: {
          request: {
            ids: [data?.id],
            archived: isArchive
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isArchive
          ? tGlobal('alertMessages.success.templates.equipment.edit.isArchived', { value: data?.name })
          : tGlobal('alertMessages.success.templates.equipment.edit.isUnArchived', { value: data?.name }),
        severity: 'success',
        autoHideDuration: 1500
      });

      setTimeout(() => {
        navigateTo(!isEquipmentTemplate ? LINKS_PAGES.locations : LINKS_PAGES.templatesEquipment);
      }, 1500);
    }
  };

  const { modalTitle, modalContent, modalBtnText, handleModalAction } = getModalData(
    data,
    state,
    tTemplates,
    handleChangeItemActiveState,
    handleChangeItemArchiveState,
    !isEquipmentTemplate
  );

  const handleOpenModal = (modalType: string) => {
    setState({ isModalOpen: true, modalType });
  };

  const handleCloseModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
  };

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { titleFallback: renderItemName }
  ];

  const breadcrumbsItemLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    {
      link: `${LINKS_PAGES.locations}${renderItemLocation ? `/${renderItemLocation.id}` : ''}`,
      titleFallback: renderItemLocation ? renderItemLocation.name : tTemplates('equipment.pageItemAllLocations')
    },
    { titleFallback: renderItemName }
  ];

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsLinks : breadcrumbsItemLinks;

  console.log(isEquipmentTemplate);

  return loadingEquipmentTemplate ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} lg={10} xl={8} item>
        <Breadcrumbs
          links={breadcrumbs}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />
        <Text component={'h2'} variant={'titleXl'}>
          {isEquipmentTemplate ? 'Template details' : 'Item details'}
        </Text>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: 'var(--4, 4px)',
            border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
            padding: '32px 24px',
            marginBottom: '32px'
          }}
        >
          <Text className={'m-0'} variant={'titleMd'}>
            {tTemplates('equipmentItem.templateDetails.mainInfo')}
          </Text>
          <Box sx={{ marginTop: '24px' }}>
            <Text variant={'labelMd'} color={'var(--text-subtle, #505668)'}>
              {tTemplates('equipmentItem.templateDetails.createdBy')}
            </Text>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderUserAvatar ? (
                <img
                  src={renderUserAvatar}
                  alt=""
                  width={24}
                  height={24}
                  style={{
                    borderRadius: '50%',
                    border: '1px solid #F7F8F9',
                    position: 'relative',
                    margin: '0 8px 0 0'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    margin: '0 8px 0 0',
                    background: 'rgba(9, 14, 22, 0.06)',
                    border: '1px solid var(--border-inverse, #F7F8F9)'
                  }}
                />
              )}
              <Text>
                {!renderUserFirstName || !renderUserLastName
                  ? EMPTY_CELL_VALUE
                  : `${renderUserFirstName} ${renderUserLastName}`}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: 'var(--4, 4px)',
            border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
            padding: '32px 24px',
            marginBottom: '32px'
          }}
        >
          <Text className={'m-0'} variant={'titleMd'}>
            {tTemplates('equipmentItem.templateDetails.history')}
          </Text>
          <Box sx={{ margin: '24px 0px' }}>
            <Text variant={'labelMd'} color={'var(--text-subtle, #505668)'}>
              {tTemplates('equipmentItem.templateDetails.created')}
            </Text>
            <Text className={'m-0'} variant={'bodyLg'}>
              {dayjs(renderItemCreatedDate).format('DD MMM YYYY, HH:mm A')}
            </Text>
          </Box>
          <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))', margin: '8px 0', width: '100%' }} />
          <Box sx={{ marginTop: '24px' }}>
            <Text variant={'labelMd'} color={'var(--text-subtle, #505668)'}>
              {tTemplates('equipmentItem.templateDetails.lastModified')}
            </Text>
            <Text className={'m-0'} variant={'bodyLg'}>
              {dayjs(renderItemLastModifiedDate).isValid()
                ? dayjs(renderItemLastModifiedDate).format('DD MMM YYYY, HH:mm A')
                : EMPTY_CELL_VALUE}
            </Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: 'var(--4, 4px)',
            border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
            padding: '32px 24px',
            marginBottom: '32px'
          }}
        >
          <Text className={'m-0'} variant={'titleMd'}>
            {tTemplates('equipmentItem.templateDetails.systemAvailibility')}
          </Text>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', marginTop: '24px' }}>
            <Box sx={{ marginRight: '32px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Text variant={'bodyMd'} m={0} sx={{ color: 'var(--text-subtle, #505668)' }}>
                  {tTemplates('equipmentItem.templateDetails.systemAvailibilityStatusDescription')}
                </Text>
                <Box sx={{ marginLeft: '8px' }}>
                  <SystemStatusChip
                    status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled}
                  />
                </Box>
              </Box>
              <Text variant={'bodyMd'} m={0} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {!renderItemActive
                  ? tTemplates('equipmentItem.templateDetails.systemAvailibilityStatusDescriptionEnabling')
                  : tTemplates('equipmentItem.templateDetails.systemAvailibilityStatusDescriptionDisabling')}
              </Text>
            </Box>
            {isShowEditItem && isUserCanCRUD && (
              <Button
                size={'medium'}
                onClick={() => handleOpenModal('toggleActive')}
                startIcon={
                  !renderItemActive ? (
                    <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 16 }} />
                  ) : (
                    <DoNotDisturbOnIcon sx={{ fill: 'var(--text-danger, #BE0E2C)' }} />
                  )
                }
              >
                {renderItemActive
                  ? tTemplates('equipmentItem.templateDetails.btnDisable')
                  : tTemplates('equipmentItem.templateDetails.btnEnable')}
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: 'var(--4, 4px)',
            border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
            padding: '32px 24px'
          }}
        >
          <Text className={'m-0'} variant={'titleMd'}>
            {tTemplates('equipmentItem.templateDetails.archive')}
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              marginTop: '24px',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Text variant={'bodyMd'} m={0} sx={{ color: 'var(--text-subtle, #505668)' }}>
              {tTemplates('equipmentItem.templateDetails.archiveDescription')}
            </Text>
            {isShowEditItem && isUserCanCRUD && (
              <Button
                size={'medium'}
                onClick={() => handleOpenModal('toggleArchive')}
                disabled={renderItemActive}
                startIcon={<ArchiveIcon fill={renderItemActive ? '#090E164D' : '#313849'} />}
              >
                {tTemplates('equipmentItem.templateDetails.btnArchive')}
              </Button>
            )}
          </Box>
        </Box>
        <Dialog
          headerTitle={modalTitle}
          hideHeaderBorder
          contentFooter={
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className={'my-0'}
                variant={'text'}
                sx={{ margin: '0 24px 0 0 !important' }}
                onClick={handleCloseModal}
              >
                {tGlobal('modal.btnCancel')}
              </Button>

              <Button className={'my-0'} variant={'contained'} onClick={handleModalAction}>
                {modalBtnText}
              </Button>
            </div>
          }
          open={state.isModalOpen}
          onClose={handleCloseModal}
        >
          <Text
            className={'m-0'}
            variant={'bodyLg'}
            sx={{
              b: {
                wordBreak: 'break-word'
              }
            }}
            dangerouslySetInnerHTML={{
              __html: modalContent as string
            }}
          />
          <Box sx={{ width: '100%', flex: '0 1 100%' }}>
            <Flex
              sx={{
                borderRadius: 'var(--4, 4px)',
                background: 'var(--background-neutral-hovered, #F7F8F9)',
                padding: '8px',
                margin: '24px 0 0',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Flex alignItems="center">
                <Text
                  variant={'labelLg'}
                  m={0}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all',
                    maxWidth: '340px'
                  }}
                >
                  {data?.name}
                </Text>
              </Flex>

              {data?.domain ? (
                <DomainStatusChip color={data?.domain?.color} label={data?.domain.name} />
              ) : (
                EMPTY_CELL_VALUE
              )}
            </Flex>
          </Box>
        </Dialog>
      </Grid>
    </Grid>
  );
};
