import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import {
  CREATE_EQUIPMENT,
  CreateEquipmentMutation,
  EquipmentType,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteControl,
  AutocompleteOptionT,
  ChipIcon,
  ModalDiscardAllChanges,
  TextFieldControl
} from '@/separatedModules/components';

import { TEXT_FIELD_REG_EXP } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { MultiStepFooter } from './components';

import {
  DEFAULT_VALUES,
  FIELD_EQUIPMENT_DOMAIN_ID,
  FIELD_EQUIPMENT_ITEM_DESCRIPTION,
  FIELD_EQUIPMENT_MANUFACTURER,
  FIELD_EQUIPMENT_MODEL_NUMBER,
  FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION,
  FIELD_EQUIPMENT_TITLE,
  FORM_ID,
  FormProps
} from './helpers';

export const EquipmentItemTemplateCreatePage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });
  const [CreateEquipmentTemplate] = useMutation<CreateEquipmentMutation>(CREATE_EQUIPMENT);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const handleSubmitForm = handleSubmit((data) => {
    CreateEquipmentTemplate({
      variables: {
        equipment: {
          name: data.name || undefined,
          domainId: data.domainId[0]?.value || undefined,
          itemDescription: data.itemDescription || undefined,
          templateDescription: data.templateDescription || undefined,
          manufacturer: data.manufacturer || undefined,
          specifications: data.specifications || undefined,
          modelNumber: data.modelNumber || undefined,
          fileKey: data.fileKey || undefined,
          type: EquipmentType.EquipmentTemplate
        }
      },
      onCompleted: ({ createEquipment: createEquipmentTemplate }) => {
        if (createEquipmentTemplate.id) {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.equipmentTemplateCreated'),
            severity: 'success'
          });

          setTimeout(() => {
            navigateTo(-1);
          }, 1000);
        }
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string
    }));
  }, [domainsData]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
          <>
            <ModalDiscardAllChanges navigateToUrl={LINKS_PAGES.templatesEquipment} sxBox={{ margin: '0 0 32px' }} />

            <Box>
              <Controller
                name={FIELD_EQUIPMENT_TITLE}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  },
                  pattern: {
                    value: TEXT_FIELD_REG_EXP,
                    message: tGlobal('validation.textFieldLength')
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      name={FIELD_EQUIPMENT_TITLE}
                      title={tTemplates('createEquipmentItem.nameTitle')}
                      subTitle={tTemplates('createEquipmentItem.nameSubTitle')}
                      label={tTemplates('createEquipmentItem.nameLabel')}
                      placeholder={tTemplates('createEquipmentItem.namePlaceholder')}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_EQUIPMENT_TITLE]?.message,
                        error: errors[FIELD_EQUIPMENT_TITLE]?.message
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_EQUIPMENT_DOMAIN_ID}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  },
                  validate: (value) => {
                    const isMoreOneItem = value.length > 1;

                    return isMoreOneItem ? tGlobal('validation.arrayFieldMaxLength', { value: 1 }) : true;
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <AutocompleteControl
                      name={FIELD_EQUIPMENT_DOMAIN_ID}
                      title={tTemplates('createEquipmentItem.domainTitle')}
                      subTitle={tTemplates('createEquipmentItem.domainSubTitle')}
                      label={tTemplates('createEquipmentItem.domainLabel')}
                      placeholder={tTemplates('createEquipmentItem.domainPlaceholder')}
                      options={domainsOptions}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_EQUIPMENT_DOMAIN_ID]?.message,
                        error: errors[FIELD_EQUIPMENT_DOMAIN_ID]?.message
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (tagValue as AutocompleteOptionT[]).map((option, index) => {
                          const { ...tagProps } = getTagProps({ index });

                          return <Chip {...tagProps} key={option.key} label={option.label} deleteIcon={<ChipIcon />} />;
                        });
                      }}
                      multiple
                      disabled={Boolean(!domainsOptions.length)}
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_EQUIPMENT_ITEM_DESCRIPTION}
                control={control}
                rules={{
                  pattern: {
                    value: /^[\w\s`'.\-,#№()_]{3,200}$/,
                    message: tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      name={FIELD_EQUIPMENT_ITEM_DESCRIPTION}
                      title={tTemplates('createEquipmentItem.itemDescriptionTitle')}
                      subTitle={tTemplates('createEquipmentItem.itemDescriptionSubTitle')}
                      label={tTemplates('createEquipmentItem.itemDescriptionLabel')}
                      placeholder={tTemplates('createEquipmentItem.itemDescriptionPlaceholder')}
                      rows={3}
                      maxContentLength={200}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_EQUIPMENT_ITEM_DESCRIPTION]?.message,
                        error: errors[FIELD_EQUIPMENT_ITEM_DESCRIPTION]?.message
                      }}
                      multiline
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_EQUIPMENT_MANUFACTURER}
                control={control}
                rules={{
                  pattern: {
                    value: TEXT_FIELD_REG_EXP,
                    message: tGlobal('validation.textFieldLength')
                  },
                  minLength: {
                    value: 3,
                    message: tGlobal('validation.textFieldLength')
                  },
                  maxLength: {
                    value: 64,
                    message: tGlobal('validation.textFieldLength')
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      name={FIELD_EQUIPMENT_MANUFACTURER}
                      title={tTemplates('createEquipmentItem.manufacturerTitle')}
                      subTitle={tTemplates('createEquipmentItem.manufacturerSubTitle')}
                      label={tTemplates('createEquipmentItem.manufacturerLabel')}
                      placeholder={tTemplates('createEquipmentItem.manufacturerPlaceholder')}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_EQUIPMENT_MANUFACTURER]?.message,
                        error: errors[FIELD_EQUIPMENT_MANUFACTURER]?.message
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION}
                control={control}
                rules={{
                  pattern: {
                    value: /^[\w\s`'.\-,#№()_]{3,200}$/,
                    message: tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      name={FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION}
                      title={tTemplates('createEquipmentItem.templateDescriptionTitle')}
                      subTitle={tTemplates('createEquipmentItem.templateDescriptionSubTitle')}
                      label={tTemplates('createEquipmentItem.templateDescriptionLabel')}
                      placeholder={tTemplates('createEquipmentItem.templateDescriptionPlaceholder')}
                      rows={3}
                      maxContentLength={200}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION]?.message,
                        error: errors[FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION]?.message
                      }}
                      multiline
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_EQUIPMENT_MODEL_NUMBER}
                control={control}
                rules={{
                  pattern: {
                    value: TEXT_FIELD_REG_EXP,
                    message: tGlobal('validation.textFieldLength')
                  },
                  minLength: {
                    value: 3,
                    message: tGlobal('validation.textFieldLength')
                  },
                  maxLength: {
                    value: 64,
                    message: tGlobal('validation.textFieldLength')
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      name={FIELD_EQUIPMENT_MODEL_NUMBER}
                      title={tTemplates('createEquipmentItem.modelNumberTitle')}
                      subTitle={tTemplates('createEquipmentItem.modelNumberSubTitle')}
                      label={tTemplates('createEquipmentItem.modelNumberLabel')}
                      placeholder={tTemplates('createEquipmentItem.modelNumberPlaceholder')}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_EQUIPMENT_MODEL_NUMBER]?.message,
                        error: errors[FIELD_EQUIPMENT_MODEL_NUMBER]?.message
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </Box>

            <MultiStepFooter currentStep={1} totalSteps={1} handleSetPrevStep={handleSubmitForm} />
          </>
        </form>
      </Grid>
    </Grid>
  );
};
