import { useCallback, useState } from 'react';

import { AddMetricButton, AddMetricsModal } from './components';

import { DomainActionTypeEnum } from './helpers';

interface CreateMetricsProps {
  actionType: DomainActionTypeEnum;
}

interface StateProps {
  isOpen: boolean;
  actionType?: DomainActionTypeEnum;
}

export const CreateMetrics = ({ actionType }: CreateMetricsProps) => {
  const [modalState, setModalState] = useState<StateProps>({
    isOpen: false,
    actionType: undefined
  });

  const handleOpenModal = useCallback(() => {
    setModalState({
      isOpen: true,
      actionType: actionType
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalState({ isOpen: false, actionType: undefined });
  }, []);

  return (
    <>
      <AddMetricButton onOpenModal={handleOpenModal} />

      {modalState.isOpen ? (
        <>
          <AddMetricsModal isModalOpen={modalState.isOpen} onCloseModal={handleCloseModal} />
        </>
      ) : null}
    </>
  );
};
