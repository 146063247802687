import Box from '@mui/material/Box';

import { PageTitle } from '@/separatedModules/components';

import { i18n } from '@/i18n';

import { CreateOrEditUnit, UnitActionTypeEnum } from '../AddOrEditDomain';
import { useContextState } from '../Providers';

export const PageTopBox = () => {
  const state = useContextState();

  const count = state.filters.search.length ? state.resultSearchCount : state.resultTotalCount;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={'Units page'}
        dataFoundTitle={!state.loadingUnits ? i18n.t('pageTitleFound', { value: count, ns: 'global' }) : ''}
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
      />

      <CreateOrEditUnit actionType={UnitActionTypeEnum.Create} />
    </Box>
  );
};
