import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProps, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text, TextField } from 'infinitytechnologies-ui';

import { Box, CircularProgress, Divider, IconButton } from '@mui/material';

import {
  COMPLETE_TASK,
  CreateEquipmentMutation,
  CustomTaskRes,
  GET_CUSTOM_TASK,
  GET_PRESIGNED_UPLOAD_URL,
  GetPresignedUploadUrlQuery,
  TaskStatus,
  UPDATE_TASK_STATUS
} from '@/logicLayers/domain';
import { AlertService, ApiService } from '@/logicLayers/infrastructure/services';

import { AddPhotoIcon, ArrowBackIcon, EMPTY_CELL_VALUE, Flex, ScheduleIcon } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { ROLE_COMMENT_REG_EXP } from '@/utils';

import { formatDate } from '../Tasks/pages/TaskDetails/helpers';
import { FIELD_COMMENT } from './helpers';

export const CustomTaskMobile = () => {
  const navigateTo = useNavigate();
  const { customTaskId = '' } = useParams();
  const { t: tTasks } = useTranslation('tasks');
  const { t: tGlobal } = useTranslation('global');

  const [isStarted, setIsStarted] = useState(false);
  const [fileKeys, setFileKeys] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const { refetch } = useQuery<GetPresignedUploadUrlQuery>(GET_PRESIGNED_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    variables: { path: 'CUSTOM_TASK_FORM_PHOTO' },
    skip: true
  });

  const {
    control,
    formState: { errors },
    watch
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_COMMENT]: ''
    }
  });

  const comment = watch(FIELD_COMMENT);

  const [CompleteTask] = useMutation<CreateEquipmentMutation>(COMPLETE_TASK);
  const [UpdateTaskStatus, { loading }] = useMutation(UPDATE_TASK_STATUS);

  const { data: dataCustomTask, refetch: refetchTask } = useQuery<CustomTaskRes>(GET_CUSTOM_TASK, {
    fetchPolicy: 'no-cache',
    variables: {
      taskId: customTaskId || undefined,
      searchCriteria: {}
    }
  });

  const renderItemTemplateStatus = dataCustomTask?.task?.status;

  const handleStartTask = () => {
    if (renderItemTemplateStatus === 'PENDING') {
      UpdateTaskStatus({
        variables: {
          taskId: customTaskId,
          status: 'IN_PROGRESS'
        },
        onCompleted: refetchTask
      });
    }
    setIsStarted(true);
  };

  const handleCompleteTask = () => {
    CompleteTask({
      variables: {
        taskId: customTaskId,
        request: {
          fileKeys,
          comment
        }
      },
      onCompleted: () => {
        navigateTo('/');
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.taskCompleted'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const handleComplete = () => {
    if (!isStarted) {
      return handleStartTask();
    }

    handleCompleteTask();
  };

  const renderItemName = dataCustomTask?.task?.name;
  const renderItemDescription = dataCustomTask?.task?.description;
  const renderItemStartDate = dataCustomTask?.task?.startDate;
  const renderItemPerformer = dataCustomTask?.task?.performer;
  const renderAddComment = dataCustomTask?.task?.addComment;
  const renderAddPhoto = dataCustomTask?.task?.addPhoto;

  const handleGetUrlForUpload = async () => {
    return await refetch().then(({ data }) => data);
  };

  const handleCapture = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFileKeys((prev) => [...prev, file.name]);

      const imageUrlForUpload = await handleGetUrlForUpload();

      await ApiService.AmazonS3.updateImage(imageUrlForUpload.presignedUploadUrl.url, file);
    }
  };

  const handleUploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
      handleCapture(event);
    }
  };

  const commentLength = (comment ?? '').length;
  const maxCommentLength = 2000;
  const isCommentOverLimit =
    commentLength > maxCommentLength ||
    renderItemTemplateStatus === 'OVERDUE' ||
    renderItemTemplateStatus === 'COMPLETED' ||
    renderItemTemplateStatus === 'CANCELLED' ||
    renderItemTemplateStatus === 'ON_HOLD_REQUESTED' ||
    (renderAddComment && !comment?.length && isStarted) ||
    (renderAddPhoto && !files.length && isStarted);

  return (
    <>
      <Box
        sx={{
          overflowY: 'scroll',
          padding: '0px 16px 18px 16px',
          height: 'calc(100vh - 137px)'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            top: '0px',
            left: '0px',
            background: '#ffffff',
            zIndex: '1',
            paddingTop: '18px',
            paddingBottom: '18px'
          }}
        >
          <Flex alignItems="center">
            <IconButton onClick={() => navigateTo(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Text
              component={'h2'}
              variant={'titleXl'}
              sx={{ fontSize: '18px', fontWeight: 600, margin: '0', marginLeft: '8px' }}
            >
              {tTasks('mobile.customTaskTitle')}
            </Text>
          </Flex>
        </Box>
        <Box>
          <Flex
            sx={{
              margin: '0px',
              marginBottom: '20px'
            }}
            alignItems="start"
            justifyContent="space-between"
          >
            <Text
              sx={{
                maxWidth: '256px',
                fontWeight: '600',
                fontSize: '16px'
              }}
            >
              {renderItemName}
            </Text>
            <Text
              className={'m-0'}
              variant={'bodyLg'}
              sx={{
                paddingTop: '5px'
              }}
            >
              <TaskStatusChip status={renderItemTemplateStatus as keyof typeof TaskStatus} />
            </Text>
          </Flex>
          <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
            {tTasks('mobile.assignedBy')}
          </Text>
          <Box>
            <Box sx={{ display: 'flex', marginBottom: '8px' }}>
              {renderItemPerformer?.userAvatar ? (
                <img
                  src={renderItemPerformer.userAvatar?.url}
                  alt=""
                  width={24}
                  height={24}
                  style={{
                    borderRadius: '50%',
                    border: '1px solid #F7F8F9',
                    position: 'relative',
                    margin: '0 8px 0 0'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    margin: '0 8px 0 0',
                    background: 'rgba(9, 14, 22, 0.06)',
                    border: '1px solid var(--border-inverse, #F7F8F9)'
                  }}
                />
              )}
              <Text>
                {!renderItemPerformer?.firstName && !renderItemPerformer?.lastName
                  ? EMPTY_CELL_VALUE
                  : `${renderItemPerformer?.firstName} ${renderItemPerformer?.lastName}`}
              </Text>
            </Box>
          </Box>
          <Box sx={{ marginTop: '16px' }}>
            <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
              {tTasks('mobile.startDate')}
            </Text>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ScheduleIcon sx={{ width: '18px', marginRight: '6px' }} />
              <Text sx={{ fontSize: '14px', fontWeight: '400' }}>
                {renderItemStartDate ? formatDate(renderItemStartDate ?? '') : EMPTY_CELL_VALUE}
              </Text>
            </Box>
          </Box>
          <Divider sx={{ margin: '20px 0px' }} />
          <Box>
            <Text component={'h3'} variant={'titleXl'} sx={{ fontSize: '16px', fontWeight: 600, margin: '0' }}>
              {tTasks('mobile.description')}
            </Text>
            <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '12px' }}>
              {renderItemDescription ? renderItemDescription : EMPTY_CELL_VALUE}
            </Text>
          </Box>
        </Box>
        {isStarted && (
          <Box>
            <Divider sx={{ margin: '20px 0px' }} />
            {renderAddPhoto && (
              <Box
                sx={{
                  borderRadius: '4px',
                  background: 'var(--background-disabled, rgba(41, 49, 62, 0.03))',
                  border: '1px solid var(--border-disabled, rgba(41, 49, 62, 0.06))',
                  padding: '16px 12px',
                  marginBottom: '16px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {files.map((file, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(file)}
                      alt={`Uploaded Image ${index + 1}`}
                      style={{
                        width: '63px',
                        height: '63px',
                        borderRadius: '8px',
                        marginRight: '5px'
                      }}
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    variant={'text'}
                    startIcon={<AddPhotoIcon />}
                    sx={{
                      background: 'none !important'
                    }}
                    onClick={() => document.getElementById('file-upload')?.click()}
                  >
                    {tTasks('mobile.uploadPhoto')}
                  </Button>
                </Box>
                <input id="file-upload" type="file" multiple style={{ display: 'none' }} onChange={handleUploadPhoto} />
              </Box>
            )}
            {renderAddComment && (
              <Box
                sx={{
                  borderRadius: '4px',
                  background: 'var(--background-disabled, rgba(41, 49, 62, 0.03))',
                  border: '1px solid var(--border-disabled, rgba(41, 49, 62, 0.06))',
                  padding: '16px 12px'
                }}
              >
                <Text
                  sx={{
                    margin: '0',
                    fontWeight: '600',
                    fontSize: '16px',
                    marginBottom: '24px'
                  }}
                >
                  {`${tTasks('mobile.customCommentTitle')}*`}
                </Text>
                <Controller
                  name={FIELD_COMMENT}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    pattern: {
                      value: ROLE_COMMENT_REG_EXP,
                      message: tGlobal('validation.textFieldCommentLength')
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        placeholder={tTasks('mobile.customCommentTitle')}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white'
                          }
                        }}
                        validation={{
                          isValid: !errors[FIELD_COMMENT]?.message,
                          error: errors[FIELD_COMMENT]?.message
                        }}
                      />
                    );
                  }}
                />
                <Text
                  sx={{
                    marginTop: '8px',
                    fontSize: '12px',
                    color: commentLength > maxCommentLength ? 'red' : 'initial',
                    textAlign: 'right'
                  }}
                >
                  {commentLength}/{'2,000'}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'sticky',
          bottom: '0px',
          left: '0px',
          padding: '16px 16px 58px 16px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          borderTop: '1px solid rgba(9, 14, 22, 0.12)',
          background: '#ffffff'
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleComplete}
          disabled={isCommentOverLimit}
          sx={{
            width: '100%'
          }}
        >
          {loading ? (
            <CircularProgress color={'inherit'} size={20} />
          ) : isStarted ? (
            tTasks('mobile.completeCustomTask')
          ) : (
            tTasks('mobile.startCustomTask')
          )}
        </Button>
      </Box>
    </>
  );
};
