import { Dayjs } from 'dayjs';

import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface DateRangePickerProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onStartDateChange: (newDate: Dayjs | null) => void;
  onEndDateChange: (newDate: Dayjs | null) => void;
  isDateInvalid: boolean;
}

export const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  isDateInvalid
}: DateRangePickerProps) => {
  return (
    <Box display="flex" sx={{ marginTop: '24px' }}>
      <DatePicker
        label="Start date"
        value={startDate}
        onChange={onStartDateChange}
        sx={{ width: '268px', marginTop: '16px', marginRight: '16px' }}
        slotProps={{
          textField: {
            error: isDateInvalid,
            helperText: isDateInvalid ? 'MM/DD/YYYY' : ''
          }
        }}
      />
      <DatePicker
        label="End date"
        value={endDate}
        onChange={onEndDateChange}
        sx={{ width: '268px', marginTop: '16px', marginRight: '16px' }}
        slotProps={{
          textField: {
            error: isDateInvalid,
            helperText: isDateInvalid ? 'MM/DD/YYYY' : ''
          }
        }}
      />
    </Box>
  );
};
