import { createContext, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Dayjs } from 'dayjs';

import { LocalStorageServiceClass } from '@/logicLayers/infrastructure/services';

import { MenuOption } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useContextState } from './ContextState';

import { ContextUpdateStateI, initState, TableProviderProps } from './helpers';

export const ContextUpdateState = createContext<ContextUpdateStateI>({
  handleChangeBySearch: () => {},
  handleChangePagination: () => {},
  handleChangeRowsPerPage: () => {},
  handleChangeSortByDate: () => {},
  handleClickRow: () => {},
  handleResetSelectedRows: () => {},
  handleSelectRow: () => {},
  handleShowAllColumns: () => {},
  handleToggleActions: () => {},
  handleToggleColumnVisibility: () => {},
  handleChangeSortByDomains: () => {},
  handleDoubleClickRow: () => {},
  handleSetPrevStep: () => {},
  handleSetNextStep: () => {},
  toggleChecked: () => {},
  toggleAllChecked: () => {},
  handleSetCSVStartDate: () => {},
  handleSetCSVEndDate: () => {},
  handleResetStep: () => {},
  handleSortByLocations: () => {},
  sortByDomain: () => {},
  sortByCyclicality: () => {}
});

export const useContextUpdateState = () => useContext(ContextUpdateState);

export const ProviderUpdateState = ({ children }: TableProviderProps) => {
  const LocalStorageService = new LocalStorageServiceClass();

  // const navigateTo = useNavigate();
  const location = useLocation();

  const { setState } = useContextState();

  const handleSaveColumnsToStorage = (hiddenColumns: string[]) => {
    if (LINKS_PAGES.locations === location.pathname) {
      LocalStorageService.set('locationsTableColumns', hiddenColumns);
    }
  };

  const handleSetPrevStep = useCallback(() => {
    setState((state) => {
      if (!state.currentStep) return state;
      return { ...state, currentStep: 1 };
    });
  }, []);

  const handleSetProgressValue = useCallback((value: number) => {
    setState((state) => {
      if (!state.currentStep) return state;
      return { ...state, progress: value };
    });
  }, []);

  const handleSetNextStep = useCallback(() => {
    setState((state) => {
      if (state.currentStep === state.totalSteps) return state;
      return { ...state, currentStep: state.currentStep + 1 };
    });
  }, []);

  const handleResetStep = useCallback(() => {
    setState((state) => {
      return { ...state, currentStep: 1 };
    });
  }, []);

  const sortByCyclicality = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        CSVFilters: {
          ...state.CSVFilters,
          sortByCyclicality: value
        }
      };
    });
  }, []);

  const toggleAllChecked = (isChecked: boolean) => {
    setState((prevState) => {
      const updatedRows = prevState.bodyDataRows.map((row) => ({
        ...row,
        checked: isChecked
      }));

      return {
        ...prevState,
        allChecked: isChecked,
        bodyDataRows: updatedRows
      };
    });
  };

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: [newPage]
        }
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: initState.pagination.page,
          rowsPerPage: [newRowsPerPage]
        }
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleSelectRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId]
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleSetCSVStartDate = useCallback((startDate: Dayjs | null) => {
    setState((state) => {
      return {
        ...state,
        CSVFilters: {
          ...state.CSVFilters,
          sortByCSVStartDate: startDate
        }
      };
    });
  }, []);

  const handleSetCSVEndDate = useCallback((endDate: Dayjs | null) => {
    setState((state) => {
      return {
        ...state,
        CSVFilters: {
          ...state.CSVFilters,
          sortByCSVEndDate: endDate
        }
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: MenuOption[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeSortByDomains = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDomains: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: [value]
        }
      };
    });
  }, []);

  const handleToggleActions = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        isVisibleActions: !state.isVisibleActions
      };
    });
  }, []);

  const handleToggleColumnVisibility = useCallback((column: string) => {
    setState((state) => {
      const inColumnHidden = state.filters.hiddenColumns.includes(column);

      const hiddenColumnsUpdated = inColumnHidden
        ? state.filters.hiddenColumns.filter((columnItem) => columnItem !== column)
        : [column, ...state.filters.hiddenColumns];

      handleSaveColumnsToStorage(hiddenColumnsUpdated);

      return {
        ...state,
        filters: {
          ...state.filters,
          hiddenColumns: hiddenColumnsUpdated
        }
      };
    });
  }, []);

  const handleShowAllColumns = useCallback(() => {
    // ToDo Refactor
    LocalStorageService.set('locationsTableColumns', []);

    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          hiddenColumns: []
        }
      };
    });
  }, []);

  const toggleChecked = (id: string) => {
    setState((prevState) => {
      const updatedRows = prevState.bodyDataRows.map((row) =>
        row.id === id ? { ...row, checked: !row.checked } : row
      );

      return {
        ...prevState,
        bodyDataRows: updatedRows
      };
    });
  };

  const handleSortByLocations = (locationIds: string[]) => {
    setState(({ CSVFilters, ...prevState }) => {
      return {
        ...prevState,
        CSVFilters: {
          ...CSVFilters,
          sortByLocations: locationIds
        }
      };
    });
  };

  const sortByDomain = (domainId: string) => {
    setState(({ CSVFilters, ...prevState }) => {
      return {
        ...prevState,
        CSVFilters: {
          ...CSVFilters,
          sortByDomain: domainId
        }
      };
    });
  };

  const contextValue = useMemo(
    () => ({
      handleChangeBySearch,
      handleChangePagination,
      handleChangeRowsPerPage,
      handleChangeSortByDate,
      handleClickRow,
      handleResetSelectedRows,
      handleSelectRow,
      handleShowAllColumns,
      handleToggleActions,
      handleToggleColumnVisibility,
      handleChangeSortByDomains,
      handleDoubleClickRow,
      handleSetPrevStep,
      handleSetNextStep,
      toggleChecked,
      toggleAllChecked,
      handleSetProgressValue,
      handleSetCSVStartDate,
      handleSetCSVEndDate,
      handleResetStep,
      handleSortByLocations,
      sortByDomain,
      sortByCyclicality
    }),
    []
  );

  return <ContextUpdateState.Provider value={contextValue}>{children}</ContextUpdateState.Provider>;
};
