import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useIsUserCanCRUD } from '@/utils';

import {
  ActionsContainer,
  FiltersContainer,
  PageTopBox,
  ProviderState,
  ProviderUpdateState,
  TableLocations
} from './components';

export const RoundRegulations = () => {
  const navigate = useNavigate();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_VIEWING']
  });
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  useEffect(() => {
    if (!isUserCanCRUD || isSmallScreen || isTablet) {
      return navigate('/');
    }
  }, [navigate]);

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item sx={{ minHeight: '70vh' }}>
        <ProviderState>
          <ProviderUpdateState>
            <PageTopBox />

            <Box sx={{ display: 'flex' }}>
              <Box width={'100%'}>
                <FiltersContainer />

                <ActionsContainer />

                <TableLocations />
              </Box>
            </Box>
          </ProviderUpdateState>
        </ProviderState>
      </Grid>
    </Grid>
  );
};
