import { Avatar, Text } from 'infinitytechnologies-ui';

import Skeleton from '@mui/material/Skeleton';

import { UserDetailsProps } from '@/separatedModules/components';

interface UserDetailsShortProps
  extends Pick<UserDetailsProps, 'firstName' | 'lastName' | 'email' | 'loading' | 'logo'> {}

export const UserDetailsShort = ({ firstName, lastName, email, logo, loading }: UserDetailsShortProps) => {
  return loading ? (
    <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={40} />
  ) : (
    <>
      {firstName && lastName ? (
        <div className={'user-details__info'}>
          <Avatar size={'48'} src={logo}>
            {firstName.at(0)}
            {lastName.at(0)}
          </Avatar>

          <div className={'user-details__info__detail'}>
            {firstName && lastName ? (
              <Text
                className={'mb-0'}
                variant={'titleMd'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {firstName} {lastName}
              </Text>
            ) : null}

            {email ? (
              <div className={'user-details__info__email'} style={{ margin: '4px 0 0' }}>
                <Text
                  className={'m-0'}
                  variant={'bodyMd'}
                  sx={{ color: 'var(--text-subtlest, #969AA8)', wordBreak: 'break-all' }}
                >
                  {email}
                </Text>
              </div>
            ) : null}

            <div className={'user-details__info__role'} />
          </div>
        </div>
      ) : null}
    </>
  );
};
