import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { GET_ACCESS_TYPE, GET_REGIONS_SIDEBAR, GetRegionsSidebarQuery } from '@/logicLayers/domain';

import { PageTitle, PlusIcon } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { useContextState } from '../Providers';

import { getBreadcrumbs } from './helpers';

export const PageTopBox = () => {
  const { t: tCompanies } = useTranslation('companies');
  const navigateTo = useNavigate();
  const { folderId } = useParams<{ folderId?: string }>();

  const state = useContextState();

  const { data: dataRegions } = useQuery<GetRegionsSidebarQuery>(GET_REGIONS_SIDEBAR, {
    variables: {
      pageSize: 100,
      page: 0
    }
  });

  const { data: dataPermission } = useQuery(GET_ACCESS_TYPE, {
    variables: {
      resourceId: folderId
    }
  });

  const handleCreateCompany = useCallback(() => {
    navigateTo(LINKS_PAGES.companyCreate.replace(':folderId', folderId ?? 'root'));
  }, [folderId]);

  const breadcrumbsLinks = getBreadcrumbs([...(dataRegions?.dataItems.items || [])], folderId);

  const count = state.filters.search.length ? state.resultSearchCount : state.resultTotalCount;

  const shouldShowButton = Boolean(folderId);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={tCompanies('pageTitle')}
        dataFoundTitle={!state.loadingCompanies ? i18n.t('pageTitleFound', { value: count ?? 0, ns: 'global' }) : ''}
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
        breadcrumbsLinks={breadcrumbsLinks}
      />

      {shouldShowButton && (
        <Button
          className={'m-0'}
          variant={'contained'}
          startIcon={<PlusIcon />}
          onClick={handleCreateCompany}
          disabled={dataPermission?.accessTypeForResource !== 'CREATE_UPDATE'}
        >
          {tCompanies('btnCreateCompany')}
        </Button>
      )}
    </Box>
  );
};
