import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'infinitytechnologies-ui';
import { v4 as uuid } from 'uuid';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, FormHelperText, IconButton } from '@mui/material';

import { EquipmentParametersQuery } from '@/logicLayers/domain';

import { PARAMETER_VALUE } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/helpers';

import { ChipIcon, Flex } from '@/separatedModules/components';

import { InputDynamicWidth } from '../components';

import { MATH_OPERATIONS_LIST } from '../helpers';

import {
  ChipWrapper,
  EditBlock,
  FormulaBlock,
  FormulaTopBlock,
  FormulaWrapper,
  MathChip,
  ParameterChip
} from '../style';

interface FormulaMainContentProps {
  isValidError?: any;
  equipmentParametersData?: EquipmentParametersQuery | undefined;
  setValue: (PARAMETER_VALUE: string, formulaResult: string) => void;
  setError?: (PARAMETER_VALUE: string, errorMessage: { message: string }) => void;
  value?: string;
  title?: string;
}

export const FormulaMainContent = ({
  equipmentParametersData,
  isValidError,
  setValue,
  setError,
  value,
  title
}: FormulaMainContentProps) => {
  const { t: tTemplates } = useTranslation('templates');

  const [isEditable, setIsEditable] = useState(false);
  const [formulaItems, setFormulaItems] = useState<any[]>([]);
  const [formulaFirstItem, setFormulaFirstItem] = useState<string | null>(null);
  const [selectedInput, setSelectedInput] = useState<null | string>(null);
  const [isSelectedFirstInput, setIsSelectedFirstInput] = useState(false);

  const formulaItemRefs = useRef<any>({});
  const formulaFirstItemRef = useRef<HTMLInputElement>(null);

  const editableBoxRef = useRef<HTMLDivElement>(null);

  const handleClickEditBlock = () => {
    setIsEditable(true);
  };

  const handleDeleteParameter = (id: string) => {
    const filtered = formulaItems.filter((i) => {
      return i.id !== id;
    });

    const updatedRefs = { ...formulaItemRefs.current };
    delete updatedRefs[id];
    formulaItemRefs.current = updatedRefs;

    setFormulaItems(filtered);
  };

  const handleUpdateFirstParameterValue = (value: string) => {
    const letter = value.match(/[+\-*\/%()]/)?.[0];

    const updatedFormulaItems = [...formulaItems];

    if (letter) {
      const operationTitle = MATH_OPERATIONS_LIST.find((mathList) => mathList.operation === letter)?.operation;
      updatedFormulaItems.unshift({ type: 'operation', id: uuid(), title: operationTitle });

      return setFormulaItems(updatedFormulaItems);
    }

    setFormulaFirstItem(value);
  };

  const handleUpdateParameterValue = (id: string, value: string) => {
    const letter = value.match(/[+\-*\/%()]/)?.[0];

    const findedItemIndex = formulaItems.findIndex((item) => item.id === id);

    let updatedFormulaItems = formulaItems.map((item) => {
      if (item.id === id) {
        const correctValue = value.replace(/[^0-9.,]/g, '');
        const regex = /[,.]{2,}/g;
        const found = correctValue.match(regex);
        const valueUpdated = found ? value.replace(regex, '.') : correctValue;

        return { ...item, value: valueUpdated };
      }
      return item;
    });

    if (letter) {
      const operationTitle = MATH_OPERATIONS_LIST.find((mathList) => mathList.operation === letter)?.operation;
      const newParameter = { type: 'operation', id: uuid(), title: operationTitle };

      updatedFormulaItems = [
        ...updatedFormulaItems.slice(0, findedItemIndex + 1),
        newParameter,
        ...updatedFormulaItems.slice(findedItemIndex + 1)
      ];
    }

    setFormulaItems(updatedFormulaItems);
  };

  useEffect(() => {
    const reducedValue = formulaItems.reduce((accumulator, currentValue) => {
      const isTypeParameter = currentValue.type === 'parameter';

      const resultValue = isTypeParameter ? `{${currentValue.propertyId}}` : currentValue.title;

      return [...accumulator, resultValue, currentValue.value];
    }, []);

    const formulaResult = [formulaFirstItem].concat(reducedValue).filter(Boolean).join(' ').trim();

    setValue(PARAMETER_VALUE, formulaResult);

    if (formulaResult) {
      setError?.(PARAMETER_VALUE, { message: '' });
    }
  }, [formulaFirstItem, formulaItems]);

  useEffect(() => {
    if (value) {
      const items = equipmentParametersData?.dataItems?.items;

      if (items) {
        const parts = value.split(' ');
        const formula = [];

        for (let i = 0; i < parts.length; i++) {
          const part = parts[i];

          if (part.startsWith('{') && part.endsWith('}')) {
            const id = part.slice(1, -1);
            const item = items.find((item) => item?.propertyId === id);
            if (item) {
              formula.push({
                type: 'parameter',
                id: uuid(),
                title: item.name,
                value: '',
                propertyId: item.propertyId
              });
            }
          } else if (MATH_OPERATIONS_LIST.some((op) => op.operation === part)) {
            if (i + 1 < parts.length && !isNaN(+parts[i + 1])) {
              formula.push({
                type: 'operation',
                id: uuid(),
                title: part,
                value: parts[i + 1]
              });
              i++;
            } else {
              formula.push({
                type: 'operation',
                id: uuid(),
                title: part
              });
            }
          } else if (!isNaN(+part)) {
            formula.push({
              id: uuid(),
              type: 'text',
              value: part
            });
          }
        }

        setFormulaItems(formula);
      }
    }
  }, [value, equipmentParametersData]);

  const keyDownHandler = (id: string, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      const item = formulaItems.find((i) => {
        return i.id === id;
      });

      if (!item.value?.length && item.type !== 'text') {
        const index = formulaItems.findIndex((i) => i.id === id);

        const prevItemId = formulaItems[index - 1]?.id;

        const prevElement = formulaItemRefs?.current?.[prevItemId as string];

        event.preventDefault();
        handleDeleteParameter(id);

        if (prevElement) {
          prevElement?.focus();

          delete formulaItemRefs.current[id];
        } else {
          formulaFirstItemRef?.current?.focus();
        }
      }
    }
  };

  return (
    <FormulaWrapper>
      <FormulaTopBlock direction={'row'}>
        <Text m={0} variant={'bodyLg'} sx={{ width: '120px' }}>
          {title ? `${title} = ` : tTemplates('createParameterItem.parameter.formula.volume')}
        </Text>

        <Box sx={{ flex: '0 1 100%', maxWidth: 'calc(100% - 140px)' }}>
          <EditBlock
            ref={editableBoxRef}
            flex={1}
            rowGap={'8px'}
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap'
            }}
            editable={value?.length || isEditable}
            error={isValidError}
            onClick={handleClickEditBlock}
          >
            {!value?.length && !isEditable ? (
              <Text
                m={0}
                variant={'bodyLg'}
                sx={{
                  color: isValidError ? '#BE0E2C' : '#969AA8'
                }}
              >
                {tTemplates('createParameterItem.parameter.formula.titlePlaceholder')}
              </Text>
            ) : (
              ((!value?.length && isEditable) ||
                (!formulaItems.length && value?.length) ||
                Boolean(formulaFirstItem)) && (
                <InputDynamicWidth
                  onChange={handleUpdateFirstParameterValue}
                  forwardedRef={formulaFirstItemRef}
                  onFocus={() => {
                    setIsSelectedFirstInput(true);
                  }}
                />
              )
            )}

            {formulaItems.length
              ? formulaItems.map((i) => {
                  return (
                    <ChipWrapper
                      key={i.id}
                      component={'div'}
                      sx={{
                        display: 'inline-flex',
                        '.MuiIconButton-root': {
                          visibility: 'hidden',
                          position: 'absolute',
                          top: '-12px',
                          right: '-8px'
                        },
                        '&:hover': {
                          '.MuiIconButton-root': {
                            visibility: 'visible'
                          }
                        }
                      }}
                    >
                      {i.type !== 'text' && (
                        <Box sx={{ position: 'relative', display: 'inline-flex', margin: '0 4px' }}>
                          {i.type === 'parameter' ? <ParameterChip id={i?.id} label={i?.title} /> : null}

                          {i.type === 'operation' ? <MathChip label={i.title} /> : null}

                          <IconButton
                            size={'small'}
                            sx={{ borderRadius: '100%' }}
                            onClick={() => handleDeleteParameter(i.id)}
                          >
                            <ChipIcon />
                          </IconButton>
                        </Box>
                      )}

                      <InputDynamicWidth
                        onChange={(value) => {
                          handleUpdateParameterValue(i?.id, value);
                        }}
                        value={i.type === 'operation' || i.type === 'text' ? i.value : ''}
                        onKeyDown={(event) => keyDownHandler(i?.id, event)}
                        forwardedRef={(ref) => (formulaItemRefs.current[i?.id] = ref)}
                        onFocus={() => {
                          setSelectedInput(i?.id);
                          setIsSelectedFirstInput(false);
                        }}
                      />
                    </ChipWrapper>
                  );
                })
              : null}
          </EditBlock>

          {isValidError ? (
            <FormHelperText component={'div'} sx={{ margin: '8px 0 0 0' }} error>
              <ErrorOutlineIcon sx={{ fontSize: '16px' }} />

              <div className={'MuiFormHelperText-root__text'}>{isValidError as string}</div>
            </FormHelperText>
          ) : null}
        </Box>
      </FormulaTopBlock>

      <FormulaBlock>
        <Flex justifyContent={'space-between'} alignItems={'center'} mb={'28px'}>
          <Text variant={'titleSm'} m={0}>
            {tTemplates('createParameterItem.parameter.formula.parameters.parametersTitle')}
          </Text>
        </Flex>

        <Flex gap={'8px'} flexWrap={'wrap'}>
          {equipmentParametersData?.dataItems?.items?.map((parameter) => {
            return (
              <ChipWrapper
                key={parameter?.id}
                type={'button'}
                onClick={() => {
                  handleClickEditBlock();

                  const findedItemIndex = formulaItems.findIndex((item) => item.id === selectedInput);
                  const newParameter = {
                    type: 'parameter',
                    id: uuid(),
                    title: parameter?.name,
                    value: '',
                    propertyId: parameter?.propertyId
                  };

                  if (isSelectedFirstInput) {
                    return setFormulaItems((state) => [newParameter, ...state]);
                  }
                  if (findedItemIndex !== -1) {
                    return setFormulaItems(() => [
                      ...formulaItems.slice(0, findedItemIndex + 1),
                      newParameter,
                      ...formulaItems.slice(findedItemIndex + 1)
                    ]);
                  }

                  setFormulaItems((state) => [...state, newParameter]);
                }}
              >
                <ParameterChip id={parameter?.id} label={parameter?.name} />
              </ChipWrapper>
            );
          })}
        </Flex>
      </FormulaBlock>

      <FormulaBlock>
        <Text m={0} variant={'titleSm'} mb={'16px'}>
          {tTemplates('createParameterItem.parameter.formula.mathOperations.title')}
        </Text>

        <Flex gap={'8px'} flexWrap={'wrap'}>
          {MATH_OPERATIONS_LIST.map((operation) => {
            return (
              <ChipWrapper
                key={operation?.id}
                type={'button'}
                onClick={() => {
                  handleClickEditBlock();

                  const findedItemIndex = formulaItems.findIndex((item) => item.id === selectedInput);

                  const newParameter = { type: 'operation', id: uuid(), title: operation.operation };

                  if (isSelectedFirstInput) {
                    return setFormulaItems((state) => [newParameter, ...state]);
                  }

                  if (findedItemIndex !== -1) {
                    return setFormulaItems(() => [
                      ...formulaItems.slice(0, findedItemIndex + 1),
                      newParameter,
                      ...formulaItems.slice(findedItemIndex + 1)
                    ]);
                  }

                  setFormulaItems((state) => [...state, newParameter]);
                }}
              >
                <MathChip label={operation.operation} />
              </ChipWrapper>
            );
          })}
        </Flex>
      </FormulaBlock>
    </FormulaWrapper>
  );
};
