import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import {
  CREATE_USER_FOR_COMPANY,
  CreateUserMutation,
  DomainDto,
  GET_ROLE_TEMPLATES,
  GET_USERS,
  GetRoleTemplatesQuery
} from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteOptionT,
  AutocompleteTagsControl,
  ButtonLoading,
  Preloader,
  PreloaderVariantsE,
  SelectControl,
  TextFieldControl
} from '@/separatedModules/components';

import { EMAIL_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import {
  AddUserModalProps,
  FIELD_COMPANY_ROLE,
  FIELD_DOMAIN_IDS,
  FIELD_USER_EMAIL,
  formatSelectOptions
} from './helpers';

interface FormProps {
  [FIELD_USER_EMAIL]: string;
  [FIELD_COMPANY_ROLE]: string;
  [FIELD_DOMAIN_IDS]: string[];
}

const FORM_ID = 'add-user-form';

export const AddUserModalClient = ({ isModalOpen, onCloseModal, refetch }: AddUserModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tErrors } = useTranslation('errors');
  const { t: tUsers } = useTranslation('users');
  const { t: tTemplates } = useTranslation('templates');
  const { t: tCompany } = useTranslation('company');

  const { profile } = useReduxSelector(selectUserData);

  const companyId = profile.companyId;
  const domainsProfile = profile?.domains;

  const [selectedRoleCompanyId, setSelectedRoleCompanyId] = useState('');
  const [selectedRoleDomains, setSelectedRoleDomains] = useState<Partial<DomainDto>[]>([]);

  const [resetKey, setResetKey] = useState(0);

  const { data: dataRoleTemplates, loading: loadingRoleTemplates } = useQuery<GetRoleTemplatesQuery>(
    GET_ROLE_TEMPLATES,
    {
      skip: !companyId,
      fetchPolicy: 'no-cache',
      variables: {
        searchCriteria: {
          pageable: {
            page: 0,
            pageSize: 1000
          },
          companyId: companyId
        }
      }
    }
  );

  const [CreateUser, { loading: loadingCreateUser, data: dataCreateUser, reset: resetDataCreateUser }] =
    useMutation<CreateUserMutation>(CREATE_USER_FOR_COMPANY);

  const {
    setValue,
    setError,
    reset,
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_USER_EMAIL]: '',
      [FIELD_COMPANY_ROLE]: '',
      [FIELD_DOMAIN_IDS]: []
    }
  });

  const userEmails = watch(FIELD_USER_EMAIL);
  const isEmailEntered = userEmails?.length > 0 && !errors[FIELD_USER_EMAIL];

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const rolesList = useMemo(() => {
    if (!dataRoleTemplates?.dataItems.items.length) return [];
    return formatSelectOptions(dataRoleTemplates.dataItems.items, 'id', 'name');
  }, [dataRoleTemplates?.dataItems.items]);

  // Client Admin
  // У нас нет поля выбора компании, мы сразу выбираем роль,
  // после выбора роли мы сравниваем сompanyId(роли) и сompanyId(компании из profile.companyId),
  // если === отображаем домены роли которую выбрали,
  // если !== отображаем домены из profile?.domains.

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (selectedRoleDomains?.length) {
      if (selectedRoleCompanyId === companyId) {
        return selectedRoleDomains.map((domain) => ({
          label: domain?.name as string,
          value: domain?.id as string,
          key: domain?.id
        }));
      }
    }

    if (domainsProfile?.length) {
      return domainsProfile.map((domain) => ({
        label: domain?.name as string,
        value: domain?.id as string,
        key: domain?.id
      }));
    }

    return [];
  }, [selectedRoleDomains, companyId, selectedRoleCompanyId, domainsProfile]);

  const handleSubmitForm = handleSubmit(() => {
    const { userEmail, companyRole, domainIds } = getValues();

    if (companyId) {
      CreateUser({
        variables: {
          companyUid: profile?.companyId,
          user: {
            email: userEmail,
            roleTemplateId: companyRole,
            domainIds: domainIds.length ? domainIds : undefined
          }
        },
        onCompleted: ({ createUser }) => {
          handleCloseModal();

          if (createUser.id) {
            /* Clear data from mutation */
            resetDataCreateUser();
            refetch?.();

            setTimeout(() => {
              AlertService.showAlert({
                title: tGlobal('alertMessages.success.userCreated'),
                severity: 'success'
              });
            }, 500);
          }
        },
        onError: (error) => {
          const errorMessage = JSON.parse(error.message);

          if (errorMessage?.errorCode) {
            setError(FIELD_USER_EMAIL, {
              message: tErrors(`company.create.${errorMessage.errorCode.replace('error.', '')}`)
            });
          }
        },
        refetchQueries: [
          {
            query: GET_USERS
          }
        ]
      });
    }
  });

  const isLoading = loadingRoleTemplates;
  const isBtnSubmitDisabled = Boolean(dataCreateUser);
  const isRoleTemplatesExist = Boolean(dataRoleTemplates?.dataItems.items.length);

  const handleRoleChange = (selectedRoleId: any) => {
    const selectedRole = dataRoleTemplates?.dataItems.items.find((role: any) => role.id === selectedRoleId);
    setSelectedRoleCompanyId(selectedRole?.companyId || '');

    setSelectedRoleDomains(selectedRole?.domains as DomainDto[]);

    setValue(FIELD_DOMAIN_IDS, []);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleDomainsChange = (items: string[]) => {
    // TODO use value instead label for Autocomplete
    const domainIds = domainsOptions?.filter((domain) => items.includes(domain.label))?.map((domain) => domain.key);

    setValue(FIELD_DOMAIN_IDS, domainIds);
  };

  return (
    <Dialog
      headerTitle={tUsers('addUser.modal.title')}
      headerSubTitle={tUsers('addUser.modal.subTitle')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tUsers('addUser.btnCancel')}
          </Button>

          <ButtonLoading
            form={FORM_ID}
            type={'submit'}
            variant={'contained'}
            sx={{ margin: '0 0 0 0 !important' }}
            disabled={isBtnSubmitDisabled}
            loading={loadingCreateUser}
          >
            {tUsers('addUser.btnInvite')}
          </ButtonLoading>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        {isLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '300px !important' }} isContainer />
        ) : (
          <>
            <Controller
              name={FIELD_USER_EMAIL}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: EMAIL_REG_EXP,
                  message: tGlobal('validation.emailValid')
                }
              }}
              render={({ field: { onBlur, value } }) => {
                return (
                  <TextFieldControl
                    name={FIELD_USER_EMAIL}
                    subTitle={tCompany('create.blank.inviteUserSubTitle')}
                    label={tUsers('addUser.modal.inviteUserLabel')}
                    placeholder={tUsers('addUser.modal.inviteUserPlaceholder')}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_USER_EMAIL]?.message,
                      error: errors[FIELD_USER_EMAIL]?.message
                    }}
                    sxInputBox={{
                      marginTop: 0
                    }}
                    onChange={(e: any) => {
                      setValue(FIELD_USER_EMAIL, e.target.value?.trim());
                    }}
                    onBlur={onBlur}
                  />
                );
              }}
            />

            <Controller
              name={FIELD_COMPANY_ROLE}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <SelectControl
                    options={rolesList}
                    label={tUsers('addUser.modal.inviteAsLabel')}
                    placeholder={tUsers('addUser.modal.inviteUserPlaceholder')}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_COMPANY_ROLE]?.message,
                      error: errors[FIELD_COMPANY_ROLE]?.message
                    }}
                    MenuProps={{
                      className: 'MuiMenu-root-Mui-select'
                    }}
                    loading={loadingRoleTemplates}
                    disabled={!isRoleTemplatesExist || !isEmailEntered}
                    onChange={(e) => {
                      onChange(e);
                      handleRoleChange(e.target.value);
                    }}
                    onBlur={onBlur}
                  ></SelectControl>
                );
              }}
            />
            <Controller
              name={FIELD_DOMAIN_IDS}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                }
              }}
              control={control}
              render={() => {
                const label = tTemplates('createRole.mainInfo.selectDomainLabel');
                return (
                  <AutocompleteTagsControl
                    name={FIELD_DOMAIN_IDS}
                    key={`domain-select-${resetKey}`}
                    label={label}
                    options={domainsOptions}
                    loading={loadingRoleTemplates}
                    validation={{
                      isValid: !errors[FIELD_DOMAIN_IDS]?.message,
                      error: errors[FIELD_DOMAIN_IDS]?.message
                    }}
                    disabled={selectedRoleCompanyId === '' || loadingRoleTemplates}
                    onChange={(_, value: any) => handleDomainsChange(value)}
                  />
                );
              }}
            />
          </>
        )}
      </form>
    </Dialog>
  );
};
