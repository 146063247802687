import { MouseEvent, ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { EditIcon, MoreVertIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { EditUnitModal } from '../../../AddOrEditDomain/components';

interface RowActionMenuProps {
  id?: string | undefined;
  name: string;
  itemsCount: number;
  lastModifiedDate?: string;
  active?: boolean;
  archived?: boolean;
  renderModalContent?: () => ReactNode;
}

export const RowActionMenu = (row: RowActionMenuProps) => {
  const { t: tTemplates } = useTranslation('templates');

  const [state, setState] = useState({
    isModalOpen: false,
    isEditModalOpen: false,
    modalType: ''
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickBox = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOpenEditModal = () => {
    setAnchorEl(null);
    setState((state) => ({ ...state, isEditModalOpen: true }));
  };

  const handleCloseEditModal = () => {
    setState((state) => ({ ...state, isEditModalOpen: false }));
  };

  return (
    <Box component={'td'} className={'table-edit-button'} onClick={handleClickBox}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenEditModal}>
          <EditIcon sx={{ fontSize: 20 }} />

          {tTemplates('equipment.table.rowEditMenu.edit')}
        </MenuItem>
      </Menu>

      <EditUnitModal domainId={row?.id} isModalOpen={state.isEditModalOpen} onCloseModal={handleCloseEditModal} />
    </Box>
  );
};
