import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { Box, Stack } from '@mui/material';

import { GET_USERS, GetUsersQuery, SEARCH_DOMAINS, SearchDomainsQuery } from '@/logicLayers/domain';

import {
  CheckboxesDropdown,
  ColumnIcon,
  MenuOption,
  // SelectsDropdown,
  // SortIcon,
  SwitchesDropdown
} from '@/separatedModules/components';

import { useIsUserSuperAdmin } from '@/utils';

import { useTranslation } from '@/i18n';

import { TableColumn } from '../MuiTable/table.config';

import { SortByCompany } from './components/SortByCompany';
import { SortByName } from './components/SortByName';

interface FiltersContainerProps {
  hiddenColumns: TableColumn[];
  onSetFilterRoles: (roles: string[]) => void;
  onColumnVisibilityChange: (checked: boolean, columnKey: string) => void;
  onApplyShowColumnsClick: () => void;
  onChangeFilterByCompany: (options: MenuOption[]) => void;
  onChangeFilterByDomain: (options: string[]) => void;
  onChangeSortByName: (options: MenuOption[]) => void;
}

export const FiltersContainer: FC<FiltersContainerProps> = ({
  hiddenColumns,
  onSetFilterRoles,
  onColumnVisibilityChange,
  onApplyShowColumnsClick,
  onChangeFilterByCompany,
  onChangeFilterByDomain,
  onChangeSortByName
}) => {
  const { t: tUsers } = useTranslation('users');
  const isUserSuperAdmin = useIsUserSuperAdmin();

  const { data } = useQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      pageSize: 100
    }
  });

  const { data: domainsData, loading: domainsDataLoading } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const usersFilteredByRoles = useMemo(() => {
    if (!data?.dataItems.items) {
      return [];
    }
    return data?.dataItems.items.filter(
      (obj, index) => data?.dataItems.items.findIndex((item) => item?.permissionTag === obj?.permissionTag) === index
    );
  }, [data?.dataItems.items]);

  const rolesList = useMemo(() => {
    return usersFilteredByRoles?.map((user) => ({
      label: user?.permissionTag ?? '',
      key: user?.permissionTag ?? '',
      value: user?.permissionTag,
      checked: true
    }));
  }, [usersFilteredByRoles]);

  const domainsList = useMemo(() => {
    if (!domainsData?.dataItems?.items) return [];

    return domainsData.dataItems.items.map((item) => ({
      label: item?.name ?? '',
      key: item?.id ?? '',
      value: item?.id
    }));
  }, [domainsData]);

  const isDisabledDomains = !domainsList.length || domainsDataLoading;

  const Title = useMemo(
    () => (
      <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
        <ColumnIcon />
        <Box>
          {tUsers('table.filters.hiddenColumns.title', {
            value: hiddenColumns.filter((c) => c.visible).length
          })}
        </Box>
      </Stack>
    ),
    [hiddenColumns]
  );

  return (
    <Stack direction={'row'} spacing={'8px'}>
      <SwitchesDropdown
        id={'User-management-hidden-columns'}
        title={Title}
        searchPlaceholder={tUsers('table.filters.hiddenColumns.placeholder')}
        menuList={hiddenColumns.map((column) => ({
          label: tUsers(`table.cells.${column.key}`),
          key: column.key,
          value: column.key,
          checked: !!column.visible
        }))}
        onSwitchClick={(option) => onColumnVisibilityChange(option.checked, option.key)}
        onChange={onApplyShowColumnsClick}
      />

      <CheckboxesDropdown
        id={'User-management-filter-by-role'}
        title={tUsers('table.filters.filterByRole.title')}
        menuList={rolesList}
        hasSelectAll
        onChange={(options) => onSetFilterRoles(options.map((finalOption) => finalOption.key))}
      />

      {isUserSuperAdmin ? <SortByCompany onChangeFilterByCompany={onChangeFilterByCompany} /> : null}

      <CheckboxesDropdown
        id={'User-management-filter-by-domain'}
        title={tUsers('table.filters.filterByDomain.title')}
        menuList={domainsList}
        disabled={isDisabledDomains}
        hasSelectAll
        onChange={(options) => onChangeFilterByDomain(options.map((finalOption) => finalOption.key))}
      />

      <SortByName onChangeSortByName={onChangeSortByName} />
    </Stack>
  );
};
