import { LocationItem } from '../../helpers';

export const findItemRecursively = (items: LocationItem[], label: string): LocationItem | null => {
  for (const item of items) {
    if (item.label === label) return item;
    if (item.children.length > 0) {
      const found = findItemRecursively(item.children, label);
      if (found) return found;
    }
  }
  return null;
};

export const findParent = (items: LocationItem[], targetId: string): LocationItem | null => {
  for (const item of items) {
    if (item.children.some((child) => child.value === targetId)) {
      return item;
    }
    const foundInChildren = findParent(item.children, targetId);
    if (foundInChildren) return foundInChildren;
  }
  return null;
};

export const resetItemsRecursively = (items: LocationItem[]): LocationItem[] => {
  return items.map((item) => ({
    ...item,
    checked: false,
    indeterminate: false,
    children: resetItemsRecursively(item.children)
  }));
};
