import { useSelector } from 'react-redux';

import { ReduxState, selectUserData } from '@/logicLayers/infrastructure/redux';

export const PERMISSION_TAGS = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
  ROLE_COMPANIES_VIEWING: 'ROLE_COMPANIES_VIEWING',
  ROLE_COMPANIES_CREATING: 'ROLE_COMPANIES_CREATING',
  ROLE_COMPANIES_UPDATING: 'ROLE_COMPANIES_UPDATING',
  ROLE_COMPANIES_DELETING: 'ROLE_COMPANIES_DELETING'
};

export const useIsUserCanCRUD = ({ permissionsCheck }: { permissionsCheck: string[] }) => {
  const { profile } = useSelector(selectUserData);

  const isUserExist = Boolean(profile.id);

  const isUserSuperAdmin = profile.permissions.find((item) => item?.name === PERMISSION_TAGS.ROLE_SUPER_ADMIN);
  const isUserCanCrudData = permissionsCheck.some((i) => {
    return profile.permissions.find((item) => item?.name === i);
  });

  return isUserExist && (Boolean(isUserSuperAdmin) || Boolean(isUserCanCrudData));
};

export const useIsUserSuperAdmin = (): boolean => {
  const profile = useSelector((state: ReduxState) => state.user.profile);

  const isUserExist = Boolean(profile.id);
  const isUserSuperAdmin = profile.permissions?.find((item) => item?.name === PERMISSION_TAGS.ROLE_SUPER_ADMIN);

  return isUserExist && Boolean(isUserSuperAdmin);
};

export const useIsUserCompanyAdmin = (): boolean => {
  const { profile } = useSelector(selectUserData);

  const isUserExist = Boolean(profile.id);
  const isUserCompanyAdmin = [
    PERMISSION_TAGS.ROLE_COMPANIES_VIEWING,
    PERMISSION_TAGS.ROLE_COMPANIES_CREATING,
    PERMISSION_TAGS.ROLE_COMPANIES_UPDATING,
    PERMISSION_TAGS.ROLE_COMPANIES_DELETING
  ].every((permission) => profile.permissions.some((perm) => perm?.name === permission));
  const isUserNotSuperAdmin = !profile.permissions.some((perm) => perm?.name === PERMISSION_TAGS.ROLE_SUPER_ADMIN);

  return isUserExist && isUserCompanyAdmin && isUserNotSuperAdmin;
};
