import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LocationsIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path
        d="M3.23668 17.1478C2.8852 17.1478 2.58633 17.0246 2.34008 16.7784C2.09383 16.5321 1.9707 16.2333 1.9707 15.8818V3.83045C1.9707 3.47897 2.09383 3.18009 2.34008 2.93383C2.58633 2.68758 2.8852 2.56445 3.23668 2.56445H8.62133C8.97281 2.56445 9.27169 2.68758 9.51795 2.93383C9.7642 3.18009 9.88733 3.47897 9.88733 3.83045V5.89779H16.7624C17.1138 5.89779 17.4127 6.02091 17.659 6.26716C17.9052 6.51343 18.0283 6.8123 18.0283 7.16379V15.8818C18.0283 16.2333 17.9052 16.5321 17.659 16.7784C17.4127 17.0246 17.1138 17.1478 16.7624 17.1478H3.23668ZM3.22066 15.8978H8.63735V13.8144H3.22066V15.8978ZM3.22066 12.5645H8.63735V10.4811H3.22066V12.5645ZM3.22066 9.23112H8.63735V7.14777H3.22066V9.23112ZM3.22066 5.89779H8.63735V3.81443H3.22066V5.89779ZM9.88733 15.8978H16.7784V7.14777H9.88733V15.8978ZM12.3392 10.4811C12.1622 10.4811 12.0137 10.4212 11.894 10.3013C11.7742 10.1815 11.7143 10.033 11.7143 9.85583C11.7143 9.67866 11.7742 9.53025 11.894 9.4106C12.0137 9.29095 12.1622 9.23112 12.3392 9.23112H14.1662C14.3433 9.23112 14.4917 9.29104 14.6115 9.41089C14.7313 9.53072 14.7912 9.67922 14.7912 9.85639C14.7912 10.0335 14.7313 10.182 14.6115 10.3016C14.4917 10.4213 14.3433 10.4811 14.1662 10.4811H12.3392ZM12.3392 13.8144C12.1622 13.8144 12.0137 13.7545 11.894 13.6347C11.7742 13.5148 11.7143 13.3663 11.7143 13.1892C11.7143 13.012 11.7742 12.8636 11.894 12.7439C12.0137 12.6243 12.1622 12.5645 12.3392 12.5645H14.1662C14.3433 12.5645 14.4917 12.6244 14.6115 12.7442C14.7313 12.8641 14.7912 13.0126 14.7912 13.1897C14.7912 13.3669 14.7313 13.5153 14.6115 13.635C14.4917 13.7546 14.3433 13.8144 14.1662 13.8144H12.3392Z"
        fill="#3C4656"
      />
    </SvgIcon>
  );
}
