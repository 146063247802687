import { gql as gqlApollo } from '@apollo/client';

export const CREATE_DOMAIN = gqlApollo(`
  mutation CreateDomain($name: String!) {
    createDomain(name: $name) {
      id
      name
    }
  }
`);

export const UPDATE_DOMAIN = gqlApollo(`
  mutation UpdateDomain($id: String!, $name: String!) {
    updateDomain(id: $id, name: $name) {
      id
      name
    }
  }
`);

export const SEARCH_DOMAINS = gqlApollo(`
  query SearchDomains($searchCriteria: SearchCriteriaInput) {
    dataItems: searchDomains(searchCriteria: $searchCriteria) {
      items {
        id
        name
        color
        lastModifiedDate
        itemsCount
        active
        archived
      }
      total
    }
  }
`);

export const GET_DOMAIN_BY_ID = gqlApollo(`
  query Domain($id: String!) {
    domain(id: $id) {
      name
    }
  }
`);

export const SWITCH_DOMAIN_ACTIVE_STATE = gqlApollo(`
  mutation SwitchDomainActiveState($request: SwitchActiveStateRequestInput!) {
    switchDomainActiveState(request: $request)
  }
`);

export const SWITCH_DOMAIN_ARCHIVE_STATE = gqlApollo(`
  mutation SwitchDomainArchiveState($request:  SwitchArchiveStateRequestInput!) {
    switchDomainArchiveState(request: $request)
  }
`);
