import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Button, Text } from 'infinitytechnologies-ui';

import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  CloseIcon,
  Flex,
  handleGetSavedColumns,
  InfoIcon,
  MuiTablePagination,
  NotFoundScreens,
  PlusIcon,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownCheckbox,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { AsideContent } from './components/AsideContent';

import { ROUND_PARAMETERS, ROUND_SELECTED_PARAMETERS } from '../../helpers';
import {
  columns,
  initState,
  ParametersTypesList,
  ParametersVisibilityList,
  skeletonTableLoaderCells,
  useTableData
} from './helpers';

export const Step3 = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();
  const TABLE_SAVED_COLUMNS_ID = `templates/equipment/item/${templateId}/parameters`;

  const { renderCount, ...tableState } = useTableData(initState, templateId);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const handleClick = () => {
    templateId && navigateTo(LINKS_PAGES.templatesParametersCreate.replace(':templateId', templateId));
  };

  const { control, watch, setValue } = useFormContext();

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const selectedParameters = watch(ROUND_PARAMETERS);

  return (
    <Controller
      name={ROUND_PARAMETERS}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <>
            <Grid justifyContent={'center'} container row sx={{ marginTop: '48px' }}>
              <Grid xs={12} item>
                <TablePageTopBox
                  title={'Select parameters'}
                  isStep
                  btnText={tTemplates('equipmentItem.parameters.btnCreateParameter')}
                  onClickBtn={handleClick}
                />
                <Text
                  sx={{
                    maxWidth: '630px',
                    fontSize: '14px',
                    color: 'var(--text-subtle, #505668)',
                    fontWeight: '400',
                    marginBottom: '24px'
                  }}
                >
                  Choose the parameters that you want to include in the round inspection
                </Text>

                <TableFiltersBox
                  searchPlaceholder={tTemplates('equipmentItem.parameters.table.filters.searchBy.placeholder')}
                  selectedItems={selectedParameters}
                  handleToggleAside={tableState.handleToggleAside}
                  handleChangeBySearch={tableState.handleChangeBySearch}
                >
                  <TableSortByOneItem
                    // ToDo Refactor
                    title={'Visibility'}
                    // @ts-ignore
                    menu={ParametersVisibilityList}
                    onChange={(value) => {
                      tableState.handleChangeByType(value, 'type2');
                    }}
                  />

                  <TableDropdownCheckbox
                    // ToDo Refactor
                    title={'Type'}
                    menuList={ParametersTypesList}
                    withSelectAll
                    withSearch
                    onChange={(value) => {
                      tableState.handleChangeByType(value, 'type1');
                    }}
                  />

                  <TableSortByOneItem
                    menu={SORT_BY_DATE_CREATE}
                    showIcon={true}
                    onChange={tableState.handleChangeSortByDate}
                  />
                </TableFiltersBox>

                {tableState.loading ? (
                  <SkeletonTableLoader cells={skeletonTableLoaderCells} />
                ) : (
                  <>
                    {renderCount ? (
                      <Flex>
                        <Box width={'100%'}>
                          <Box sx={{ overflow: 'auto', maxHeight: '400px' }}>
                            <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                              {Boolean(selectedParameters.length) && (
                                <Flex
                                  sx={{
                                    width: '100%',
                                    height: '32px',
                                    background: 'var(--background-neutral-pressed, #F1F2F4)',
                                    borderRadius: '4px',
                                    padding: '0px 4px',
                                    marginBottom: '16px'
                                  }}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Flex alignItems="center">
                                    <IconButton
                                      size={'medium'}
                                      className={'aside-table__btn__close'}
                                      onClick={() => {
                                        onChange([]);
                                        setValue(ROUND_SELECTED_PARAMETERS, []);
                                        tableState.handleClearSelectedItems();
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <Text sx={{ fontSize: '14px', marginLeft: '16px' }}>
                                      {selectedParameters.length} selected
                                    </Text>
                                  </Flex>
                                  <IconButton
                                    size={'medium'}
                                    sx={{ borderRadius: '100%' }}
                                    onClick={tableState.handleToggleAside}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Flex>
                              )}
                              <Table className={'m-0 MuiTable-root__columns-3'}>
                                <TableHead>
                                  {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                      {headerGroup.headers.map((header) => (
                                        <TableCell key={header.id}>
                                          {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableHead>

                                <TableBody>
                                  {table.getRowModel().rows.map((row) => {
                                    return (
                                      <TableRowWrap
                                        key={row.original.id}
                                        id={row.original.id as string}
                                        isSelected={selectedParameters.some(
                                          (param: { id: string }) => param.id === row.original.id
                                        )}
                                        handleClickRow={(rowId) => {
                                          const parameter = theData.find((data) => data?.id === rowId);

                                          const inSelectedExist = selectedParameters.some(
                                            (param: { id: string }) => param.id === rowId
                                          );

                                          const updatedSelectedItems = inSelectedExist
                                            ? selectedParameters.filter(
                                                (selectedParam: { id: string }) => selectedParam.id !== rowId
                                              )
                                            : [parameter, ...selectedParameters];

                                          const updatedSelectedIds = updatedSelectedItems.map(
                                            (item: { id: string }, index: number) => ({
                                              id: item.id,
                                              uploadPhoto: false,
                                              addNote: false,
                                              order: index
                                            })
                                          );

                                          setValue(ROUND_SELECTED_PARAMETERS, updatedSelectedIds);

                                          onChange(updatedSelectedItems);
                                        }}
                                        handleDoubleClickRow={tableState.handleDoubleClickRow}
                                      >
                                        {row.getVisibleCells().map((cell) => {
                                          return (
                                            <TableCell key={cell.id}>
                                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRowWrap>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>

                          <MuiTablePagination
                            count={renderCount}
                            page={tableState.state.page[0]}
                            rowsPerPage={tableState.state.rowsPerPage[0]}
                            rowsPerPageOptions={[20, 50, 100]}
                            onChangePage={tableState.handleChangePagination}
                            onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                          />
                        </Box>

                        <TableAside
                          rows={table.getRowModel().rows}
                          selectedItems={selectedParameters.map(
                            (selectedParameter: { id: number }) => selectedParameter.id
                          )}
                          isAsideOpen={tableState.state.isAsideOpen}
                          handleToggleAside={tableState.handleToggleAside}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            wordBreak: 'break-all'
                          }}
                        >
                          {(props: any) => (
                            <AsideContent
                              handleChangeTest={() => {
                                navigateTo(
                                  `/${TABLE_SAVED_COLUMNS_ID.replace('/parameters', '/parameter')}/${selectedParameters[0]}`
                                );
                              }}
                              {...props}
                            />
                          )}
                        </TableAside>
                      </Flex>
                    ) : tableState.state.filters.search[0] ? (
                      <Box sx={{ height: '59vh' }}>
                        <NotFoundScreens
                          type={'search'}
                          title={`${tableState.state.filters.search[0]} not found`}
                          subTitle="Want to create new parameter?"
                          afterContent={
                            <Button
                              className={'m-0'}
                              variant={'outlined'}
                              startIcon={<PlusIcon />}
                              onClick={handleClick}
                              sx={{ border: 'none !important', width: '100%' }}
                            >
                              Create parameter
                            </Button>
                          }
                        />
                      </Box>
                    ) : (
                      <Box sx={{ height: '59vh' }}>
                        <NotFoundScreens
                          type={'companies'}
                          title={tTemplates('equipmentItem.parameters.notFound.title')}
                          subTitle={tTemplates('equipmentItem.parameters.notFound.subTitle')}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};
