import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Box, Grid } from '@mui/material';

import { AddParameter } from './components/AddParameter';
import { SelectedParametersList } from './components/SelectedParametersList';

interface Step2Props {
  selectedItems: any[];
  onChange: any;
  value: any;
  onChangeParameters: any;
}

export const Step2 = ({ selectedItems, value, onChange, onChangeParameters }: Step2Props) => {
  const [parameters, setParameters] = useState(selectedItems);
  const [correctParameters, setCorrectParameters] = useState(value);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const newItems = [...parameters];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setParameters(newItems.map((item, index) => ({ ...item, order: index })));

    const newCorrectItems = [...correctParameters];
    const [movedCorrectItem] = newCorrectItems.splice(result.source.index, 1);
    newCorrectItems.splice(result.destination.index, 0, movedCorrectItem);

    setCorrectParameters(newCorrectItems.map((item, index) => ({ ...item, order: index })));
  };

  useEffect(() => {
    onChangeParameters(parameters);
    onChange(correctParameters);
  }, [parameters]);

  return (
    <>
      <Grid justifyContent={'center'} container row mb={'77px'} sx={{ marginTop: '48px' }}>
        <AddParameter />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-parameters">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                {parameters.map((parameter, index) => (
                  <Draggable key={parameter.id} draggableId={parameter.id} index={index}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <SelectedParametersList
                          key={parameter.id}
                          parameter={parameter}
                          parameters={selectedItems}
                          selectedParameters={value}
                          setValue={onChange}
                          onChangeParameters={onChangeParameters}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </>
  );
};
