import { toPng } from 'html-to-image';
import JSZip from 'jszip';

import { i18n } from '@/i18n/config';

import { qrCode } from '../../../Providers/helpers';

export const generateQRCodeWithText = async (qrValue: string, size: number, image: string): Promise<string> => {
  qrCode.update({
    data: qrValue,
    width: size,
    height: size,
    image,
    margin: 6,
    dotsOptions: {
      type: 'square'
    },
    backgroundOptions: {
      color: 'transparent'
    }
  });

  const qrBlob = await qrCode.getRawData('png');

  if (!qrBlob) {
    throw new Error('Failed to generate QR code blob.');
  }

  const qrImage = await loadImageFromBlob(qrBlob);

  // Вычисляем padding как 10% от общего размера
  const padding = size * 0.1;
  // Новая логика: холст имеет размер size, а QR-код отрисовывается с учетом отступов
  const qrDrawSize = size - padding * 2;

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get canvas context.');
  }

  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const x = padding;
  const y = padding;
  ctx.drawImage(qrImage, x, y, qrDrawSize, qrDrawSize);

  const qrDataUrl = canvas.toDataURL('image/png');

  return qrDataUrl;
};

const loadImageFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(blob);
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.onerror = (e) => {
      URL.revokeObjectURL(url);
      reject(e);
    };
    img.src = url;
  });
};

export const createSticker = async (item: any, size: number, breadcrumbsLinks: any[]): Promise<string> => {
  const stickerContainer = document.createElement('div');
  stickerContainer.style.backgroundColor = 'white';
  stickerContainer.style.padding = '17px';
  stickerContainer.style.width = `${size}px`;
  stickerContainer.style.height = `${size}px`;
  stickerContainer.style.display = 'flex';
  stickerContainer.style.flexDirection = 'column';
  stickerContainer.style.justifyContent = 'space-between';
  stickerContainer.style.border = '2px solid black';

  const titleFontSize = size * 0.0877;
  const spanFontSize = Math.max(0, titleFontSize - 2);

  stickerContainer.innerHTML = `
  <div>
    <h1 
      style="
        font-size: ${titleFontSize}px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
      "
    >
      ${item?.name}
    </h1>
    
    <div 
      style="
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        margin-top: 4px;
      "
    >
      ${breadcrumbsLinks
        .map(({ titleFallback }, index) => {
          const isFirstItem = index === 0;
          return `
            <span style="font-size: ${spanFontSize}px;">
              ${isFirstItem ? '' : ' > '} 
              ${titleFallback}
            </span>
          `;
        })
        .join('')}
    </div>
  </div>
  
  <div>
    <h1 style="font-size: ${titleFontSize}px;">System name</h1>
    <div style="margin-top: 4px;"> 
      <span style="font-size: ${spanFontSize}px;">${item?.systemName}</span>
    </div>
  </div>
`;

  stickerContainer.style.boxSizing = 'border-box';
  document.body.appendChild(stickerContainer);
  const stickerPngData = await toPng(stickerContainer, { quality: 1, pixelRatio: 1 });

  document.body.removeChild(stickerContainer);

  return stickerPngData;
};

export const addFilesToZip = (
  zip: JSZip,
  folderName: string,
  qrData: string,
  stickerData: string,
  fileName: string
) => {
  const folder = zip.folder(folderName || '');
  folder?.file(`QR_${fileName}.png`, qrData.split(',')[1], { base64: true });
  folder?.file(`SCR_${fileName}.png`, stickerData.split(',')[1], { base64: true });
};

export function getBreadcrumbs(locationFolder: any) {
  const breadcrumbs = [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  return breadcrumbs;
}
