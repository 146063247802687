import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { PageTitle, PlusIcon } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { useContextState } from '../Providers';

export const PageTopBox = () => {
  const location = useLocation();

  const isMainRolePage = location.pathname === '/role';

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_PERMISSIONS_CREATING', 'ROLE_PERMISSIONS_UPDATING']
  });

  const { t: tTemplates } = useTranslation('templates');

  const navigateTo = useNavigate();

  const state = useContextState();

  const handleCreateRole = () => {
    navigateTo(LINKS_PAGES.roleCreate, { state: { isMainRolePage } });
  };

  const pageTitle = isMainRolePage ? 'Role' : tTemplates('role.pageTitle');
  const foundTitle = isMainRolePage
    ? ''
    : !state.loadingRoleTemplates && state.resultSearchCount
      ? i18n.t('pageTitleFound', { value: state.resultTotalCount, ns: 'global' })
      : '';

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={pageTitle}
        dataFoundTitle={foundTitle}
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
      />

      <Button
        className={'m-0'}
        variant={'contained'}
        startIcon={<PlusIcon />}
        disableElevation
        onClick={handleCreateRole}
        disabled={!isUserCanCRUD}
      >
        {tTemplates('role.create.btnAdd')}
      </Button>
    </Box>
  );
};
