import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import { PARAMETER_TITLE, PARAMETER_VALUE } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/helpers';

import { TextBlockControl, TextFieldControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { FormulaMainContent } from './FormulaMainContent';

import { useData } from './helpers';

export const Formula = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();

  const {
    setValue,
    setError,
    control,
    watch,
    formState: { errors }
  } = useFormContext();

  const title = watch(PARAMETER_TITLE);

  const { equipmentParametersData } = useData({ templateId });

  const isValidError = errors[PARAMETER_VALUE]?.message;

  return (
    <>
      <TextBlockControl
        title={tTemplates('createParameterItem.parameter.formula.title')}
        ContentAfterTitle={
          <ul style={{ color: 'var(--text-subtle, #505668)' }}>
            <li>
              <Text className={'m-0'} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {tTemplates('createParameterItem.parameter.formula.list.item1')}
              </Text>
            </li>
            <li>
              <Text className={'m-0'} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {tTemplates('createParameterItem.parameter.formula.list.item2')}
              </Text>
            </li>
            <li>
              <Text className={'m-0'} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {tTemplates('createParameterItem.parameter.formula.list.item3')}
              </Text>
            </li>
          </ul>
        }
      />

      <Controller
        name={PARAMETER_VALUE}
        defaultValue={''}
        control={control}
        rules={{
          required: {
            value: true,
            message: tGlobal('validation.required')
          }
        }}
        render={({ field: { value } }) => {
          return <TextFieldControl name={PARAMETER_VALUE} value={value} sx={{ display: 'none' }} />;
        }}
      />

      <FormulaMainContent
        setValue={setValue}
        isValidError={isValidError}
        equipmentParametersData={equipmentParametersData}
        setError={setError}
        title={title?.label || ''}
      />
    </>
  );
};
