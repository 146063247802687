import { useCallback, useState } from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { GetUsersQuery } from '@/logicLayers/domain';

import { RenderWhen } from '@/separatedModules/components';

import { isSuperAdmin } from '@/utils';

import { AddUserButton } from './components/AddUserButton';
import { AddUserModalAdmin, AddUserModalClient } from './components/AddUserModal';

interface AddUserProps {
  refetch?: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetUsersQuery>>;
}

export const AddUser = ({ refetch }: AddUserProps) => {
  const isSuperAdminRole = isSuperAdmin();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <AddUserButton handleOpenModal={handleOpenModal} />

      <RenderWhen condition={!isSuperAdminRole}>
        <AddUserModalClient isModalOpen={isModalOpen} onCloseModal={handleCloseModal} refetch={refetch} />
      </RenderWhen>

      <RenderWhen condition={isSuperAdminRole}>
        <AddUserModalAdmin isModalOpen={isModalOpen} onCloseModal={handleCloseModal} refetch={refetch} />
      </RenderWhen>
    </>
  );
};
