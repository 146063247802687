import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { GetUsersQuery } from '@/logicLayers/domain';

export interface AddUserModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  refetch?: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetUsersQuery>>;
}

export const FIELD_USER_EMAIL = 'userEmail';
export const FIELD_COMPANY_ID = 'companyId';
export const FIELD_COMPANY_ROLE = 'companyRole';
export const FIELD_DOMAIN_IDS = 'domainIds';

export const formatSelectOptions = (items: any[], valueKey: string, labelKey: string) => {
  return items.map((item) => ({
    value: item[valueKey],
    label: item[labelKey]
  }));
};
