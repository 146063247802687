import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GetNotifications,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  SUBSCRIBE_TO_NOTIFICATIONS
} from '@/logicLayers/domain';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { NotificationItem } from './components/NotificationItem';
import { RequestOnHoldModal } from './components/RequestOnHoldModal';

import { NotificationType } from './helpers';

interface NotificationsPopoverProps {
  idPopover: string | undefined;
  anchorEl: HTMLElement | null;
  isOpenPopover: boolean;
  handleClosePopover: () => void;
  setIsNewNotification: (value: boolean) => void;
}

export const NotificationsPopover = ({
  idPopover,
  anchorEl,
  isOpenPopover,
  handleClosePopover,
  setIsNewNotification
}: NotificationsPopoverProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [currentPageSystemNotifications, setCurrentPageSystemNotifications] = useState(0);
  const [hasMoreSystemNotifications, setHasMoreSystemNotifications] = useState(true);
  const [currentPageRequests, setCurrentPageRequests] = useState(0);
  const [hasMoreRequests, setHasMoreRequests] = useState(true);
  const [currentPageFailed, setCurrentPageFailed] = useState(0);
  const [hasMoreFailed, setHasMoreFailed] = useState(true);

  const [tabIndex, setTabIndex] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newIndex: string) => {
    setTabIndex(newIndex);
  };

  const [isOpenRequestOnHoldModal, setIsOpenRequestOnHoldModal] = useState(false);
  const [selectedRequestIds, setSelectedRequestIds] = useState({ resourceId: '', notificationId: '' });

  const {
    data: systemNotificationsData,
    fetchMore: fetchMoreSystemNotifications,
    refetch: refetchSystemNotifications
  } = useQuery<GetNotifications>(GET_NOTIFICATIONS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 20,
          page: 0
        },
        sortable: {
          column: 'createdDate',
          direction: 'DESC'
        }
      },
      types: [
        NotificationType.RoundCreated,
        NotificationType.RoundAssigned,
        NotificationType.RoundCompletedSuccessfully,
        NotificationType.RoundCompletedNoValidation,
        NotificationType.RoundOnHoldApproved,
        NotificationType.RoundOnHoldDeclined,
        NotificationType.RoundOverdue,
        NotificationType.RoundOverdueApproaching,
        NotificationType.RoundServiceStaffNotAssigned,
        NotificationType.Error,
        NotificationType.ROUND_COMPLETED_UNSUCCESSFULLY,
        NotificationType.EQUIPMENT_DISABLED
      ]
    },
    fetchPolicy: 'no-cache'
  });

  const {
    data: requestsData,
    fetchMore: fetchMoreRequests,
    refetch: refetchRequests
  } = useQuery<GetNotifications>(GET_NOTIFICATIONS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 20,
          page: 0
        },
        sortable: {
          column: 'createdDate',
          direction: 'DESC'
        }
      },
      types: [NotificationType.RoundOnHoldRequested]
    },
    fetchPolicy: 'no-cache'
  });

  const {
    data: failedData,
    fetchMore: fetchMoreFailed,
    refetch: refetchFailed
  } = useQuery<GetNotifications>(GET_NOTIFICATIONS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 20,
          page: 0
        },
        sortable: {
          column: 'createdDate',
          direction: 'DESC'
        }
      },
      types: [NotificationType.ROUND_PARAMETER_FAILED]
    },
    fetchPolicy: 'no-cache'
  });

  const { data: newNotification } = useSubscription(SUBSCRIBE_TO_NOTIFICATIONS);

  const [markAllNotificationsAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  useEffect(() => {
    refetchSystemNotifications();
    refetchRequests();
    refetchFailed();
  }, [newNotification]);

  useEffect(() => {
    if (
      systemNotificationsData?.notifications.items.some((notification) => !notification.read) ||
      requestsData?.notifications.items.some((notification) => !notification.read)
    ) {
      setIsNewNotification(true);
    } else {
      setIsNewNotification(false);
    }
  }, [systemNotificationsData, requestsData]);

  const handleMarkAsRead = (id: string) => {
    markNotificationAsRead({ variables: { notificationId: id } });
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  const handleRemove = (id: string) => {
    deleteNotification({ variables: { notificationId: id } });
  };

  const fetchMoreSystemNotificationsData = () => {
    fetchMoreSystemNotifications({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPageSystemNotifications + 1,
            pageSize: 20
          },
          sortable: {
            column: 'createdDate',
            direction: 'DESC'
          }
        },
        types: [
          NotificationType.RoundCreated,
          NotificationType.RoundAssigned,
          NotificationType.RoundCompletedSuccessfully,
          NotificationType.RoundCompletedNoValidation,
          NotificationType.RoundOnHoldApproved,
          NotificationType.RoundOnHoldDeclined,
          NotificationType.RoundOverdue,
          NotificationType.RoundOverdueApproaching,
          NotificationType.RoundServiceStaffNotAssigned,
          NotificationType.Error,
          NotificationType.ROUND_COMPLETED_UNSUCCESSFULLY,
          NotificationType.EQUIPMENT_DISABLED
        ]
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setHasMoreSystemNotifications((currentPageSystemNotifications + 1) * 20 < fetchMoreResult.notifications.total);
        setCurrentPageSystemNotifications(currentPageSystemNotifications + 1);

        return {
          notifications: {
            items: [...prev.notifications.items, ...fetchMoreResult.notifications.items],
            total: fetchMoreResult.notifications.total
          }
        };
      }
    });
  };

  const fetchMoreRequestsData = () => {
    fetchMoreRequests({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPageRequests + 1,
            pageSize: 20
          },
          sortable: {
            column: 'createdDate',
            direction: 'DESC'
          }
        },
        types: [NotificationType.RoundOnHoldRequested]
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setHasMoreRequests((currentPageRequests + 1) * 20 < fetchMoreResult.notifications.total);
        setCurrentPageRequests(currentPageRequests + 1);

        return {
          notifications: {
            items: [...prev.notifications.items, ...fetchMoreResult.notifications.items],
            total: fetchMoreResult.notifications.total
          }
        };
      }
    });
  };

  const fetchMoreFailedData = () => {
    fetchMoreFailed({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPageFailed + 1,
            pageSize: 20
          },
          sortable: {
            column: 'createdDate',
            direction: 'DESC'
          }
        },
        types: [NotificationType.ROUND_PARAMETER_FAILED]
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setHasMoreFailed((currentPageFailed + 1) * 20 < fetchMoreResult.notifications.total);
        setCurrentPageFailed(currentPageFailed + 1);

        return {
          notifications: {
            items: [...prev.notifications.items, ...fetchMoreResult.notifications.items],
            total: fetchMoreResult.notifications.total
          }
        };
      }
    });
  };

  const handleDetails = (ids: { resourceId: string; notificationId: string }) => {
    setSelectedRequestIds(ids);
    setIsOpenRequestOnHoldModal(true);
    handleClosePopover();
  };

  return (
    <>
      <Popover
        id={idPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={isOpenPopover}
        onClose={handleClosePopover}
      >
        <Box
          sx={{ padding: '4px 0', margin: '0 0 8px', width: '450px', height: '600px', overflowY: 'scroll' }}
          id="scrollableDiv"
        >
          <Box
            sx={{
              height: '56px',
              padding: '12px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid rgba(41, 49, 62, 0.15)',
              position: 'sticky',
              top: '-4px',
              zIndex: '1',
              background: '#FFFFFF',
              marginTop: '-8px'
            }}
          >
            <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{tGlobal('notifications.title')}</Text>
            <Button variant={'text'} sx={{ padding: '0', color: '#265299 !important' }} onClick={handleMarkAllAsRead}>
              {tGlobal('notifications.btnMarkAllAsRead')}
            </Button>
          </Box>
          <TabContext value={tabIndex}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab
                label="System notifications"
                value="1"
                sx={{
                  fontSize: '14px',
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: tabIndex === '1' ? '#121B2A' : '#505864',
                  '&.Mui-selected': {
                    backgroundColor: '#FFFFFF',
                    textTransform: 'none',
                    color: '#121B2A'
                  }
                }}
              />
              <Tab
                label="Requests"
                value="2"
                sx={{
                  fontSize: '14px',
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: tabIndex === '2' ? '#121B2A' : '#505864',
                  '&.Mui-selected': {
                    backgroundColor: '#FFFFFF',
                    textTransform: 'none',
                    color: '#121B2A'
                  }
                }}
              />
              <Tab
                label="Failed round"
                value="3"
                sx={{
                  fontSize: '14px',
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: tabIndex === '2' ? '#121B2A' : '#505864',
                  '&.Mui-selected': {
                    backgroundColor: '#FFFFFF',
                    textTransform: 'none',
                    color: '#121B2A'
                  }
                }}
              />
            </Tabs>
            <TabPanel value="1" sx={{ padding: '0px' }}>
              <Box>
                {systemNotificationsData?.notifications.items.length ? (
                  <Box sx={{ height: '100%' }}>
                    <InfiniteScroll
                      dataLength={systemNotificationsData?.notifications?.items.length ?? 0}
                      next={fetchMoreSystemNotificationsData}
                      hasMore={hasMoreSystemNotifications}
                      loader={null}
                      scrollableTarget={'scrollableDiv'}
                    >
                      <>
                        {systemNotificationsData?.notifications.items.map((notification) => (
                          <NotificationItem
                            key={notification.id}
                            notification={notification}
                            handleMarkAsRead={handleMarkAsRead}
                            handleRemove={handleRemove}
                          />
                        ))}
                      </>
                    </InfiniteScroll>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '544px'
                    }}
                  >
                    <img src={LINKS_IMAGES.notFoundSearch} alt="No notifications" />
                    <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>
                      {tGlobal('notifications.noNotifications')}
                    </Text>
                    <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
                      {tGlobal('notifications.noNotificationsDescription')}
                    </Text>
                  </Box>
                )}
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '0px' }}>
              <Box>
                {requestsData?.notifications.items.length ? (
                  <Box sx={{ height: '100%' }}>
                    <InfiniteScroll
                      dataLength={requestsData?.notifications?.items.length ?? 0}
                      next={fetchMoreRequestsData}
                      hasMore={hasMoreRequests}
                      loader={null}
                      scrollableTarget={'scrollableDiv'}
                    >
                      <>
                        {requestsData?.notifications.items.map((notification) => (
                          <NotificationItem
                            key={notification.id}
                            notification={notification}
                            handleMarkAsRead={handleMarkAsRead}
                            handleDetails={handleDetails}
                          />
                        ))}
                      </>
                    </InfiniteScroll>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '544px'
                    }}
                  >
                    <img src={LINKS_IMAGES.notFoundSearch} alt="No notifications" />
                    <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>
                      {tGlobal('notifications.noNotifications')}
                    </Text>
                    <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
                      {tGlobal('notifications.noNotificationsDescription')}
                    </Text>
                  </Box>
                )}
              </Box>
            </TabPanel>
            <TabPanel value="3" sx={{ padding: '0px' }}>
              <Box>
                {failedData?.notifications.items.length ? (
                  <Box sx={{ height: '100%' }}>
                    <InfiniteScroll
                      dataLength={failedData?.notifications?.items.length ?? 0}
                      next={fetchMoreFailedData}
                      hasMore={hasMoreFailed}
                      loader={null}
                      scrollableTarget={'scrollableDiv'}
                    >
                      <>
                        {failedData?.notifications.items.map((notification) => (
                          <NotificationItem
                            key={notification.id}
                            notification={notification}
                            handleMarkAsRead={handleMarkAsRead}
                            handleDetails={() => {
                              navigateTo(
                                `/tasks/${notification.details?.roundId}/task?parameterId=${notification.details?.parameterId}`
                              );
                              handleClosePopover();
                            }}
                          />
                        ))}
                      </>
                    </InfiniteScroll>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '544px'
                    }}
                  >
                    <img src={LINKS_IMAGES.notFoundSearch} alt="No notifications" />
                    <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>
                      {tGlobal('notifications.noNotifications')}
                    </Text>
                    <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
                      {tGlobal('notifications.noNotificationsDescription')}
                    </Text>
                  </Box>
                )}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Popover>
      <RequestOnHoldModal
        isOpen={isOpenRequestOnHoldModal}
        onClose={() => setIsOpenRequestOnHoldModal(false)}
        handleRemove={handleRemove}
        requestIds={selectedRequestIds}
      />
    </>
  );
};
