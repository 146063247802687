import { FC, ReactNode, useEffect, useState } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { Box, IconButton } from '@mui/material';

import { CloseIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { ActionNodeIconButton, ManagementWrapper } from './style';

interface ActionNode {
  node: ReactNode;
  disabled?: boolean;
}

interface TableManagementProps {
  count?: number;
  actionNodeList?: ActionNode[];
  open?: boolean;
  onClickInfo: () => void;
  onOpen?: (isOpen: boolean) => void;
  onClose?: () => void;
}

export const TableManagement: FC<TableManagementProps> = ({
  open = false,
  count = 0,
  actionNodeList,
  onClose,
  onOpen
}) => {
  const { t: tGlobal } = useTranslation('global');

  const [isOpen, setIsOpen] = useState(open);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  useEffect(() => {
    onOpen?.(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <ManagementWrapper
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      flex={isOpen ? 1 : 0}
      isOpen={isOpen}
      data-testid="table-management-wrapper"
    >
      {isOpen ? (
        <Box display={'flex'} alignItems={'center'} data-testid="table-open-wrapper">
          <IconButton size={'small'} onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          <Box px={'16px'}>
            <Text variant={'bodyMd'} color={'#090E16'} sx={{ mb: 0 }}>
              {tGlobal('table.management.counter', {
                count
              })}
            </Text>
          </Box>

          {actionNodeList
            ? actionNodeList.map((actionNode, index) => (
                <ActionNodeIconButton key={index} size={'small'} disabled={actionNode.disabled}>
                  {actionNode.node}
                </ActionNodeIconButton>
              ))
            : null}
        </Box>
      ) : null}
    </ManagementWrapper>
  );
};
