import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Divider, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import {
  EquipmentRes,
  GET_EQUIPMENT,
  GET_EQUIPMENT_ROUND_REGULATION,
  GET_USER_PROFILE,
  GetProfileQuery,
  RoundRegulationQuery
} from '@/logicLayers/domain';
import { RoundRegulationsQuery, SWITCH_EQUIPMENTS_ACTIVE_STATE } from '@/logicLayers/domain';
import { GET_TASKS } from '@/logicLayers/domain/tasks';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  ArrowBackIcon,
  Breadcrumbs,
  ChevronRightIcon,
  CloseIcon,
  DomainStatusChip,
  EditItemHover,
  EMPTY_CELL_VALUE,
  Flex,
  ImageLazy,
  MoreVertIcon,
  Preloader,
  PreloaderVariantsE,
  ScheduleIcon,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';
import { CallIcon } from '@/separatedModules/components/Icon/svg-mui-theme/Call';
import { NotFoundCompanies } from '@/separatedModules/components/Icon/svg-mui-theme/NotFoundCompanies';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_IMAGES, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { RowActionMenu } from '../../../Equipment/components/TableBody/components/RowActionMenu';

import { MobileTaskItem } from './component/MobileTaskItem';
import { TaskPreviewMobile } from './components/TaskPreviewMobile';

import { getBreadcrumbs, useQueryData } from './helpers';

// TODO: Refactor this component

export const TemplatesEquipmentItemPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const { templateId = '', taskId = '' } = useParams();
  const navigateTo = useNavigate();
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');
  const { profile } = useReduxSelector(selectUserData);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const [tabIndex, setTabIndex] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newIndex: string) => {
    setTabIndex(newIndex);
  };

  const [currentPageTasks, setCurrentPageTasks] = useState(0);
  const [hasMoreTasks, setHasMoreTasks] = useState(true);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const { dataEquipmentTemplate, loadingEquipmentTemplate } = useQueryData({ templateId });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const {
    data: dataTasks,
    loading: loadingTasks,
    fetchMore
  } = useQuery<RoundRegulationsQuery>(GET_TASKS, {
    variables: {
      searchCriteria: {
        pageable: {
          page: 0,
          pageSize: 20
        }
      },
      type: 'ROUND',
      equipmentIds: [templateId],
      serviceStaffIds: [profile?.id],
      statuses: ['PENDING', 'IN_PROGRESS', 'ON_HOLD', 'ON_HOLD_REQUESTED']
    },
    fetchPolicy: 'no-cache'
  });

  const [requestSwitchActive] = useMutation(SWITCH_EQUIPMENTS_ACTIVE_STATE, {
    refetchQueries: [GET_TASKS]
  });

  const handleEquipmentEnable = () => {
    requestSwitchActive({
      variables: {
        request: {
          ids: [templateId],
          active: true
        }
      },
      refetchQueries: [GET_EQUIPMENT],
      onCompleted: () => {
        setDrawerOpen(false);
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.equipmentEnabled'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const handleEditItem = (toPage: string) => {
    const link = isEquipmentTemplate ? LINKS_PAGES.templatesEquipmentItemEdit : LINKS_PAGES.equipmentModelEdit;

    navigateTo({
      pathname: link.replace(':templateId', templateId),
      search: `field=${toPage}`
    });
  };

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const handleEditName = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('name');
  const handleEditItemDescription = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('itemDescription');
  const handleEditTemplateDescription = () =>
    dataEquipmentTemplate?.equipment?.active && handleEditItem('templateDescription');
  const handleEditModelNumber = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('modelNumber');
  const handleEditManufacturer = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('manufacturer');
  const handleEditDomain = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('domainId');
  const handleEditEmergencyContact = () =>
    dataEquipmentTemplate?.equipment?.active && handleEditItem('emergencyContact');

  const { data: dataEquipmentTemplateRoundRegulation } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId: taskId || undefined,
        searchCriteria: {}
      },
      skip: !taskId
    }
  );

  const fetchMoreDataTasks = () => {
    fetchMore({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPageTasks + 1,
            pageSize: 20
          }
        },
        type: 'ROUND',
        equipmentIds: [templateId]
      },
      updateQuery: (
        prev: RoundRegulationsQuery,
        { fetchMoreResult }: { fetchMoreResult: RoundRegulationsQuery }
      ): RoundRegulationsQuery => {
        if (!fetchMoreResult) return prev;
        setHasMoreTasks((currentPageTasks + 1) * 20 < fetchMoreResult.dataItems.total);
        setCurrentPageTasks(currentPageTasks + 1);

        return {
          dataItems: {
            items: [...prev.dataItems.items, ...fetchMoreResult.dataItems.items],
            total: fetchMoreResult.dataItems.total
          }
        };
      }
    });
  };

  const renderItemId = Boolean(dataEquipmentTemplate?.equipment?.id);
  const renderItemActive = Boolean(dataEquipmentTemplate?.equipment?.active);
  const renderItemTemplateDescription = dataEquipmentTemplate?.equipment?.templateDescription || EMPTY_CELL_VALUE;
  const renderItemItemDescription = dataEquipmentTemplate?.equipment?.itemDescription || EMPTY_CELL_VALUE;
  const renderItemDomain = dataEquipmentTemplate?.equipment?.domain;
  const renderItemManufacturer = dataEquipmentTemplate?.equipment?.manufacturer || EMPTY_CELL_VALUE;
  const renderItemModelNumber = dataEquipmentTemplate?.equipment?.modelNumber || EMPTY_CELL_VALUE;
  const renderItemSystemName = dataEquipmentTemplate?.equipment?.systemName || EMPTY_CELL_VALUE;
  const renderItemName = dataEquipmentTemplate?.equipment?.name;
  const renderItemResponsible = dataEquipmentTemplate?.equipment?.responsible;
  const renderItemLocation = dataEquipmentTemplate?.equipment?.location;
  const renderItemCreatedBy = dataEquipmentTemplate?.equipment?.createdBy;
  const renderItemPhone = renderItemResponsible?.phoneNumber;
  const renderTaskItemName = dataEquipmentTemplateRoundRegulation?.dataItems?.name;

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { titleFallback: renderItemName }
  ];

  const breadcrumbsItemLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    {
      link: `${LINKS_PAGES.locations}${renderItemLocation ? `/${renderItemLocation.id}` : ''}`,
      titleFallback: renderItemLocation ? renderItemLocation.name : tTemplates('equipment.pageItemAllLocations')
    },
    { titleFallback: renderItemName }
  ];

  const handleCopy = () => {
    navigator.clipboard.writeText(renderItemSystemName);

    AlertService.showAlert({
      title: tGlobal('alertMessages.success.copied'),
      severity: 'success'
    });
  };

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsLinks : breadcrumbsItemLinks;

  const isEmptyTasks = !dataTasks?.dataItems?.items?.length;

  const handleCall = () => {
    window.location.href = `tel:${renderItemPhone}`;
  };

  return loadingEquipmentTemplate || loadingTasks ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : isSmallScreen || isTablet ? (
    <Box>
      <TabContext value={tabIndex}>
        <Box sx={{ position: 'sticky', top: '0px', zIndex: '1000', backgroundColor: '#FFFFFF' }}>
          <Flex alignItems="center" padding="12px 16px">
            <IconButton onClick={() => navigateTo('/')}>
              <ArrowBackIcon />
            </IconButton>
            <Text
              component={'h2'}
              variant={'titleXl'}
              sx={{ fontSize: '18px', fontWeight: 600, margin: '0', marginLeft: '8px' }}
            >
              {taskId ? renderTaskItemName : renderItemName}
            </Text>
          </Flex>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              position: 'relative',
              padding: '0px',
              margin: '0px',
              borderBottom: '1px solid var(--background-disabled, rgba(41, 49, 62, 0.15))'
            }}
          >
            <Tab
              label={taskId ? 'Task' : 'Tasks'}
              value="1"
              sx={{
                width: '50%',
                fontSize: '16px',
                backgroundColor: '#FFFFFF',
                textTransform: 'none',
                color: '#505864',
                '&.Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: '#121B2A'
                }
              }}
            />
            <Tab
              label="Additional info"
              value="2"
              sx={{
                width: '50%',
                fontSize: '16px',
                backgroundColor: '#FFFFFF',
                textTransform: 'none',
                color: '#505864',
                '&.Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: '#121B2A'
                }
              }}
            />
          </Tabs>
        </Box>

        <TabPanel value="1" sx={{ padding: '0px !important' }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: '744px',
              padding: taskId ? '0px' : '0px 16px 96px 16px',
              position: isEmptyTasks ? 'absolute' : 'static',
              top: isEmptyTasks ? '35%' : 'auto'
            }}
          >
            {!isEmptyTasks ? (
              <>
                {taskId ? (
                  <TaskPreviewMobile />
                ) : (
                  <InfiniteScroll
                    dataLength={dataTasks?.dataItems.items.length ?? 0}
                    next={fetchMoreDataTasks}
                    hasMore={hasMoreTasks}
                    loader={null}
                  >
                    <>
                      {dataTasks?.dataItems?.items?.map((item) => {
                        return <MobileTaskItem key={item.id} item={item} isFromEquipment />;
                      })}
                    </>
                  </InfiniteScroll>
                )}
              </>
            ) : (
              <Flex
                sx={{
                  height: '100%',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}
              >
                <NotFoundCompanies />
                <Text sx={{ marginTop: '32px', marginBottom: '16px', fontWeight: 600 }}>No tasks yet</Text>
                <Text sx={{ color: '#505864', fontWeight: 400, fontSize: '14px' }}>
                  This equipment has no assigned tasks
                </Text>
              </Flex>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '16px 16px 96px 16px !important' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 0px',
              marginTop: '16px'
            }}
          >
            <SystemStatusChip status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled} />
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MoreVertIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: '16px' }}>
            <Text variant={'labelMd'} sx={{ color: '#505864', fontSize: '12px' }}>
              {tTemplates('equipmentItem.mobile.assignedBy')}
            </Text>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {renderItemCreatedBy?.userAvatar?.url ? (
                <ImageLazy
                  width={24}
                  height={24}
                  src={renderItemCreatedBy.userAvatar?.url}
                  sxStyle={{
                    borderRadius: '16px',
                    border: '1px solid #F7F8F9',
                    position: 'relative'
                  }}
                  alt={'user avatar'}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    background: 'rgb(240, 240, 241)',
                    border: '1px solid #F7F8F9'
                  }}
                />
              )}
              <Text
                className={'m-0'}
                variant={'bodyLg'}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {renderItemCreatedBy?.firstName && renderItemCreatedBy?.lastName
                  ? `${renderItemCreatedBy?.firstName} ${renderItemCreatedBy?.lastName}`
                  : EMPTY_CELL_VALUE}
              </Text>
            </Box>
          </Box>
          <Box sx={{ margin: '16px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
            <Box>
              <Text variant={'labelMd'} sx={{ color: '#505864', fontSize: '12px', marginBottom: '8px' }}>
                {tTemplates('equipmentItem.mobile.emergencyContact')}
              </Text>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                {renderItemResponsible?.userAvatar?.url ? (
                  <ImageLazy
                    width={24}
                    height={24}
                    src={renderItemResponsible.userAvatar?.url}
                    sxStyle={{
                      borderRadius: '16px',
                      border: '1px solid #F7F8F9',
                      position: 'relative'
                    }}
                    alt={''}
                  />
                ) : (
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      flex: '0 0 24px',
                      borderRadius: '50%',
                      background: 'rgb(240, 240, 241)',
                      border: '1px solid #F7F8F9'
                    }}
                  />
                )}
                <Text
                  className={'m-0'}
                  variant={'bodyLg'}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {renderItemResponsible?.firstName && renderItemResponsible?.lastName
                    ? `${renderItemResponsible?.firstName} ${renderItemResponsible?.lastName}`
                    : EMPTY_CELL_VALUE}
                </Text>
              </Box>
            </Box>
            {renderItemPhone && <CallIcon sx={{ width: '20px', height: '20px' }} onClick={handleCall} />}
          </Box>
          <Divider />
          <Box sx={{ marginTop: '16px' }}>
            <Text variant={'labelMd'} sx={{ color: '#505864', fontSize: '12px' }}>
              {tTemplates('equipmentItem.mobile.description')}
            </Text>
            <Text variant={'bodyLg'}>{renderItemItemDescription}</Text>
          </Box>
          <Box sx={{ marginTop: '16px' }}>
            <Text variant={'labelMd'} sx={{ color: '#505864', fontSize: '12px' }}>
              {tTemplates('equipmentItem.mobile.location')}
            </Text>
            <Breadcrumbs
              links={getBreadcrumbs(renderItemLocation)}
              separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
              sx={{ paddingTop: 0 }}
            />
          </Box>
          <Box sx={{ marginTop: '16px' }}>
            <Text variant={'labelMd'} sx={{ color: '#505864', fontSize: '12px' }}>
              {tTemplates('equipmentItem.mobile.systemName')}
            </Text>
            <Text variant={'bodyLg'} sx={{ color: '#265299' }}>
              {renderItemSystemName}
            </Text>
          </Box>
        </TabPanel>
      </TabContext>
      <SwipeableDrawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        PaperProps={{
          sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '32px',
                height: '4px',
                backgroundColor: '#5C6C83',
                opacity: '0.4',
                borderRadius: '100px',
                margin: '16px'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '40px',
              position: 'relative',
              alignItems: 'center'
            }}
          >
            <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
              {tTemplates('equipmentItem.mobile.drawer.title')}
            </Text>
            <CloseIcon
              sx={{
                fontSize: '24px',
                position: 'absolute',
                right: '12px',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
              onClick={() => setDrawerOpen(false)}
            />
          </Box>
          {renderItemActive ? (
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                padding: '8px 16px',
                margin: '16px 0px 53px 0px'
              }}
              onClick={() =>
                navigateTo(
                  `/locations/equipment/${templateId}/requestDisabled?equipmentName=${encodeURIComponent(renderItemName ?? '')}`
                )
              }
            >
              <ScheduleIcon />
              <Text sx={{ fontSize: '16px' }}>{tTemplates('equipmentItem.mobile.drawer.requestDisable')}</Text>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                padding: '8px 16px',
                margin: '16px 0px 53px 0px'
              }}
              onClick={handleEquipmentEnable}
            >
              <ScheduleIcon />
              <Text sx={{ fontSize: '16px' }}>{tTemplates('equipmentItem.mobile.drawer.requestEnable')}</Text>
            </Box>
          )}
        </Box>
      </SwipeableDrawer>
    </Box>
  ) : (
    <Grid justifyContent={'center'} container row>
      {!dataEquipmentTemplate && !loadingEquipmentTemplate ? <Navigate to={LINKS_PAGES.templatesEquipment} /> : null}

      <Grid xs={12} lg={10} xl={8} item>
        <Breadcrumbs
          links={breadcrumbs}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />

        <Text component={'h2'} variant={'titleXl'}>
          {tTemplates('equipmentItem.overview.pageTitle')}
        </Text>

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box
            sx={{
              flex: '0 1 100%',
              maxWidth: {
                md: 'calc(100% - 204px)'
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 0 8px 0' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {renderItemName ? (
                  <EditItemHover
                    sxBoxWrap={{ padding: '0 8px 0 0' }}
                    isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                    onClick={handleEditName}
                  >
                    <Text className={'m-0'} variant={'titleMd'}>
                      {renderItemName}
                    </Text>
                  </EditItemHover>
                ) : null}

                {!isEquipmentTemplate && (
                  <Flex sx={{ marginRight: '16px', cursor: 'pointer' }} onClick={handleCopy}>
                    <Text sx={{ fontSize: '14px', fontWeight: '500' }}>UID:</Text>
                    <Text
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#505668',
                        marginLeft: '4px',
                        marginRight: '7px'
                      }}
                    >
                      {renderItemSystemName}
                    </Text>
                    <ContentCopyIcon sx={{ width: '18px', height: '18px', fill: '#63697C' }} />
                  </Flex>
                )}

                <SystemStatusChip
                  status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled}
                />
              </Box>

              {isShowEditItem && isUserCanCRUD && renderItemId ? (
                <RowActionMenu
                  {...(dataEquipmentTemplate?.equipment as EquipmentRes)}
                  isTableVariant={false}
                  isEquipmentItem={!isEquipmentTemplate}
                  renderModalContent={() => {
                    return (
                      <Flex
                        sx={{
                          borderRadius: 'var(--4, 4px)',
                          background: 'var(--background-neutral-hovered, #F7F8F9)',
                          padding: '8px',
                          margin: '24px 0 0',
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Flex alignItems="center">
                          <Text
                            variant={'labelLg'}
                            m={0}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              wordBreak: 'break-all',
                              maxWidth: '340px'
                            }}
                          >
                            {renderItemName}
                          </Text>
                        </Flex>

                        {renderItemDomain ? (
                          <DomainStatusChip color={renderItemDomain?.color} label={renderItemDomain.name} />
                        ) : (
                          EMPTY_CELL_VALUE
                        )}
                      </Flex>
                    );
                  }}
                />
              ) : null}
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditItemDescription}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                  {renderItemItemDescription}
                </Text>
              </EditItemHover>
            </Box>

            {isEquipmentTemplate && (
              <Box sx={{ margin: '0 0 32px' }}>
                <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.templateDescription')}</Text>

                <EditItemHover
                  isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                  onClick={handleEditTemplateDescription}
                >
                  <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                    {renderItemTemplateDescription}
                  </Text>
                </EditItemHover>
              </Box>
            )}

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.domain')}</Text>

              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditDomain}
              >
                {renderItemDomain ? (
                  <DomainStatusChip color={renderItemDomain?.color} label={renderItemDomain.name} />
                ) : (
                  EMPTY_CELL_VALUE
                )}
              </EditItemHover>
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.modelNumber')}</Text>

              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditModelNumber}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                  {renderItemModelNumber}
                </Text>
              </EditItemHover>
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.manufacturer')}</Text>

              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditManufacturer}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                  {renderItemManufacturer}
                </Text>
              </EditItemHover>
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.emergencyContact')}</Text>

              <EditItemHover onClick={handleEditEmergencyContact} isDisabled={!isUserCanCRUD}>
                {renderItemResponsible ? (
                  <>
                    {renderItemResponsible?.userAvatar?.url ? (
                      <ImageLazy
                        width={24}
                        height={24}
                        src={renderItemResponsible.userAvatar?.url}
                        sxStyle={{
                          borderRadius: '16px',
                          border: '1px solid #F7F8F9',
                          position: 'relative',
                          marginRight: '8px'
                        }}
                        alt={''}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          flex: '0 0 24px',
                          borderRadius: '50%',
                          background: 'rgb(240, 240, 241)',
                          border: '1px solid #F7F8F9',
                          marginRight: '8px'
                        }}
                      />
                    )}
                    <Text className={'m-0'} variant={'bodyLg'}>
                      {renderItemResponsible?.firstName && renderItemResponsible?.lastName
                        ? `${renderItemResponsible?.firstName} ${renderItemResponsible?.lastName}`
                        : EMPTY_CELL_VALUE}
                    </Text>
                  </>
                ) : (
                  <>
                    <img src={LINKS_IMAGES.emergencyContact} width={24} height={24} alt="" />
                    <Text variant={'bodyLg'} sx={{ margin: '0 0 0 8px' }}>
                      {tTemplates('equipmentItem.overview.emergencyContactNotSet')}
                    </Text>
                  </>
                )}
              </EditItemHover>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
