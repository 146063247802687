export const trimFromArrayOrString = (value: string | string[]) => {
  if (typeof value === 'string') {
    return value.trim();
  } else if (Array.isArray(value) && value.length) {
    return value.map((i) => i.trim());
  }
};

export const parseJwt = <T>(token: string | null): T | null => {
  if (!token) {
    return null;
  }
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
