import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';

import { UnitQuery, UpdateUnitMutation } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { CloseIcon, Preloader, PreloaderVariantsE, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_DOMAIN_NAME } from './helpers';

import { GET_UNIT_BY_ID, SEARCH_TEMPLATES_UNITS, UPDATE_UNIT } from '@/logicLayers/units';

interface FormProps {
  [FIELD_DOMAIN_NAME]: string;
}

interface AddOrEditUnitModalProps {
  domainId?: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const EditUnitModal = ({ domainId, isModalOpen, onCloseModal }: AddOrEditUnitModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const { data: domainData, loading: domainLoading } = useQuery<UnitQuery>(GET_UNIT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: domainId
    }
  });

  const [UpdateUnit] = useMutation<UpdateUnitMutation>(UPDATE_UNIT, {
    refetchQueries: [SEARCH_TEMPLATES_UNITS]
  });

  const domain = domainData?.unit;

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_DOMAIN_NAME]: ''
    }
  });

  useEffect(() => {
    if (domain?.name) {
      setValue(FIELD_DOMAIN_NAME, domain?.name);
    }
  }, [domain?.name]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    if (domain?.name === data[FIELD_DOMAIN_NAME]) {
      handleCloseModal();
      return;
    }

    UpdateUnit({
      variables: { id: domainId, name: data.name },
      onCompleted: ({ updateUnit }) => {
        handleCloseModal();

        if (updateUnit.id) {
          setTimeout(() => {
            AlertService.showAlert({
              title: 'Unit edited successfully',
              severity: 'success'
            });
          }, 500);
        }
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog open={isModalOpen} onClose={onCloseModal}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 24px' }}>
          <Text variant="titleLg" sx={{ fontSize: 20 }}>
            {'Edit unit'}
          </Text>
          <CloseIcon onClick={onCloseModal} />
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box sx={{ padding: '32px 24px 20px 24px' }}>
          <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            {domainLoading ? (
              <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '150px !important' }} isContainer />
            ) : (
              <>
                <Controller
                  name={FIELD_DOMAIN_NAME}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    pattern: {
                      value: ROLE_NAME_REG_EXP,
                      message: tGlobal('validation.textFieldLength')
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <TextFieldControl
                        label={'Unit title'}
                        placeholder={'Enter unit title'}
                        name={FIELD_DOMAIN_NAME}
                        value={value}
                        validation={{
                          isValid: !errors[FIELD_DOMAIN_NAME]?.message,
                          error: errors[FIELD_DOMAIN_NAME]?.message
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    );
                  }}
                />
              </>
            )}
          </form>
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 24px' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={onCloseModal}>
            {tTemplates('domains.table.edit.modal.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_DOMAIN_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tTemplates('domains.table.edit.modal.btnSave')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
