import { Text } from 'infinitytechnologies-ui';

import { Box, Button } from '@mui/material';

import { Flex, PreviewIcon } from '@/separatedModules/components';

interface StepParametersHeaderProps {
  handleOpenRoundForm: () => void;
}

export const StepParametersHeader = ({ handleOpenRoundForm }: StepParametersHeaderProps) => (
  <Flex justifyContent="space-between">
    <Box sx={{ maxWidth: '481px' }}>
      <Text sx={{ fontWeight: 600 }}>Set up the parameters</Text>
      <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
        Specify the validation rules for each parameter and apply additional options like need to upload photo or
        ability to add note for service staff (optional)
      </Text>
    </Box>
    <Button
      variant="outlined"
      startIcon={<PreviewIcon />}
      size="large"
      sx={{
        height: '32px',
        width: '120px',
        '&.MuiButton-outlined.MuiButton-sizeLarge': {
          padding: '0 !important'
        },
        '.MuiButton-startIcon': {
          marginRight: '4px'
        }
      }}
      onClick={handleOpenRoundForm}
    >
      <span style={{ fontSize: '14px' }}>Round form</span>
    </Button>
  </Flex>
);
