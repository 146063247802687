import { MouseEvent, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';

import { DeleteTrashIcon, MoreVertIcon } from '@/separatedModules/components';
import { UploadHeader } from '@/separatedModules/components/Icon/svg-mui-theme/UploadHeader';

interface RowActionMenuProps {
  id: string;
  title: string;
  url: string;
  icon?: React.ReactNode;
  handleOpenDeleteModal: (item: { id: string; title: string; icon: React.ReactNode }) => void;
  isDisabled?: boolean;
}

export const RowActionMenu = ({ id, title, url, handleOpenDeleteModal, icon, isDisabled }: RowActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleOpenModal = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
    handleOpenDeleteModal({
      id,
      title,
      icon
    });
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url;
    const filename = url.split('/').pop() || 'download';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box component={'td'} className={'table-edit-button'}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleDownload}>
          <UploadHeader sx={{ fontSize: 20 }} />
          Download
        </MenuItem>
        {!isDisabled && (
          <MenuItem onClick={handleOpenModal}>
            <DeleteTrashIcon sx={{ fontSize: 20 }} />
            Remove
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
