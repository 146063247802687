import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { Flex } from '@/separatedModules/components';

interface ValidationConditionTypeProps {
  label: string;
  description: string;
  selectedCondition: string;
  handleClick: () => void;
  isShowRoundCustomParams?: boolean;
}

export const ValidationConditionType = ({
  description,
  label,
  handleClick,
  selectedCondition,
  isShowRoundCustomParams
}: ValidationConditionTypeProps) => {
  const isSelected = selectedCondition === label;

  return (
    <Flex
      sx={{
        border: isSelected ? '1px solid #388BFF' : '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
        borderRadius: '8px',
        width: '248px',
        height: '95px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: !isShowRoundCustomParams ? 'pointer' : 'auto',
        background: isSelected ? 'var(--background-selected-default, #F2F7FF)' : 'transparent'
      }}
      onClick={() => !isShowRoundCustomParams && handleClick()}
    >
      <Box sx={{ padding: '0px 20px' }}>
        <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{label}</Text>
        <Text sx={{ fontSize: '14px', fontWeight: '400', color: '#505668', maxWidth: '200px' }}>{description}</Text>
      </Box>
    </Flex>
  );
};
