import { BaseMenuOption } from '@/separatedModules/components';

import { i18n } from '@/i18n';

export enum LocationActionTypeEnum {
  CreateEquipment = 'create-equipment',
  CreateLocation = 'create-location',
  RenameLocation = 'rename-location',
  AddToFavorites = 'add-to-favorites',
  Activate = 'activate',
  Deactivate = 'deactivate',
  Move = 'move',
  Archive = 'archive'
}

interface BaseLocationOption {
  value: LocationActionTypeEnum;
  disabled?: boolean;
}

export type ModifiedBaseMenuOption = Omit<BaseMenuOption, 'value'> & BaseLocationOption;

export interface LocationFolder {
  id: string;
  name: string;
  active: boolean;
  groupItems?: {
    items: LocationFolder[];
  };
}

const getTranslations = (key: string) => {
  return i18n.t(key, { ns: 'locations' });
};

export const locationRootActionsOptions = (): ModifiedBaseMenuOption[] => [
  {
    label: getTranslations('actions.menuList.createLocation'),
    key: '',
    value: LocationActionTypeEnum.CreateLocation,
    disabled: false
  }
];

export const locationActionsOptions = (
  active = false,
  archived = false,
  isEquipmentDisabled = false
): ModifiedBaseMenuOption[] => [
  {
    label: getTranslations('actions.menuList.createEquipment'),
    key: '',
    value: LocationActionTypeEnum.CreateEquipment,
    disabled: !active || isEquipmentDisabled
  },
  {
    label: getTranslations('actions.menuList.createLocation'),
    key: '',
    value: LocationActionTypeEnum.CreateLocation,
    disabled: !active
  },
  // {
  //   label: getTranslations('actions.menuList.addToFavorites'),
  //   key: '',
  //   value: LocationActionTypeEnum.AddToFavorites,
  //   disabled: true
  // },
  {
    label: getTranslations('actions.menuList.rename'),
    key: '',
    value: LocationActionTypeEnum.RenameLocation,
    disabled: !active
  },
  {
    label: getTranslations('actions.menuList.move'),
    key: '',
    value: LocationActionTypeEnum.Move,
    disabled: !active
  },
  {
    label: getTranslations(`actions.menuList.${active ? 'disable' : 'enable'}`),
    key: '',
    value: active ? LocationActionTypeEnum.Deactivate : LocationActionTypeEnum.Activate,
    disabled: archived
  },
  {
    label: getTranslations('actions.menuList.archive'),
    key: '',
    value: LocationActionTypeEnum.Archive,
    disabled: active || archived
  }
];

const MAX_DEPTH = 5;

function getFolderDeep(folderId: string, items: LocationFolder[], currDeep = 1): number {
  for (const item of items) {
    if (item.id === folderId) {
      return currDeep;
    } else if (item.groupItems) {
      const depth = getFolderDeep(folderId, item.groupItems.items, currDeep + 1);

      if (depth) return depth;
    }
  }
  return 0;
}

function findFolderById(folderId: string, items: LocationFolder[] = []): LocationFolder | undefined {
  for (const item of items) {
    if (item.id === folderId) {
      return item;
    }

    if (item.groupItems && item.groupItems.items.length > 0) {
      const foundFolder = findFolderById(folderId, item.groupItems.items);

      if (foundFolder) {
        return foundFolder;
      }
    }
  }

  return undefined;
}

export function canCreateSubfolder(folderId: string, items: LocationFolder[]): boolean {
  const folder = findFolderById(folderId, items);

  if (folder && folder.active && getFolderDeep(folderId, items) < MAX_DEPTH) {
    return true;
  }

  return false;
}
