import Box from '@mui/material/Box';

import { PageTitle } from '@/separatedModules/components';

import { isSuperAdmin } from '@/utils';

import { i18n, useTranslation } from '@/i18n';

import { CreateMetrics, DomainActionTypeEnum } from '../AddMetrics';
import { useContextState } from '../Providers';

export const PageTopBox = () => {
  const { t: tTemplates } = useTranslation('templates');
  const isUserSuperAdmin = isSuperAdmin();

  const state = useContextState();

  const count = state.filters.search.length ? state.resultSearchCount : state.resultTotalCount;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={tTemplates('metrics.pageTitle')}
        dataFoundTitle={!state.loadingDomains ? i18n.t('pageTitleFound', { value: count, ns: 'global' }) : ''}
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
      />

      {isUserSuperAdmin && <CreateMetrics actionType={DomainActionTypeEnum.Create} />}
    </Box>
  );
};
