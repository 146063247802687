import { Autocomplete } from 'infinitytechnologies-ui';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/RoundRegulations/components/Providers';
import { options } from '@/separatedModules/pages/Widgets/components/Calendar/CalendarWidget/helpers';

export const SortByCyclicality = () => {
  const { sortByCyclicality } = useContextUpdateState();
  const {
    CSVFilters: { sortByCyclicality: sortByCyclicalityAction }
  } = useContextState();

  const defaultValue = options.find((item) => item.value === sortByCyclicalityAction) || null;

  return (
    <Autocomplete
      name="Select cyclicality"
      label="Select cyclicality"
      disablePortal={false}
      sx={{ marginRight: '16px' }}
      options={options}
      defaultValue={defaultValue as any}
      getOptionKey={(option: any) => option.value as string}
      onChange={(e, value: any) => {
        if (value) {
          sortByCyclicality(value.value);
        } else {
          sortByCyclicality('');
        }
      }}
    />
  );
};
