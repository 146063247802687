import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  CREATE_EQUIPMENT,
  CREATE_EQUIPMENT_BY_TEMPLATE,
  CreateEquipmentByTemplateMutation,
  CreateEquipmentMutation,
  EquipmentType,
  GET_LOCATION_FOLDER
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Steps } from '@/separatedModules/pages/Templates/pages/EquipmentItemCreate/components';

import {
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  ModalDiscardAllChanges,
  MultiStepFooter,
  MultiStepHeader
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from './ContextProvider';

import { DEFAULT_VALUES, EquipmentItemTypes, FORM_ID, getBreadcrumbs } from './helpers';

export const ContextConsumer = () => {
  const { t: tEquipment } = useTranslation('equipment');
  const { t: tGlobal } = useTranslation('global');
  const { locationId } = useParams();
  const navigateTo = useNavigate();

  const { data: dataLocationFolder } = useQuery(GET_LOCATION_FOLDER, {
    variables: {
      id: locationId
    },
    skip: !locationId
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const breadcrumbsLinks = getBreadcrumbs(dataLocationFolder);

  const { setState, handleSetPrevStep, ...state } = useContextState();

  const [CreateEquipmentItem] = useMutation<CreateEquipmentMutation>(CREATE_EQUIPMENT);
  const [CreateEquipmentByTemplate] = useMutation<CreateEquipmentByTemplateMutation>(CREATE_EQUIPMENT_BY_TEMPLATE);

  const handleSubmitForm = methods.handleSubmit((data) => {
    if (state.currentStep < state.totalSteps) {
      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else if (state.equipmentItemType === EquipmentItemTypes.TEMPLATE) {
      CreateEquipmentByTemplate({
        variables: {
          templateId: state.selected?.id || '',
          locationId: locationId && locationId !== 'root' ? locationId : undefined
        },
        onCompleted: ({ createEquipmentByTemplate }) => {
          if (createEquipmentByTemplate.id) {
            AlertService.showAlert({
              title: tGlobal('alertMessages.success.equipmentItemCreated'),
              severity: 'success'
            });

            setTimeout(() => {
              navigateTo(-1);
            }, 1000);
          }
        },
        onError: () => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.errors.base'),
            severity: 'error'
          });
        }
      });
    } else if (state.equipmentItemType === EquipmentItemTypes.NEW) {
      CreateEquipmentItem({
        variables: {
          equipment: {
            name: data.name || undefined,
            domainId: data.domainId[0]?.value || undefined,
            itemDescription: data.itemDescription || undefined,
            manufacturer: data.manufacturer || undefined,
            modelNumber: data.modelNumber || undefined,
            fileKey: data.fileKey || undefined,
            locationId: locationId && locationId !== 'root' ? locationId : undefined,
            type: EquipmentType.Equipment
          }
        },
        onCompleted: ({ createEquipment: createEquipmentItem }) => {
          if (createEquipmentItem.id) {
            AlertService.showAlert({
              title: tGlobal('alertMessages.success.equipmentItemCreated'),
              severity: 'success'
            });

            setTimeout(() => {
              navigateTo(-1);
            }, 1000);
          }
        },
        onError: () => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.errors.base'),
            severity: 'error'
          });
        }
      });
    }
  });

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} onSubmit={handleSubmitForm}>
            <ModalDiscardAllChanges navigateToUrl={-1 as any} sxBox={{ margin: '0 0 32px' }} />
            <MultiStepHeader
              title={tEquipment('createEquipmentItem.multiStep.title')}
              titleStep={tEquipment('createEquipmentItem.multiStep.titleStep', {
                currentStep: state.currentStep,
                totalSteps: state.totalSteps
              })}
              description={
                state.currentStep === 1 && breadcrumbsLinks?.length ? (
                  <Box>
                    <Text sx={{ marginBottom: '16px' }}>{tEquipment('createEquipmentItem.multiStep.description')}</Text>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '40px',
                        padding: '20px',
                        background: 'var(--background-neutral-hovered, #F7F8F9)',
                        borderRadius: 'var(--4, 4px)',
                        gap: '8px',
                        width: 'fit-content'
                      }}
                    >
                      <BuildingIcon />
                      <Breadcrumbs
                        links={breadcrumbsLinks}
                        separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
                        sx={{ padding: 0 }}
                      />
                    </Box>
                  </Box>
                ) : null
              }
              currentStep={state.currentStep}
              totalStep={state.totalSteps}
              sxDescription={{ maxWidth: 660 }}
            />
            <Steps step={`step${state.currentStep}`} />
            <MultiStepFooter
              btnNextStepText={tGlobal('multiStep.btnNextStep')}
              btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
              btnCreateTemplateText={tEquipment('createEquipmentItem.multiStep.btnCreateTemplate')}
              isLoadingBtnCreate={false}
              isDisabledBtnCreate={state.equipmentItemType === EquipmentItemTypes.TEMPLATE && !state.selected?.id}
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
              handleSetPrevStep={handleSetPrevStep}
            />
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
