import { gql as gqlApollo } from '@apollo/client';

export const CREATE_WIDGET = gqlApollo(`
  mutation CreateWidget($request: CreateDashboardReqInput!) {
    createDashboard(request: $request) {
      id
      name
    }
  }
`);

export const EDIT_WIDGET = gqlApollo(`
  mutation UpdateWidgetReqInput($widgetId: String!, $request: EditWidgetReqInput!) {
    updateWidget(widgetId: $widgetId, request: $request) {
      id
    }
  }
`);

export const DELETE_WIDGET = gqlApollo(`
  mutation DeleteWidget($widgetId: String!) {
    deleteWidget(widgetId: $widgetId)
  }
`);

export const DELETE_DASHBOARD = gqlApollo(`
  mutation DeleteDashboard($id: String!) {
    deleteDashboard(id: $id)
  }
`);

export const GET_DASHBOARDS = gqlApollo`
  query GetDashboards($searchCriteria: SearchCriteriaInput) {
    dashboards(searchCriteria: $searchCriteria) {
      items {
        id
        name
        widgets {
          dashboardId
          id
          resourceIds
          type
        }
      }
      total
    }
  }
`;

export const GET_ACCESS_TYPE_FOR_DASHBOARD = gqlApollo(`
  query AccessTypeForDashboard($id: String!) {
    accessTypeForDashboard(id: $id)
  }
`);

export const GET_ROUND_CALENDARS = gqlApollo`
  query CalendarOfRoundsWidget($widgetId: String!, $fromDate: Date!, $toDate: Date!) {
    calendarOfRoundsWidget(widgetId: $widgetId, fromDate: $fromDate, toDate: $toDate) {
      rounds {
        frequency
        name
        id
      }
      date
    }
 
  }
`;

export const GET_USERS_WITH_DASHBOARD_ACCESS = gqlApollo(`
  query UsersWithDashboardAccess($id: String!) {
    dataItems: usersWithDashboardAccess(id: $id) {
      user {
        id
        firstName
        lastName
        userAvatar {
          url
        }
      }
      accessType
      userId
    }
  }
`);

export const EDIT_DASHBOARD_ACCESS = gqlApollo(`
  mutation EditDashboardAccess($request: ShareDashboardReqInput!) {
    editDashboardAccess(request: $request)
  }
`);

export const GET_ROUNDS_BY_FREQUENCY_WIDGET = gqlApollo(`
  query RoundsByFrequencyWidget($widgetId: String!) {
    roundsByFrequencyWidget(widgetId: $widgetId) {
      frequency
      groupedRounds {
        checkedSummary {
          checked
          total
        }
        description
        name
      }
    }
  }
`);

export const GET_TASKS_BY_EMPLOYEE_WIDGET = gqlApollo(`
  query TasksByEmployeeWidget($widgetId: String!) {
    tasksByEmployeeWidget(widgetId: $widgetId) {
      name
      id
      status
      performer {
        firstName
        lastName
        userAvatar {
          url
        }
      }
    }
  }
`);

export const GET_ROUNDS_BY_EMPLOYEE_WIDGET = gqlApollo(`
  query RoundsByEmployeeWidget($widgetId: String!) {
    roundsByEmployeeWidget(widgetId: $widgetId) {
      name
      id
      status
      assignee {
        firstName
        lastName
        userAvatar {
          url
        }
      }
    }
  }
`);

const LOCATION_ITEM_FRAGMENT = gqlApollo(`
  fragment locationFolderRes on LocationFolderRes {
    id
    name
    active
    parentsLocationFolders {
      depth
      id
      name
    }
  }
`);

export const GET_EQUIPMENT_IN_CRITICAL_CONDITION_WIDGET = gqlApollo`
${LOCATION_ITEM_FRAGMENT}
  query EquipmentInCriticalConditionWidget($widgetId: String!) {
    equipmentsInCriticalConditions(widgetId: $widgetId) {
      equipments {
        id
        name
        itemDescription
        systemName
        location {
          ...locationFolderRes
          groupItems: locationFolders {
            items {
              ...locationFolderRes
              groupItems: locationFolders {
                items {
                  ...locationFolderRes
                  groupItems: locationFolders {
                    items {
                      ...locationFolderRes
                      groupItems: locationFolders {
                        items {
                          ...locationFolderRes
                          groupItems: locationFolders {
                            items {
                              ...locationFolderRes
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      domain {
        id
        color
        name
      }
    }
  }
`;
