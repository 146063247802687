import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { InfoIcon, SearchAutocomplete } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextUpdateState } from '../Providers';

import { SortBy, ToggleColumns } from './components';

import { FiltersContainerWrp } from './style';

export const FiltersContainer = () => {
  const { t: tTemplates } = useTranslation('templates');

  const { handleToggleAside, handleChangeBySearch } = useContextUpdateState();

  const handleClearSearch = () => handleChangeBySearch('');
  const handleChangeSearch = (value: string) => handleChangeBySearch(value || '');

  return (
    <FiltersContainerWrp withSearch>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ToggleColumns />

          <SortBy />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <SearchAutocomplete
            placeholder={tTemplates('role.table.filters.searchBy.placeholder')}
            options={[]}
            sx={{ margin: '0' }}
            onChange={handleChangeSearch}
            onClear={handleClearSearch}
          />

          <IconButton size={'medium'} sx={{ borderRadius: '100%' }} onClick={handleToggleAside}>
            <InfoIcon />
          </IconButton>
        </Box>
      </Box>
    </FiltersContainerWrp>
  );
};
