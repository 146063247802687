import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { EquipmentQuery, GET_EQUIPMENT } from '@/logicLayers/domain';

import {
  Flex,
  handleGetSavedColumns,
  handleSetSavedColumns,
  MuiTablePagination,
  NotFoundScreens,
  SelectsDropdown,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownColumnsVisibility,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { ActionsContainer, AsideContent } from './components';

import { columns, createStatuses, initState, skeletonTableLoaderCells, useTableData } from './helpers';

export const TemplatesEquipmentItemItemsPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tCompanies } = useTranslation('companies');
  const { t: tEquipment } = useTranslation('equipment');

  const navigateTo = useNavigate();

  const { templateId = '' } = useParams();
  const TABLE_SAVED_COLUMNS_ID = `templates/equipment/item/${templateId}/items`;

  const { renderCount, ...tableState } = useTableData(initState, templateId);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: templateId || undefined
    }
  });

  const columnsVisibility = useMemo(() => {
    const columnsVisibilityStatus = table.getAllLeafColumns().reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.id]: currentValue.getIsVisible() };
    }, {});

    handleSetSavedColumns(TABLE_SAVED_COLUMNS_ID, columnsVisibilityStatus);

    return table.getAllLeafColumns().map((column) => {
      return {
        value: column.id,
        label: i18n.t(`equipmentItem.items.table.cells.${column.id}`, { ns: 'templates' }),
        checked: column.getIsVisible(),
        onToggleVisibility: column.toggleVisibility
      };
    });
  }, [columnVisibility]);

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { titleFallback: dataEquipmentTemplate?.equipment?.name }
  ];

  const handleChangeTest = useCallback(
    (Id: string) => {
      navigateTo(`/locations/equipment/${Id}`);
    },
    [navigateTo, templateId]
  );

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item>
        <TablePageTopBox
          title={tTemplates('equipmentItem.items.pageTitle')}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          withBreadcrumbs
          breadcrumbsLinks={breadcrumbsLinks}
          isHideButton={true}
        />

        <TableFiltersBox
          searchPlaceholder={tTemplates('equipmentItem.items.table.filters.searchBy.placeholder')}
          selectedItems={tableState.state.selectedItems}
          handleToggleAside={tableState.handleToggleAside}
          handleChangeBySearch={tableState.handleChangeBySearch}
        >
          <TableDropdownColumnsVisibility
            menu={columnsVisibility}
            toggleAllColumnsVisible={table.toggleAllColumnsVisible}
          />

          <SelectsDropdown
            isRound
            id={'equipment-items-sort-by-status'}
            title={tCompanies('table.filters.sortByStatus')}
            menuList={createStatuses()}
            onChange={tableState.handleChangeSortByStatus}
          />

          <TableSortByOneItem menu={SORT_BY_DATE_CREATE} showIcon={true} onChange={tableState.handleChangeSortByDate} />
        </TableFiltersBox>

        <ActionsContainer
          state={tableState.state}
          handleResetSelectedRows={tableState.handleResetSelectedRows}
          handleToggleAside={tableState.handleToggleAside}
        />

        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {renderCount ? (
              <Flex>
                <Box width={'100%'}>
                  <Box sx={{ overflow: 'auto' }}>
                    <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                      <Table className={'m-0 MuiTable-root__columns-4'}>
                        <TableHead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <TableCell key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>

                        <TableBody>
                          {table.getRowModel().rows.map((row) => {
                            return (
                              <TableRowWrap
                                key={row.original.id}
                                id={row.original.id as string}
                                isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                handleClickRow={tableState.handleClickRow}
                                handleDoubleClickRow={tableState.handleDoubleClickRow}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <TableCell key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                  );
                                })}
                              </TableRowWrap>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <MuiTablePagination
                    count={renderCount}
                    page={tableState.state.page[0]}
                    rowsPerPage={tableState.state.rowsPerPage[0]}
                    rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                    onChangePage={tableState.handleChangePagination}
                    onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                  />
                </Box>

                <TableAside
                  rows={table.getRowModel().rows}
                  selectedItems={tableState.state.selectedItems}
                  isAsideOpen={tableState.state.isAsideOpen}
                  handleToggleAside={tableState.handleToggleAside}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {(props: any) => <AsideContent handleChangeTest={handleChangeTest} {...props} />}
                </TableAside>
              </Flex>
            ) : tableState.state.filters.search[0] ? (
              <NotFoundScreens type={'search'} />
            ) : (
              <NotFoundScreens
                type={'companies'}
                title={tEquipment('notFound.title')}
                subTitle={tEquipment('notFound.subTitle')}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
