import { Key } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Button, SvgIcon } from '@mui/material';

import { getBreadcrumbs } from '@/separatedModules/pages/Locations/components/Aside/components/EquipmentItem/helpers';

import {
  ArrowForwardIcon,
  Breadcrumbs,
  ChevronRightIcon,
  EMPTY_CELL_VALUE,
  Flex,
  SystemStatusChip,
  SystemStatusesEnum,
  TableAsideItem
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

export const RoundRegulationsAsideContent = (props: any) => {
  const navigateTo = useNavigate();
  const formattedText = props?.frequency ? props?.frequency.toLowerCase() : 'one-time round';
  const capitalizedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1).split('_').join(' ');

  const status = props?.active ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled;

  const name = props?.equipment[0].name;
  const templateId = props?.equipment[0].id;
  const breadcrumbsLinks = getBreadcrumbs(props?.equipment[0].location);

  const handleClickDetails = () => {
    const { id } = props;
    if (id) {
      navigateTo(
        LINKS_PAGES.templatesEquipmentItemRoundsRoundRegulation
          .replace(':parameterId', id)
          .replace(':templateId', templateId)
      );
    }
  };

  return (
    <>
      <TableAsideItem subTitle={<SystemStatusChip status={status} />} isShowTitle={false} />

      <TableAsideItem subTitle={props?.description} isShowTitle={false} />

      <TableAsideItem
        title={'Start date & time'}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {props?.activationTargetDate
              ? dayjs(props?.activationTargetDate.activationTargetDate).format('DD MMM YYYY, HH:mm A')
              : EMPTY_CELL_VALUE}
          </Text>
        }
      />
      <TableAsideItem
        title={'Frequency'}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {capitalizedText}
          </Text>
        }
      />

      <TableAsideItem
        title={'Service staff'}
        subTitle={
          <Box>
            {props?.serviceStaff.map(
              (
                service: { userAvatar: { url: string | undefined }; firstName: string; lastName: string },
                index: Key | null | undefined
              ) => {
                const displayName =
                  service?.firstName.length && service?.lastName.length
                    ? `${service.firstName} ${service.lastName}`
                    : EMPTY_CELL_VALUE;

                return (
                  <Flex sx={{ alignItems: 'center', marginTop: '12px' }} key={index}>
                    <>
                      {service?.userAvatar?.url ? (
                        <img
                          width={24}
                          height={24}
                          src={service.userAvatar?.url}
                          style={{
                            borderRadius: '16px',
                            border: '1px solid #F7F8F9',
                            position: 'relative'
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            flex: '0 0 24px',
                            borderRadius: '50%',
                            background: 'rgb(240, 240, 241)',
                            border: '1px solid #F7F8F9'
                          }}
                        />
                      )}
                    </>
                    <Text
                      component={'span'}
                      variant={'bodyMd'}
                      sx={{ margin: '0px', marginLeft: '8px', fontSize: '16px' }}
                    >
                      {`${displayName}`}
                    </Text>
                  </Flex>
                );
              }
            )}
          </Box>
        }
      />

      <TableAsideItem
        title={'Creation date'}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {dayjs(props?.createdDate).format('DD MMM YYYY, HH:mm A')}
          </Text>
        }
      />

      <TableAsideItem
        title={'Equipment & Location'}
        subTitle={
          <Flex alignItems="center">
            <Box sx={{ margin: '0px' }}>
              {name ? (
                <Text
                  className={'m-0'}
                  variant={'inherit'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {name}
                </Text>
              ) : (
                EMPTY_CELL_VALUE
              )}

              <Text className={'m-0'} variant={'bodyLg'}>
                {breadcrumbsLinks.length > 0 ? (
                  <Breadcrumbs
                    links={breadcrumbsLinks}
                    separator={<ChevronRightIcon sx={{ height: '14px', width: '20px' }} />}
                    sx={{ padding: 0, width: '160px' }}
                    fontSize="14px"
                    separatorStyle={{ padding: '0px', width: '20px', paddingTop: '3px' }}
                  />
                ) : (
                  EMPTY_CELL_VALUE
                )}
              </Text>
            </Box>
          </Flex>
        }
      />

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        onClick={handleClickDetails}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
      >
        {'Details'}
      </Button>
    </>
  );
};
