import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { GET_LOCATIONS_SIDEBAR, GetLocationsSidebarQuery } from '@/logicLayers/domain';

import { Flex } from '@/separatedModules/components';

import { useContextState, useContextUpdateState } from '../../../Providers';

import { LocationsAutocomplete } from './components/LocationsAutocomplete';
import { SortByCyclicality } from './components/SortByCyclicality';
import { SortByDomain } from './components/SortByDomain';

import {
  computeEffectiveSelection,
  LocationItem,
  transformLocationsTest,
  updateCheckedState,
  updateParentStates
} from './helpers';

export const Step1 = () => {
  const [selectedItems, setSelectedItems] = useState<LocationItem[]>([]);
  const {
    CSVFilters: { sortByLocations }
  } = useContextState();
  const { handleSortByLocations } = useContextUpdateState();

  const { loading } = useQuery<GetLocationsSidebarQuery>(GET_LOCATIONS_SIDEBAR, {
    variables: {
      searchCriteria: {
        pageable: { page: 0, pageSize: 1000 },
        isArchive: false
      }
    },
    onCompleted: (data) => {
      setSelectedItems(transformLocationsTest(data.locationFolders.items, sortByLocations));
    }
  });

  const selectedPath = useMemo(() => {
    if (sortByLocations.length === 0) return '';
    const firstSelected = sortByLocations[0];

    const findItemByValue = (items: LocationItem[], value: string): LocationItem | null => {
      for (const item of items) {
        if (item.value === value) return item;
        if (item.children.length > 0) {
          const found = findItemByValue(item.children, value);
          if (found) return found;
        }
      }
      return null;
    };

    const selectedItem = findItemByValue(selectedItems, firstSelected);
    return selectedItem ? selectedItem.label : '';
  }, [sortByLocations, selectedItems]);

  const handleToggleLocation = (id: string) => {
    setSelectedItems((prevItems) => {
      const updatedTree = updateCheckedState(prevItems, id);

      const newTree = updateParentStates(updatedTree);
      const effectiveSelection = computeEffectiveSelection(newTree);

      handleSortByLocations(effectiveSelection);
      return newTree;
    });
  };

  return (
    <>
      <Text>
        Specify the round regulation details to export the data in CSV format. Only data related to the selected round
        regulation will be included in the file.
      </Text>
      <Flex
        sx={{
          width: '100%'
        }}
      >
        <Box
          sx={{
            marginTop: '24px',
            width: '100%',
            height: '195px'
          }}
        >
          <LocationsAutocomplete
            selectedItems={selectedItems}
            onToggleLocation={handleToggleLocation}
            sortByLocations={sortByLocations}
            selectedPath={selectedPath}
            loading={loading}
            setSelectedItems={setSelectedItems}
            handleSortByLocations={handleSortByLocations}
          />
          <Flex>
            <SortByCyclicality />
            <SortByDomain />
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
