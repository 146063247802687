import { FC, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';

import { Flex, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from '../../../ContextProvider';
import { EquipmentItemPlate } from '../../EquipmentItemPlate';

import { EQUIPMENT_ITEM_TYPES_LIST } from './helpers';

export const Step1: FC = () => {
  const { t: tEquipment } = useTranslation('equipment');

  const { handleSetEquipmentItemType } = useContextState();

  const [selectedId, setSelectedId] = useState(0);

  const selectEquipmentItemHandled = (id: number, equipmentType: string) => {
    setSelectedId(id);
    handleSetEquipmentItemType(equipmentType);
  };

  useEffect(() => {
    handleSetEquipmentItemType(EQUIPMENT_ITEM_TYPES_LIST[0].equipmentType);
  }, []);

  return (
    <Stack minHeight={'50vh'}>
      <TextBlockControl
        title={tEquipment('createEquipmentItem.step1.title')}
        subTitle={tEquipment('createEquipmentItem.step1.description')}
        sxBox={{ marginTop: '24px' }}
        sxSubTitle={{ marginBottom: 0, maxWidth: 660 }}
      />

      <Flex flexWrap={'wrap'} gap={'24px'}>
        {EQUIPMENT_ITEM_TYPES_LIST.map((equipmentItem: any) => (
          <EquipmentItemPlate
            key={equipmentItem.id}
            equipmentItem={equipmentItem}
            selectedId={selectedId}
            onClick={() => selectEquipmentItemHandled(equipmentItem.id, equipmentItem.equipmentType)}
          />
        ))}
      </Flex>
    </Stack>
  );
};
