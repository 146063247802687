import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Button, Text } from 'infinitytechnologies-ui';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Divider from '@mui/material/Divider';

import {
  ASSIGN_USER_TO_ROUND,
  GET_EQUIPMENT_ROUND_REGULATION,
  GET_ROUND_PARAMETERS,
  ParameterType,
  RoundParametersQuery,
  RoundRegulationQuery,
  TaskStatus,
  UPDATE_PARAMETER_ORDER,
  UPDATE_ROUND_STATUS
} from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { getBreadcrumbs } from '@/separatedModules/pages/Locations/components/PageTopBox/helpers';
import { ParameterItem as FormParameterItem } from '@/separatedModules/pages/Tasks/pages/ExecuteTaskMobile/components/ParameterItem';
import { ParameterItem } from '@/separatedModules/pages/Templates/pages/Equipment/pages/RoundRegulations/pages/Details/components';
import { ActionMenu } from '@/separatedModules/pages/Templates/pages/Equipment/pages/RoundRegulations/pages/Details/components/ActionMenu';

import {
  ArrowBackIcon,
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  Flex,
  ImageLazy,
  LowPriorityIcon,
  MoreVertIcon,
  PreviewIcon,
  ScheduleIcon
} from '@/separatedModules/components';
import { EditIcon, EditItemHover, SystemStatusChip, SystemStatusesEnum } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ActionStatusMenu } from './components/ActionStatusMenu';
import { MobileActions } from './components/MobileActions';
import { ParameterItemMobile } from './components/ParameterItemMobile/ParameterItemMobile';

import { formatDate } from './helpers';

export const TaskDetails = () => {
  const { taskId = '' } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const parameterId = searchParams.get('parameterId');
  const { t: tTemplates } = useTranslation('templates');
  const { t: tTasks } = useTranslation('tasks');
  const { t: tGlobal } = useTranslation('global');
  const { isQRScanned } = location.state || {};
  const navigateTo = useNavigate();

  useEffect(() => {
    if (parameterId) {
      setTimeout(() => {
        const element = document.getElementById(parameterId);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [parameterId]);

  const { profile } = useReduxSelector(selectUserData);

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_TASKS_CREATING', 'ROLE_TASKS_UPDATING']
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const {
    data: dataEquipmentTemplateRoundRegulation,
    loading,
    refetch: refetchRR
  } = useQuery<RoundRegulationQuery>(GET_EQUIPMENT_ROUND_REGULATION, {
    fetchPolicy: 'no-cache',
    variables: {
      roundId: taskId || undefined,
      searchCriteria: {}
    }
  });

  const { data: dataParameters } = useQuery<RoundParametersQuery>(GET_ROUND_PARAMETERS, {
    fetchPolicy: 'no-cache',
    variables: {
      roundId: taskId || undefined,
      ...(isSmallScreen || isTablet
        ? {
            excludeDisabled: true,
            types: ['STRING', 'NUMERIC_INPUT', 'BOOLEAN', 'DATA_TIME']
          }
        : {})
    }
  });

  const [updateParameterOrder] = useMutation(UPDATE_PARAMETER_ORDER, {
    refetchQueries: [GET_EQUIPMENT_ROUND_REGULATION]
  });

  const [updateRoundStatus] = useMutation(UPDATE_ROUND_STATUS);
  const [assignUserToRound] = useMutation(ASSIGN_USER_TO_ROUND);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImageUrl, setDialogImageUrl] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDragDropActive, setIsDragDropActive] = useState(false);
  const [parameters, setParameters] = useState(dataEquipmentTemplateRoundRegulation?.dataItems?.parameters ?? []);

  const methods = useForm({
    mode: 'all'
  });

  const handleOpenDialog = (url: string) => {
    setDialogImageUrl(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogImageUrl('');
  };

  const [mobileActionsOpen, setMobileActionsOpen] = useState(false);

  const handleOpenMobileActions = () => {
    setMobileActionsOpen(true);
  };

  const handleCloseMobileActions = () => {
    setMobileActionsOpen(false);
  };

  const handleStartTask = () => {
    assignUserToRound({
      variables: {
        roundId: taskId,
        userId: profile?.id
      },
      onCompleted: () => {
        updateRoundStatus({
          variables: {
            roundId: taskId,
            status: 'IN_PROGRESS'
          }
        });
      }
    });
  };

  const renderItemName = dataEquipmentTemplateRoundRegulation?.dataItems?.name;
  const renderItemEquipmentName = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0]?.name;
  const renderItemEquipmentImage = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0]?.fileUrl?.url;
  const renderItemActive = Boolean(dataEquipmentTemplateRoundRegulation?.dataItems?.active);
  const renderItemTemplateDescription = dataEquipmentTemplateRoundRegulation?.dataItems?.description;
  const renderItemTemplateStatus = dataEquipmentTemplateRoundRegulation?.dataItems.status;
  const renderItemCreatedDate = dataEquipmentTemplateRoundRegulation?.dataItems?.createdDate;
  const renderItemCompletionDate = dataEquipmentTemplateRoundRegulation?.dataItems?.completionDate;
  const renderItemEquipment = dataEquipmentTemplateRoundRegulation?.dataItems.equipment;
  const renderItemServiceStuff = dataEquipmentTemplateRoundRegulation?.dataItems?.serviceStaff;
  const renderDomainStatusChipName = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0].domain?.name;
  const renderDomainStatusChipColor = dataEquipmentTemplateRoundRegulation?.dataItems?.equipment?.[0].domain?.color;
  const renderItemAssignedBy = dataEquipmentTemplateRoundRegulation?.dataItems?.createdBy;
  const renderItemActivationTargetDate = dataEquipmentTemplateRoundRegulation?.dataItems?.activationTargetDate;
  const renderItemParameters = dataParameters?.dataItems;

  useEffect(() => {
    if (dataEquipmentTemplateRoundRegulation?.dataItems?.parameters) {
      setParameters(dataEquipmentTemplateRoundRegulation.dataItems.parameters);
    }
  }, [dataEquipmentTemplateRoundRegulation?.dataItems?.parameters]);

  const isTaskAccomplished = ['COMPLETED', 'OVERDUE', 'CANCELLED'].includes(renderItemTemplateStatus ?? '');

  const breadcrumbsLinks = [
    {
      link: isTaskAccomplished ? LINKS_PAGES.tasksAccomplished : LINKS_PAGES.tasks,
      titleFallback: isTaskAccomplished
        ? tTemplates('equipmentItem.roundRegulationsDetails.titleFallbackAccomplishedTask')
        : tTemplates('equipmentItem.roundRegulationsDetails.titleFallbackTask')
    },
    { titleFallback: renderItemName }
  ];

  if (loading) return null;

  const handleEditItem = (toPage: string) => {
    const templateId = renderItemEquipment?.[0].id as string;

    const pathname = LINKS_PAGES.editItemRoundsTask
      .replace(':templateId', templateId)
      .replace(':roundRegulationId', taskId);

    navigateTo({
      pathname,
      search: `field=${toPage}`
    });
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const newItems = [...parameters];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setParameters(newItems);
  };

  const handleChangeOrder = () => {
    if (isDragDropActive) {
      updateParameterOrder({
        variables: {
          request: {
            roundId: taskId,
            parameters: parameters.map((item, index) => ({ parameterId: item.id, order: index }))
          }
        },
        onCompleted: () => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.edits'),
            severity: 'success'
          });
        },
        onError: () => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.errors.base'),
            severity: 'error'
          });
        }
      });
      setIsDragDropActive(false);
    } else {
      setIsDragDropActive(true);
    }
  };

  const handleEditTitle = () => handleEditItem('roundTitle');
  const handleEditDescription = () => handleEditItem('roundDescription');
  const handleEditStaff = () => handleEditItem('roundStaff');
  const handleEditParameters = () => handleEditItem('roundParameters');

  const equipmentBreadcrumbs = getBreadcrumbs(renderItemEquipment?.[0].location, false);
  const equipmentBreadcrumbsMobile = getBreadcrumbs(renderItemEquipment?.[0].location, true).map((item) => ({
    titleFallback: item.titleFallback
  }));

  return (
    <>
      {isSmallScreen || isTablet ? (
        <>
          <Box
            sx={{
              overflowY: 'scroll',
              padding: '0px 16px 18px 16px',
              height: isQRScanned ? 'calc(100vh - 137px)' : '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'sticky',
                top: '0px',
                left: '0px',
                background: '#ffffff',
                zIndex: '1',
                paddingTop: '18px',
                paddingBottom: '18px'
              }}
            >
              <Flex alignItems="center">
                <IconButton onClick={() => navigateTo(-1)}>
                  <ArrowBackIcon />
                </IconButton>
                <Text
                  component={'h2'}
                  variant={'titleXl'}
                  sx={{ fontSize: '18px', fontWeight: 600, margin: '0', marginLeft: '8px' }}
                >
                  {renderItemName}
                </Text>
              </Flex>
              {renderItemTemplateStatus === 'PENDING' || renderItemTemplateStatus === 'IN_PROGRESS' ? (
                <IconButton sx={{ padding: '4px' }} onClick={handleOpenMobileActions}>
                  <MoreVertIcon sx={{ width: '32px', height: '32px' }} />
                </IconButton>
              ) : null}
            </Box>
            <Box marginTop="32px">
              <Text component={'h3'} variant={'titleXl'} sx={{ fontSize: '16px', fontWeight: 600, margin: '0' }}>
                {renderItemEquipmentName}
              </Text>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px', paddingBottom: '12px' }}>
                <BuildingIcon sx={{ width: '18px', height: '18px', marginRight: '6px' }} />
                <Breadcrumbs
                  links={equipmentBreadcrumbsMobile}
                  separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
                  sx={{ padding: 0 }}
                  fontSize="12px"
                />
              </Box>
              <DomainStatusChip color={renderDomainStatusChipColor} label={renderDomainStatusChipName} />
              <ImageLazy
                width={'100%'}
                height={172}
                sxStyle={{ borderRadius: '4px', marginTop: '16px', objectFit: 'cover' }}
                src={renderItemEquipmentImage ? renderItemEquipmentImage : ''}
                alt={renderItemEquipmentImage ? renderItemEquipmentImage : ''}
              />
              <Box sx={{ marginTop: '16px' }}>
                <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
                  {tTasks('mobile.status')}
                </Text>
                <TaskStatusChip status={renderItemTemplateStatus as keyof typeof TaskStatus} />
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
                  {tTasks('mobile.assignedBy')}
                </Text>
                <Box>
                  <Box sx={{ display: 'flex', marginBottom: '8px' }}>
                    {renderItemAssignedBy?.userAvatar ? (
                      <ImageLazy
                        src={renderItemAssignedBy.userAvatar?.url}
                        alt=""
                        width={24}
                        height={24}
                        sxStyle={{
                          borderRadius: '50%',
                          border: '1px solid #F7F8F9',
                          position: 'relative',
                          margin: '0 8px 0 0'
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          flex: '0 0 24px',
                          borderRadius: '50%',
                          margin: '0 8px 0 0',
                          background: 'rgba(9, 14, 22, 0.06)',
                          border: '1px solid var(--border-inverse, #F7F8F9)'
                        }}
                      />
                    )}
                    <Text
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-all'
                      }}
                    >
                      {!renderItemAssignedBy?.firstName && !renderItemAssignedBy?.lastName
                        ? EMPTY_CELL_VALUE
                        : `${renderItemAssignedBy?.firstName} ${renderItemAssignedBy?.lastName}`}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Text sx={{ fontSize: '12px', fontWeight: '500', marginBottom: '8px', color: '#505864' }}>
                  {tTasks('mobile.startDate')}
                </Text>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ScheduleIcon sx={{ width: '18px', marginRight: '6px' }} />
                  <Text sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {renderItemActivationTargetDate
                      ? formatDate(renderItemActivationTargetDate ?? '')
                      : EMPTY_CELL_VALUE}
                  </Text>
                </Box>
              </Box>
              <Divider sx={{ margin: '20px 0px' }} />
              <Box>
                <Text component={'h3'} variant={'titleXl'} sx={{ fontSize: '16px', fontWeight: 600, margin: '0' }}>
                  {tTasks('mobile.description')}
                </Text>
                <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '12px' }}>
                  {renderItemTemplateDescription ? renderItemTemplateDescription : EMPTY_CELL_VALUE}
                </Text>
              </Box>
              <Box sx={{ marginTop: '20px' }}>
                {renderItemParameters?.map((item) => {
                  return <ParameterItemMobile item={item} key={item.id} />;
                })}
              </Box>
            </Box>
          </Box>
          {isQRScanned && (
            <Box
              sx={{
                width: '100%',
                position: 'sticky',
                bottom: '0px',
                left: '0px',
                padding: '16px 16px 58px 16px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                borderTop: '1px solid rgba(41, 49, 62, 0.15)',
                background: '#FFFFFF'
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{ width: '100px' }}
                disabled={
                  renderItemTemplateStatus === 'OVERDUE' ||
                  renderItemTemplateStatus === 'COMPLETED' ||
                  renderItemTemplateStatus === 'CANCELLED' ||
                  renderItemTemplateStatus === 'ON_HOLD_REQUESTED'
                }
                onClick={() => {
                  if (renderItemTemplateStatus === 'PENDING') {
                    handleStartTask();
                  }
                  navigateTo(`/tasks/${taskId}/execute`);
                }}
              >
                {tTasks('mobile.startBtn')}
              </Button>
            </Box>
          )}
          <MobileActions
            isOpen={mobileActionsOpen}
            onOpen={handleOpenMobileActions}
            onClose={handleCloseMobileActions}
            taskName={renderItemName ?? ''}
          />
        </>
      ) : (
        <Grid justifyContent={'space-between'} container row>
          <Grid xs={12} sm={10} md={10} item>
            <Box>
              <Breadcrumbs
                links={breadcrumbsLinks}
                separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
                sx={{ paddingTop: 0 }}
              />
              <Text component={'h2'} variant={'titleXl'}>
                {renderItemName}
              </Text>
            </Box>

            <Box
              sx={{
                flex: '0 1 100%',
                marginTop: '32px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {renderItemName ? (
                    <EditItemHover onClick={handleEditTitle} isDisabled={!isUserCanCRUD || isTaskAccomplished}>
                      <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
                        {renderItemName}
                      </Text>
                    </EditItemHover>
                  ) : null}

                  <SystemStatusChip
                    status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled}
                  />
                </Box>
                {isUserCanCRUD && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <Button variant="outlined" startIcon={<PreviewIcon />} onClick={() => setIsDrawerOpen(true)}>
                      {tTemplates('equipmentItem.roundRegulationsDetails.roundFormBtn')}
                    </Button>
                    <ActionMenu
                      data={dataEquipmentTemplateRoundRegulation}
                      isRoundTask
                      renderModalContent={() => {
                        return (
                          <Box
                            sx={{
                              borderRadius: 'var(--4, 4px)',
                              background: 'var(--background-neutral-hovered, #F7F8F9)',
                              margin: '24px 0px',
                              height: '48px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '8px'
                            }}
                          >
                            <Text variant={'labelLg'} m={0}>
                              {renderItemName}
                            </Text>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', marginTop: '8px' }}>
                <EditItemHover onClick={handleEditDescription} isDisabled={!isUserCanCRUD || isTaskAccomplished}>
                  <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                    {renderItemTemplateDescription?.length ? renderItemTemplateDescription : EMPTY_CELL_VALUE}
                  </Text>
                </EditItemHover>
              </Box>
              <Box sx={{ marginTop: '32px' }}>
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.status')}
                </Text>
                <ActionStatusMenu
                  renderItemTemplateStatus={renderItemTemplateStatus || ''}
                  refetchTask={refetchRR as any}
                />
              </Box>
              <Box sx={{ marginTop: '32px' }}>
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.dateAndTime')}
                </Text>
                <Text className={'m-0'} variant={'bodyLg'}>
                  {dayjs(renderItemCreatedDate).format('DD MMM YYYY, HH:mm A')}
                </Text>
              </Box>
              {isTaskAccomplished && (
                <Box sx={{ marginTop: '32px' }}>
                  <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
                    {tTemplates('equipmentItem.roundRegulationsDetails.completionDateAndTime')}
                  </Text>
                  <Text className={'m-0'} variant={'bodyLg'}>
                    {renderItemCompletionDate
                      ? dayjs(renderItemCompletionDate).format('DD MMM YYYY, HH:mm A')
                      : EMPTY_CELL_VALUE}
                  </Text>
                </Box>
              )}
              <Box sx={{ marginTop: '32px' }}>
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.serviceStuff')}
                </Text>
                <EditItemHover onClick={handleEditStaff} isDisabled={!isUserCanCRUD || isTaskAccomplished}>
                  <Box>
                    {renderItemServiceStuff?.map((item, index) => {
                      return (
                        <Box key={index} sx={{ display: 'flex', marginBottom: '8px' }}>
                          {item?.userAvatar ? (
                            <img
                              src={item.userAvatar?.url}
                              alt=""
                              width={24}
                              height={24}
                              style={{
                                borderRadius: '50%',
                                border: '1px solid #F7F8F9',
                                position: 'relative',
                                margin: '0 8px 0 0'
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                width: '24px',
                                height: '24px',
                                flex: '0 0 24px',
                                borderRadius: '50%',
                                margin: '0 8px 0 0',
                                background: 'rgba(9, 14, 22, 0.06)',
                                border: '1px solid var(--border-inverse, #F7F8F9)'
                              }}
                            />
                          )}
                          <Text>
                            {!item?.firstName && !item?.lastName
                              ? EMPTY_CELL_VALUE
                              : `${item?.firstName} ${item?.lastName}`}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>
                </EditItemHover>
              </Box>
              <Box sx={{ marginTop: '32px' }}>
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.equipment')}
                </Text>
                <Flex alignItems="center">
                  {renderItemEquipment?.[0].fileUrl ? (
                    <img
                      src={renderItemEquipment[0].fileUrl.url}
                      width={32}
                      height={32}
                      alt={''}
                      onClick={() => handleOpenDialog(renderItemEquipment[0].fileUrl.url)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '32px',
                        height: '32px',
                        flex: '0 0 32px',
                        margin: '0',
                        background: 'rgba(9, 14, 22, 0.06)',
                        border: '1px solid var(--border-inverse, #F7F8F9)'
                      }}
                    />
                  )}

                  <Box sx={{ margin: '0 0 0 8px' }}>
                    {renderItemEquipment?.[0].name ? (
                      <Text
                        variant={'inherit'}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                          wordBreak: 'break-all',
                          fontSize: '16px',
                          marginBottom: '4px'
                        }}
                      >
                        {renderItemEquipment[0].name}
                      </Text>
                    ) : (
                      EMPTY_CELL_VALUE
                    )}

                    <Text className={'m-0'} variant={'bodyLg'}>
                      {breadcrumbsLinks.length > 0 ? (
                        <Breadcrumbs
                          links={equipmentBreadcrumbs}
                          separator={<ChevronRightIcon sx={{ height: '10px', width: '16px' }} />}
                          sx={{ padding: 0, width: '800px' }}
                          fontSize="14px"
                          separatorStyle={{ padding: '0px', width: '16px' }}
                        />
                      ) : (
                        EMPTY_CELL_VALUE
                      )}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '48px',
                  marginBottom: '24px',
                  alignItems: 'center'
                }}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.parameters')}
                </Text>
                {isUserCanCRUD && !isTaskAccomplished && (
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    {isDragDropActive && (
                      <Button size={'medium'} onClick={() => setIsDragDropActive(false)}>
                        {tTemplates('equipmentItem.roundRegulationsDetails.cancelBtn')}
                      </Button>
                    )}
                    <Button
                      size={'medium'}
                      onClick={handleChangeOrder}
                      startIcon={<LowPriorityIcon sx={{ fontSize: 20 }} />}
                    >
                      {isDragDropActive
                        ? tTemplates('equipmentItem.roundRegulationsDetails.saveOrderBtn')
                        : tTemplates('equipmentItem.roundRegulationsDetails.manageOrderBtn')}
                    </Button>
                    <Button
                      size={'medium'}
                      onClick={handleEditParameters}
                      startIcon={<EditIcon sx={{ fontSize: 20 }} />}
                    >
                      {tTemplates('equipmentItem.roundRegulationsDetails.editBtn')}
                    </Button>
                  </Box>
                )}
              </Box>
              {isDragDropActive ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable-order">
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        {parameters.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id ?? ''} index={index}>
                            {(provided) => (
                              <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <ParameterItem item={item} />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                parameters.map((item) => (
                  <Box key={item.id} id={item.parameterDetails.id ?? ''}>
                    <ParameterItem item={item} isAccomplished={renderItemTemplateStatus === 'COMPLETED'} />
                  </Box>
                ))
              )}
            </Box>
          </Grid>

          <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
            <DialogTitle sx={{ position: 'relative' }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
                sx={{ position: 'absolute', right: '15px', top: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <img src={dialogImageUrl} alt="Enlarged view" style={{ width: '950px', height: '632px' }} />
            </DialogContent>
          </Dialog>
          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} anchor="right">
            <Box
              sx={{
                backgroundColor: '#F5F7FA',
                borderRadius: '4px',
                height: '100vh',
                minHeight: 'fit-content',
                width: '300px'
              }}
            >
              <Box sx={{ padding: '16px' }}>
                <Text sx={{ fontSize: '14px', fontWeight: '600', color: '#121B2A' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.taskDescription')}
                </Text>
                <Text sx={{ fontSize: '14px', fontWeight: '400', color: '#121B2A' }}>
                  {renderItemTemplateDescription?.length ? renderItemTemplateDescription : EMPTY_CELL_VALUE}
                </Text>
              </Box>
              <FormProvider {...methods}>
                <Box sx={{ padding: '20px 16px 20px 16px' }}>
                  {dataParameters?.dataItems
                    ?.filter(
                      (item) =>
                        item.parameterDetails.type !== ParameterType.NumericFixed &&
                        item.parameterDetails.type !== ParameterType.NumericFormula
                    )
                    .map((item, index) => <FormParameterItem key={index} item={item} isPreview />)}
                </Box>
              </FormProvider>
            </Box>
          </Drawer>
        </Grid>
      )}
    </>
  );
};
