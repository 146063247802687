import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import { Box, IconButton } from '@mui/material';

import { GET_TASKS, RoundRegulationsQuery } from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import { ArrowBackIcon, Flex, NotFoundScreens, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { MobileLocationFilter } from '../Home/components/MobileLocationFilter';
import { MobileSort } from '../Home/components/MobileSort';
import { SearchWithFilters } from '../Home/components/SearchWithFilters';
import { MobileTaskItem } from '../Templates/pages/Equipment/pages/EquipmentItem/component/MobileTaskItem';

import { FrequencyEnum } from '../Home/helpers';

export const TasksMobile = () => {
  const navigateTo = useNavigate();
  const { frequency } = useParams<{ frequency?: keyof typeof FrequencyEnum }>();
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { t: tHome } = useTranslation('home');
  const { profile } = useReduxSelector(selectUserData);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortByDate, setSortByDate] = useState<string>('');
  const [isSortDrawerOpen, setIsSortDrawerOpen] = useState(false);
  const [filterByLocation, setFilterByLocation] = useState<string[]>();

  const { data, loading, fetchMore } = useQuery<RoundRegulationsQuery>(GET_TASKS, {
    variables: {
      searchCriteria: {
        query: searchQuery ?? undefined,
        pageable: {
          page: 0,
          pageSize: 20
        },
        sortable: {
          column: sortByDate.length ? 'createdDate' : undefined,
          direction: sortByDate.length ? sortByDate : undefined
        }
      },
      locationIds: filterByLocation?.length ? filterByLocation : undefined,
      type: 'ROUND',
      statuses: ['PENDING', 'IN_PROGRESS', 'ON_HOLD_REQUESTED', 'ON_HOLD'],
      frequencies: frequency,
      serviceStaffIds: [profile?.id]
    },
    fetchPolicy: 'no-cache'
  });

  const handleSortByDate = (value: string) => {
    setSortByDate(value);
  };

  const handleFilterByLocation = (locationIds: string[]) => {
    setFilterByLocation(locationIds);
  };

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPage + 1,
            pageSize: 20
          }
        },
        type: 'ROUND'
      },
      updateQuery: (
        prev: RoundRegulationsQuery,
        { fetchMoreResult }: { fetchMoreResult: RoundRegulationsQuery }
      ): RoundRegulationsQuery => {
        if (!fetchMoreResult) return prev;
        setHasMore((currentPage + 1) * 20 < fetchMoreResult.dataItems.total);
        setCurrentPage(currentPage + 1);

        return {
          dataItems: {
            items: [...prev.dataItems.items, ...fetchMoreResult.dataItems.items],
            total: fetchMoreResult.dataItems.total
          }
        };
      }
    });
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <Box
      sx={{
        padding: '16px'
      }}
    >
      <Flex alignItems="center" marginBottom={'20px'}>
        <IconButton onClick={() => navigateTo(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Text
          component={'h2'}
          variant={'titleXl'}
          sx={{ fontSize: '18px', fontWeight: 600, margin: '0', marginLeft: '8px' }}
        >
          {frequency && FrequencyEnum[frequency] ? FrequencyEnum[frequency] : 'Unknown Frequency'}
        </Text>
      </Flex>
      <SearchWithFilters
        handleOpenFilter={() => setIsFilterDrawerOpen(true)}
        handleOpenSort={() => setIsSortDrawerOpen(true)}
        handleSearch={handleSearch}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto'
        }}
      >
        {loading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer />
        ) : data?.dataItems.items.length ? (
          <Box sx={{ width: '100%', maxWidth: '744px' }}>
            <InfiniteScroll
              dataLength={data?.dataItems.items.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={null}
            >
              <>
                {data?.dataItems?.items?.map((item) => {
                  return <MobileTaskItem item={item} key={item.id} isFromEquipment={false} />;
                })}
              </>
            </InfiniteScroll>
          </Box>
        ) : searchQuery ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '544px'
            }}
          >
            <img src={LINKS_IMAGES.notFoundSearch} alt="No results" />
            <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>{tHome('noResultsTitle')}</Text>
            <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>{tHome('noResultsSubTitle')}</Text>
          </Box>
        ) : (
          <Box sx={{ height: '544px' }}>
            <NotFoundScreens type={'companies'} title={tHome('noTasksYetTitle')} />
          </Box>
        )}
      </Box>
      <MobileLocationFilter
        isOpen={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        onOpen={() => setIsFilterDrawerOpen(true)}
        onChangeFilter={(list) => handleFilterByLocation(list.map((item) => item.id))}
      />
      <MobileSort
        isOpen={isSortDrawerOpen}
        onClose={() => setIsSortDrawerOpen(false)}
        onOpen={() => setIsSortDrawerOpen(true)}
        onChangeSort={(value) => {
          handleSortByDate(value);
        }}
      />
    </Box>
  );
};
