import { MouseEvent, ReactNode, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  EquipmentParameterRes,
  GET_EQUIPMENT_ROUND_REGULATIONS,
  SWITCH_ROUND_ACTIVE_STATE
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { AlertCheckNoticeIcon, DoNotDisturbOnIcon, Flex } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface ActionMenuProps {
  data: EquipmentParameterRes | undefined;
  isRoundTask?: boolean;
  modalTitle?: ReactNode;
  modalContent?: ReactNode;
}

interface RowActionMenuState {
  isModalOpen: boolean;
  modalType: string;
}

export const ActionMenu = ({ data, modalTitle, modalContent }: ActionMenuProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [state, setState] = useState<RowActionMenuState>({
    isModalOpen: false,
    modalType: ''
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [SwitchRoundActiveState] = useMutation<any>(SWITCH_ROUND_ACTIVE_STATE, {
    refetchQueries: [GET_EQUIPMENT_ROUND_REGULATIONS]
  });

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeItemActiveState = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const isActive = data?.active;

    SwitchRoundActiveState({
      variables: {
        request: {
          ids: [data?.id],
          active: !isActive
        }
      }
    });

    handleCloseModal();

    AlertService.showAlert({
      title: !isActive
        ? tGlobal('alertMessages.success.templates.round.edit.isEnabled')
        : tGlobal('alertMessages.success.templates.round.edit.isDisabled'),
      severity: 'success',
      autoHideDuration: 1500
    });
  };

  const handleOpenModal = (modalType: string) => {
    setAnchorEl(null);

    setState({ isModalOpen: true, modalType });
  };

  const handleCloseModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
  };

  return (
    <Box component={'td'}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <KeyboardArrowDownIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '160px',
            minWidth: '160px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenModal('toggleActive');
          }}
          sx={{ background: data?.active ? '#E6F3FF' : 'white' }}
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex>
              <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 20 }} />

              {tGlobal('statuses.enable')}
            </Flex>
            {data?.active && <CheckIcon />}
          </Flex>
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenModal('toggleActive');
          }}
          sx={{ background: !data?.active ? '#E6F3FF' : 'white' }}
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex>
              <DoNotDisturbOnIcon />

              {tGlobal('statuses.disable')}
            </Flex>
            {!data?.active && <CheckIcon sx={{ fontSize: 'medium' }} />}
          </Flex>
        </MenuItem>
      </Menu>

      <Dialog
        headerTitle={modalTitle}
        hideHeaderBorder
        contentFooter={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={'my-0'}
              variant={'text'}
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCloseModal}
            >
              {'No'}
            </Button>

            <Button className={'my-0'} variant={'contained'} onClick={handleChangeItemActiveState}>
              {'Yes'}
            </Button>
          </Box>
        }
        open={state.isModalOpen}
        onClose={handleCloseModal}
      >
        {modalContent ? modalContent : null}
      </Dialog>
    </Box>
  );
};
